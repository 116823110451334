import { isQuote } from '@bas/financial-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type QuotePdfByQuoteIdRequestProps = {
  quoteId: Uuid;
};

export const QuotePdfByQuoteIdRequest = async ({
  quoteId,
  ...params
}: QuotePdfByQuoteIdRequestProps): Promise<AxiosResponse<Blob>> =>
  axios.get(`api/{tenantId}/quotes/${quoteId}/download-pdf`, {
    responseType: 'blob',
    params,
  });

export const useQuotePdfByQuoteIdRequestQuery = (
  request: QuotePdfByQuoteIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {}
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => QuotePdfByQuoteIdRequest(request),
    queryKey: ['quotes', 'pdf', request.quoteId],
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    gcTime: 0,
    staleTime: 0,
  });

export const QuotePdfByQuoteIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isQuote(data)) {
    queryClient.invalidateQueries({
      queryKey: ['quotes', 'pdf', data.quoteId],
    });
  }
};
