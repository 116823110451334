import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  useFilterForm,
  UseFilterFormProps,
  UseFilterFormResult,
} from './useFilterForm';
import {
  usePagination,
  UsePaginationProps,
  UsePaginationResult,
} from './usePagination';

export type UseDatatableResult<TFieldValues extends FieldValues = FieldValues> =
  UsePaginationResult & UseFilterFormResult<TFieldValues>;

export type UseDatatableProps<TFieldValues extends FieldValues = FieldValues> =
  UsePaginationProps & UseFilterFormProps<TFieldValues>;

export const useDatatable = <TFieldValues extends FieldValues = FieldValues>(
  {
    defaultRowsPerPage,
    defaultValues,
    validationSchema,
    dateFields,
  }: UseDatatableProps<TFieldValues> = { dateFields: [] }
): UseDatatableResult<TFieldValues> => {
  const pagination = usePagination({ defaultRowsPerPage });
  const onFilter = useCallback(() => {
    pagination.changePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filters = useFilterForm<TFieldValues>({
    defaultValues,
    validationSchema,
    onFilter,
    dateFields,
  });

  return { ...pagination, ...filters };
};
