import * as Yup from 'yup';
import {
  ConnectIntegrationInputType,
  ConnectIntegrationInputTypeValidationBuilder,
} from '../connectIntegrationInputType';

export interface ConnectKingFinanceIntegrationInputType
  extends ConnectIntegrationInputType {
  environmentCode: string;
}

export const ConnectKingFinanceIntegrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectKingFinanceIntegrationInputType> =>
    Yup.object({
      environmentCode: Yup.string().required().label('label.environmentCode'),
    }).concat(ConnectIntegrationInputTypeValidationBuilder());
