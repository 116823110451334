import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basCrockeryCabinet: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'crockery-cabinet',
  icon: [
    512,
    512,
    [],
    'e001',
    'M88.01 9c-8.285 0-15 6.715-15 15v422.01c0 8.285 6.715 15.001 15 15.001h17l6 42h36.002l6-42h206.005l6 42h36l6.001-42h18c8.285 0 15.001-6.716 15.001-15.001V24c0-8.285-6.716-15-15-15H88.009zm15 422.01V253.004h307.007v178.004H103.01zm86.002-208.006H103.01V39h307.007v184.004H215.013c19.33 0 35-15.67 35-35v-71.002h-96.002v71.002c0 19.33 15.67 35 35.001 35zm-5-35v-41.001h36v41a5 5 0 01-4.999 5h-26a5 5 0 01-5-5z',
  ],
};

export default basCrockeryCabinet;
