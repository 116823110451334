import { UseFormWatch, FieldValues } from 'react-hook-form';
import useDebounce from 'react-use/lib/useDebounce';
import localForage from 'localforage';

export type UsePersistFormProps<
  TFieldValues extends FieldValues = FieldValues
> = {
  watch: UseFormWatch<TFieldValues>;
  persist?: string;
  disableLocalStorage?: boolean;
  onChange?: (values: TFieldValues) => void;
};

export const usePersistForm = <TFieldValues extends FieldValues = FieldValues>({
  watch,
  persist,
  onChange,
  disableLocalStorage = false,
}: UsePersistFormProps<TFieldValues>): void => {
  const values = watch();

  useDebounce(
    () => {
      onChange?.(values);
      if (persist) {
        localForage.setItem(persist, values);
        if (!disableLocalStorage) {
          try {
            window.localStorage.setItem(persist, JSON.stringify(values));
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            // Ignore
          }
        }
      }
    },
    300,
    [persist, values, onChange]
  );
};
