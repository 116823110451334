import { isObjectResponse } from '@bas/value-objects';
import type { DeclarationCategory } from '../types';

export function isDeclarationCategory(
  object: unknown
): object is DeclarationCategory {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'DeclarationCategory'
  );
}
