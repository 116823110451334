import * as Yup from 'yup';

export interface ConnectExternalEmployeeInputType {
  externalId: string;
  employeeId: string;
}

export const ConnectExternalEmployeeInputTypeDefaultValues =
  (): ConnectExternalEmployeeInputType => ({
    externalId: '',
    employeeId: '',
  });

export const ConnectExternalEmployeeInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectExternalEmployeeInputType> =>
    Yup.object({
      externalId: Yup.string().required().label('label.externalId'),
      employeeId: Yup.string().required().label('label.employeeId'),
    });
