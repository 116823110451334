import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basGoKart: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'go-kart',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M330.7 90.4c5.9 5.9 5.9 15.4 0 21.2l-25.5 25.5 50.7 50.7c24.2 1.2 56.9 7.7 84.8 24.2c31.6 18.6 57.3 50.5 55.8 100.3v44.3c0 0 0 .1 0 .1c3.9 2.7 6.5 7.2 6.5 12.4c0 8.3-6.7 15-15 15H441.9c-6.8 24.8-29.5 43-56.4 43s-49.6-18.2-56.4-43H186.9c-6.8 24.8-29.5 43-56.4 43c-27 0-49.7-18.2-56.4-43c-.4 0-.7 0-1.1 0H24c-8.3 0-15-6.7-15-15c0-4.2 1.8-8.1 4.6-10.8c-.1-.6-.1-1.1-.1-1.7V284.3C13 267 18.3 242.8 36 222.7c16.8-19 43.3-32.9 82.3-34.9l-16.7-64c-2.1-8 2.7-16.2 10.7-18.3s16.2 2.7 18.3 10.7l22.4 85.6c4.9 3.9 10.5 8.4 16 12.8c7.4 6 14.7 11.9 20.1 16.4c1.9 1.5 3.5 2.9 4.9 4h81.4l50.5-35-41.7-41.7-25.5 25.5c-5.9 5.9-15.4 5.9-21.2 0s-5.9-15.4 0-21.2l72.1-72.1c5.9-5.9 15.4-5.9 21.2 0zM328.8 354c6.5-25.3 29.4-44 56.7-44s50.2 18.7 56.7 44h24.3V312c0-.2 0-.3 0-.5c1.2-36.9-16.8-59.5-41-73.8c-23.2-13.7-51.7-19.3-72.5-20.1l-64.5 44.7c-2.5 1.7-5.5 2.7-8.5 2.7H188.5c-3.5 0-6.9-1.2-9.6-3.4l-.6-.5c-.4-.3-1-.9-1.8-1.5c-1.6-1.3-3.8-3.1-6.5-5.4c-5.4-4.4-12.7-10.4-20-16.3c-7.3-5.9-14.7-11.8-20.3-16.1c-2.2-1.7-4.1-3.1-5.6-4.2c-35.1 .9-54.7 12.5-65.7 25c-11.9 13.5-15.4 29.9-15 40.9c0 .2 0 .4 0 .5v70H73c.3 0 .5 0 .8 0c6.4-25.3 29.4-44 56.7-44s50.2 18.7 56.7 44H328.8zM121.7 215.8c0 0 0 0 0 0l0 0zM357 368.5a28.5 28.5 0 1 0 57 0 28.5 28.5 0 1 0 -57 0zm-255 0a28.5 28.5 0 1 0 57 0 28.5 28.5 0 1 0 -57 0z',
  ],
};

export default basGoKart;
