import { isObjectResponse } from '@bas/value-objects';
import type { QuoteEvent } from '../types';

export function isQuoteEvent(object: unknown): object is QuoteEvent {
  return (
    isObjectResponse(object) &&
    [
      'SendingQuoteFailedEvent',
      'QuoteOpenedEvent',
      'QuoteFinalizedEvent',
      'QuoteDeclinedEvent',
      'QuoteAcceptedEvent',
      'QuoteSentEvent',
      'QuoteExpiredEvent',
      'QuoteReminderSentEvent',
      'QuoteCanceledEvent',
      'ManuallyAcceptedQuoteEvent',
    ].includes(object['@type']) &&
    Object.prototype.hasOwnProperty.call(object, 'eventType')
  );
}
