import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { isWarehouse, Warehouse } from '@bas/wms-domain/models';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type WarehousesRequestProps = Pagination & Filterable;
type Response = AxiosResponse<Collection<Warehouse>>;

export const WarehousesRequest = async ({
  ...params
}: WarehousesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/warehouses`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useWarehousesRequest = (
  request: WarehousesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => WarehousesRequest(request),
    queryKey: ['warehouses', 'list', request],
  });

export const usePrefetchWarehousesRequest = (
  request: WarehousesRequestProps
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['warehouses', 'list', request],
      queryFn: async () => WarehousesRequest(request),
    });
  }, [queryClient, request]);
};

export const WarehousesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isWarehouse(data)) {
    queryClient.invalidateQueries({ queryKey: ['warehouses', 'list'] });
  }
};
