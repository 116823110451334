export const fontSizes = {
  xs: 10,
  sm: 14,
  base: 16,
  lg: 20,
  xl: 24,
  '2xl': 36,
  '3xl': 48,
  '4xl': 62,
};

export const fontSizesWeb = {
  xs: '0.625rem', // 10px
  sm: '0.875rem', // 14px
  base: '1rem', // 16px
  lg: '1.25rem', // 20px
  xl: '1.5rem', // 24px
  '2xl': '2.25rem', // 36px
  '3xl': '3rem', // 48px
  '4xl': '3.875rem', // 62px
};

export const lineHeights = {
  xs: 13,
  sm: 18,
  base: 22,
  lg: 26,
  xl: 31,
  '2xl': 47,
  '3xl': 62,
  '4xl': 83,
};
