import { WhatsAppSettings } from '@bas/integration-domain/models';
import { Button } from '@bas/ui/web/atoms';
import { ContentSection } from '@bas/ui/web/molecules';
import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { WhatsAppPhoneNumberNameStatusChip } from '../WhatsAppPhoneNumberStatusChip';

export type ConnectedWhatsAppNumbersBlockProps = {
  providerSettings: WhatsAppSettings;
  onConnectExtraNumber: () => void;
};

const ConnectedWhatsAppNumbersBlock = ({
  providerSettings,
  onConnectExtraNumber,
}: ConnectedWhatsAppNumbersBlockProps): ReactElement => (
  <ContentSection actions={[]}>
    <Grid container columnSpacing={3}>
      <Grid item xs={12}>
        <Button onClick={() => onConnectExtraNumber()} size="small">
          <FormattedMessage id="button.connectAExtraNumber" />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.connectedWhatsAppNumbers" />
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        {providerSettings.numbers.map((number) => (
          <Grid item xs={12} container key={number.id}>
            <Grid item xs={6}>
              {number.verified_name}
            </Grid>
            <Grid item xs={3}>
              {number.display_phone_number}
            </Grid>
            <Grid item xs={3}>
              <WhatsAppPhoneNumberNameStatusChip
                phoneNumberStatus={number.name_status}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </ContentSection>
);

export default ConnectedWhatsAppNumbersBlock;
