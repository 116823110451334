export enum StorageEventType {
  StorageCreatedEvent = 'storage_created',
  StorageReservedForCustomerEvent = 'storage_reserved_for_customer',
  StorageEmptiedEvent = 'storage_emptied',
  StorageWasSealedEvent = 'storage_was_sealed',
  StorageSealWasRemovedEvent = 'storage_seal_was_removed',
  StorageWasInvoicedEvent = 'storage_was_invoiced',
  StoragePositionedForPickupEvent = 'storage_positioned_for_pickup',
  StorageWasPickedUpEvent = 'storage_was_picked_up',
  StorageWasReturnedToWarehouseEvent = 'storage_was_returned_to_warehouse',
  StorageMovedToNewLocationEvent = 'storage_moved_to_new_location',
}
