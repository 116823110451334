import { isTimeType, TimeType } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeTypesRequestProps = Pagination & Filterable;

export const TimeTypesRequest = async ({
  ...params
}: TimeTypesRequestProps): Promise<AxiosResponse<Collection<TimeType>>> =>
  axios.get(`api/{tenantId}/hrm/time-types`, {
    params: { ...params },
  });

export const useTimeTypesRequest = (
  request: TimeTypesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<TimeType>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeType>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<TimeType>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<TimeType>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeType>>
  >({
    ...options,
    queryFn: async () => TimeTypesRequest(request),
    queryKey: ['time-types', 'list', request],
  });

export const TimeTypesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTimeType(data)) {
    queryClient.invalidateQueries({ queryKey: ['time-types', 'list'] });
  }
};
