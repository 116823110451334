import { EmailAddress } from '@bas/value-objects';
import * as Yup from 'yup';

export interface ResendEmailInputType {
  receivers: EmailAddress[];
}

export const ResendEmailInputTypeDefaultValues = (): ResendEmailInputType => ({
  receivers: [],
});

export const ResendEmailInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ResendEmailInputType> =>
    Yup.object({
      receivers: Yup.array()
        .of(Yup.string().email().required())
        .min(1)
        .required(),
    });
