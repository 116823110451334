import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { isStorage, Storage } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StoragesByProjectIdRequestProps = Pagination &
  Filterable & {
    projectId: Uuid;
  };

type Response = AxiosResponse<Collection<Storage>>;

export const StoragesByProjectIdRequest = async ({
  projectId,
  ...params
}: StoragesByProjectIdRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/storages', {
    params: { ...params, storedForProject: projectId },
  });

export const useStoragesByProjectIdRequest = (
  request: StoragesByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      StoragesByProjectIdRequest({ perPage: 100, ...request }),
    queryKey: ['storages', 'list', request.projectId],
  });

export const StoragesByProjectIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isStorage(data)) {
    queryClient.invalidateQueries({
      queryKey: ['storages', 'list', data.storedForProjectId],
    });
  }
};
