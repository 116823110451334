import {
  InventoryEventsByProjectIdRequestInvalidator,
  InventoryItemsStockRequestInvalidator,
  MovingBoxByMovingBoxIdRequestInvalidator,
  MovingBoxesRequestInvalidator,
  OpenOrdersRequestInvalidator,
  OutstandingAndUsedStockByProjectIdRequestInvalidator,
  OutstandingStockByCustomerIdRequestInvalidator,
  OutstandingStockByProjectIdRequestInvalidator,
  OutstandingStockRequestInvalidator,
  PublicMovingBoxesTypeRequestInvalidator,
  ReusableMaterialByReusableMaterialIdRequestQueryInvalidator,
  ReusableMaterialsRequestInvalidator,
  StorageOutstandingStockByProjectIdRequestInvalidator,
  SuppliersRequestInvalidator,
} from './lib/inventory';
import {
  HandlingCostsByStorageIdInvalidator,
  StorageByStorageIdRequestInvalidator,
  StorageLocationByStorageLocationIdRequestInvalidator,
  StorageLocationsRequestInvalidator,
  StoragesByProjectIdRequestInvalidator,
  StoragesRequestInvalidator,
  StoredItemsRequestInvalidator,
  WarehouseByWarehouseIdRequestInvalidator,
  WarehousesRequestInvalidator,
} from './lib/storage';

export const queryInvalidators = [
  InventoryEventsByProjectIdRequestInvalidator,
  InventoryItemsStockRequestInvalidator,
  MovingBoxByMovingBoxIdRequestInvalidator,
  MovingBoxesRequestInvalidator,
  OpenOrdersRequestInvalidator,
  OutstandingAndUsedStockByProjectIdRequestInvalidator,
  OutstandingStockByCustomerIdRequestInvalidator,
  OutstandingStockByProjectIdRequestInvalidator,
  OutstandingStockRequestInvalidator,
  PublicMovingBoxesTypeRequestInvalidator,
  ReusableMaterialByReusableMaterialIdRequestQueryInvalidator,
  ReusableMaterialsRequestInvalidator,
  StorageOutstandingStockByProjectIdRequestInvalidator,
  SuppliersRequestInvalidator,
  HandlingCostsByStorageIdInvalidator,
  StorageByStorageIdRequestInvalidator,
  StorageLocationByStorageLocationIdRequestInvalidator,
  StorageLocationsRequestInvalidator,
  StoragesByProjectIdRequestInvalidator,
  StoragesRequestInvalidator,
  StoredItemsRequestInvalidator,
  WarehouseByWarehouseIdRequestInvalidator,
  WarehousesRequestInvalidator,
];
