import { BillingMomentType } from '@bas/shared/models';
import { CreateManualPackageInputType } from '@bas/tenant-domain/input-types';
import { BillingMomentsStepper } from '@bas/ui/web/molecules';
import { Grid, Slide } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SwitchTransition } from 'react-transition-group';
import { BillingMomentForm } from '../BillingMomentForm';
import { QuoteMomentForm } from '../QuoteMomentForm';

const ManualBillingMomentsForm = ({
  useIdentities,
}: {
  useIdentities: boolean;
}): ReactElement => {
  const slideRef = useRef<HTMLDivElement>(null);
  const [activeBillingMomentType, setActiveBillingMomentType] = useState<
    BillingMomentType | 'quote'
  >();

  const billingMoments = useWatch<
    CreateManualPackageInputType,
    'billingMoments'
  >({
    name: 'billingMoments',
  });

  const quote = useWatch<CreateManualPackageInputType, 'quote'>({
    name: 'quote',
  });

  const activeBillingMomentIndex: number | undefined = useMemo(() => {
    if (!activeBillingMomentType) {
      return undefined;
    }

    return billingMoments.findIndex(
      ({ billingMomentType }) => billingMomentType === activeBillingMomentType
    );
  }, [billingMoments, activeBillingMomentType]);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const [notifiedSubmitCount, setNotifiedSubmitCount] = useState(0);
  const { submitCount, errors } = useFormState();
  useEffect(() => {
    if (notifiedSubmitCount < submitCount) {
      let error = '';
      if (errors.quote) {
        error = formatMessage({
          id: 'label.packageQuoteError',
        });
      } else if (errors.billingMoments) {
        error = formatMessage({
          id: 'label.packageBillingMomentsError',
        });
      }
      enqueueSnackbar(
        `${formatMessage({
          id: 'label.somethingWentWrongDuringSaving',
        })}: ${error}`,
        {
          variant: 'warning',
        }
      );
      setNotifiedSubmitCount(submitCount);
    }
  }, [
    errors,
    notifiedSubmitCount,
    submitCount,
    enqueueSnackbar,
    formatMessage,
  ]);

  return (
    <Grid container rowSpacing={6}>
      <Grid item xs={12}>
        <BillingMomentsStepper
          quoteMoment={quote}
          activeBillingMomentType={activeBillingMomentType}
          onChangeActiveBillingMomentType={(val) =>
            setActiveBillingMomentType((curr) =>
              curr === val ? undefined : val
            )
          }
          usedBillingMomentTypes={billingMoments
            .filter(({ useBillingMoment }) => useBillingMoment)
            .map(({ billingMomentType }) => billingMomentType)}
          billedBillingMomentTypes={[]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        ref={slideRef}
        sx={{
          overflow: 'hidden',
          marginLeft: (theme) => `-${theme.spacing(3)}`,
          marginRight: (theme) => `-${theme.spacing(3)}`,
          paddingLeft: (theme) => theme.spacing(3),
          paddingRight: (theme) => theme.spacing(3),
        }}
      >
        <SwitchTransition mode="out-in">
          <Slide
            key={activeBillingMomentType || 'none'}
            timeout={175}
            direction="down"
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
          >
            <Grid container rowSpacing={3} justifyContent="center">
              <Grid
                item
                sx={{ maxWidth: 800, width: '100%', boxSizing: 'content-box' }}
              >
                {activeBillingMomentType === 'quote' && (
                  <QuoteMomentForm
                    useIdentities={useIdentities}
                    forceQuoteMoment
                  />
                )}
                {activeBillingMomentIndex !== undefined &&
                  activeBillingMomentIndex >= 0 && (
                    <BillingMomentForm
                      prefix={`billingMoments.${activeBillingMomentIndex}`}
                      useIdentities={useIdentities}
                      forceBillingMoment
                      hideTask
                    />
                  )}
              </Grid>
            </Grid>
          </Slide>
        </SwitchTransition>
      </Grid>
    </Grid>
  );
};

ManualBillingMomentsForm.defaultProps = {
  hideQuote: false,
};

export default ManualBillingMomentsForm;
