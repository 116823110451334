import { User } from '@bas/authentication-domain/models';
import { Uuid } from '@bas/value-objects';
import axios, { AxiosResponse } from 'axios';

export type UserByUserIdRequestProps = {
  userId: Uuid;
  token?: string;
};

export const UserByUserIdRequest = async ({
  userId,
  token,
}: UserByUserIdRequestProps): Promise<AxiosResponse<User>> =>
  axios.get(
    `api/users/${userId}`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : undefined
  );
