import { ReactElement } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { Checkbox, CheckboxProps } from '../Checkbox';

export interface ReactHookFormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<CheckboxProps, 'name' | 'value' | 'error' | 'onChange'> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

const fieldToCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  disabled,
  helperText,
  field: { onChange, onBlur, value },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  ...props
}: ReactHookFormCheckboxProps<TFieldValues, TName>): CheckboxProps => {
  const fieldError = error?.message;
  const showError = invalid;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    value,
    checked: value,
    ...props,
    onBlur,
    onChange,
  };
};

const ReactHookFormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...args
}: ReactHookFormCheckboxProps<TFieldValues, TName>): ReactElement => (
  <Checkbox {...fieldToCheckbox(args)} />
);

export default ReactHookFormCheckbox;
