import {
  TaskByTaskIdRequestInvalidator,
  TaskCommentsByTaskIdRequestInvalidator,
  TaskPrioritiesRequestInvalidator,
  TaskSettingsByTaskSettingsIdsRequestInvalidator,
  TasksRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  TaskByTaskIdRequestInvalidator,
  TaskCommentsByTaskIdRequestInvalidator,
  TaskPrioritiesRequestInvalidator,
  TaskSettingsByTaskSettingsIdsRequestInvalidator,
  TasksRequestInvalidator,
];
