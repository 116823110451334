import { useMarkSessionAsActiveMutation } from '@bas/authentication-domain/mutations';
import { useSessionBySessionIdRequestQuery } from '@bas/authentication-domain/requests';
import { useAppStore, useAuthStore, useTenantStore } from '@bas/shared/state';
import { ConfirmDialog } from '@bas/ui/web/organisms';
import { Typography } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const ActiveSessionListener = ({
  children,
}: {
  children: ReactNode;
}): ReactElement | null => {
  const { mutateAsync: markSessionAsActive } = useMarkSessionAsActiveMutation();
  const navigate = useNavigate();
  const authState = useAuthStore();
  const tenantState = useTenantStore((state) => state.internalTenant);
  const { sessionBlocked, unblockSession, blockSession } = useAppStore();
  const enableFeature = useFlag('block-multiple-session');

  const { data: sessionData } = useSessionBySessionIdRequestQuery(
    {
      sessionId: authState.sessionId || '',
      userId: authState.userId || '',
    },
    {
      enabled: !!authState.sessionId && !!authState.userId && enableFeature,
    }
  );

  const handleMarkSessionAsActive = useCallback(async () => {
    if (
      !authState.sessionId ||
      !tenantState ||
      tenantState.allowUnlimitedDevices ||
      !enableFeature
    ) {
      return;
    }

    await markSessionAsActive({
      sessionId: authState.sessionId,
    });
    unblockSession();
  }, [
    authState.sessionId,
    tenantState,
    enableFeature,
    markSessionAsActive,
    unblockSession,
  ]);

  const session = sessionData?.data;

  useEffect(() => {
    handleMarkSessionAsActive();
  }, [handleMarkSessionAsActive]);

  useEffect(() => {
    if (
      session &&
      tenantState &&
      !tenantState.allowUnlimitedDevices &&
      enableFeature
    ) {
      if (session.activeSession) {
        unblockSession();
      } else {
        blockSession();
      }
    }
  }, [blockSession, enableFeature, session, tenantState, unblockSession]);

  if (
    tenantState?.allowUnlimitedDevices ||
    !session ||
    !tenantState ||
    !enableFeature
  ) {
    return <span>{children}</span>;
  }

  return (
    <>
      <ConfirmDialog
        open={sessionBlocked}
        onCancel={() => navigate('logout')}
        onConfirm={handleMarkSessionAsActive}
        title={<FormattedMessage id="label.sessionDisconnected" />}
        content={
          <Typography whiteSpace="pre-wrap" sx={{ overflowWrap: 'break-word' }}>
            <FormattedMessage id="label.somebodyLoggedIntoTheSameAccountOnADifferentDevice" />
          </Typography>
        }
      />

      {children}
    </>
  );
};

export default ActiveSessionListener;
