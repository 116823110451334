import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  InventoryStorageOutstandingStock,
  isInventoryEvent,
} from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StorageOutstandingStockByProjectIdRequestProps = Pagination & {
  projectId: Uuid;
};

export const StorageOutstandingStockByProjectIdRequest = async ({
  projectId,
  perPage,
  page,
  ...params
}: StorageOutstandingStockByProjectIdRequestProps): Promise<
  AxiosResponse<Collection<InventoryStorageOutstandingStock>>
> =>
  axios.get(`api/{tenantId}/inventory/item/storage-outstanding/${projectId}`, {
    params: { ...params, perPage: perPage || 100, page: page || 1 },
  });

export const useStorageOutstandingStockByProjectIdRequest = (
  request: StorageOutstandingStockByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<InventoryStorageOutstandingStock>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<InventoryStorageOutstandingStock>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<InventoryStorageOutstandingStock>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<InventoryStorageOutstandingStock>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<InventoryStorageOutstandingStock>>
  >({
    ...options,
    queryFn: async () => StorageOutstandingStockByProjectIdRequest(request),
    queryKey: ['moving-boxes', 'outstanding', request.projectId],
  });

export const StorageOutstandingStockByProjectIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isInventoryEvent(data) && data.projectId) {
      queryClient.invalidateQueries({
        queryKey: ['moving-boxes', 'outstanding', data.projectId],
      });
    }
  };
