export const halfHourHeightPerMaxScreenHeight: {
  [key: number | string]: number;
} = {
  1180: 32,
  1272: 34,
  1334: 38,
  1500: 40,
  1600: 45,
  1871: 51,
  default: 61,
};
