import { User } from '@bas/authentication-domain/models';
import { EmployeeRole } from '@bas/hrm-domain/models';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

type UserStoreType = {
  user?: User | undefined;
  setUser: (user: User) => void;
  logout: () => void;
  getTenantRoles: (tenantId: string) => EmployeeRole[] | undefined;
};

export const useUserStore = create<UserStoreType>()(
  persist(
    (set, get) => ({
      setUser: (user) => set({ user }),
      logout: () => set({ user: undefined }),
      getTenantRoles: (tenantId) => {
        const tenantRights = (get().user?.accessToTenants || []).find(
          (access) => access.tenantId === tenantId
        );

        return tenantRights?.roles;
      },
    }),
    {
      name: 'user-store',
      storage: zustandStorage<UserStoreType>(),
    }
  )
);
