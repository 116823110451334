import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basSideboard: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'sideboard',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M9 153c0-8.3 6.7-15 15-15H488c8.3 0 15 6.7 15 15V364c0 8.3-6.7 15-15 15H406.9L401 420H365l-5.9-41H152.9L147 420H111l-5.9-41H24c-8.3 0-15-6.7-15-15V153zm30 15V349H157l0-181H39zm148 0l0 181H325V168H187zM473 349V277l-118 0v72H473zm0-102V168H355v79l118 0z',
  ],
};

export default basSideboard;
