import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basChildrenBicycle: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'childerens-bicycle',
  icon: [
    797,
    512,
    [],
    'e001',
    'M609.623 152.519c-20.86-.338-40.772 3.559-58.946 10.874L456.886 12.085a23.682 23.682 0 00-8.602-8.208A23.631 23.631 0 00436.785.89h-80.439c-7.839 0-14.193 6.364-14.193 14.213v18.951c0 7.85 6.354 14.214 14.193 14.214h67.277l38.18 61.59H304.3v-18.95c0-7.85-6.355-14.214-14.194-14.214h-113.56c-13.065 0-23.657 10.606-23.657 23.69 0 13.082 10.592 23.688 23.658 23.688h72.379l-30.343 43.403c-19.87-9.597-42.151-14.975-65.688-14.976C69.345 152.496.952 221.429 1.48 305.084c.524 83.281 68.111 150.632 151.408 150.632 68.835 0 126.931-45.998 145.33-108.969h39.203c3.804 0 7.551-.92 10.925-2.677a23.7 23.7 0 008.457-7.426l130.164-186.19 23.112 37.285c-33.282 27.857-54.439 69.755-54.369 116.603.124 81.585 68.5 150.61 149.975 151.368 84.698.785 153.526-68.067 152.843-152.85-.658-81.907-67.114-149.017-148.905-150.341zM152.888 408.337c-57.398 0-104.096-46.757-104.096-104.23 0-57.473 46.698-104.232 104.096-104.232a103.449 103.449 0 0138.01 7.197l-71.587 102.4c-10.969 15.695.286 37.275 19.382 37.275h109.176c-16.308 36.273-52.746 61.59-94.981 61.59zm31.244-108.967l45.709-65.382c15.86 17.436 25.903 40.266 27.031 65.382h-72.74zm140.97 0H304.22c-1.265-41.225-18.957-78.307-46.753-104.9l26.03-37.234h140.971L325.103 299.37zm277.135 108.855c-53.838-2.478-97.253-46.451-99.144-100.384-1.092-31.152 11.55-59.432 32.326-79.224l56.593 91.296c4.136 6.67 12.888 8.72 19.547 4.581l16.08-9.995c6.66-4.14 8.709-12.903 4.575-19.572l-56.011-90.356c10.659-3.328 22.05-4.982 33.862-4.654 54.173 1.506 98.552 45.092 101.04 99.301 2.815 61.325-47.621 111.827-108.868 109.007z',
  ],
};

export default basChildrenBicycle;
