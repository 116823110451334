import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basClothesRack: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'clothes-rack',
  icon: [
    487,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M230.6 122.4c0-6.9 5.6-12.4 12.4-12.4s12.4 5.6 12.4 12.4s-5.6 12.4-12.4 12.4c-8.3 0-15 6.7-15 15v26.7L120.5 233.8c-6.1 3.2-9.2 10.2-7.5 16.9s7.7 11.4 14.6 11.4h231c6.9 0 12.9-4.7 14.6-11.4s-1.4-13.6-7.5-16.9L258 176.6V162.1c16-6.1 27.4-21.5 27.4-39.7C285.4 99 266.5 80 243 80s-42.4 19-42.4 42.4c0 8.3 6.7 15 15 15s15-6.7 15-15zM187.7 232L243 202.6 298.4 232H187.7zM32.5 5c-8.3 0-15 6.7-15 15s6.7 15 15 15h17l0 293h-17c-8.3 0-15 6.7-15 15s6.7 15 15 15h17v35.9c-24.2 6.4-42 28.4-42 54.6C7.5 479.7 32.8 505 64 505s56.5-25.3 56.5-56.5c0-25.8-17.3-47.6-41-54.3V358h330v35.9c-24.2 6.4-42 28.4-42 54.6c0 31.2 25.3 56.5 56.5 56.5s56.5-25.3 56.5-56.5c0-25.8-17.3-47.6-41-54.3V358h25c8.3 0 15-6.7 15-15s-6.7-15-15-15h-25V35h25c8.3 0 15-6.7 15-15s-6.7-15-15-15H32.5zm377 30V328H79.5l0-293h330zM64 475a26.5 26.5 0 1 1 0-53 26.5 26.5 0 1 1 0 53zm360 0a26.5 26.5 0 1 1 0-53 26.5 26.5 0 1 1 0 53z',
  ],
};

export default basClothesRack;
