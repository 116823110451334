import { isQuoteEvent } from './QuoteEvent';
import { QuoteDeclinedEvent, QuoteEventType } from '../types';

export function isQuoteDeclinedEvent(
  object: unknown
): object is QuoteDeclinedEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.QuoteDeclinedEvent
  );
}
