import { isObjectResponse } from '@bas/value-objects';
import type { InvoiceEvent } from '../types';

export function isInvoiceEvent(object: unknown): object is InvoiceEvent {
  return (
    isObjectResponse(object) &&
    [
      'SendingInvoiceFailedEvent',
      'InvoiceExpiredEvent',
      'InvoiceFinalizedEvent',
      'InvoicePaidEvent',
      'InvoicePaymentProcessedEvent',
      'InvoiceReminderSentEvent',
      'InvoiceScheduledEvent',
      'InvoiceSentEvent',
      'InvoicePaymentRemovedEvent',
      'InvoiceWasNotPaidEvent',
    ].includes(object['@type']) &&
    Object.prototype.hasOwnProperty.call(object, 'eventType')
  );
}
