import { colors } from '@bas/theme';
import { WorkingDaySlider } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { WorkingDay } from '@bas/value-objects';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Box, BoxProps, Button, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { ReactElement } from 'react';
import { FormattedDate } from 'react-intl';

dayjs.extend(weekday);

export type EditableWorkingDayScheduleProps = BoxProps & {
  workingDay: WorkingDay;
  dayIndex: number;
  onToggleDay: () => void;
  onChange: (newWorkingDay: WorkingDay) => void;
  onChangeCommitted: (newWorkingDay: WorkingDay) => void;
};

const timeToValueMapper: { [key: string]: number } = {
  '20:00:00': 0,
  '19:30:00': 5,
  '19:00:00': 10,
  '18:30:00': 15,
  '18:00:00': 20,
  '17:30:00': 25,
  '17:00:00': 30,
  '16:30:00': 35,
  '16:00:00': 40,
  '15:30:00': 45,
  '15:00:00': 50,
  '14:30:00': 55,
  '14:00:00': 60,
  '13:30:00': 65,
  '13:00:00': 70,
  '12:30:00': 75,
  '12:00:00': 80,
  '11:30:00': 85,
  '11:00:00': 90,
  '10:30:00': 95,
  '10:00:00': 100,
  '09:30:00': 105,
  '09:00:00': 110,
  '08:30:00': 115,
  '08:00:00': 120,
  '07:30:00': 125,
  '07:00:00': 130,
  '06:30:00': 135,
  '06:00:00': 140,
};

const getValueFromTimes = (startTime?: string, endTime?: string): number[] => {
  if (!startTime || !endTime) {
    return [0, 0];
  }

  return [timeToValueMapper[startTime] || 0, timeToValueMapper[endTime] || 0];
};

const valueKeys = Object.keys(timeToValueMapper);

const getTimeFromValue = (value: number): string | undefined =>
  valueKeys.find((key) => timeToValueMapper[key] === value);

const getTimesFromValues = (values: number[] | number): WorkingDay => {
  if (!Array.isArray(values) || values.length !== 2) {
    return {
      startTime: undefined,
      endTime: undefined,
    };
  }
  let [startValue, endValue] = values;
  if (startValue < values[1]) {
    [endValue, startValue] = values;
  }

  return {
    startTime: getTimeFromValue(startValue),
    endTime: getTimeFromValue(endValue),
  };
};

const EditableWorkingDaySchedule = ({
  className,
  onToggleDay,
  onChange,
  onChangeCommitted,
  dayIndex,
  workingDay,
  ...args
}: EditableWorkingDayScheduleProps): ReactElement => (
  <Box
    className={clsx(
      className,
      {
        'Bas-EditableWorkingDaySchedule-IsWorking':
          workingDay.startTime && workingDay.endTime,
      },
      {
        'Bas-EditableWorkingDaySchedule-IsNotWorking':
          !workingDay.startTime || !workingDay.endTime,
      }
    )}
    {...args}
  >
    <Button
      className="Bas-EditableWorkingDaySchedule-ToggleDay"
      onClick={onToggleDay}
      variant="text"
    >
      <Icon icon={faCheck} />
      <Typography fontWeight="bold">
        <FormattedDate
          value={dayjs().weekday(dayIndex).toDate()}
          weekday="short"
        />
      </Typography>
    </Button>
    <Box className="Bas-EditableWorkingDaySchedule-Slider">
      <WorkingDaySlider
        value={getValueFromTimes(workingDay.startTime, workingDay.endTime)}
        disabled={!workingDay.startTime || !workingDay.endTime}
        onChange={(event, newValues) => onChange(getTimesFromValues(newValues))}
        onChangeCommitted={(event, newValues) =>
          onChangeCommitted(getTimesFromValues(newValues))
        }
      />
    </Box>
  </Box>
);

const StyledEditableWorkingDaySchedule = styled(EditableWorkingDaySchedule)(
  ({ theme }) => `
  width: 68px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Bas-EditableWorkingDaySchedule-Slider {
    width: 100%;
    flex: 1;
    padding-top: 14px;
    padding-bottom: 8px;
    box-sizing: border-box;
  }

  .MuiButton-root {
    height: 72px;
    width: 100%;
    background: transparent;
    border-radius: 3px;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease, color 0.3s ease;
    color: ${colors.lila[800]};

    svg {
      font-size: 18px;
      padding-bottom: 7px;
      transition: opacity 0.3s, color 0.3s ease;
    }
  }

  &.Bas-EditableWorkingDaySchedule-IsWorking, &.Bas-EditableWorkingDaySchedule-IsNotWorking {
    .MuiButton-root {
      &:hover {
        background: ${colors.lila[200]};
        svg {
           color: ${colors.lila[800]};
           opacity: 1;
        }
      }
    }
  }

  &.Bas-EditableWorkingDaySchedule-IsWorking {
    .MuiButton-root {
      svg {
        color: ${colors.blue[500]};
        opacity: 1;
      }
    }
  }

  &.Bas-EditableWorkingDaySchedule-IsNotWorking {
    .MuiButton-root {
      color: ${colors.lila[400]};
      svg {
        opacity: 0;
      }
    }
  }
`
);
export default StyledEditableWorkingDaySchedule;
