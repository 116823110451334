import { colors, fontSizesWeb } from '@bas/theme';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';

export type TextFieldProps = MuiTextFieldProps & {
  light?: boolean;
  mobile?: boolean;
};

const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,react/prop-types
      light,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,react/prop-types
      mobile,
      ...props
    }: TextFieldProps,
    ref
  ) => <MuiTextField {...props} ref={ref} />
);

TextField.defaultProps = {
  variant: 'standard',
  light: false,
  mobile: false,
};

const StyledTextField = styled(TextField)(({ theme, light, mobile }) => {
  const mainColor = colors.lila[light ? 100 : 800];

  return `
  color: ${mainColor};
  font-size: ${mobile ? fontSizesWeb.lg : fontSizesWeb.base};

  .MuiInput-input[readonly] {
    color: ${colors.lila[700]};
    cursor: default;
  }

  .MuiInputAdornment-root, .MuiInputAdornment-root .MuiButtonBase-root {
    color: ${mainColor};
  }

  & .MuiInputBase-root.MuiInput-root:not(.Mui-error):after {
    border-bottom: 1px solid ${colors.blue[700]};
  }

  & .MuiInputBase-root.MuiInput-root.Mui-error:after {
    border-bottom: 1px solid ${colors.red[700]};
  }

  & > .MuiInputBase-root.MuiInput-root:before {
    border-bottom: 1px solid ${mainColor};
  }
  & .MuiInputBase-root.MuiInput-root.Mui-disabled:before {
    border-bottom: 1px solid ${colors.lila[400]};
  }

  &:hover .MuiInputBase-root.MuiInput-root:not(.Mui-disabled):before {
    border-bottom: 1px solid ${colors.blue[500]};
  }

  & .MuiOutlinedInput-root {
    padding: 24px;
  }

  & .MuiFilledInput-root, .MuiFilledInput-root:hover, .MuiFilledInput-root.Mui-selected, .MuiFilledInput-root.Mui-focused {
    border-radius: 5px;
    background: ${
      light ? theme.palette.common.white : colors.lila[700]
    } !important;
    border: 1px solid ${colors.lila[400]};
    min-height: 44px;
    .MuiFilledInput-input {
      padding: 0;
      text-align: center;
    }
    &:before, &:after {
        display: none;
    }
    &.MuiInputBase-multiline {
     padding-top: 8px;
    }
  }

  .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input:focus {
    background: none;
  }

  & .MuiFilledInput-root.Bas-Select-WithLabel, &.Bas-Select-WithLabel .MuiFilledInput-root {
    padding-top: 12px;
    &.MuiAutocomplete-inputRoot {
      padding-top: 24px;
      padding-bottom: 16px;
    }
    &.MuiAutocomplete-inputRoot .MuiFilledInput-input {
      padding: 7px 4px !important;
    }
  }

  & .MuiFormHelperText-root {
    font-size: ${mobile ? fontSizesWeb.sm : fontSizesWeb.xs};
  }

  & .MuiFormHelperText-root, .MuiInputLabel-root, .MuiInput-input {
    color: ${mainColor};
  }

  .MuiInputLabel-root, .MuiInput-input {
    font-size: ${mobile ? fontSizesWeb.lg : fontSizesWeb.base};
  }

  .MuiInputLabel-shrink {
    font-size: ${fontSizesWeb.sm};
  }

  & .MuiInputLabel-root.Mui-disabled {
    -webkit-text-fill-color: ${colors.lila[400]};
    color: ${colors.lila[400]};
  }

  .MuiFormHelperText-root.Mui-error, .MuiInput-root.Mui-error .MuiInput-input {
    color: ${colors.red[700]};
  }

  & .MuiInputLabel-root.MuiInputLabel-shrink {
    color: ${colors.lila[light ? 100 : 700]};
  }

  &:hover {
    .MuiInputLabel-root {
     color: ${colors.blue[500]};
    }
  }
`;
});
export default StyledTextField;
