import { StorageEventType, StorageMovedToNewLocationEvent } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageMovedToNewLocationEvent(
  object: unknown
): object is StorageMovedToNewLocationEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageMovedToNewLocationEvent
  );
}
