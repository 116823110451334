import { colors } from '@bas/theme';
import { Box, BoxProps, styled } from '@mui/material';

export type DarkCenteredTemplateProps = BoxProps;

const DarkCenteredTemplate = ({
  children,
  ...args
}: DarkCenteredTemplateProps) => (
  <Box {...args}>
    <Box className="Bas-DarkCenteredTemplate-Content">{children}</Box>
  </Box>
);

const StyledDarkCenteredTemplate = styled(DarkCenteredTemplate)`
  height: 100vh;
  width: 100%;
  background: ${colors.blue[900]};
  display: flex;
  align-items: center;
  justify-content: center;

  .Bas-DarkCenteredTemplate-Content {
    color: ${colors.white};
  }
`;
export default StyledDarkCenteredTemplate;
