import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basGardenSeat: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'garden-seat',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M120.6 83.4c-2-8-10.1-12.9-18.2-10.9s-12.9 10.1-10.9 18.2l54.5 219c1.7 6.7 7.7 11.4 14.6 11.4h48.2L171 419.7c-3 7.7 .9 16.4 8.7 19.4s16.4-.9 19.4-8.7L240.8 321h49.5L332 430.4c3 7.7 11.6 11.6 19.4 8.7s11.6-11.6 8.7-19.4L322.4 321H400c.9 0 1.8-.1 2.7-.2c.9 .2 1.9 .3 2.8 .3c8.3 0 15-6.7 15-15c0-15.5-5.7-34.2-16.7-49.3c-11.3-15.4-29.2-28.2-53.3-28.2H156.7L120.6 83.4zm43.6 175.1H350.5c12.4 0 22 6.2 29.1 15.9c3.8 5.1 6.5 10.9 8.3 16.6H172.2l-8.1-32.5z',
  ],
};

export default basGardenSeat;
