import { AccountingIntegrationSettingsInputType } from '@bas/integration-domain/input-types';
import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import {
  ReactHookFormAutocomplete,
  ReactHookFormCheckbox,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import {
  MappingTableBasOption,
  MappingTableExternalOption,
} from '@bas/value-objects';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Grid, IconButton, MenuItem, styled, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type MappingTableFormProps = {
  vatMapping?: boolean;
  basOptions: MappingTableBasOption[];
  externalOptions: MappingTableExternalOption[];
  prefix:
    | 'costCenterMapping'
    | 'generalLedgerMapping'
    | 'vatMapping'
    | 'administrationMapping'
    | 'purchaseCostMapping';
  basIdLabel: string | ReactNode;
  externalIdLabel: string | ReactNode;
  noExternalSelect?: boolean;
};

const MappingTableForm = ({
  vatMapping,
  basOptions,
  externalOptions,
  prefix,
  basIdLabel,
  externalIdLabel,
  noExternalSelect,
  ...args
}: MappingTableFormProps): ReactElement => {
  const { fields, append, remove } =
    useFieldArray<AccountingIntegrationSettingsInputType>({
      name: prefix,
    });

  const defaultMapping = vatMapping
    ? { basId: '', externalId: '', reversed: false }
    : { basId: '', externalId: '' };

  return (
    <Grid container {...args} rowSpacing={2}>
      {fields.length === 0 && (
        <Grid item xs={12}>
          <Typography>
            <FormattedMessage id="label.noRowsAddedYet" />
          </Typography>
        </Grid>
      )}
      {fields.map((field, index) => (
        <Grid
          item
          xs={12}
          container
          key={field.id}
          alignItems="flex-end"
          columnSpacing={3}
        >
          {vatMapping && (
            <Grid item>
              <Controller
                name={`${prefix}.${index}.reversed`}
                render={(registered) => (
                  <ReactHookFormCheckbox
                    {...registered}
                    label={<FormattedMessage id="label.reversedVat" />}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item className="Bas-MappingTableForm-Grow">
            <Controller
              name={`${prefix}.${index}.basId`}
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  autoComplete="off"
                  fullWidth
                  label={basIdLabel}
                  select
                >
                  {basOptions.map((basOption) => (
                    <MenuItem key={basOption.basId} value={basOption.basId}>
                      {basOption.label}
                    </MenuItem>
                  ))}
                </ReactHookFormTextField>
              )}
            />
          </Grid>
          <Grid item className="Bas-MappingTableForm-Grow">
            {noExternalSelect ? (
              <Controller
                name={`${prefix}.${index}.externalId`}
                render={(registered) => (
                  <ReactHookFormTextField
                    {...registered}
                    autoComplete="off"
                    fullWidth
                    label={externalIdLabel}
                  />
                )}
              />
            ) : (
              <Controller
                name={`${prefix}.${index}.externalId`}
                render={(registered) => (
                  <ReactHookFormAutocomplete
                    {...registered}
                    fullWidth
                    textField={{
                      label: externalIdLabel,
                    }}
                    identifier="externalId"
                    options={externalOptions}
                    getOptionLabel={(
                      option: string | MappingTableExternalOption
                    ) => {
                      if (
                        typeof option === 'string' ||
                        typeof option === 'number'
                      ) {
                        const found = externalOptions.find(
                          ({ externalId }) => externalId === option
                        );

                        return found?.label || option;
                      }

                      return option.label;
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => remove(index)}>
              <Icon icon={faTrashCan} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => append({ ...defaultMapping })}
        >
          <FormattedMessage id="button.newRow" />
        </Button>
      </Grid>
    </Grid>
  );
};

const StyledMappingTableForm = styled(MappingTableForm)(
  () => `
  .Bas-MappingTableForm-Grow {
    flex: 1;
  }

  .MuiFormControl-root {
    min-width: 250px;
  }
`
);
export default StyledMappingTableForm;
