import { useTenantStore } from '@bas/shared/state';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import { Tooltip } from '@bas/ui/web/atoms';
import { Uuid } from '@bas/value-objects';
import { Box, BoxProps, Grid, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, useMemo } from 'react';

export type IdentityIndicatorProps = BoxProps & {
  identityId?: Uuid | null;
  full?: boolean;
  disableSpacing?: boolean;
  size?: 'xs' | 'small' | 'medium';
};

const IdentityIndicator = ({
  identityId,
  className,
  size,
  full,
  disableSpacing,
  children,
  ...args
}: IdentityIndicatorProps): ReactElement | null => {
  const tenantState = useTenantStore((state) => state.tenant);

  const { data: identitiesData } = useIdentitiesRequest(
    {},
    {
      enabled: tenantState?.useIdentities,
    }
  );

  const identities = useMemo(
    () => identitiesData?.data?.['hydra:member'] || [],
    [identitiesData?.data]
  );

  const identity = useMemo(() => {
    if (!identityId || !tenantState?.useIdentities) {
      return undefined;
    }

    return identities.find((id) => id.identityId === identityId);
  }, [identities, identityId, tenantState?.useIdentities]);

  if (!identity) {
    return <span>{children}</span>;
  }

  return (
    <Grid alignItems="center" container columnSpacing={disableSpacing ? 0 : 1}>
      <Grid item>
        <Tooltip
          title={identity.companyInformation.companyName}
          disabled={full}
        >
          <Box
            {...args}
            sx={{ backgroundColor: `${identity.color} !important` }}
            className={clsx(
              'Bas-IdentityIndicator',
              className,
              { 'Bas-IdentityIndicator-Xs': size === 'xs' },
              { 'Bas-IdentityIndicator-Small': size === 'small' },
              { 'Bas-IdentityIndicator-Medium': size === 'medium' }
            )}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        {full ? identity.companyInformation.companyName : children}
      </Grid>
    </Grid>
  );
};

IdentityIndicator.defaultProps = {
  size: 'small',
};

const StyledIdentityIndicator = styled(IdentityIndicator)(
  ({ theme }) => `
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${theme.palette.primary.main};
  &.Bas-IdentityIndicator-Xs {
    width: 6px;
    height: 6px;
  }

  &.Bas-IdentityIndicator-Small {
    width: 8px;
    height: 8px;
  }

  &.Bas-IdentityIndicator-Medium {
    width: 12px;
    height: 12px;
  }
  `
);
export default StyledIdentityIndicator;
