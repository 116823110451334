import { ExactOnlineVatCode } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequestProps =
  Pagination & {
    integrationId: Uuid;
    divisionCode: string;
  };

type Response = AxiosResponse<Collection<ExactOnlineVatCode>>;

export const ExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequest = async ({
  integrationId,
  divisionCode,
  ...params
}: ExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/integrations/${integrationId}/exact-online/${divisionCode}/exact-online-vat-codes`,
    {
      params: { ...params },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequest = (
  request: ExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequest(request),
    queryKey: [
      'integrations',
      'exact-online-vat-codes',
      request.integrationId,
      request,
    ],
  });
