import { Button } from '@bas/ui/web/atoms';
import { Dialog, DialogProps } from '@bas/ui/web/molecules';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type ConfirmDialogProps = Omit<DialogProps, 'title' | 'content'> & {
  onCancel?: () => void | Promise<void>;
  onConfirm: () => void | Promise<void>;
  title: ReactNode | string;
  content: ReactNode | string;
  dangerous?: boolean;
  extraButtons?: ReactNode;
  confirmButtonLabel?: string;
};

const ConfirmDialog = ({
  title,
  content,
  onCancel,
  onConfirm,
  dangerous,
  extraButtons,
  confirmButtonLabel,
  ...args
}: ConfirmDialogProps): ReactElement => (
  <Dialog
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="sm"
    {...args}
  >
    <DialogTitle
      id="alert-dialog-title"
      sx={{
        color: (theme) =>
          dangerous ? `${theme.palette.error.main} !important` : undefined,
      }}
    >
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {onCancel && (
        <Button
          onClick={onCancel}
          autoFocus
          variant="outlined"
          color="secondary"
          sx={{
            alignSelf: 'flex-start',
          }}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
      )}
      {extraButtons}
      <Button
        onClick={onConfirm}
        autoFocus
        color={dangerous ? 'error' : 'primary'}
      >
        <FormattedMessage id={confirmButtonLabel || 'button.confirm'} />
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
