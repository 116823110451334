import { colors } from '@bas/theme';
import { Tab, TabProps } from '@bas/ui/web/atoms';
import { Tabs } from '@bas/ui/web/molecules';
import { Box, styled, Tab as MuiTab } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import {
  Link as RouterLink,
  matchPath,
  resolvePath,
  To,
  useLocation,
} from 'react-router-dom';

export type NavigationTabProps =
  | {
      label: ReactNode | string;
      to: To;
      exact?: boolean;
    }
  | {
      spacer: true;
    };

export type TabsHeaderTemplateProps = {
  className?: string;
  parentPath: string;
  tabs: NavigationTabProps[];
  children: ReactNode;
};

const isSpacer = (tab: NavigationTabProps): tab is { spacer: true } =>
  (
    tab as {
      spacer: boolean;
    }
  )?.spacer;

const RouterTab = ((props: TabProps) => <Tab {...props} />) as typeof MuiTab;

const TabsHeaderTemplate = ({
  className,
  parentPath,
  tabs,
  children,
}: TabsHeaderTemplateProps): ReactElement => {
  const { pathname } = useLocation();
  const activeTab = tabs.findIndex((tab) => {
    if (isSpacer(tab)) {
      return false;
    }

    const { to, exact } = tab;
    const resolved = resolvePath(to, parentPath);
    return !!matchPath({ path: resolved.pathname, end: exact }, pathname);
  });

  return (
    <Box className={className}>
      <Box className="Bas-TabsHeaderTemplate-Tabs">
        <Tabs value={activeTab === -1 ? 0 : activeTab}>
          {tabs.map((tab, index) => {
            if (isSpacer(tab)) {
              return (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={`spacer-${index}`}
                  height={48}
                  width="1px"
                  style={{
                    background: colors.lila[400],
                  }}
                />
              );
            }

            const { to, label } = tab;
            return (
              <RouterTab<typeof RouterLink>
                label={label}
                component={RouterLink}
                to={to}
                value={index}
                key={`tab-${to}`}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box className="Bas-TabsHeaderTemplate-Content">{children}</Box>
    </Box>
  );
};

const StyledTabsHeaderTemplate = styled(TabsHeaderTemplate)(
  ({ theme }) => `
  padding-left: ${theme.spacing(7)};
  height: 100%;
  display: flex;
  flex-direction: column;

  .Bas-TabsHeaderTemplate-Tabs {
    padding-top: ${theme.spacing(7)};
    width: 100%;
    position: relative;
    box-shadow: inset 0px -1px 0px ${colors.lila[400]};

    & .MuiTab-root:first-of-type {
      margin-left: 0;
    }
  }

  .Bas-TabsHeaderTemplate-Content {
    flex: 1;
    max-height: calc(100% - (${theme.spacing(7)} + 48px));
    > .Bas-Content-Overflow {
      height: 100%;
      width: 100%;
      overflow: auto;
      margin-left: -${theme.spacing(3)};
      padding-left: ${theme.spacing(3)};
      box-sizing: border-box;
    }

    .Bas-TwoSidesTemplate-Open .Bas-TwoSidesTemplate-Slide, .Bas-TwoSidesTemplate-FullHeightFallback .Bas-TwoSidesTemplate-Fallback {
      top: calc((${theme.spacing(7)} + 48px) * -1);
    }

    .Bas-TwoSidesTemplate-Fallback, .Bas-TwoSidesTemplate-LeftSide {
      padding-top: ${theme.spacing(3)};
    }

    .Bas-TwoSidesTemplate-LeftSide {
      padding-left: 0;
    }
  }
`
);

const RealTabsHeaderTemplate = (props: TabsHeaderTemplateProps) => (
  <StyledTabsHeaderTemplate {...props} />
);

export default RealTabsHeaderTemplate;
