import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basCradle: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'cradle',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M23.7 31.2C15.6 31.2 9 37.8 9 45.9V398.1c0 8.1 6.6 14.6 14.6 14.6h79.6l5.3 37.1h35.1l5.3-37.1H351.1l5.3 37.1h35.1l5.3-37.1h79.6c8.1 0 14.6-6.6 14.6-14.6V45.9c0-8.1-6.6-14.6-14.6-14.6H23.7zM461.7 297.6v85.9H38.3V297.6H461.7zm0-29.3H405.1V60.5h56.6V268.3zm-85.9 0H313.4V60.5h62.4V268.3zm-91.7 0H221.7V60.5h62.4V268.3zm-91.7 0H130V60.5h62.4V268.3zM38.3 60.5h62.4V268.3H38.3V60.5z',
  ],
};

export default basCradle;
