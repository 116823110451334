import { isObjectResponse } from '@bas/value-objects';
import { OctopusSettings } from '../../types';

export function isOctopusSettings(object: unknown): object is OctopusSettings {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'OctopusSettings'
  );
}
