import {
  useKingFinanceCostCentersByIntegrationIdRequest,
  useKingFinanceGeneralLedgersByIntegrationIdRequest,
  useKingFinanceJournalsByIntegrationIdRequest,
  useKingFinanceVatCodesByIntegrationIdRequest,
} from '@bas/integration-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';

export const useKingFinanceMappingOptions = (integrationId: Uuid) => {
  const { data: generalLedgersData, isError: generalLedgersError } =
    useKingFinanceGeneralLedgersByIntegrationIdRequest({
      integrationId,
    });

  const generalLedgerExternalOptions = useMemo(
    () =>
      (generalLedgersData?.data?.['hydra:member'] || []).map(
        ({ NR, OMSCHR }) => ({
          externalId: NR,
          label: OMSCHR,
        })
      ),
    [generalLedgersData?.data]
  );

  const { data: costCentersData, isError: departmentsError } =
    useKingFinanceCostCentersByIntegrationIdRequest({
      integrationId,
    });

  const costCenterExternalOptions = useMemo(
    () =>
      (costCentersData?.data?.['hydra:member'] || []).map(({ NR, OMSCHR }) => ({
        externalId: NR,
        label: OMSCHR,
      })),
    [costCentersData?.data]
  );

  const { data: vatCodesData, isError: vatCodesError } =
    useKingFinanceVatCodesByIntegrationIdRequest({
      integrationId,
    });

  const vatExternalOptions = useMemo(
    () =>
      (vatCodesData?.data?.['hydra:member'] || []).map(({ NR, OMSCHR }) => ({
        externalId: NR,
        label: OMSCHR,
      })),
    [vatCodesData?.data]
  );

  const { data: journalsData, isError: journalsError } =
    useKingFinanceJournalsByIntegrationIdRequest({
      integrationId,
    });

  const journalsExternalOptions = useMemo(
    () =>
      (journalsData?.data?.['hydra:member'] || []).map(({ NR, OMSCHR }) => ({
        externalId: NR,
        label: OMSCHR,
      })),
    [journalsData?.data]
  );

  return {
    generalLedgersError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsError,
    journalsExternalOptions,
  };
};
