import { QuoteLine } from '@bas/financial-domain/models';
import {
  FormattedCurrency,
  OptionalLabel,
  TableCell,
  TableRow,
} from '@bas/ui/web/atoms';
import { Checkbox } from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { ChangeEvent, ReactElement } from 'react';

export type AcceptingFinancialDocumentLineTableRowProps = {
  className?: string;
  showCheckbox?: boolean;
  showQuantity: boolean;
  showTotal: boolean;
  line: QuoteLine;
  acceptedLines?: Uuid[];
  onCheck?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const AcceptingFinancialDocumentLineTableRow = ({
  className,
  showCheckbox,
  acceptedLines,
  line,
  showQuantity,
  showTotal,
  onCheck,
}: AcceptingFinancialDocumentLineTableRowProps): ReactElement => (
  <TableRow className={className}>
    {showCheckbox && onCheck && (
      <TableCell sx={{ width: '76px' }}>
        <Checkbox
          disabled={!line.optional}
          checked={!!acceptedLines && acceptedLines.includes(line.lineId)}
          onChange={onCheck}
        />
      </TableCell>
    )}
    {showQuantity && (
      <TableCell sx={{ width: '55px' }}>{`${line.quantity}x`}</TableCell>
    )}
    <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
      {line.description}
      {line.optional && <OptionalLabel />}
    </TableCell>
    <TableCell sx={{ textAlign: 'right', width: '100px' }}>
      <FormattedCurrency {...line.pricePerUnit} />
    </TableCell>
    {showTotal && (
      <TableCell sx={{ textAlign: 'right', width: '100px' }}>
        <FormattedCurrency {...line.totalPrice} />
      </TableCell>
    )}
    <TableCell sx={{ textAlign: 'right', width: '76px' }}>
      {`${line.vatPercentage.percentage}%`}
    </TableCell>
  </TableRow>
);

export default AcceptingFinancialDocumentLineTableRow;
