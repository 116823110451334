import { isObjectResponse } from '@bas/value-objects';
import { ScanMoversSettings } from '../../types';

export function isScanMoversSettings(
  object: unknown
): object is ScanMoversSettings {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'ScanMoversSettings'
  );
}
