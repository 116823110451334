export enum ProviderType {
  SCHOUTEN_ZEKERHEID = 'schouten-zekerheid',
  EXACT_ONLINE = 'exact-online',
  EXACT_GLOBE = 'exact-globe',
  TWINFIELD = 'twinfield',
  SNEL_START = 'snel-start',
  MOLLIE = 'mollie',
  REELEEZEE = 'reeleezee',
  IMUIS = 'imuis',
  YUKI = 'yuki',
  KLANTENVERTELLEN = 'klantenvertellen',
  WEBSITE = 'website',
  WHATSAPP = 'whatsapp',
  GOOGLE_CALENDAR = 'google-calendar',
  VERHUIS_OFFERTES = 'verhuis-offertes',
  SCAN_MOVERS = 'scan-movers',
  OCTOPUS = 'octopus',
  NMBRS = 'nmbrs',
}
