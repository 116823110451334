import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basMattress: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'mattress',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M109.5 70.2c-6.1 0-11.5 3.7-13.7 9.4L10.1 303.7c-.7 1.7-1.1 3.5-1.1 5.4c0 0 0 .1 0 .1L9 424.4c0 8.1 6.6 14.6 14.6 14.6H476.4c8.1 0 14.6-6.6 14.6-14.6V309.3c0 0 0-.1 0-.1c0-.3 0-.6 0-.9c-.1-1.6-.5-3.2-1.1-4.7L404.2 79.6c-2.2-5.7-7.6-9.4-13.7-9.4h-281zM455.1 294.6H44.9L119.6 99.5H380.4l74.7 195.1zm6.6 29.3v85.9H38.3V323.9H461.7z',
  ],
};

export default basMattress;
