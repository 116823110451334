import { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import { useUploadFileToCreateAMediaObjectMutation } from '@bas/media-domain/mutations';
import { useIntl } from 'react-intl';
import { UseFormSetValue } from 'react-hook-form';

export type UploadingFile = {
  file: File;
  progress: number;
  failed?: boolean;
  success?: boolean;
  reason?: string | ReactNode;
};

export const useUploadFile = (
  fieldName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
) => {
  const { mutateAsync: uploadFile } =
    useUploadFileToCreateAMediaObjectMutation();
  const [uploadingFile, setUploadingFile] = useState<UploadingFile>();
  const { formatMessage } = useIntl();

  const handleUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (!files || !files[0]) {
        return;
      }

      const file = files[0];

      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 45) {
        setUploadingFile({
          file,
          progress: 0,
          reason: formatMessage({ id: 'label.fileTooBig' }),
          failed: true,
        });

        return;
      }

      setUploadingFile({ file, progress: 0 });

      uploadFile({
        file,
        onUploadProgress: (progressEvent) => {
          setUploadingFile((cur) => {
            if (cur) {
              return {
                ...cur,
                progress: Math.round(
                  (progressEvent.loaded * 100) / (progressEvent?.total || 0)
                ),
              };
            }

            return undefined;
          });
        },
      })
        .then((result) => {
          setUploadingFile({
            file,
            progress: 100,
            failed: false,
            success: true,
          });

          setValue(fieldName, result.data.mediaObjectId);
        })
        .catch(() => {
          setUploadingFile({
            file,
            progress: 0,
            reason: formatMessage({ id: 'label.failedToUpload' }),
            failed: true,
          });
        });
    },
    [fieldName, formatMessage, setValue, uploadFile]
  );

  return {
    handleUpload,
    uploadingFile,
  };
};
