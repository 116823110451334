import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basChevronDown: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'chevron-down',
  icon: [
    16,
    16,
    [],
    'e001',
    'M12.4512 7.00632C12.7529 6.70894 12.7529 6.22222 12.4512 5.92485C12.1558 5.63368 11.6815 5.63342 11.3858 5.92427L8.96076 8.30948C8.64945 8.61569 8.1501 8.61569 7.83878 8.30948L5.41379 5.92427C5.11809 5.63342 4.64371 5.63368 4.34833 5.92485C4.04665 6.22222 4.04665 6.70894 4.34833 7.00632L7.83816 10.4464C8.14963 10.7534 8.64992 10.7534 8.96138 10.4464L12.4512 7.00632Z',
  ],
};

export default basChevronDown;
