import {
  emptyRecurringInformation,
  RecurringInformationType,
} from '@bas/value-objects';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { RRule } from 'rrule';
import { getWeekDayString } from './getWeekDayString';

const weekDayMapping = {
  monday: RRule.MO,
  tuesday: RRule.TU,
  wednesday: RRule.WE,
  thursday: RRule.TH,
  friday: RRule.FR,
  saturday: RRule.SA,
  sunday: RRule.SU,
};

type dayOfWeek = keyof typeof weekDayMapping;

const repeatMappingTable = {
  daily: RRule.DAILY,
  weekly: RRule.WEEKLY,
  monthly: RRule.MONTHLY,
  yearly: RRule.YEARLY,
};

const repeatOnMapping = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  last: -1,
};

dayjs.extend(timezone);

export const convertRecurringInformationToRRule = (
  recurringInformation: RecurringInformationType,
  startTime: Date,
  timezone: string | undefined
): RRule => {
  let weekday;
  if (recurringInformation?.repeats === 'weekly') {
    weekday = (recurringInformation.weekDays || []).map(
      (day) => weekDayMapping[day]
    );
  } else if (
    recurringInformation?.repeats === 'monthly' &&
    recurringInformation.repeatOnType === 'weekDay' &&
    recurringInformation.repeatOn?.day
  ) {
    if (
      !['day', 'weekday', 'weekend-day'].includes(
        recurringInformation.repeatOn.day
      )
    ) {
      weekday = [weekDayMapping[recurringInformation.repeatOn.day as 'monday']];
    } else if (recurringInformation.repeatOn.day === 'weekday') {
      weekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
    } else if (recurringInformation.repeatOn.day === 'weekend-day') {
      weekday = [RRule.SA, RRule.SU];
    } else if (recurringInformation.repeatOn.day === 'day') {
      weekday = [
        RRule.MO,
        RRule.TU,
        RRule.WE,
        RRule.TH,
        RRule.FR,
        RRule.SA,
        RRule.SU,
      ];
    }
  }
  let dtstart;
  if (
    recurringInformation.repeatFrom &&
    dayjs(recurringInformation.repeatFrom).isValid()
  ) {
    const startTimeDayjs = dayjs(startTime);
    dtstart = dayjs(recurringInformation.repeatFrom)
      .set('hour', startTimeDayjs.get('hour'))
      .set('minute', startTimeDayjs.get('minute'))
      .set('second', 0)
      .set('millisecond', 0)
      .utc(true)
      .toDate();
  }

  return new RRule({
    freq: repeatMappingTable[recurringInformation.repeats || 'daily'],
    interval: recurringInformation.repeatInterval,
    tzid: timezone || dayjs.tz.guess() || 'Europe/Amsterdam',
    count:
      recurringInformation.untilType === 'count'
        ? recurringInformation.repeatCount
        : undefined,
    dtstart,
    until:
      recurringInformation.untilType === 'date' &&
      dayjs(recurringInformation.repeatUntil).isValid()
        ? dayjs(recurringInformation.repeatUntil)
            .endOf('day')
            .utc(true)
            .toDate()
        : undefined,
    byweekday: weekday,
    bymonthday:
      recurringInformation?.repeats === 'monthly' &&
      recurringInformation.repeatOnType === 'dayOfMonth'
        ? recurringInformation.repeatOnDayOfMonth
        : undefined,
    bysetpos:
      recurringInformation?.repeats === 'monthly' &&
      recurringInformation.repeatOnType === 'weekDay'
        ? repeatOnMapping[recurringInformation?.repeatOn?.which || 'first']
        : undefined,
  });
};

const allWeekDays = [
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
  RRule.SU,
];

export const convertRRuleToRecurringInformation = (
  rrule: string
): RecurringInformationType => {
  const parsedRRule = RRule.fromString(rrule);

  const weekDays = parsedRRule.options.byweekday
    ?.map((day) => {
      const rruleWeekday = allWeekDays.find((w) => w.weekday === day);

      return Object.keys(weekDayMapping).find(
        (key) => weekDayMapping[key as dayOfWeek] === rruleWeekday
      );
    })
    .filter((day) => !!day) as dayOfWeek[];

  const repeats = (Object.keys(repeatMappingTable).find(
    (key) =>
      repeatMappingTable[key as keyof typeof repeatMappingTable] ===
      parsedRRule.options.freq
  ) || 'weekly') as 'daily' | 'weekly' | 'monthly' | 'yearly';

  const defaultValues = emptyRecurringInformation();

  return {
    rrule,
    repeats,
    repeatFrom: parsedRRule.options.dtstart || defaultValues.repeatFrom,
    repeatUntil: parsedRRule.options.until || defaultValues.repeatUntil,
    repeatCount: parsedRRule.options.count || defaultValues.repeatCount,
    repeatInterval:
      parsedRRule.options.interval || defaultValues.repeatInterval,
    untilType: parsedRRule.options.count ? 'count' : 'date',
    repeatOnType:
      repeats !== 'monthly' || parsedRRule.options.bymonthday
        ? 'dayOfMonth'
        : 'weekDay',
    repeatOnDayOfMonth:
      parsedRRule.options.bymonthday[0] || defaultValues.repeatOnDayOfMonth,
    repeatOn: {
      which: parsedRRule.options.bysetpos
        ? (Object.keys(repeatOnMapping).find(
            (key) =>
              repeatOnMapping[key as keyof typeof repeatOnMapping] ===
              parsedRRule.options.bysetpos[0]
          ) as 'first' | 'second' | 'third' | 'fourth' | 'last')
        : defaultValues.repeatOn?.which || 'first',
      day:
        weekDays?.[0] ||
        defaultValues.repeatOn?.day ||
        getWeekDayString(dayjs()),
    },
    weekDays: weekDays || defaultValues.weekDays,
  };
};
