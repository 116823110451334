import * as Yup from 'yup';
import {
  ConnectIntegrationInputType,
  ConnectIntegrationInputTypeValidationBuilder,
} from '../connectIntegrationInputType';

export interface ConnectVerhuisOffertesIntegrationInputType
  extends ConnectIntegrationInputType {
  sharedSecret: string;
}

export const ConnectVerhuisOffertesIntegrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectVerhuisOffertesIntegrationInputType> =>
    Yup.object({
      sharedSecret: Yup.string().required().label('label.sharedSecret'),
    }).concat(ConnectIntegrationInputTypeValidationBuilder());
