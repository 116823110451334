import { Document, isDocument } from '@bas/crm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type DocumentsRequestProps = Pagination & {
  projectId?: Uuid;
  storageId?: Uuid;
  damageId?: Uuid;
  relationId?: Uuid;
  globalFilter?: string;
  visibleInApp?: boolean;
};

type Response = AxiosResponse<Collection<Document>>;

export const DocumentsRequest = async ({
  ...params
}: DocumentsRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/documents', {
    params: { ...params },
  });

export const useDocumentsRequest = (
  request: DocumentsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    placeholderData: keepPreviousData,
    queryFn: async () => DocumentsRequest(request),
    queryKey: [
      'documents',
      'list',
      request.projectId || request.storageId || request.relationId,
      JSON.stringify(request),
    ],
  });

export const usePrefetchDocumentsRequest = (
  request: DocumentsRequestProps
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'documents',
        'list',
        request.projectId || request.storageId || request.relationId,
        JSON.stringify(request),
      ],
      queryFn: async () => DocumentsRequest(request),
    });
  }, [queryClient, request]);
};

export const DocumentsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isDocument(data)) {
    queryClient.invalidateQueries({
      queryKey: ['documents', 'list', data.relationId],
    });
    if (data.projectId) {
      queryClient.invalidateQueries({
        queryKey: ['documents', 'list', data.projectId],
      });
    }
    if (data.storageId) {
      queryClient.invalidateQueries({
        queryKey: ['documents', 'list', data.storageId],
      });
    }
  }
};
