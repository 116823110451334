import { useVatCodesBasOptions } from '@bas/integration-domain/web/hooks';
import { MappingTableExternalOption } from '@bas/value-objects';
import { Grid } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { MappingTableForm } from '../MappingTableForm';

export type VatMappingFormProps = {
  externalOptions: MappingTableExternalOption[];
  noExternalSelect?: boolean;
};

const VatMappingForm = ({
  externalOptions,
  noExternalSelect,
}: VatMappingFormProps): ReactElement => {
  const basOptions = useVatCodesBasOptions();

  return (
    <Grid container>
      <Grid item xs={12}>
        <MappingTableForm
          vatMapping
          basOptions={basOptions}
          externalOptions={externalOptions}
          prefix="vatMapping"
          basIdLabel={<FormattedMessage id="label.vatCode" />}
          externalIdLabel={<FormattedMessage id="label.externalVatCode" />}
          noExternalSelect={noExternalSelect}
        />
      </Grid>
    </Grid>
  );
};

export default VatMappingForm;
