import { formatDateTime } from '@bas/shared/utils';
import { fontSizesWeb } from '@bas/theme';
import lateBackground from '@bas/ui/assets/Resources/dashedBackground.svg';
import { Box, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { forwardRef, ReactElement, ReactNode, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type VerticalPlanningEventCalendarItemTravelProps = {
  className?: string;
  timeType: 'start' | 'end';
  heightPerMinute: number;
  startTime: Date;
  realStartTime?: Date | null;
  finishTime: Date;
  realFinishTime?: Date | null;
  showTimes?: boolean;
  small?: boolean;
  finishLabel?: ReactNode;
};

const VerticalPlanningEventCalendarItemTravel = forwardRef<
  HTMLDivElement,
  VerticalPlanningEventCalendarItemTravelProps
>(
  (
    {
      timeType,
      heightPerMinute,
      startTime,
      realStartTime,
      finishTime,
      realFinishTime,
      className,
      showTimes,
      small,
      finishLabel,
    }: VerticalPlanningEventCalendarItemTravelProps,
    ref
  ): ReactElement | null => {
    const startToUse = useMemo(() => {
      if (!realStartTime) {
        return startTime;
      }

      return dayjs(realStartTime).isAfter(startTime)
        ? startTime
        : realStartTime;
    }, [realStartTime, startTime]);

    const endToUse = useMemo(() => {
      if (!realFinishTime) {
        return finishTime;
      }

      return realFinishTime;
    }, [finishTime, realFinishTime]);

    const travelHeight = useMemo(
      () => heightPerMinute * dayjs(endToUse).diff(dayjs(startToUse), 'minute'),
      [endToUse, heightPerMinute, startToUse]
    );

    const travelStartedTooLateHeight = useMemo(() => {
      if (!realStartTime || !startTime) {
        return 0;
      }

      const result =
        heightPerMinute * dayjs(realStartTime).diff(dayjs(startTime), 'minute');

      if (result < 0) {
        return 0;
      }

      return result;
    }, [heightPerMinute, realStartTime, startTime]);

    const arrivedTooLateHeight = useMemo(() => {
      if (!realFinishTime || !finishTime) {
        return 0;
      }

      let finishTimeToCompare = dayjs(finishTime);
      if (realStartTime && finishTimeToCompare.isBefore(realStartTime)) {
        finishTimeToCompare = dayjs(realStartTime);
      }

      let borderWidth = 3;
      if (travelStartedTooLateHeight === 0) {
        borderWidth = 0;
      }

      const result =
        heightPerMinute *
          dayjs(realFinishTime).diff(finishTimeToCompare, 'minute') -
        borderWidth;
      if (result < 0) {
        return 0;
      }

      return result;
    }, [
      finishTime,
      heightPerMinute,
      realFinishTime,
      realStartTime,
      travelStartedTooLateHeight,
    ]);

    if (
      realStartTime &&
      realFinishTime &&
      dayjs(realStartTime).isAfter(dayjs(realFinishTime))
    ) {
      return (
        <Box
          data-testid="travel-time"
          data-height={18}
          ref={ref}
          className={clsx(
            'Bas-VerticalPlanningEventCalendarItem-Travel',
            'Bas-VerticalPlanningEventCalendarItem-Travel--NoBorder',
            {
              'Bas-VerticalPlanningEventCalendarItem-Travel--Start':
                timeType === 'start',
            },
            {
              'Bas-VerticalPlanningEventCalendarItem-Travel--End':
                timeType === 'end',
            },
            className
          )}
          sx={{
            height: `18px`,
            minHeight: `18px`,
          }}
        >
          <Typography
            data-testid="travel-time--start"
            className={clsx(
              'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime',
              'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--Invalid'
            )}
          >
            <FormattedMessage id="label.invalid" />
          </Typography>
        </Box>
      );
    }

    if (travelHeight <= 0) {
      return (
        <Box
          data-testid="travel-time"
          data-height={travelHeight}
          ref={ref}
          className={clsx(
            'Bas-VerticalPlanningEventCalendarItem-Travel',
            'Bas-VerticalPlanningEventCalendarItem-Travel--NoBorder',
            {
              'Bas-VerticalPlanningEventCalendarItem-Travel--Start':
                timeType === 'start',
            },
            {
              'Bas-VerticalPlanningEventCalendarItem-Travel--End':
                timeType === 'end',
            },
            className
          )}
        >
          {showTimes && (
            <Typography
              data-testid="travel-time--start"
              data-bottom="4"
              className={clsx(
                'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime',
                'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--Start'
              )}
              sx={{ bottom: 4 }}
            >
              <FormattedDate
                value={
                  realStartTime && dayjs(realStartTime).isValid()
                    ? realStartTime
                    : startTime
                }
                timeStyle="short"
              />
            </Typography>
          )}
        </Box>
      );
    }

    return (
      <Box
        data-testid="travel-time"
        data-height={travelHeight}
        ref={ref}
        className={clsx(
          'Bas-VerticalPlanningEventCalendarItem-Travel',
          {
            'Bas-VerticalPlanningEventCalendarItem-Travel--Start':
              timeType === 'start',
          },
          {
            'Bas-VerticalPlanningEventCalendarItem-Travel--End':
              timeType === 'end',
          },
          {
            'Bas-VerticalPlanningEventCalendarItem-Travel--End-Late':
              timeType === 'end' && arrivedTooLateHeight >= 0,
          },
          {
            'Bas-VerticalPlanningEventCalendarItem-Travel--Small': small,
          },
          className
        )}
        onClick={() =>
          // eslint-disable-next-line no-console
          console.table({
            startToUse: formatDateTime(startToUse),
            endToUse: formatDateTime(endToUse),
            realStartTime: realStartTime ? formatDateTime(realStartTime) : null,
            realFinishTime: realFinishTime
              ? formatDateTime(realFinishTime)
              : null,
            startTime: formatDateTime(startTime),
            finishTime: formatDateTime(finishTime),
          })
        }
        sx={{
          height: `${travelHeight}px`,
          minHeight: `${travelHeight}px`,
        }}
      >
        <Box
          data-testid="travel-time-late--start"
          data-height={travelStartedTooLateHeight}
          className={clsx(
            'Bas-VerticalPlanningEventCalendarItem-Travel-Late',
            'Bas-VerticalPlanningEventCalendarItem-Travel-Late--Started'
          )}
          sx={{ height: travelStartedTooLateHeight }}
        />
        {showTimes &&
          !dayjs(realStartTime || startTime).isSame(
            realFinishTime || finishTime,
            'minute'
          ) && (
            <Typography
              data-testid="travel-time--start"
              className={clsx(
                'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime',
                'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--Start'
              )}
              data-top={
                timeType === 'end' && travelStartedTooLateHeight > 0
                  ? travelStartedTooLateHeight - 17
                  : '-17px'
              }
              sx={{
                top:
                  timeType === 'end' && travelStartedTooLateHeight > 0
                    ? travelStartedTooLateHeight - 17
                    : '-17px',
              }}
            >
              <FormattedDate
                value={
                  realStartTime && dayjs(realStartTime).isValid()
                    ? realStartTime
                    : startTime
                }
                timeStyle="short"
              />
            </Typography>
          )}
        <Box
          data-testid="travel-time-late--end"
          data-height={arrivedTooLateHeight}
          className={clsx(
            'Bas-VerticalPlanningEventCalendarItem-Travel-Late',
            'Bas-VerticalPlanningEventCalendarItem-Travel-Late--Arrived'
          )}
          sx={{ height: arrivedTooLateHeight }}
        >
          {finishLabel}
        </Box>
        {showTimes && (
          <Typography
            data-testid="travel-time--end"
            data-bottom={timeType === 'end' ? 7 : arrivedTooLateHeight + 7}
            className={clsx(
              'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime',
              'Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--End'
            )}
            sx={{ bottom: timeType === 'end' ? 7 : arrivedTooLateHeight + 7 }}
          >
            <FormattedDate
              value={
                realFinishTime && dayjs(realFinishTime).isValid()
                  ? realFinishTime
                  : finishTime
              }
              timeStyle="short"
            />
          </Typography>
        )}
      </Box>
    );
  }
);

const StyledVerticalPlanningEventCalendarItemTravel = styled(
  VerticalPlanningEventCalendarItemTravel
)`
  &.Bas-VerticalPlanningEventCalendarItem-Travel {
    position: relative;
    min-width: 100%;
    box-sizing: content-box;
    border-color: white;
    border-style: solid;
    border-width: 0;
    margin-left: -3px;
    margin-right: -3px;

    &--NoBorder {
      border: none !important;
    }

    &--Start {
      border-bottom-width: 3px;
    }

    &--End {
      &:not(.Bas-VerticalPlanningEventCalendarItem-Travel--End-Late) {
        border-top-width: 3px;
      }
    }
  }

  .Bas-VerticalPlanningEventCalendarItem-Travel-Late {
    // eslint-disable-next-line
    // prettier-ignore
    background: url("${lateBackground}");

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--Arrived {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.Bas-VerticalPlanningEventCalendarItem-Travel--End-Late {
    .Bas-VerticalPlanningEventCalendarItem-Travel-Late--Started {
      border-bottom: 3px solid white;
      box-sizing: content-box;
    }
  }

  .Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime {
    color: white;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    text-align: center;

    &--Invalid {
      color: ${({ theme }) => theme.palette.error.main};
      font-size: ${fontSizesWeb.sm};
      line-height: 18px;
    }
  }

  &.Bas-VerticalPlanningEventCalendarItem-Travel--Start {
    .Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--Start {
      padding-top: 4px;
    }

    .Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--End {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -23px;
      text-align: center;
    }

    &.Bas-VerticalPlanningEventCalendarItem-Travel--Small {
      .Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--End {
        bottom: -16px;
      }
    }
  }

  &.Bas-VerticalPlanningEventCalendarItem-Travel--End {
    .Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--Start {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
    }

    .Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--End {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
    }

    &.Bas-VerticalPlanningEventCalendarItem-Travel--Small {
      .Bas-VerticalPlanningEventCalendarItem-Travel-TravelTime--Start {
        top: -13px;
      }
    }
  }
`;
export default StyledVerticalPlanningEventCalendarItemTravel;
