import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { InventoryItemStock, isInventoryEvent } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type InventoryItemsStockRequestProps = Pagination &
  Filterable & {
    inventoryItemIds: Uuid[];
  };

type Response = AxiosResponse<Collection<InventoryItemStock>>;

export const InventoryItemsStockRequest = async ({
  inventoryItemIds,
  perPage,
  ...params
}: InventoryItemsStockRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/inventory/item/stock`, {
    params: {
      inventoryItemId: inventoryItemIds,
      perPage: 9999 || perPage,
      ...params,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useInventoryItemsStockRequest = (
  request: InventoryItemsStockRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => InventoryItemsStockRequest(request),
    queryKey: ['inventory-items', 'stock', request],
  });

export const InventoryItemsStockRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isInventoryEvent(data)) {
    queryClient.invalidateQueries({ queryKey: ['inventory-items', 'stock'] });
  }
};
