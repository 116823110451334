import { isObjectResponse } from '@bas/value-objects';
import type { TimeOffType } from '../types';

export function isTimeOffType(object: unknown): object is TimeOffType {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'TimeOffType'
  );
}
