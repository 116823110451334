import { isRelation, Relation } from '@bas/crm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import {
  Collection,
  EmailAddress,
  ErrorResponse,
  PhoneNumber,
} from '@bas/value-objects';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type CustomersRequestProps = Pagination &
  Filterable & {
    name?: string;
    customerNumber?: string;
    zipCode?: string;
    city?: string;
    emailAddress?: EmailAddress;
    phoneNumber?: PhoneNumber | null;
  };

type Response = AxiosResponse<Collection<Relation>>;

export const CustomersRequest = async ({
  globalFilter,
  zipCode,
  customerNumber,
  city,
  ...params
}: CustomersRequestProps): Promise<Response> => {
  const extraFilters: {
    'address.zipCode'?: string;
    'address.city'?: string;
    'customerInformation.customerNumber'?: string;
  } = {};

  if (zipCode) {
    extraFilters['address.zipCode'] = zipCode;
  }
  if (city) {
    extraFilters['address.city'] = city;
  }

  if (customerNumber) {
    extraFilters['customerInformation.customerNumber'] = customerNumber;
  }

  let url = `api/{tenantId}/customers`;
  if (globalFilter) {
    url += `/search/${globalFilter}`;
  }

  return axios.get(url, {
    params: { ...params, ...extraFilters, globalFilter },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });
};

export const useCustomersRequest = (
  request: CustomersRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    placeholderData: keepPreviousData,
    queryFn: async () => CustomersRequest(request),
    queryKey: ['customers', 'list', JSON.stringify(request)],
  });

export const usePrefetchCustomersRequest = (
  request: CustomersRequestProps
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['customers', 'list', JSON.stringify(request)],
      queryFn: async () => CustomersRequest(request),
    });
  }, [queryClient, request]);
};

export const CustomersRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isRelation(data)) {
    queryClient.invalidateQueries({ queryKey: ['customers', 'list'] });
  }
};
