import { TableCell, TableCellProps, TableRow } from '@bas/ui/web/atoms';
import { Skeleton } from '@mui/material';
import { ReactElement } from 'react';

export type LoadingTableRowProps = {
  numberOfColumns: number;
  columnProps?: Record<number, TableCellProps>;
};

const LoadingTableRow = ({
  numberOfColumns,
  columnProps,
}: LoadingTableRowProps): ReactElement => (
  <TableRow>
    {Array.from({ length: numberOfColumns }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <TableCell key={index} {...(columnProps?.[index] || {})}>
        <Skeleton
          variant="text"
          width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
        />
      </TableCell>
    ))}
  </TableRow>
);

export default LoadingTableRow;
