import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import dayjs, { Dayjs } from 'dayjs';
import { zustandStorage } from './zustandStorage';

type HoursOverviewStore = {
  startDate: Dayjs | Date;
  endDate: Dayjs | Date;
  setDates: ({
    startDate,
    endDate,
  }: {
    startDate: Dayjs | Date;
    endDate: Dayjs | Date;
  }) => void;
  formatHours: boolean;
  toggleFormatHours: () => void;
};

export const useHoursOverviewStore = create<HoursOverviewStore>()(
  persist(
    (set) => ({
      startDate: dayjs().startOf('week'),
      endDate: dayjs().endOf('week'),
      setDates: (dates) => {
        set(dates);
      },
      formatHours: true,
      toggleFormatHours: () => {
        set((state) => ({ formatHours: !state.formatHours }));
      },
    }),
    {
      name: 'hours-overview-store',
      storage: zustandStorage<HoursOverviewStore>(),
      version: 0,
    }
  )
);
