import { isObjectResponse } from '@bas/value-objects';
import { WhatsAppSettings } from '../../types';

export function isWhatsAppSettings(
  object: unknown
): object is WhatsAppSettings {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'WhatsAppSettings'
  );
}
