import { useUserStore } from '@bas/shared/state';
import { Language } from '@bas/value-objects';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import 'dayjs/locale/nl';

export const useSetUserLocale = (): Language | undefined => {
  const language = useUserStore((state) => state.user?.language);

  useEffect(() => {
    dayjs.locale(language || 'nl');
  }, [language]);

  return language;
};
