import { ScanMoversSettingsInputType } from '@bas/integration-domain/input-types';
import {
  Integration,
  isScanMoversSettings,
} from '@bas/integration-domain/models';
import { useUpdateIntegrationSettingsMutation } from '@bas/integration-domain/mutations';
import { EditableScanMoversSettingsBlock } from '@bas/integration-domain/web/organisms';
import { TextField, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, Skeleton, styled, Typography } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export type ScanMoversSettingsTabProps = {
  integration: Integration;
  className?: string;
};

const ScanMoversSettingsTab = ({
  integration,
  className,
}: ScanMoversSettingsTabProps): ReactElement => {
  const webhookUrl = `${import.meta.env.VITE_API_URL}api/public/${
    integration.tenantId
  }/integrations/${integration.integrationId}/new-moving-job-lead`;
  const { mutateAsync: updateIntegrationSettingsMutation } =
    useUpdateIntegrationSettingsMutation();

  const handleUpdateSettings = useCallback(
    async ({ ...settings }: ScanMoversSettingsInputType) => {
      await updateIntegrationSettingsMutation({
        integrationId: integration.integrationId,
        provider: integration.provider,
        settings: {
          provider: integration.provider,
          ...settings,
        },
      });
    },
    [
      integration.integrationId,
      integration.provider,
      updateIntegrationSettingsMutation,
    ]
  );

  return (
    <Grid container spacing={4} className={className}>
      <Grid item xs={12}>
        <EditableScanMoversSettingsBlock
          integration={integration}
          onUpdateSettings={handleUpdateSettings}
        />
      </Grid>
      {isScanMoversSettings(integration.providerSettings) && (
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.webhookUrl" />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {!webhookUrl && <Skeleton variant="text" />}
              {webhookUrl && (
                <TextField
                  id="webhookUrl"
                  value={webhookUrl}
                  variant="outlined"
                  fullWidth
                  sx={{ padding: 0 }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="label.copy" />}>
              <Box>
                <Icon
                  sx={{ cursor: 'pointer' }}
                  icon={faCopy}
                  onClick={() => {
                    const copyText = document.getElementById('webhookUrl');
                    if (copyText instanceof HTMLInputElement) {
                      copyText.select();
                      copyText.setSelectionRange(0, 99999);
                      navigator.clipboard.writeText(copyText.value);
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const StyledScanMoversSettingsTab = styled(ScanMoversSettingsTab)(
  () => `
  .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root {
    width: 100%;
    padding: 0;
  }
`
);
export default StyledScanMoversSettingsTab;
