import { TasksFeatureInputType } from '@bas/tenant-domain/input-types';
import { TasksFeature } from '@bas/tenant-domain/models';
import { TextField, TextFieldNumberFormat } from '@bas/ui/web/atoms';
import { Grid, styled, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type EditableTasksFeatureBlockProps = {
  feature: TasksFeature;
  disabled?: boolean;
  onUpdateTasksFeature: (values: TasksFeatureInputType) => Promise<void>;
};

const EditableTasksFeatureBlock = ({
  feature,
  disabled,
  onUpdateTasksFeature,
  ...args
}: EditableTasksFeatureBlockProps): ReactElement => {
  const [
    {
      howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials,
      howManyDaysAfterMovingDoYouWantToPickupBoxes,
      howManyBusinessDaysBeforeEventDoYouWantToReserveStorage,
      howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes,
      howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns,
      howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate,
      howManyBusinessDaysBeforeEventDoYouWantToRequestPermits,
      howManyBusinessDaysAfterEventItNeedsToBeFinished,
      howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend,
    },
    setDaysInput,
  ] = useState<{
    howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials: number;
    howManyDaysAfterMovingDoYouWantToPickupBoxes: number;
    howManyBusinessDaysBeforeEventDoYouWantToReserveStorage: number;
    howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes: number;
    howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns: number;
    howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate: number;
    howManyBusinessDaysBeforeEventDoYouWantToRequestPermits: number;
    howManyBusinessDaysAfterEventItNeedsToBeFinished: number;
    howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend: number;
  }>({ ...feature });

  useDebounce(
    () => {
      if (
        howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials !==
          feature.howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials ||
        howManyDaysAfterMovingDoYouWantToPickupBoxes !==
          feature.howManyDaysAfterMovingDoYouWantToPickupBoxes ||
        howManyBusinessDaysBeforeEventDoYouWantToReserveStorage !==
          feature.howManyBusinessDaysBeforeEventDoYouWantToReserveStorage ||
        howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes !==
          feature.howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes ||
        howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns !==
          feature.howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns ||
        howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate !==
          feature.howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate ||
        howManyBusinessDaysBeforeEventDoYouWantToRequestPermits !==
          feature.howManyBusinessDaysBeforeEventDoYouWantToRequestPermits ||
        howManyBusinessDaysAfterEventItNeedsToBeFinished !==
          feature.howManyBusinessDaysAfterEventItNeedsToBeFinished ||
        howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend !==
          feature.howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend
      ) {
        onUpdateTasksFeature({
          ...feature,
          howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials,
          howManyDaysAfterMovingDoYouWantToPickupBoxes,
          howManyBusinessDaysBeforeEventDoYouWantToReserveStorage,
          howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes,
          howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns,
          howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate,
          howManyBusinessDaysBeforeEventDoYouWantToRequestPermits,
          howManyBusinessDaysAfterEventItNeedsToBeFinished,
          howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend,
        });
      }
    },
    300,
    [
      howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials,
      howManyDaysAfterMovingDoYouWantToPickupBoxes,
      howManyBusinessDaysBeforeEventDoYouWantToReserveStorage,
      howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes,
      howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns,
      howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate,
      howManyBusinessDaysBeforeEventDoYouWantToRequestPermits,
      howManyBusinessDaysAfterEventItNeedsToBeFinished,
      howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend,
      feature.howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials,
      feature.howManyDaysAfterMovingDoYouWantToPickupBoxes,
      feature.howManyBusinessDaysBeforeEventDoYouWantToReserveStorage,
      feature.howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes,
      feature.howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns,
      feature.howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate,
      feature.howManyBusinessDaysBeforeEventDoYouWantToRequestPermits,
      feature.howManyBusinessDaysAfterEventItNeedsToBeFinished,
      feature.howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend,
    ]
  );

  return (
    <Grid container spacing="12px" {...args}>
      <Grid item xs={12} pt={2}>
        <Typography variant="subtitle1">
          <FormattedMessage id="featureTypes.tasks" />
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={
              howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials
            }
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials:
                  parseFloat(e.target.value),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials"
              values={{
                numberOfDays:
                  howManyBusinessDaysBeforeEventDoYouWantToPlanEmployeesAndMaterials,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyDaysAfterMovingDoYouWantToPickupBoxes}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyDaysAfterMovingDoYouWantToPickupBoxes: parseFloat(
                  e.target.value
                ),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyDaysAfterMovingDoYouWantToPickupBoxes"
              values={{
                numberOfDays: howManyDaysAfterMovingDoYouWantToPickupBoxes,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyBusinessDaysBeforeEventDoYouWantToReserveStorage}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysBeforeEventDoYouWantToReserveStorage:
                  parseFloat(e.target.value),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysBeforeEventDoYouWantToReserveStorage"
              values={{
                numberOfDays:
                  howManyBusinessDaysBeforeEventDoYouWantToReserveStorage,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes:
                  parseFloat(e.target.value),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes"
              values={{
                numberOfDays:
                  howManyBusinessDaysBeforeEventDoYouWantToDeliverBoxes,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns:
                  parseFloat(e.target.value),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns"
              values={{
                numberOfDays:
                  howManyBusinessDaysBeforeMovingDoYouWantToPlaceSigns,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate:
                  parseFloat(e.target.value),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate"
              values={{
                numberOfDays:
                  howManyBusinessDaysBeforeEventDoYouWantToRequestCertificate,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyBusinessDaysBeforeEventDoYouWantToRequestPermits}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysBeforeEventDoYouWantToRequestPermits:
                  parseFloat(e.target.value),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysBeforeEventDoYouWantToRequestPermits"
              values={{
                numberOfDays:
                  howManyBusinessDaysBeforeEventDoYouWantToRequestPermits,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyBusinessDaysAfterEventItNeedsToBeFinished}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysAfterEventItNeedsToBeFinished: parseFloat(
                  e.target.value
                ),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysAfterEventItNeedsToBeFinished"
              values={{
                numberOfDays: howManyBusinessDaysAfterEventItNeedsToBeFinished,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) =>
              setDaysInput((val) => ({
                ...val,
                howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend: parseFloat(
                  e.target.value
                ),
              }))
            }
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.tasks.howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend"
              values={{
                numberOfDays:
                  howManyBusinessDaysAfterEventItInvoiceNeedsToBeSend,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledEditableTasksFeatureBlock = styled(EditableTasksFeatureBlock)(
  () => `
  .MuiFormControl-root {
    width: 36px;
  }
  .MuiInput-input {
    text-align: center;
  }
`
);
export default StyledEditableTasksFeatureBlock;
