export enum Salutation {
  UNKNOWN = '',
  HEER = 'heer',
  DE_HEER_EN_MEVROUW = 'de heer en mevrouw',
  MEVROUW = 'mevrouw',
  FAMILIE = 'familie',
  DHR = 'Dhr.',
  MVR = 'Mevr.',
  FAM = 'Fam.',
  MR = 'Mr',
  MS = 'Ms',
  FAMILY = 'family',
}
