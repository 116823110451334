import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basDishwasher: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'dishwasher',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M79.3 89.8a12.7 12.7 0 1 1 25.4 0 12.7 12.7 0 1 1 -25.4 0zm56.6 12.7a12.7 12.7 0 1 0 0-25.4 12.7 12.7 0 1 0 0 25.4zM9 30.2c0-8.1 6.6-14.6 14.6-14.6H476.3c8.1 0 14.6 6.6 14.6 14.6V440c0 8.1-6.6 14.6-14.6 14.6h-79l-5.9 41H356.3l-5.9-41h-201l-5.9 41H108.5l-5.9-41h-79C15.6 454.6 9 448.1 9 440V30.2zM38.3 44.9v84.9H461.7V44.9H38.3zm0 380.5H461.7V159H353.4v18.6l0 .1c-.2 13.2-6.5 24.8-16.2 32.2c-7 5.4-15.8 8.6-25.3 8.6H192c-9.5 0-18.3-3.2-25.3-8.6c-9.7-7.4-16-19.1-16.2-32.2l0-.1V159H38.3V425.4zM324.1 159H179.8v18.4c.1 3.7 1.9 7.1 4.7 9.3c2.1 1.6 4.6 2.5 7.5 2.5h120c2.8 0 5.4-.9 7.5-2.5c2.9-2.2 4.7-5.6 4.7-9.3V159z',
  ],
};

export default basDishwasher;
