import { isMileageReport, MileageReport } from '@bas/planning-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import Qs from 'qs';
import { useEffect } from 'react';

export type MileageReportsByMaterialIdRequestProps = Pagination &
  Filterable & {
    materialId: Uuid;
    reportStartDate?: Date | Dayjs;
    reportEndDate?: Date | Dayjs;
  };

type Response = AxiosResponse<Collection<MileageReport>>;

export const MileageReportsByMaterialIdRequest = async ({
  reportStartDate,
  reportEndDate,
  materialId,
  ...params
}: MileageReportsByMaterialIdRequestProps): Promise<Response> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryParams: any = { ...params };
  if (reportStartDate) {
    queryParams['reportStartDate[before]'] = formatDate(reportStartDate);
  }
  if (reportEndDate) {
    queryParams['reportEndDate[after]'] = formatDate(reportEndDate);
  }

  return axios.get(`api/{tenantId}/vehicles/${materialId}/mileage-reports`, {
    params: queryParams,
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });
};

export const useMileageReportsByMaterialIdRequest = (
  { materialId, ...request }: MileageReportsByMaterialIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      MileageReportsByMaterialIdRequest({ ...request, materialId }),
    queryKey: [
      'mileage-reports',
      'list',
      materialId,
      ...Object.values(request),
    ],
  });

export const usePrefetchMileageReportsByMaterialIdRequest = ({
  materialId,
  ...request
}: MileageReportsByMaterialIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'mileage-reports',
        'list',
        materialId,
        ...Object.values({ materialId, ...request }),
      ],
      queryFn: async () =>
        MileageReportsByMaterialIdRequest({ materialId, ...request }),
    });
  }, [materialId, queryClient, request]);
};

export const MileageReportsByMaterialIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isMileageReport(data)) {
    queryClient.invalidateQueries({
      queryKey: ['mileage-reports', 'list', data.materialId],
    });
  }
};
