import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basWaterbed: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'waterbed',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M264 134l-12.8-7.8c2.7-4.5 7.6-7.2 12.8-7.2s10.1 2.7 12.8 7.2L264 134zM70 48c-8.3 0-15 6.7-15 15V222H24c-8.3 0-15 6.7-15 15V407c0 8.3 6.7 15 15 15h81l6 42h36l6-42H359l6 42h36l6-42h81c8.3 0 15-6.7 15-15V237c0-8.3-6.7-15-15-15H458V63c0-8.3-6.7-15-15-15H70zM428 222H326.5c-5.6-13.9-13-29-20.2-42.9c-7.4-14.2-14.7-27.4-20.2-37.1c-2.8-4.8-5.1-8.8-6.7-11.5c-.8-1.4-1.4-2.4-1.9-3.2c-.2-.4-.4-.6-.5-.8l-.2-.3s0 0-12.8 7.8c-12.8-7.8-12.8-7.8-12.8-7.8l-.2 .3c-.1 .2-.3 .5-.5 .8c-.4 .7-1.1 1.8-1.9 3.2c-1.6 2.7-3.9 6.7-6.7 11.5c-5.5 9.6-12.9 22.8-20.2 37.1c-7.2 13.9-14.5 29-20.2 42.9H85V78H428V222zm-92 30H473V392H39V252H192c-.6 3.5-1 6.9-1 10c0 40.3 32.7 73 73 73s73-32.7 73-73c0-3.1-.4-6.5-1-10zm-87.7-59.1c5.5-10.6 11-20.6 15.7-29c4.7 8.3 10.2 18.4 15.7 29c7.1 13.8 14.2 28.3 19.3 41.2c5.5 13.6 8 23.1 8 27.9c0 23.7-19.2 43-43 43s-43-19.2-43-43c0-4.8 2.5-14.4 8-27.9c5.2-12.9 12.2-27.4 19.3-41.2z',
  ],
};

export default basWaterbed;
