import {
  List as MuiList,
  ListProps as MuiListProps,
  styled,
} from '@mui/material';

export type ListProps = MuiListProps;

const List = ({ ...args }: ListProps) => <MuiList {...args} />;

List.defaultProps = {
  disablePadding: true,
};

const StyledList = styled(List)(() => ``);
export default StyledList;
