import { InternalTimeOffTypeGroup } from '../types';

export const timeOffTypeGroupsOrder = [
  InternalTimeOffTypeGroup.HOLIDAY,
  InternalTimeOffTypeGroup.COMPENSATORY_LEAVE,
  InternalTimeOffTypeGroup.SICKNESS,
  InternalTimeOffTypeGroup.SPECIAL_LEAVE,
  InternalTimeOffTypeGroup.UNPAID,
  InternalTimeOffTypeGroup.OTHER,
];

export const timeOffTypeGroupsToCalculate = [
  InternalTimeOffTypeGroup.HOLIDAY,
  InternalTimeOffTypeGroup.COMPENSATORY_LEAVE,
];

export const timeOffTypeGroupsShowStatistics = [
  InternalTimeOffTypeGroup.HOLIDAY,
  InternalTimeOffTypeGroup.COMPENSATORY_LEAVE,
  InternalTimeOffTypeGroup.SICKNESS,
];

export const timeOffTypeGroupsWithBuildUp = [
  InternalTimeOffTypeGroup.HOLIDAY,
  InternalTimeOffTypeGroup.COMPENSATORY_LEAVE,
];
