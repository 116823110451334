import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basWardrobe: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'wardrobe',
  icon: [
    40,
    40,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M1.87503 0.859863H38.1259C38.7732 0.859863 39.2978 1.38454 39.2978 2.03177V34.8451C39.2978 35.4923 38.7732 36.017 38.1259 36.017H31.7977L31.3289 39.2983H28.5163L28.0476 36.017H11.9534L11.4846 39.2983H8.67208L8.20331 36.017H1.87503C1.2278 36.017 0.703125 35.4923 0.703125 34.8451V2.03177C0.703125 1.38454 1.2278 0.859863 1.87503 0.859863ZM3.04693 33.6732H11.094L11.094 3.20367H3.04693V33.6732ZM13.4378 33.6732H36.954V27.3449H13.4378V33.6732ZM13.4378 25.0011H36.954V11.0945H26.0163V14.2961L32.4364 18.4297C32.8735 18.7111 33.073 19.2469 32.9263 19.7456C32.7796 20.2444 32.3219 20.5869 31.802 20.5869H17.8129C17.293 20.5869 16.8353 20.2444 16.6886 19.7456C16.5419 19.2469 16.7414 18.7111 17.1785 18.4297L23.6725 14.2486V11.0945H13.4378L13.4378 25.0011ZM13.4378 8.75069H36.954V3.20367H13.4378V8.75069ZM27.817 18.2431L24.8075 16.3054L21.7979 18.2431H27.817Z',
  ],
};

export default basWardrobe;
