import { ReactElement } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { ButtonGroupField, ButtonGroupFieldProps } from '../ButtonGroupField';

export interface ReactHookFormButtonGroupFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ButtonGroupFieldProps, 'name' | 'value' | 'error' | 'onChange'> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
}

const fieldToButtonGroupField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  disabled,
  helperText,
  field: { onChange, onBlur, value },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  ...props
}: ReactHookFormButtonGroupFieldProps<
  TFieldValues,
  TName
>): ButtonGroupFieldProps => {
  const fieldError = error?.message;
  const showError = invalid;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    value,
    ...props,
    onBlur,
    onChange,
  };
};

const ReactHookFormButtonGroupField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  children,
  ...args
}: ReactHookFormButtonGroupFieldProps<TFieldValues, TName>): ReactElement => (
  <ButtonGroupField {...fieldToButtonGroupField(args)}>
    {children}
  </ButtonGroupField>
);

export default ReactHookFormButtonGroupField;
