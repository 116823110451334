export enum EmployeeProjectRole {
  PROJECT_LEADER = 'project-leader',
  FOREMAN = 'foreman',
  APPRAISER = 'appraiser',
  HANDYMAN = 'handyman',
  CO_DRIVER = 'co-driver',
  PACKER = 'packer',
  LIFT_TRUCK = 'lift-truck',
  ATTENDEE = 'attendee',
  INAPPLICABLE = 'inapplicable',
}
