import { List, ListProps, styled } from '@mui/material';
import { ReactElement } from 'react';
import {
  EmployeeListItem,
  EmployeeListItemProps,
  EmployeeListItemVariant,
} from '../EmployeeListItem';

export type EmployeeListProps = ListProps & {
  employeeItems: EmployeeListItemProps[];
  variant?: EmployeeListItemVariant;
  onClickEmployee?: (index: number) => void;
};

const EmployeeList = ({
  employeeItems,
  children,
  variant,
  onClickEmployee,
  ...args
}: EmployeeListProps): ReactElement => (
  <List {...args}>
    {employeeItems.map(
      ({ variant: employeeItemVariant, ...employeeItem }, index) => (
        <EmployeeListItem
          variant={variant || employeeItemVariant}
          {...employeeItem}
          onClick={
            employeeItem.onClick ||
            (onClickEmployee ? () => onClickEmployee(index) : undefined)
          }
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        />
      )
    )}
    {children}
  </List>
);

EmployeeList.defaultProps = {
  variant: undefined,
  onClickEmployee: undefined,
  employeeItems: [],
};

const StyledEmployeeList = styled(EmployeeList)(
  () => `
  padding: 0;
`
);
export default StyledEmployeeList;
