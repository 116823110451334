import {
  SnelStartSettingsInputType,
  SnelStartSettingsInputTypeDefaultValues,
  SnelStartSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import {
  Integration,
  isSnelStartSettings,
} from '@bas/integration-domain/models';
import { SnelStartSettingsContentSection } from '@bas/integration-domain/web/molecules';
import {
  ContentSection,
  ContentSectionEditAction,
  ReactHookForm,
} from '@bas/ui/web/molecules';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditingSnelStartSettingsContentSection } from '../EditingSnelStartSettingsContentSection';

export type EditableSnelStartSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: SnelStartSettingsInputType) => Promise<void>;
};

const EditableSnelStartSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableSnelStartSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<SnelStartSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          ...SnelStartSettingsInputTypeDefaultValues(),
          ...integration.providerSettings,
        }}
        validationSchema={SnelStartSettingsInputTypeValidationBuilder()}
      >
        <EditingSnelStartSettingsContentSection
          integrationId={integration.integrationId}
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  if (!isSnelStartSettings(integration.providerSettings)) {
    return (
      <ContentSection
        actions={[
          <ContentSectionEditAction onClick={() => setIsEditing(true)} />,
        ]}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.settings" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="label.integrationIsNotSetUpYet" />
            </Typography>
          </Grid>
        </Grid>
      </ContentSection>
    );
  }

  return (
    <SnelStartSettingsContentSection
      onEdit={() => setIsEditing(true)}
      integrationId={integration.integrationId}
      providerSettings={integration.providerSettings}
    />
  );
};

export default EditableSnelStartSettingsBlock;
