import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

type TextFieldNumberFormatProps = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  NumericFormatProps<any>,
  'defaultValue'
> & {
  name: string;
  value: string | number | undefined | null;
  defaultValue?: string | number | undefined | null;
  onBlur: () => void;
  onChange: (event: {
    target: { name: string; value: number | string | undefined | null };
  }) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TextFieldNumberFormat: any = forwardRef<
  typeof NumericFormat,
  TextFieldNumberFormatProps
>(
  (
    {
      name,
      onChange,
      onBlur,
      value,
      defaultValue,
      ...rest
    }: TextFieldNumberFormatProps,
    ref
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    const fieldValue = value ?? defaultValue;
    return (
      <NumericFormat
        onBlur={() => onBlur()}
        value={fieldValue === undefined ? '' : fieldValue}
        getInputRef={ref}
        thousandSeparator="."
        decimalSeparator=","
        onValueChange={(values) => {
          const newValue = values.floatValue;
          if (
            newValue !==
            (typeof fieldValue === 'string'
              ? parseFloat(fieldValue)
              : fieldValue)
          ) {
            onChange({
              target: {
                name,
                value: newValue === 0 ? '0' : newValue || null,
              },
            });
          }
        }}
        valueIsNumericString
        {...rest}
      />
    );
  }
);

export default TextFieldNumberFormat;
