import { Event } from '../types';

export function isEvent(object: unknown): object is Event {
  return (
    !!object &&
    Object.prototype.hasOwnProperty.call(object, 'eventId') &&
    Object.prototype.hasOwnProperty.call(object, 'eventType') &&
    Object.prototype.hasOwnProperty.call(object, 'employees')
  );
}
