import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basLaundryBasket: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'laundry-basket',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M263.7 196.1c0-8.1-6.6-14.6-14.6-14.6s-14.6 6.6-14.6 14.6v96.6c0 8.1 6.6 14.6 14.6 14.6s14.6-6.6 14.6-14.6V196.1zm71.2-14.6c8.1 0 14.6 6.6 14.6 14.6v96.6c0 8.1-6.6 14.6-14.6 14.6s-14.6-6.6-14.6-14.6V196.1c0-8.1 6.6-14.6 14.6-14.6zM177.8 196.1c0-8.1-6.6-14.6-14.6-14.6s-14.6 6.6-14.6 14.6v96.6c0 8.1 6.6 14.6 14.6 14.6s14.6-6.6 14.6-14.6V196.1zm-160-66.3c0-8.1 6.6-14.6 14.6-14.6H467.1c8.1 0 14.6 6.6 14.6 14.6s-6.6 14.6-14.6 14.6H455.9c0 .5 0 1.1-.1 1.6L430.1 375.8c-1.1 9.9-9.5 17.3-19.4 17.3H87.3c-9.9 0-18.3-7.5-19.4-17.3L42.3 146c-.1-.5-.1-1.1-.1-1.6H32.4c-8.1 0-14.6-6.6-14.6-14.6zm53.8 14.6L96.1 363.9H402l24.5-219.5H71.6z',
  ],
};

export default basLaundryBasket;
