import { ErrorResponse } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ValidateVatNumberMutationProps = {
  vatNumber: string;
};

export const ValidateVatNumberMutation = async ({
  ...values
}: ValidateVatNumberMutationProps): Promise<AxiosResponse> =>
  axios.post(`api/validate-vat-number`, {
    ...values,
  });

export const useValidateVatNumberMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ValidateVatNumberMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ValidateVatNumberMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ValidateVatNumberMutationProps
  >({ mutationFn: ValidateVatNumberMutation, throwOnError: false, ...options });
