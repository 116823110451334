/* eslint-disable func-names */
import { parsePhoneNumber } from 'awesome-phonenumber';
import get from 'lodash/get';
import * as Yup from 'yup';
import { getDefaultCountry } from './countryService';

export const registerCustomYupTypes = (
  // eslint-disable-next-line
  yupLocale: any,
  validateVatNumber: (value: string) => Promise<boolean>
): void => {
  Yup.addMethod(Yup.string, 'phone', function () {
    return this.test({
      name: 'phone',
      message: yupLocale?.mixed?.phone || yupLocale?.mixed?.default,
      test: async (value?: string) => {
        if (typeof value === 'undefined' || value === null || value === '') {
          return true;
        }

        const phoneNumber = parsePhoneNumber(value, {
          regionCode: getDefaultCountry(),
        });

        return phoneNumber.valid;
      },
    });
  });
  Yup.addMethod(Yup.string, 'vatNumber', function () {
    return this.test({
      name: 'vatNumber',
      message: yupLocale?.mixed?.vatNumber || yupLocale?.mixed?.default,
      test: async (value?: string) => {
        if (typeof value === 'undefined' || value === null || value === '') {
          return true;
        }

        const result = await validateVatNumber(value);

        console.log({ result, value });

        return result;
      },
    });
  });

  Yup.addMethod(
    Yup.object,
    'hasUniqueType',

    function (path, parentPath, allowValues, formatMessage, translationPath) {
      return this.test({
        name: 'hasUniqueType',
        test: (value, { createError, parent, path: objectPath }) => {
          if (!Array.isArray(parent)) {
            throw new Error(
              `Parent is not an array, it is an "${typeof parent}"`
            );
          }

          const uniqueValue = get(value, path);
          if (!value || allowValues.includes(uniqueValue)) {
            return true;
          }

          const index = parent.findIndex(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (subValue: any) => get(subValue, path) === uniqueValue
          );

          if (objectPath.includes(`${parentPath}[${index}]`)) {
            return true;
          }

          return createError({
            path: `${objectPath}.${path}`,
            message: formatMessage(
              {
                id: `${translationPath}.validationError`,
              },
              {
                [path]: formatMessage({
                  id: `${translationPath}.${uniqueValue}`,
                }),
              }
            ),
          });
        },
      });
    }
  );
};
