import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Uuid } from '@bas/value-objects';
import { zustandStorage } from './zustandStorage';

type RequestFilters = {
  [key: string]: string | number | string[] | boolean;
};

type InvoiceBulkStore = {
  checkedBasedUponFilter: boolean;
  checkedInvoiceIds: Uuid[];
  requestFilters?: RequestFilters;
  toggleInvoice: (invoiceId: Uuid) => void;
  checkInvoice: (invoiceId: Uuid) => void;
  uncheckInvoice: (invoiceId: Uuid) => void;
  setCheckedBasedUponFilter: (checked: boolean) => void;
  setCheckedInvoiceIds: (invoiceIds: Uuid[]) => void;
  setRequestFilters: (requestFilters: RequestFilters) => void;
  setInvoiceBulkState: (invoiceBulkState: {
    checkedBasedUponFilter: boolean;
    checkedInvoiceIds: Uuid[];
    requestFilters?: RequestFilters;
  }) => void;
};

export const useInvoiceBulkStore = create<InvoiceBulkStore>()(
  persist(
    (set) => ({
      checkedBasedUponFilter: false,
      checkedInvoiceIds: [],
      requestFilters: undefined,
      toggleInvoice: (invoiceId: Uuid) =>
        set((state) => {
          if (state.checkedInvoiceIds.includes(invoiceId)) {
            return {
              checkedInvoiceIds: state.checkedInvoiceIds.filter(
                (id) => id !== invoiceId
              ),
            };
          }

          return {
            checkedInvoiceIds: [...state.checkedInvoiceIds, invoiceId],
          };
        }),
      checkInvoice: (invoiceId: Uuid) =>
        set((state) => ({
          checkedInvoiceIds: [...state.checkedInvoiceIds, invoiceId],
        })),
      uncheckInvoice: (invoiceId: Uuid) =>
        set((state) => ({
          checkedInvoiceIds: state.checkedInvoiceIds.filter(
            (id) => id !== invoiceId
          ),
        })),
      setCheckedBasedUponFilter: (checked: boolean) =>
        set({ checkedBasedUponFilter: checked }),
      setCheckedInvoiceIds: (invoiceIds: Uuid[]) =>
        set({ checkedInvoiceIds: invoiceIds }),
      setRequestFilters: (requestFilters: RequestFilters) =>
        set({ requestFilters }),
      setInvoiceBulkState: (invoiceBulkState) => set(invoiceBulkState),
    }),
    {
      name: 'invoice-bulk-store',
      storage: zustandStorage<InvoiceBulkStore>(),
    }
  )
);
