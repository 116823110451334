import {
  useAxiosInterceptors,
  useFlagConfig,
  useLogout,
  useMonitorAndRefreshAccessToken,
  useRetrieveAndSetTenantStores,
  useRetrieveGlobalStates,
  useSetUserLocale,
  useYupTranslations,
} from '@bas/shared/hooks';
import {
  useAppStore,
  useAuthStore,
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import nlTranslations from '@bas/translations/nlTranslations.json';
import {
  BasUpdateMessage,
  BootIntercom,
  MercureSubscriber,
} from '@bas/ui/web/organisms';
import { AppErrorBoundary, SecureRoute } from '@bas/ui/web/pages';
import { ActiveSessionListener, IdleTimer } from '@bas/ui/web/templates';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import FlagProvider from '@unleash/proxy-client-react';
import axios from 'axios';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import CountryService from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import nlCountries from 'i18n-iso-countries/langs/nl.json';
import { lazy, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { v4 } from 'uuid';
import { Backoffice } from '../Backoffice';
import { BootPage } from '../BootPage';
import * as serviceWorker from '../serviceWorkerRegistration';

const LoginPage = lazy(
  () => import('@bas/authentication-domain/web/pages/LoginPage/LoginPage')
);
const ForgotPasswordPage = lazy(
  () =>
    import(
      '@bas/authentication-domain/web/pages/ForgotPasswordPage/ForgotPasswordPage'
    )
);
const ResetPasswordPage = lazy(
  () =>
    import(
      '@bas/authentication-domain/web/pages/ResetPasswordPage/ResetPasswordPage'
    )
);
const LogoutPage = lazy(
  () => import('@bas/authentication-domain/web/pages/LogoutPage/LogoutPage')
);

const PublicQuotePage = lazy(() =>
  import('@bas/public-domain/web/pages').then((module) => ({
    default: module.PublicQuotePage,
  }))
);

const FinishedPaymentPage = lazy(() =>
  import('@bas/public-domain/web/pages').then((module) => ({
    default: module.FinishedPaymentPage,
  }))
);

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(weekYear);
dayjs.extend(weekday);

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.headers['Content-Type'] = 'application/ld+json';

CountryService.registerLocale(enCountries);
CountryService.registerLocale(nlCountries);

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_KEY || '');

const onResize = () => {
  const vh =
    document.documentElement?.clientHeight || window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const BootApp = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const { isComplete } = useAxiosInterceptors();

  const logout = useLogout();
  useMonitorAndRefreshAccessToken(logout);
  useRetrieveGlobalStates();
  useYupTranslations();
  useRetrieveAndSetTenantStores(window.location.hostname);

  const { tenantId, internalTenantId } = useTenantStore((state) => ({
    tenantId: state.tenant?.tenantId,
    internalTenantId: state.internalTenant?.tenantId,
  }));
  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const { isAuthenticated, initialized, userId } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    initialized: state.initialized,
    employeeId: state.employeeId,
    userId: state.userId,
  }));

  useEffect(() => {
    if (window.location.hostname.split('.')[0]) {
      Sentry.setTag('tenant', window.location.hostname.split('.')[0]);
    }
  }, []);

  useEffect(() => {
    Sentry.setTag('tenant_id', tenantId);
  }, [tenantId]);

  useEffect(() => {
    Sentry.setTag('employee_id', employeeId);
  }, [employeeId]);

  useEffect(() => {
    Sentry.setTag('user_id', userId);
  }, [userId]);

  const user = useUserStore((state) => state.user);

  useEffect(() => {
    Sentry.setUser(
      user
        ? {
            id: user.userId as string,
            email: user.emailAddress as string,
            username: user.emailAddress as string,
          }
        : null
    );
  }, [user]);

  const config = useFlagConfig({
    clientKey: import.meta.env.VITE_UNLEASH_ACCESS_TOKEN || '',
  });

  const fallBack = useCallback(
    ({
      eventId,
      error,
      resetError,
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      error: Error;
      // eslint-disable-next-line react/no-unused-prop-types
      eventId: string | null;
      // eslint-disable-next-line react/no-unused-prop-types
      resetError: () => void;
    }) => (
      <AppErrorBoundary
        resetError={resetError}
        eventId={eventId}
        error={error}
      />
    ),
    []
  );

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    if (registration.waiting?.scriptURL.includes('OneSignalSDKWorker')) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('Found new service worker update', registration.waiting);
    setWaitingWorker(registration.waiting);
  }, []);
  const { resetAvailableVersion } = useAppStore();

  const onSWSuccess = useCallback(() => {
    setWaitingWorker(null);
    resetAvailableVersion();
    window.location.reload();
  }, [resetAvailableVersion]);

  useEffect(() => {
    (async () => {
      if (!window.localStorage.getItem('deviceId')) {
        window.localStorage.setItem('deviceId', v4());
      }

      serviceWorker.register({
        onUpdateInstalled: onSWUpdate,
        onSuccess: onSWSuccess,
      });

      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        if (serviceWorkerRegistration.waiting) {
          onSWUpdate(serviceWorkerRegistration);
        }
      });
    })();
  }, [onSWSuccess, onSWUpdate]);

  const { availableVersion } = useAppStore();
  useEffect(() => {
    if (!availableVersion) {
      return;
    }

    serviceWorker.register({
      onUpdateInstalled: onSWUpdate,
      onSuccess: onSWSuccess,
    });
  }, [availableVersion, onSWSuccess, onSWUpdate]);

  const [roles] = useUserStore((state) => [state.user?.roles]);

  if (
    ((!tenantId ||
      (!employeeId && !roles?.includes('ROLE_SUPER_ADMIN')) ||
      !isComplete) &&
      isAuthenticated) ||
    !initialized
  ) {
    return <BootPage />;
  }

  return (
    <FlagProvider config={config}>
      <ErrorBoundary fallback={fallBack}>
        <ActiveSessionListener>
          <IdleTimer>
            <MercureSubscriber />
            {import.meta.env.MODE !== 'production' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <BasUpdateMessage waitingWorker={waitingWorker} />
            <BootIntercom />

            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route
                path="/create-password/:code"
                element={<ResetPasswordPage create />}
              />
              <Route
                path="/reset-password/:code"
                element={<ResetPasswordPage create={false} />}
              />
              <Route path="/logout" element={<LogoutPage />} />
              <Route
                path="/financial/quotes/accept/*"
                element={<PublicQuotePage />}
              />
              <Route
                path="/financial/invoices/was-already-paid/:code"
                element={<FinishedPaymentPage />}
              />
              <Route
                path="/financial/invoices/payment-finished/:code"
                element={<FinishedPaymentPage />}
              />
              <Route
                path="/financial/invoices/payment-failed/:code"
                element={<FinishedPaymentPage />}
              />
              {!!internalTenantId && (
                <Route
                  path="/app/*"
                  element={
                    <SecureRoute>
                      <div>go to new app</div>
                    </SecureRoute>
                  }
                />
              )}
              {internalTenantId ? (
                <Route
                  path="/*"
                  element={
                    <SecureRoute>
                      <Backoffice />
                    </SecureRoute>
                  }
                />
              ) : (
                <Route
                  path="/*"
                  element={
                    <SecureRoute>
                      <BootPage />
                    </SecureRoute>
                  }
                />
              )}
            </Routes>
          </IdleTimer>
        </ActiveSessionListener>
      </ErrorBoundary>
    </FlagProvider>
  );
};

export const App = () => {
  const language = useSetUserLocale();

  return (
    <IntlProvider locale="nl" messages={nlTranslations}>
      <LocalizationProvider
        adapterLocale={language || 'nl'}
        dateAdapter={AdapterDayjs}
      >
        <BootApp />
      </LocalizationProvider>
    </IntlProvider>
  );
};

export default App;
