import {
  Integration,
  isWhatsAppSettings,
} from '@bas/integration-domain/models';
import {
  ConnectedWhatsAppBusinessesBlock,
  ConnectedWhatsAppNumbersBlock,
} from '@bas/integration-domain/web/molecules';
import { Grid } from '@mui/material';
import { ReactElement, useCallback } from 'react';

export type WhatsAppSettingsTabProps = {
  integration: Integration;
};

const WhatsAppSettingsTab = ({
  integration,
}: WhatsAppSettingsTabProps): ReactElement => {
  const handleConnectExtraNumber = useCallback(() => {
    window.open(
      `${import.meta.env.VITE_API_URL}connect/${integration.provider}/${
        integration.integrationId
      }`
    );
  }, [integration.integrationId, integration.provider]);

  return (
    <Grid container spacing={4}>
      {isWhatsAppSettings(integration.providerSettings) && (
        <>
          <Grid item xs={12}>
            <ConnectedWhatsAppNumbersBlock
              providerSettings={integration.providerSettings}
              onConnectExtraNumber={handleConnectExtraNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <ConnectedWhatsAppBusinessesBlock
              providerSettings={integration.providerSettings}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default WhatsAppSettingsTab;
