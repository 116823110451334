import { isObjectResponse } from '@bas/value-objects';
import type { TimeEntry } from '../types';

export function isTimeEntry(object: unknown): object is TimeEntry {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    (object['@type'] === 'TimeEntry' || object['@type'] === 'remove_time_entry')
  );
}
