import { Money } from '@bas/value-objects';
import { FormattedNumber } from 'react-intl';

const FormattedCurrency = ({ amount, currency }: Money) => (
  <FormattedNumber
    {...{
      value: amount / 100,
      style: 'currency',
      currency: currency || 'EUR',
    }}
  />
);

export default FormattedCurrency;
