import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  InventoryOutstandingStock,
  isInventoryEvent,
} from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type OutstandingStockByProjectIdRequestProps = Pagination &
  Filterable & {
    projectId: Uuid;
  };

type Response = AxiosResponse<Collection<InventoryOutstandingStock>>;

export const OutstandingStockByProjectIdRequest = async ({
  projectId,
  perPage,
  page,
  ...params
}: OutstandingStockByProjectIdRequestProps): Promise<
  AxiosResponse<Collection<InventoryOutstandingStock>>
> =>
  axios.get(
    `api/{tenantId}/inventory/item/outstanding-by-project/${projectId}`,
    {
      params: { ...params, perPage: perPage || 100, page: page || 1 },
    }
  );

export const useOutstandingStockByProjectIdRequest = (
  request: OutstandingStockByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => OutstandingStockByProjectIdRequest(request),
    queryKey: [
      'moving-boxes',
      'outstanding',
      request.projectId,
      ...Object.values(request),
    ],
  });

export const OutstandingStockByProjectIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isInventoryEvent(data) && data.projectId) {
    queryClient.invalidateQueries({
      queryKey: ['moving-boxes', 'outstanding', data.projectId],
    });
  }
};
