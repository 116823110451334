import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basBathroomCabinet: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'bathroom-cabinet',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M264.6 93.2c0-19.7 15.9-35.6 35.6-35.6s35.6 15.9 35.6 35.6c0 8.1 6.6 14.6 14.6 14.6s14.6-6.6 14.6-14.6c0-35.8-29-64.9-64.9-64.9s-64.9 29-64.9 64.9V223.4H206.1v-42c0-8.1-6.6-14.6-14.6-14.6s-14.6 6.6-14.6 14.6v42H23.7C15.6 223.4 9 230 9 238.1V411.7c0 8.1 6.6 14.6 14.6 14.6h79l5.9 41h35.1l5.9-41h201l5.9 41h35.1l5.9-41h79c8.1 0 14.6-6.6 14.6-14.6V238.1c0-8.1-6.6-14.6-14.6-14.6H324.2v-42c0-8.1-6.6-14.6-14.6-14.6s-14.6 6.6-14.6 14.6v42H264.6V93.2zM38.3 252.7H235.4V397.1H38.3V252.7zM461.7 397.1H264.6V252.7H461.7V397.1z',
  ],
};

export default basBathroomCabinet;
