import { Money, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface FixedBillingMomentLineInputType {
  description: string;
  linePrice?: Money | null;
  quantity: number;
  vatCodeId: Uuid;
  isPerEvent: boolean | null;
}

export const FixedBillingMomentLineInputTypeDefaultValues =
  (): FixedBillingMomentLineInputType => ({
    description: '',
    linePrice: {
      amount: 0,
      currency: 'EUR',
    },
    quantity: 1,
    vatCodeId: '',
    isPerEvent: false,
  });

export const FixedBillingMomentLineInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FixedBillingMomentLineInputType> =>
    Yup.object({
      description: Yup.string().required().ensure().label('label.description'),
      quantity: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value
        )
        .nullable()
        .required()
        .label('label.quantity'),
      linePrice: Yup.object({
        amount: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value
          )
          .nullable()
          .required()
          .label('label.paymentAmount'),
        currency: Yup.string().required(),
      }).optional(),
      vatCodeId: Yup.string().required().ensure().label('label.vatCodeId'),
      isPerEvent: Yup.boolean().required().nullable().label('label.isPerEvent'),
    });
