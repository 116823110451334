import * as Yup from 'yup';
import {
  ConnectIntegrationInputType,
  ConnectIntegrationInputTypeValidationBuilder,
} from '../connectIntegrationInputType';

export type ConnectSchoutenZekerheidIntegrationInputType =
  ConnectIntegrationInputType &
    (
      | { apiKeyPerIdentity: boolean }
      | {
          apiKey: string;
          apiKeyPerIdentity: false;
        }
      | {
          apiKeyPerIdentity: true;

          apiKeys: {
            apiKey: string;
            identityId: string;
          }[];
        }
    );

export const ConnectSchoutenZekerheidIntegrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectSchoutenZekerheidIntegrationInputType> =>
    Yup.object({
      apiKeyPerIdentity: Yup.boolean().required(),
    })
      .when('apiKeyPerIdentity', {
        is: false,
        then: (schema) =>
          schema.concat(
            Yup.object({
              apiKey: Yup.string().required().label('label.apiKey'),
            })
          ),
        otherwise: (schema) =>
          schema.concat(
            Yup.object({
              apiKeys: Yup.array()
                .of(
                  Yup.object({
                    apiKey: Yup.string().label('label.apiKey').required(),
                    identityId: Yup.string()
                      .label('label.identityId')
                      .required(),
                  })
                )
                .required(),
            })
          ),
      })
      .concat(ConnectIntegrationInputTypeValidationBuilder());
