import { colors } from '@bas/theme';
import { FormControl, FormControlProps } from '@mui/base';
import {
  FormHelperText,
  FormHelperTextProps,
  InputLabel,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

export type ButtonGroupFieldOption = {
  disabled?: boolean;
  value: string | boolean;
  label: ReactElement | string;
};

export type ButtonGroupFieldProps = FormControlProps & {
  toggleButtonGroup?: ToggleButtonGroupProps;
  value: string | boolean;
  options: ButtonGroupFieldOption[];
  label?: ReactElement | string;
  disabled?: boolean;
  onChange: (newValue: string) => void;
  error?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: ReactNode | string;
  light?: boolean;
  desktop?: boolean;
  allowNull?: boolean;
};

const ButtonGroupField = ({
  toggleButtonGroup,
  value,
  options,
  label,
  onChange,
  disabled,
  error,
  FormHelperTextProps: helperProps,
  helperText,
  light,
  desktop,
  className,
  allowNull,
  ...args
}: ButtonGroupFieldProps) => (
  <FormControl
    className={clsx(
      className,
      { 'Bas-ButtonGroupField-Light': light },
      { 'Bas-ButtonGroupField-Desktop': desktop }
    )}
    {...args}
    error={error}
  >
    {label && (
      <InputLabel shrink={!!value || value === false} error={error}>
        {label}
      </InputLabel>
    )}
    <ToggleButtonGroup
      value={value}
      onChange={(e, newValue) =>
        (newValue !== null || allowNull) && onChange(newValue)
      }
      disabled={disabled}
      {...toggleButtonGroup}
      className={clsx(toggleButtonGroup?.className, {
        'Bas-ButtonGroupField-Error': error,
      })}
    >
      {options.map((option) => (
        <ToggleButton
          key={option.value.toString()}
          className="Bas-ButtonGroupField-Button"
          disabled={disabled || option.disabled}
          value={option.value}
          disableFocusRipple
          disableRipple
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
    {helperText && (
      <FormHelperText {...helperProps} error={error}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
);

ButtonGroupField.defaultProps = {
  light: false,
  allowNull: true,
};

const StyledButtonGroupField = styled(ButtonGroupField)(
  ({ theme }) => `

  .MuiInputLabel-root.MuiInputLabel-shrink, .MuiFormLabel-colorPrimary {
    color: ${colors.lila[700]};
  }

  &.Bas-ButtonGroupField-Light {
    .MuiInputLabel-root.MuiInputLabel-shrink, .MuiFormLabel-colorPrimary {
      color: ${colors.lila[100]};
    }
  }

  .MuiToggleButtonGroup-root {
    &.Bas-ButtonGroupField-Error {
      .Bas-ButtonGroupField-Button.Mui-selected {
        background: ${colors.red[500]};
        border: 1px solid ${colors.red[700]};
      }
    }

    .Bas-ButtonGroupField-Button {
      box-shadow: none;
      color: ${colors.lila[800]};
      background: ${theme.palette.common.white};
      border: 1px solid ${colors.lila[300]};
      border-radius: 4px;
      text-transform: none;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
        margin-left: 0px;
        border: 1px solid ${colors.lila[300]};
      }

      &.MuiToggleButtonGroup-grouped:not(:first-of-type), &.MuiToggleButtonGroup-grouped:not(:last-of-type) {
        border-radius: 4px;
      }

      &.Mui-selected, &.MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected {
        background: ${colors.blue[500]};
        border: 1px solid ${colors.blue[700]};
        color: ${theme.palette.common.white};
      }

      &.Mui-disabled, &.MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-disabled {
        background: ${colors.lila[400]};
        border: 1px solid ${colors.lila[400]};
        color: ${theme.palette.common.white};
      }
    }
  }

  &.Bas-ButtonGroupField-Desktop {

    .Bas-ButtonGroupField-Button {
      box-shadow: none;
      color: ${colors.lila[700]};
      background: ${theme.palette.common.white};
      border: 1px solid ${colors.lila[400]};
      border-radius: 5px;
      text-transform: none;
      height: 45px;
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
        margin-left: 0px;
        border: 1px solid ${colors.lila[300]};
      }

      &.MuiToggleButtonGroup-grouped:not(:first-of-type), &.MuiToggleButtonGroup-grouped:not(:last-of-type) {
        border-radius: 4px;
      }

      &.Mui-selected, &.MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected {
        background-color: #F6F9FF;
        border: 1px solid ${colors.blue[200]};
        color: ${colors.blue[500]};
      }

      &.Mui-disabled, &.MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-disabled {
        background: ${colors.lila[400]};
        border: 1px solid ${colors.lila[400]};
        color: ${theme.palette.common.white};
      }
    }
  }
`
);
export default StyledButtonGroupField;
