import { Integration, isIntegration } from '@bas/integration-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type IntegrationByIntegrationIdRequestProps = {
  integrationId: Uuid;
};

type Response = AxiosResponse<Integration>;

export const IntegrationByIntegrationIdRequest = async ({
  integrationId,
  ...params
}: IntegrationByIntegrationIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/integrations/${integrationId}`, {
    params: { ...params },
  });

export const useIntegrationByIntegrationIdRequest = (
  request: IntegrationByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => IntegrationByIntegrationIdRequest(request),
    queryKey: ['integrations', 'detail', request.integrationId],
  });

export const IntegrationByIntegrationIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isIntegration(data)) {
    queryClient.invalidateQueries({
      queryKey: ['integrations', 'detail', data.integrationId],
    });
  }
};
