import { isObjectResponse } from '@bas/value-objects';
import { ExternalEmployee } from '../types';

export function isExternalEmployee(
  object: unknown
): object is ExternalEmployee {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'ExternalEmployee'
  );
}
