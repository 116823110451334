import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ApproveBatchDataMutationProps = {
  integrationId: Uuid;
  batchId: Uuid;
  datas: { dataId: Uuid }[];
};

export const ApproveBatchDataMutation = async ({
  integrationId,
  batchId,
  ...values
}: ApproveBatchDataMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/integrations/${integrationId}/batches/${batchId}/batch-datas/approve`,
    {
      integrationId,
      batchId,
      ...values,
    }
  );

export const useApproveBatchDataMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveBatchDataMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ApproveBatchDataMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ApproveBatchDataMutationProps
  >({
    mutationFn: ApproveBatchDataMutation,
    throwOnError: false,
    ...options,
  });
