import { Pagination, TablePagination } from '@bas/ui/web/molecules';
import { Grid, styled } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, MouseEvent } from 'react';

export type FullPaginationProps = {
  page: number;
  itemCount: number;
  rowsPerPage: number;
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | ChangeEvent<unknown> | null,
    page: number
  ) => void;
  onRowsPerPageChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  rowsPerPageOptions?: Array<number>;
  hideNextButton?: boolean;
  hideLastButton?: boolean;
  siblingCount?: number;
  boundaryCount?: number;
};

const FullPagination = ({
  page,
  itemCount,
  rowsPerPage,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
  hideNextButton,
  hideLastButton,
  siblingCount,
  boundaryCount,
}: FullPaginationProps) => (
  <Grid container alignItems="center" justifyContent="space-between">
    <Grid item>
      <Pagination
        page={page}
        count={Math.ceil(itemCount / rowsPerPage)}
        onChange={(e, pageIndex) => onPageChange(e, pageIndex)}
        shape="rounded"
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        showFirstButton
        showLastButton
      />
    </Grid>
    <Grid item>
      <TablePagination
        page={page - 1}
        count={itemCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={(e, pageIndex) => onPageChange(e, pageIndex + 1)}
        onRowsPerPageChange={onRowsPerPageChange}
        hideNextButton={hideNextButton}
        hideLastButton={hideLastButton}
      />
    </Grid>
  </Grid>
);

FullPagination.defaultProps = {
  rowsPerPageOptions: [5, 10, 25, 50],
  hideNextButton: true,
  hideLastButton: true,
  siblingCount: 1,
  boundaryCount: 0,
};

const StyledFullPagination = styled(FullPagination)(
  () => `
`
);
export default StyledFullPagination;
