import {
  VerhuisOffertesSettingsInputType,
  VerhuisOffertesSettingsInputTypeDefaultValues,
  VerhuisOffertesSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { Integration } from '@bas/integration-domain/models';
import { VerhuisOffertesSettingsContentSection } from '@bas/integration-domain/web/molecules';
import { ReactHookForm } from '@bas/ui/web/molecules';
import { ReactElement, useState } from 'react';
import { EditingVerhuisOffertesSettingsContentSection } from '../EditingVerhuisOffertesSettingsContentSection';

export type EditableVerhuisOffertesSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: VerhuisOffertesSettingsInputType) => Promise<void>;
};

const EditableVerhuisOffertesSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableVerhuisOffertesSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<VerhuisOffertesSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          ...VerhuisOffertesSettingsInputTypeDefaultValues(),
          ...integration.providerSettings,
        }}
        validationSchema={VerhuisOffertesSettingsInputTypeValidationBuilder()}
      >
        <EditingVerhuisOffertesSettingsContentSection
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  return (
    <VerhuisOffertesSettingsContentSection
      onEdit={() => setIsEditing(true)}
      integration={integration}
    />
  );
};

export default EditableVerhuisOffertesSettingsBlock;
