import { isObjectResponse } from '@bas/value-objects';
import { ExternalInvoice } from '../types';

export function isExternalInvoice(object: unknown): object is ExternalInvoice {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'ExternalInvoice'
  );
}
