import { isProject, Project } from '@bas/project-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ProjectsByProjectIdsRequestProps = Pagination & {
  projectIds: string[];
};

type Response = AxiosResponse<Collection<Project>>;
type QueryKeyType = QueryKey & {
  [0]: 'projects';
  [1]: 'list';
  [2]: Uuid[];
};

function isCurrentQueryKey(object: unknown): object is QueryKeyType {
  return (
    Array.isArray(object) &&
    object[0] === 'projects' &&
    object[1] === 'list' &&
    object[2] &&
    Array.isArray(object[2])
  );
}

export const ProjectsByProjectIdsRequest = async ({
  projectIds,
  ...params
}: ProjectsByProjectIdsRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/projects', {
    params: { projectId: projectIds, ...params },
  });

export const useProjectsByProjectIdsRequest = (
  request: ProjectsByProjectIdsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    enabled: request.projectIds.length > 0 && !!request.projectIds,
    ...options,
    queryFn: async () => ProjectsByProjectIdsRequest({ ...request }),
    queryKey: ['projects', 'list', request.projectIds],
  });

export const ProjectsByProjectIdsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isProject(data)) {
    queryClient.setQueriesData<Response | undefined>(
      {
        predicate: ({ queryKey }) => {
          if (!isCurrentQueryKey(queryKey)) {
            return false;
          }

          return (
            queryKey[0] === 'projects' &&
            queryKey[1] === 'list' &&
            queryKey[2].includes(data.projectId)
          );
        },
      },
      (previous) => {
        if (!previous) {
          return previous;
        }

        return {
          ...previous,
          data: {
            ...previous.data,
            'hydra:member': previous.data['hydra:member']?.map((project) =>
              project.projectId === data.projectId ? data : project
            ),
          },
        };
      }
    );
  }
};
