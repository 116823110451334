import { isParticipant } from './Participant';
import { EmployeeParticipant, ParticipantType } from '../../types';

export function isEmployeeParticipant(
  object: unknown
): object is EmployeeParticipant {
  return (
    isParticipant(object) && object.participantType === ParticipantType.EMPLOYEE
  );
}
