export enum QuoteEventType {
  SendingQuoteFailedEvent = 'sending-quote-failed-event',
  QuoteOpenedEvent = 'quote-opened-event',
  QuoteFinalizedEvent = 'quote-finalized-event',
  QuoteDeclinedEvent = 'quote-declined-event',
  QuoteAcceptedEvent = 'quote-accepted-event',
  QuoteSentEvent = 'quote-sent-event',
  QuoteExpiredEvent = 'quote-expired-event',
  QuoteReminderSentEvent = 'quote-reminder-sent-event',
  QuoteCanceledEvent = 'quote-canceled-event',
  ManuallyAcceptedQuoteEvent = 'manually-accepted-quote-event',
}
