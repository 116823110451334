import {
  InvoiceByInvoiceIdRequestQueryInvalidator,
  InvoicesRequestInvalidator,
  InvoiceStatisticsRequestInvalidator,
  PaymentMethodsRequestInvalidator,
  QuoteAcceptedPdfByQuoteIdRequestInvalidator,
  QuoteByQuoteIdRequestInvalidator,
  QuotePdfByQuoteIdRequestInvalidator,
  QuotesRequestInvalidator,
  QuoteStatisticsRequestInvalidator,
  TurnoverGroupByTurnoverGroupIdRequestInvalidator,
  TurnoverGroupsRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  InvoiceByInvoiceIdRequestQueryInvalidator,
  InvoicesRequestInvalidator,
  InvoiceStatisticsRequestInvalidator,
  PaymentMethodsRequestInvalidator,
  QuoteAcceptedPdfByQuoteIdRequestInvalidator,
  QuoteByQuoteIdRequestInvalidator,
  QuotePdfByQuoteIdRequestInvalidator,
  QuotesRequestInvalidator,
  QuoteStatisticsRequestInvalidator,
  TurnoverGroupByTurnoverGroupIdRequestInvalidator,
  TurnoverGroupsRequestInvalidator,
];
