import { isQuoteEvent } from './QuoteEvent';
import { QuoteAcceptedEvent, QuoteEventType } from '../types';

export function isQuoteAcceptedEvent(
  object: unknown
): object is QuoteAcceptedEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.QuoteAcceptedEvent
  );
}
