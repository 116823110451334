import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import { ReactNode } from 'react';

export type ProgressValue = {
  value: number;
  color: string;
};

export type StackedCircularProgressProps = CircularProgressProps & {
  values: Array<ProgressValue>;
  children?: ReactNode;
  hideFirst?: boolean;
  dark?: boolean;
  defaultColor?: string;
};

const StackedCircularProgress = ({
  values,
  children,
  hideFirst,
  dark,
  defaultColor,
  sx,
  ...props
}: StackedCircularProgressProps) => (
  <Box sx={{ position: 'relative' }}>
    {children && <Box>{children}</Box>}
    {!hideFirst && (
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            defaultColor ||
            theme.palette.grey[
              theme.palette.mode === 'light' && !dark ? 400 : 800
            ],
          ...(sx || {}),
        }}
        {...props}
        value={100}
      />
    )}
    {values
      .sort(({ value: aValue }, { value: bValue }) =>
        aValue > bValue ? -1 : 1
      )
      .map(({ value, color }) => (
        <CircularProgress
          key={color}
          variant="determinate"
          sx={{
            color,
            position: 'absolute',
            left: 0,
            top: 0,
            ...(sx || {}),
          }}
          {...props}
          value={value > 100 ? 100 : value}
        />
      ))}
  </Box>
);

export default StackedCircularProgress;
