export enum BuildingType {
  UNKNOWN = '',
  APARTMENT = 'apartment',
  HOUSE = 'house',
  BUNGALOW = 'bungalow',
  OFFICE = 'office',
  STORAGE = 'storage',
  DRIVE_IN = 'drive-in',
  DOUBLE_APARTMENT = 'double-apartment',
  STUDIO = 'studio',
  VILLA = 'villa',
  FARM = 'farm',
  TWO_UNDER_ONE_ROOF = 'two-under-one-roof',
  MANSION = 'mansion',
  UPSTAIRS_APARTMENT = 'upstairs-apartment',
  DETACHED_HOUSE = 'detached-house',
  TERRACED_HOUSE = 'terraced-house',
  CORNER_HOUSE = 'corner-house',
  SCHOOL = 'school',
  NURSING_HOME = 'nursing-home',
}
