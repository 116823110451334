import { isInventoryItem } from './InventoryItem';
import { ReusableMaterial } from '../../types';

export function isReusableMaterial(
  object: unknown
): object is ReusableMaterial {
  return (
    isInventoryItem(object) && object.inventoryType === 'reusable-material'
  );
}
