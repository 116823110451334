import { TimeOffRegistrationStatus } from '@bas/hrm-domain/models';
import { Chip } from '@bas/ui/web/atoms';
import { Badge, Box } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type TimeOffRegistrationStatusChipProps = {
  status: TimeOffRegistrationStatus;
};

const timeOffRegistrationStatusColors: {
  [key: string]:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
} = {
  [TimeOffRegistrationStatus.PENDING]: 'warning',
  [TimeOffRegistrationStatus.APPROVED]: 'success',
  [TimeOffRegistrationStatus.REJECTED]: 'error',
  [TimeOffRegistrationStatus.CANCELLED]: 'error',
};

const TimeOffRegistrationStatusChip = ({
  status,
}: TimeOffRegistrationStatusChipProps): ReactElement => (
  <Chip
    label={
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {timeOffRegistrationStatusColors[status] && (
          <Badge
            color={timeOffRegistrationStatusColors[status]}
            badgeContent=" "
            variant="dot"
          />
        )}
        <span style={{ paddingLeft: '8px' }}>
          <FormattedMessage id={`timeOffRegistrationStatus.${status}`} />
        </span>
      </Box>
    }
  />
);

export default TimeOffRegistrationStatusChip;
