import { colors } from '@bas/theme';
import {
  styled,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from '@mui/material';

export type TableRowProps = MuiTableRowProps;

const TableRow = ({ ...args }: TableRowProps) => <MuiTableRow {...args} />;

TableRow.defaultProps = {};

const StyledTableRow = styled(TableRow)(
  ({ onClick, theme }) => `
    ${onClick && 'cursor: pointer;'}
    ${
      onClick && '&:hover .MuiTableCell-root, '
    }.MuiTableRow-hover:hover .MuiTableCell-root {
      color: ${colors.blue[500]};
    }

    &.Mui-selected .MuiTableCell-root {
      color: ${colors.blue[500]};
      font-weight: bold;
    }

    &.MuiTableRow-hover:hover, &.Mui-selected, &.Mui-selected:hover {
      background-color: inherit;
    }
`
);
export default StyledTableRow;
