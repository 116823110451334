/* eslint-disable */
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

import axios from 'axios';
import hash from 'object-hash';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdateInstalled?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(import.meta.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      console.warn('Service worker registration failed');
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    console.log('Start registering service worker');
    console.log('Load event fired');
    const swUrl =
      import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw';

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      // eslint-disable-next-line no-use-before-define
      checkValidServiceWorker(swUrl, config);

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then(() => {
        console.log(
          'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://cra.link/PWA'
        );
      });
    } else {
      console.log('Registering service worker');
      // Is not localhost. Just register service worker
      // eslint-disable-next-line no-use-before-define
      registerValidSW(swUrl, config);
    }
  }
}

const registerPush = (registration: ServiceWorkerRegistration) => {
  const publicKey =
    import.meta.env.VITE_WEB_PUSH_KEY ||
    'BBJP5Fu5PiqBOO_xl8N_aRfHdwl82fyaQyNz-AcHrIJlgsIh53YsIc8uCY3XpwIVnNTe-y7puEyS_izHuEAvcjM';
  console.log('Registering push');
  if (!registration.pushManager) {
    console.log('Push manager unavailable');
    return;
  }

  registration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicKey,
    })
    .then(
      // (B3-1) OK - TEST PUSH NOTIFICATION
      (sub) => {
        console.log('Send push data to API');
        const hashToCheck = hash(sub.toJSON());
        if (hashToCheck !== window.localStorage.getItem('push-hash')) {
          axios
            .post(`api/public/devices/register-web-push`, {
              subscription: sub,
              userAgent: navigator.userAgent,
              deviceType: 'web',
              userId: null,
              deviceId: window.localStorage.getItem('deviceId') || '',
            })
            .then((r) => window.localStorage.setItem('push-hash', hashToCheck));
        }
      },

      // (B3-2) ERROR!
      (err) => console.error(err)
    );
};

function registerValidSW(swUrl: string, config?: Config) {
  console.log('Start service worker');
  navigator.serviceWorker
    .register(
      import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw',
      { type: import.meta.env.MODE === 'production' ? 'classic' : 'module' }
    )
    .then((registration) => {
      console.warn({ registration });
      if (registration.active) {
        console.log('Service worker is active');
        registerPush(registration);
      }

      if (registration.waiting) {
        console.log('Service worker is waiting');
        if (config && config.onUpdateInstalled) {
          config.onUpdateInstalled(registration);
        }
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            console.log('Service worker installed');
            if (config && config.onUpdateInstalled) {
              config.onUpdateInstalled(registration);
            }
          }

          if (installingWorker.state === 'activated') {
            registerPush(registration);

            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              );

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
