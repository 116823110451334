import { colors } from '@bas/theme';
import { Box, BoxProps, styled } from '@mui/material';

export type RectangularTileProps = BoxProps;

const RectangularTile = ({ children, ...args }: RectangularTileProps) => (
  <Box {...args}>{children}</Box>
);

const StyledRectangularTile = styled(RectangularTile)(
  ({ theme }) => `
  background: ${colors.white};
  color: ${colors.lila[800]};
  width: 100%;
  height: 64px;
  border-radius: 5px;
  padding: ${theme.spacing(2)};
  box-sizing: border-box;
  position: relative;
  `
);
export default StyledRectangularTile;
