import {
  BatchDataByIntegrationIdRequestInvalidator,
  ExternalContractByEmployeeIdRequestInvalidator,
  ExternalEmployeesByIntegrationIdRequestInvalidator,
  IntegrationByIntegrationIdRequestInvalidator,
  IntegrationsRequestInvalidator,
} from './lib';
import { ExternalInvoicesByIntegrationIdRequestInvalidator } from './lib/externaInvoicesByIntegrationIdRequest';

export const queryInvalidators = [
  IntegrationByIntegrationIdRequestInvalidator,
  BatchDataByIntegrationIdRequestInvalidator,
  IntegrationsRequestInvalidator,
  ExternalContractByEmployeeIdRequestInvalidator,
  ExternalEmployeesByIntegrationIdRequestInvalidator,
  ExternalInvoicesByIntegrationIdRequestInvalidator,
];
