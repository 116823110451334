import { isRoomType, PublicRoomType } from '@bas/shared/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type PublicRoomTypesRequestProps = Pagination;
type Response = AxiosResponse<Collection<PublicRoomType>>;

export const PublicRoomTypesRequest = async ({
  ...params
}: PublicRoomTypesRequestProps): Promise<Response> =>
  axios.get('api/public/settings/room-types', {
    params: { ...params },
  });

export const usePublicRoomTypesRequest = (
  request: PublicRoomTypesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => PublicRoomTypesRequest(request),
    queryKey: ['room-types', 'public-list'],
  });

export const usePrefetchPublicRoomTypesRequest = (
  request: PublicRoomTypesRequestProps = {}
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['room-types', 'public-list'],
      queryFn: async () =>
        PublicRoomTypesRequest({ perPage: 9999, ...request }),
    });
  }, [request, queryClient]);
};

export const publicRoomTypeRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isRoomType(data)) {
    queryClient.invalidateQueries({ queryKey: ['room-types', 'public-list'] });
  }
};
