import { CostType, isCostType } from '@bas/shared/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CostTypesRequestProps = Pagination;

export const CostTypesRequest = async ({
  ...params
}: CostTypesRequestProps): Promise<AxiosResponse<Collection<CostType>>> =>
  axios.get(`api/{tenantId}/settings/cost-types`, {
    params: { ...params },
  });

export const useCostTypesRequest = (
  request: CostTypesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<CostType>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<CostType>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<CostType>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<CostType>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<CostType>>
  >({
    ...options,
    queryFn: async () => CostTypesRequest(request),
    queryKey: ['cost-types', 'list'],
  });

export const CostTypeRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isCostType(data)) {
    queryClient.invalidateQueries({ queryKey: ['cost-types', 'list'] });
  }
};
