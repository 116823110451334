import { ErrorFallbackTemplate } from '@bas/ui/web/templates';
import { FormattedMessage } from 'react-intl';

type AppErrorBoundaryProps = {
  resetError(): void;
};

const AppErrorBoundary = ({ resetError }: AppErrorBoundaryProps) => (
  <ErrorFallbackTemplate
    title={<FormattedMessage id="errorFallback.oopsSomethingWentWrong" />}
    description={<FormattedMessage id="errorFallback.errorDescription" />}
    resetError={resetError}
  />
);

export default AppErrorBoundary;
