import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';

export type TimeDifferenceProps = {
  differenceInHours: number;
  className?: string;
  isPlanned?: boolean;
};

const TimeDifference = ({
  differenceInHours,
  isPlanned,
  className,
}: TimeDifferenceProps): ReactElement => (
  <Box
    component="span"
    className={clsx(className, 'Bas-TimeDifference-Difference', {
      'Bas-TimeDifference-Difference-Positive': differenceInHours > 0,
      'Bas-TimeDifference-Difference-Negative': differenceInHours < 0,
      'Bas-TimeDifference-Difference-Neutral': differenceInHours === 0,
      'Bas-TimeDifference-Difference-Planned': isPlanned,
    })}
  >
    {differenceInHours > 0 && '+'}
    {differenceInHours !== 0 || isPlanned
      ? formatHours(differenceInHours)
      : '-'}
  </Box>
);

const StyledTimeDifference = styled(TimeDifference)`
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 5px;
  width: 100%;
  text-align: center;

  &.Bas-TimeDifference-Difference-Positive {
    background: ${colors.pink[200]};
    color: ${colors.red[700]};
  }

  &.Bas-TimeDifference-Difference-Negative {
    background: ${colors.orange[300]};
    color: ${colors.orange[800]};
  }

  &.Bas-TimeDifference-Difference-Planned.Bas-TimeDifference-Difference-Neutral {
    background: ${colors.green[300]};
    color: ${colors.green[800]};
  }
`;
export default StyledTimeDifference;
