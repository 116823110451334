import {
  isTimeOffRegistration,
  TimeOffRegistration,
} from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import Qs from 'qs';
import { useEffect } from 'react';

export type TimeOffRegistrationsByEmployeeIdRequestProps = Pagination &
  Filterable & {
    employeeId: Uuid;
    startBefore?: Date | Dayjs;
    startAfter?: Date | Dayjs;
  };

type Response = AxiosResponse<Collection<TimeOffRegistration>>;

export const TimeOffRegistrationsByEmployeeIdRequest = async ({
  employeeId,
  startBefore,
  startAfter,
  ...params
}: TimeOffRegistrationsByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/${employeeId}/time-off-registrations`,
    {
      params: {
        'start[before]': startBefore ? formatDate(startBefore) : undefined,
        'start[after]': startAfter ? formatDate(startAfter) : undefined,
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useTimeOffRegistrationsByEmployeeIdRequest = (
  { employeeId, ...request }: TimeOffRegistrationsByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      TimeOffRegistrationsByEmployeeIdRequest({ ...request, employeeId }),
    queryKey: ['time-off-registrations', 'list', employeeId, request],
  });

export const usePrefetchTimeOffRegistrationsByEmployeeIdRequest = ({
  employeeId,
  ...request
}: TimeOffRegistrationsByEmployeeIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'time-off-registrations',
        'list',
        employeeId,
        ...Object.values({ employeeId, ...request }),
      ],
      queryFn: async () =>
        TimeOffRegistrationsByEmployeeIdRequest({ employeeId, ...request }),
    });
  }, [employeeId, queryClient, request]);
};

export const TimeOffRegistrationsByEmployeeIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isTimeOffRegistration(data)) {
      queryClient.invalidateQueries({
        queryKey: ['time-off-registrations', 'list', data.employeeId],
      });
    }
  };
