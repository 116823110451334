import { InvoiceStatistics, isInvoice } from '@bas/financial-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';

export type InvoiceStatisticsRequestProps = {
  startPeriod: Dayjs;
  endPeriod: Dayjs;
};
type Response = AxiosResponse<InvoiceStatistics>;

export const InvoiceStatisticsRequest = async ({
  startPeriod,
  endPeriod,
}: InvoiceStatisticsRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/invoices/invoice-statistics/${formatDate(
      startPeriod
    )}/${formatDate(endPeriod)}`,
    {}
  );

export const useInvoiceStatisticsRequest = (
  request: InvoiceStatisticsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => InvoiceStatisticsRequest(request),
    queryKey: [
      'invoices',
      'statistics',
      request.startPeriod,
      request.endPeriod,
    ],
  });

export const InvoiceStatisticsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isInvoice(data)) {
    queryClient.invalidateQueries({ queryKey: ['invoices', 'statistics'] });
  }
};
