import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basTvCabinet: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'tv-cabinet',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M297.632 89.398c5.859 5.858 5.859 15.356 0 21.213l-53.001 53.002c-5.857 5.858-15.355 5.858-21.213 0-5.859-5.858-5.859-15.356 0-21.214l53-53.001c5.86-5.858 15.357-5.858 21.214 0zm-48 7.213c5.857-5.858 5.857-15.356 0-21.214-5.859-5.858-15.356-5.858-21.215 0l-26 26.001c-5.858 5.858-5.858 15.356 0 21.214 5.858 5.858 15.356 5.858 21.214 0l26.001-26zM103.022 9.002c-8.285 0-15.001 6.716-15.001 15v189.005c0 8.285 6.716 15 15 15H409.03c8.284 0 15-6.715 15-15V24.003c0-8.285-6.716-15-15-15H103.021zm15 189.005V39.003h276.007v159.004H118.022zm-94.003 64.001c-8.284 0-15 6.716-15 15v149.005c0 8.285 6.716 15 15 15h81.002l6 42.001h36.001l6-42h206.005l6 42h36.002l6-42h81.002c8.284 0 15-6.716 15-15V277.008c0-8.285-6.716-15-15-15H24.019zm15 149.004V292.009h202.005v119.003H39.02zm232.007 0V292.009H473.03v119.003H271.026z',
  ],
};

export default basTvCabinet;
