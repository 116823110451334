import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basDisassemble: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'disassemble',
  icon: [
    500,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M130.525 241.073L9.159 119.61C-3.037 107.415-3.036 87.707 9.06 75.415L75.305 9.17C81.354 3.024 89.354 0 97.354 0s16 3.122 22.049 9.17l121.463 121.464c26.731 26.732 30.048 67.902 9.952 98.244l83.414 83.415h71.61L499.5 437.17l-62.44 62.439-124.877-93.659v-71.61l-83.415-83.415c-12.975 8.684-28 12.976-43.024 12.976-20 0-40-7.61-55.219-22.829zm212.877 102.439v46.829l90.733 68 24.096-24.098-68-90.731h-46.829zM218.818 152.781L97.45 31.317 31.207 97.561l121.464 121.365c8.78 8.879 20.585 13.757 33.073 13.757 12.487 0 24.195-4.878 33.074-13.757 8.877-8.78 13.755-20.585 13.755-33.072 0-12.488-4.878-24.196-13.755-33.073zm119.974 54.816c-6.096 6.096-6.096 15.98 0 22.075 6.096 6.097 15.979 6.097 22.076 0l78.87-78.87v48.842c0 8.62 6.99 15.61 15.61 15.61 8.621 0 15.61-6.99 15.61-15.61v-74.928c0-15.517-12.58-28.097-28.098-28.097h-74.927c-8.62 0-15.61 6.989-15.61 15.61 0 8.62 6.99 15.61 15.61 15.61h50.617l-79.758 79.758zM187.932 459.47c8.622 0 15.61-6.99 15.61-15.61 0-8.622-6.988-15.61-15.61-15.61h-50.616l79.758-79.758c6.097-6.097 6.097-15.98 0-22.076-6.095-6.097-15.98-6.097-22.074 0l-78.872 78.87v-48.84c0-8.622-6.989-15.61-15.61-15.61-8.62 0-15.61 6.988-15.61 15.61v74.926c0 15.518 12.58 28.098 28.098 28.098h74.927z',
  ],
};

export default basDisassemble;
