import { Event, isEvent } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';

export type EventsByMaterialIdRequestProps = Pagination & {
  materialId: Uuid;
  fromDate?: Date | Dayjs;
  untilDate?: Date | Dayjs;
};

type Response = AxiosResponse<Collection<Event>>;

export const EventsByMaterialIdRequest = async ({
  materialId,
  fromDate,
  untilDate,
  ...params
}: EventsByMaterialIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events/by-material/${materialId}`, {
    params: {
      ...params,
      fromDate: fromDate ? formatDate(fromDate) : undefined,
      untilDate: untilDate ? formatDate(untilDate) : undefined,
    },
  });

export const useEventsByMaterialIdRequest = (
  request: EventsByMaterialIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      EventsByMaterialIdRequest({ perPage: 100, ...request }),
    queryKey: [
      'events',
      'list',
      request.materialId,
      request.fromDate,
      request.untilDate,
    ],
  });

export const EventsByMaterialIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isEvent(data)) {
    data.materialIds.forEach((materialId) =>
      queryClient.invalidateQueries({
        queryKey: ['events', 'list', materialId],
      })
    );
  }
};
