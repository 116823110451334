import { Damage, isDamage } from '@bas/project-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DamagesRequestProps = Pagination &
  Filterable & {
    projectId?: Uuid;
    eventId?: Uuid;
  };

type Response = AxiosResponse<Collection<Damage>>;

export const DamagesRequest = async ({
  ...params
}: DamagesRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/damages', { params });

export const useDamagesRequest = (
  request: DamagesRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => DamagesRequest({ ...request }),
    queryKey: ['damages', 'list', request],
  });

export const DamagesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isDamage(data)) {
    queryClient.invalidateQueries({ queryKey: ['damages', 'list'] });
  }
};
