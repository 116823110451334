import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basHorseTrolley: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'horse-trolley',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M412.7 386.1c-.3-33.3 0-91 .2-126.9v0c.1-13.3 .1-23.6 .1-28.5c0-9.1 .8-17 1.9-23.6c4.4 2.3 9.2 4.6 14.3 6.2c6 1.9 12.9 3.1 20.3 1.9c7.6-1.2 14.5-4.7 20.7-10.3c11.6-10.6 17.7-22.2 16.5-34.7c-1.1-11.3-8.1-19.3-11.5-23c-.2-.2-.3-.4-.5-.5L421.3 93.2c1-1.5 1.9-3 2.6-4.5c2.3-4.8 5.2-14-1-22.3c-5.3-7.1-13.6-8.2-17.5-8.4c-4.8-.2-9.9 .5-14.5 1.5c-37.8 8.1-61.6 32-75.6 57.7c-10.4 19.1-15.7 39.4-17.7 56h-96c-20.3 0-45.6 1.2-66.1 11.4c-10.6 5.3-20.2 13-27.1 24.3c-6.8 11.3-10.3 25-10.3 41.3V381.5c-26.9-19.9-48.7-44.7-63.8-72.7c-3.9-7.3-13-10-20.3-6.1s-10 13-6.1 20.3C52.8 406.5 148.7 463 258.5 463s205.7-56.5 250.7-139.9c3.9-7.3 1.2-16.4-6.1-20.3s-16.4-1.2-20.3 6.1c-16.3 30.1-40.4 56.5-70.1 77.2zM390.4 104.8l62.7 62.7c2.9 3.3 3.5 4.8 3.6 5.5l0 0c0 .1 .1 .7-.6 2.1c-.7 1.5-2.4 4.1-6.2 7.5c-2.3 2.1-4 2.7-5.2 2.9c-1.4 .2-3.4 .1-6.5-.8c-3.2-1-6.8-2.7-11.2-5.1c-2.1-1.1-4.3-2.4-6.6-3.7l-.3-.2c-2.4-1.4-4.9-2.8-7.5-4.2c-3.7-2-8.1-2.3-12.1-.9s-7.2 4.5-8.8 8.4c-4.9 11.8-8.6 29.8-8.6 51.7c0 4.6-.1 14.5-.1 27.4v0c-.2 42.8-.6 118.7 .1 145.4l.7 0c-6.6 3.4-13.4 6.5-20.4 9.3c.4-3.9 .6-7.8 .6-11.8c0-58.5-47.5-106-106-106s-106 47.5-106 106c0 3.8 .2 7.6 .6 11.3c-8.5-3.5-16.7-7.4-24.6-11.7V250.2c0-12 2.5-20.1 6-25.7c3.4-5.6 8.3-9.8 14.8-13c13.6-6.7 32.6-8.3 52.8-8.3h110c8.2 0 14.9-6.6 15-14.8c.1-14.1 4-36.6 15.1-56.8c9.3-17 23.4-32 44.6-39.6c-.7 4.5 .7 9.3 4.2 12.8zM258 325c42 0 76 34 76 76c0 7.9-1.2 15.6-3.5 22.7c-22.8 6-46.9 9.3-72 9.3c-25.5 0-50.1-3.4-73.1-9.6c-2.2-7.1-3.4-14.6-3.4-22.5c0-42 34-76 76-76z',
  ],
};

export default basHorseTrolley;
