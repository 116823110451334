import { colors } from '@bas/theme';
import { Tooltip, TooltipProps } from '@bas/ui/web/atoms';
import { EventSourceInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import { Backdrop, styled } from '@mui/material';
import { ReactElement, RefObject, useEffect } from 'react';
import { MonthlyCalendar } from '../MonthlyCalendar';

export type MonthlyCalendarTooltipProps = Omit<TooltipProps, 'title'> & {
  currentDate: Date;
  monthlyRef: RefObject<FullCalendar | undefined>;
  onChangeCurrentDate?: (date: Date) => void;
  onChangeViewDates?: (viewStartDate: Date, viewEndDate: Date) => void;
  events: EventSourceInput;
};

const MonthlyCalendarTooltip = ({
  open,
  onClose,
  currentDate,
  monthlyRef,
  onChangeCurrentDate,
  onChangeViewDates,
  events,
  ...props
}: MonthlyCalendarTooltipProps): ReactElement => {
  useEffect(() => {
    if (!open) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    let tries = 0;
    const maxTries = 5;

    const interval = setInterval(() => {
      const calendar = (
        monthlyRef.current as unknown as {
          calendar: FullCalendar;
        }
      )?.calendar;
      if (calendar?.render) {
        calendar?.render();
      }

      if (tries >= maxTries) {
        clearInterval(interval);
      }
      tries += 1;
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [monthlyRef, open, events]);

  return (
    <>
      <Backdrop open={open || false} onClick={onClose} sx={{ zIndex: 1500 }} />
      <Tooltip
        {...props}
        placement="bottom"
        arrow
        allowWider
        title={
          <MonthlyCalendar
            locale="nl"
            initialDate={currentDate}
            ref={monthlyRef}
            onChangeCurrentDate={onChangeCurrentDate}
            onChangeViewDates={onChangeViewDates}
            events={events}
            size="medium"
            weekNumbers
          />
        }
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={onClose}
      />
    </>
  );
};

const StyledMonthlyCalendarTooltip = styled(MonthlyCalendarTooltip)`
  background-color: ${colors.white} !important;
  width: 761px;
  height: 738px;
  border-radius: 10px;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(9)};
  padding-right: ${({ theme }) => theme.spacing(9)};
  box-sizing: border-box;

  .fc-scroller-harness {
    overflow: unset !important;
  }

  .MuiTooltip-arrow {
    margin-top: -19px !important;
    width: 32px;
    height: 32px;
    color: ${colors.white} !important;

    &:before {
      transform-origin: 0 75% !important;
    }
  }
`;

export default StyledMonthlyCalendarTooltip;
