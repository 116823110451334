import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basWineRack: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'wine-rack',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M277.331 175.224c-5.957-3.49-13.614-1.49-17.104 4.468-3.49 5.955-1.49 13.614 4.467 17.103 15.326 8.98 27.34 23.284 33.566 40.475 2.353 6.49 9.521 9.846 16.012 7.494 6.49-2.352 9.846-9.52 7.494-16.011-8.213-22.668-24.065-41.595-44.435-53.529zm-41.32-159.218c-13.807 0-25 11.193-25 25v19c0 6.395 3.159 12.051 8 15.495v55.082c-40.844 15.053-70.001 54.317-70.001 100.428 0 .734.006 1.468.022 2.2-.016.273-.022.546-.022.824v237.982c0 13.807 11.192 25 25 25h164.005c13.807 0 25-11.193 25-25V234.035c0-.276-.008-.551-.022-.824.014-.732.021-1.466.021-2.2 0-45.735-28.684-84.733-69-100.054V74.999c4.264-3.389 7-8.621 7-14.492v-19.5c0-13.808-11.194-25.001-25.001-25.001h-40.001zm5 36.507v-6.507h30v7.008c-4.264 3.39-6.998 8.622-6.998 14.493v66.647c0 11.661 7.85 20.794 17.459 24.159 30.036 10.519 51.542 39.117 51.542 72.698 0 .837-.013 1.67-.04 2.5-.018.568-.004 1.13.04 1.685v231.821H179.01v-231.82c.043-.555.057-1.118.04-1.687-.028-.829-.04-1.662-.04-2.5 0-33.858 21.862-62.649 52.284-72.953 9.726-3.293 17.717-12.475 17.717-24.245V68.007c0-6.394-3.159-12.05-8-15.494z',
  ],
};

export default basWineRack;
