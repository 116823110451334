import * as Yup from 'yup';
import {
  ConnectIntegrationInputType,
  ConnectIntegrationInputTypeValidationBuilder,
} from '../connectIntegrationInputType';

export interface ConnectYukiIntegrationInputType
  extends ConnectIntegrationInputType {
  apiKey: string;
}

export const ConnectYukiIntegrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectYukiIntegrationInputType> =>
    Yup.object({
      apiKey: Yup.string().required().label('label.apiKey'),
    }).concat(ConnectIntegrationInputTypeValidationBuilder());
