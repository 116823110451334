import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basPaddlingPool: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'paddling-pool',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M206.1 210.3c-8.4 0-16.1-3.2-22-8.5c-5.8 5.3-13.5 8.5-22 8.5c-5.9 0-10.7-4.8-10.7-10.7s4.8-10.7 10.7-10.7c5.9 0 10.7-4.5 11.2-10.3c0-.3 0-.6 0-1c0-5.9 4.8-10.7 10.7-10.7l0 0 0 0c5.9 0 10.7 4.8 10.7 10.7c0 .3 0 .6 0 1c.5 5.8 5.3 10.3 11.2 10.3c6.2 0 11.2-5 11.2-11.2c0-5.9 4.8-10.7 10.7-10.7s10.7 4.8 10.7 10.7c0 6.2 5 11.2 11.2 11.2s11.2-5 11.2-11.2c0-5.9 4.8-10.7 10.7-10.7c.1 0 .1 0 .2 0s.1 0 .2 0c5.9 0 10.7 4.8 10.7 10.7c0 6.2 5 11.2 11.2 11.2s11.2-5 11.2-11.2c0-5.9 4.8-10.7 10.7-10.7c.1 0 .1 0 .2 0s.1 0 .2 0c5.9 0 10.7 4.8 10.7 10.7c0 6.2 5 11.2 11.2 11.2c5.9 0 10.7 4.8 10.7 10.7s-4.8 10.7-10.7 10.7c-8.6 0-16.4-3.3-22.2-8.7c-5.8 5.4-13.6 8.7-22.2 8.7c-8.6 0-16.3-3.3-22.2-8.7c-5.8 5.4-13.6 8.7-22.2 8.7c-8.5 0-16.2-3.2-22-8.5c-5.8 5.3-13.5 8.5-22 8.5zM85.3 125.5c42.8-14.4 101.1-23.1 164.7-23.1s121.9 8.7 164.7 23.1c21.3 7.2 39.7 16 53 26.6c13.1 10.4 23.3 24.2 23.3 41.1c0 1.7-.1 3.3-.3 4.9h.3V324.9c0 16.9-10.2 30.6-23.3 41.1c-13.3 10.6-31.6 19.4-53 26.6C371.9 407 313.7 415.6 250 415.6s-121.9-8.7-164.7-23.1c-21.3-7.2-39.7-16-53-26.6C19.2 355.5 9 341.7 9 324.9V198.1h.3c-.2-1.6-.3-3.2-.3-4.9c0-16.9 10.2-30.6 23.3-41.1c13.3-10.6 31.6-19.4 53-26.6zM50.6 175c-9.8 7.8-12.3 14-12.3 18.2s2.5 10.4 12.3 18.2c9.6 7.6 24.4 15.2 44.1 21.8c39.1 13.1 94 21.5 155.4 21.5s116.3-8.4 155.4-21.5c19.6-6.6 34.4-14.1 44.1-21.8c9.8-7.8 12.3-14 12.3-18.2s-2.5-10.4-12.3-18.2c-9.6-7.6-24.4-15.2-44.1-21.8c-39.1-13.1-94-21.5-155.4-21.5s-116.3 8.4-155.4 21.5C75 159.8 60.2 167.4 50.6 175zm364.2 85.8c-10.7 3.6-22.4 6.9-35 9.7V372.2c9.1-2.2 17.7-4.7 25.6-7.4c19.6-6.6 34.4-14.1 44.1-21.8c9.8-7.8 12.3-14 12.3-18.2V238.6c-12.6 8.7-28.7 16.1-47 22.2zm-64.2 15.4c-30.6 5-64.7 7.7-100.5 7.7c-35.4 0-69.2-2.7-99.5-7.5v102c29.7 5 63.5 7.9 99.5 7.9c36.4 0 70.6-3 100.5-8.1v-102zm-229.3-5.4c-12.9-2.9-24.9-6.2-35.9-9.9c-18.3-6.2-34.4-13.6-47-22.2v86.2c0 4.2 2.5 10.4 12.3 18.2c9.6 7.6 24.4 15.2 44.1 21.8c8.2 2.8 17.1 5.3 26.6 7.6V270.8z',
  ],
};

export default basPaddlingPool;
