import { InvoiceData, SyncStatus } from '@bas/integration-domain/models';
import { SyncStatusChip } from '@bas/integration-domain/web/molecules';
import { Button, TableCell, TableRow, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { Checkbox, IdentityIndicator } from '@bas/ui/web/molecules';
import {
  faArrowsRotate,
  faCheckToSlot,
} from '@fortawesome/pro-light-svg-icons';
import { Grid, Link } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

export type InvoiceTableRowProps = {
  data: InvoiceData;
  showExternalId?: boolean;
  onRetry: () => Promise<void> | void;
  onMarkAsCompleted: () => Promise<void> | void;
  onCheck: () => Promise<void> | void;
  checked?: boolean;
  showCheck?: boolean;
};

const InvoiceTableRow = ({
  data: {
    invoiceId,
    invoiceNumber,
    identityId,
    externalId,
    messages,
    syncStatus,
    processedAt,
    failedAt,
  },
  showExternalId,
  onRetry,
  onMarkAsCompleted,
  onCheck,
  checked,
  showCheck,
}: InvoiceTableRowProps): ReactElement => (
  <TableRow>
    {showCheck && (
      <TableCell paddingRight>
        <Checkbox
          checked={checked}
          onChange={onCheck}
          removePadding
          size="small"
          lightIcon
        />
      </TableCell>
    )}
    <TableCell paddingRight>
      <Link
        to={`/financial/invoices/${invoiceId}`}
        component={RouterLink}
        color="textPrimary"
      >
        <IdentityIndicator identityId={identityId}>
          {invoiceNumber}
        </IdentityIndicator>
      </Link>
    </TableCell>
    {showExternalId && (
      <TableCell paddingRight>
        {externalId || <FormattedMessage id="label.unknown" />}
      </TableCell>
    )}
    <TableCell paddingRight>
      <SyncStatusChip syncStatus={syncStatus} messages={messages} />
    </TableCell>
    <TableCell paddingRight>
      {(!failedAt || syncStatus === SyncStatus.SUCCESS) && processedAt && (
        <FormattedDate
          value={dayjs(processedAt).toDate()}
          dateStyle="short"
          timeStyle="short"
        />
      )}
      {failedAt && syncStatus !== SyncStatus.SUCCESS && (
        <FormattedDate
          value={dayjs(failedAt).toDate()}
          dateStyle="short"
          timeStyle="short"
        />
      )}
    </TableCell>
    <TableCell>
      {[SyncStatus.FAILED, SyncStatus.DISAPPROVED].includes(syncStatus) && (
        <Grid container spacing={1}>
          <Grid item>
            <Tooltip title={<FormattedMessage id="button.markAsCompleted" />}>
              <Button
                onClick={onMarkAsCompleted}
                size="small"
                variant="outlined"
              >
                <Icon icon={faCheckToSlot} />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="button.retry" />}>
              <Button onClick={onRetry} size="small" variant="outlined">
                <Icon icon={faArrowsRotate} />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </TableCell>
  </TableRow>
);

export default InvoiceTableRow;
