import { fontSizesWeb } from '@bas/theme';
import { Country } from '@bas/value-objects';
import { Box, styled } from '@mui/material';

export type LicensePlateProps = {
  country: Country;
  licensePlate: string;
};

const countryColors = {
  NL: {
    base: '#FFE662',
    color: '#000000',
    left: '#3A78F2',
  },
  BE: {
    base: '#FFFFFF',
    color: '#842328',
    left: '#3A78F2',
  },
};

const LicensePlate = ({ licensePlate, ...args }: LicensePlateProps) => (
  <Box component="span" {...args}>
    <Box className="Bas-LicensePlate-country" />
    <Box className="Bas-LicensePlate-plate">{licensePlate}</Box>
  </Box>
);

const StyledLicensePlate = styled(LicensePlate)(({ theme, country }) => {
  const belgiumBorder = `border: 1px solid ${countryColors[country].color};`;

  return `
  background: ${countryColors[country].base};
  color: ${countryColors[country].color};
  font-size: ${fontSizesWeb.sm};
  ${country === 'BE' && belgiumBorder}
  font-weight: bold;
  line-height: 18px;
  height: 27px;
  width: 91px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  .Bas-LicensePlate-plate {
    width: 100%;
    text-align: center;
  }

  .Bas-LicensePlate-country {
    width: 12px;
    height: 27px;
    display: block;
    border-radius: 5px 0px 0px 5px;
    background: ${countryColors[country].left};
  }
`;
});
export default StyledLicensePlate;
