import { colors } from '@bas/theme';
import { PaginationItem, PaginationItemProps } from '@bas/ui/web/atoms';
import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  styled,
} from '@mui/material';

export type PaginationProps = MuiPaginationProps;

const Pagination = ({ ...args }: PaginationProps) => (
  <MuiPagination {...args} />
);

Pagination.defaultProps = {
  renderItem: (item) => (
    <PaginationItem {...(item as unknown as PaginationItemProps)} />
  ),
} as Partial<PaginationProps>;

const StyledPagination = styled(Pagination)(
  ({ theme }) => `
    color: ${colors.lila[700]};
`
);
export default StyledPagination;
