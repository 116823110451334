import {
  AutocompletePopper,
  TextField,
  TextFieldProps,
} from '@bas/ui/web/atoms';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  FormHelperTextProps,
} from '@mui/material';
import { ChipTypeMap } from '@mui/material/Chip';
import * as React from 'react';
import { ReactNode } from 'react';

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> = Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'renderInput'
> & {
  textField?: TextFieldProps;
  error?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: ReactNode;
  renderInput?: MuiAutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >['renderInput'];
};

const Autocomplete = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T = any,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({
  textField,
  error,
  FormHelperTextProps: helperProps,
  helperText,
  ...props
}: AutocompleteProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) => (
  <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo, ChipComponent>
    PopperComponent={AutocompletePopper}
    renderInput={(params) => (
      <TextField
        {...params}
        {...textField}
        error={error}
        FormHelperTextProps={helperProps}
        helperText={helperText}
      />
    )}
    {...props}
  />
);

Autocomplete.defaultProps = {};

export default Autocomplete;
