import { TurnoverGroup } from '@bas/financial-domain/models';
import { Icon } from '@bas/ui/web/base';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Box, BoxProps, IconButton, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';

export type TurnoverGroupDetailsPageTemplateProps = BoxProps & {
  turnoverGroup: TurnoverGroup;
  className?: string;
  onClose?: () => void;
};

const TurnoverGroupDetailsPageTemplate = ({
  turnoverGroup,
  children,
  onClose,
  ...args
}: TurnoverGroupDetailsPageTemplateProps): ReactElement => (
  <Box {...args}>
    {onClose && (
      <IconButton
        className="Bas-TurnoverGroupDetailsPageTemplate-Close"
        onClick={onClose}
      >
        <Icon icon={faTimes} />
      </IconButton>
    )}
    <Box className="Bas-TurnoverGroupDetailsPageTemplate-Header">
      <Typography color="textPrimary" variant="h3">
        {turnoverGroup.name}
      </Typography>
    </Box>
    <Box className="Bas-TurnoverGroupDetailsPageTemplate-Content">
      {children}
    </Box>
  </Box>
);

const StyledTurnoverGroupDetailsPageTemplate = styled(
  TurnoverGroupDetailsPageTemplate
)(
  ({ theme }) => `
  position: relative;

  .Bas-TurnoverGroupDetailsPageTemplate-Close {
    position: absolute;
    font-size: 24px;
    padding: 0;
    z-index: 15;
    top: -${theme.spacing(3)};
    left: -${theme.spacing(3)};
    ${theme.breakpoints.down('lg')} {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
    @media (max-width: 1024px) {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
  }


  .Bas-TurnoverGroupDetailsPageTemplate-Header {
    padding-bottom: ${theme.spacing(2)};
  }
`
);

export default StyledTurnoverGroupDetailsPageTemplate;
