import { TurnoverGroup, VatCode } from '@bas/financial-domain/models';
import { colors } from '@bas/theme';
import {
  CurrencyTextFieldNumberFormat,
  TableCell,
  TableRow,
  TableRowProps,
  TextFieldNumberFormat,
  Tooltip,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ReactHookFormTextField } from '@bas/ui/web/molecules';
import {
  faArrowDown,
  faArrowUp,
  faRepeat,
  faRepeat1,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { Grid, IconButton, MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type FixedBillingMomentLineFormProps = TableRowProps & {
  index: number;
  prefix: string;
  vatCodes: VatCode[];
  turnoverGroups: TurnoverGroup[];
  remove: (index: number) => void;
  allowMoveUp: boolean;
  allowMoveDown: boolean;
  moveUp: () => void;
  moveDown: () => void;
};

const FixedBillingMomentLineForm = ({
  index,
  prefix,
  turnoverGroups,
  remove,
  allowMoveDown,
  allowMoveUp,
  moveDown,
  moveUp,
  vatCodes,
  ...args
}: FixedBillingMomentLineFormProps): ReactElement => {
  const isPerEvent = useWatch({
    name: `${prefix}.invoiceLines.${index}.isPerEvent`,
  });
  const { setValue } = useFormContext();

  return (
    <>
      <TableRow {...args}>
        <TableCell>
          <Grid container spacing={1}>
            <Grid
              item
              sx={{
                color: (theme) => (allowMoveUp ? colors.lila[400] : 'inherit'),
              }}
            >
              <Icon
                icon={faArrowUp}
                onClick={allowMoveUp ? undefined : moveUp}
                fontSize={20}
              />
            </Grid>
            <Grid
              item
              sx={{
                color: (theme) =>
                  allowMoveDown ? colors.lila[400] : 'inherit',
              }}
            >
              <Icon
                icon={faArrowDown}
                onClick={allowMoveDown ? undefined : moveDown}
                fontSize={20}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Controller
            name={`${prefix}.invoiceLines.${index}.description`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                debounce
                multiline
                fullWidth
                variant="filled"
              />
            )}
          />
        </TableCell>
        <TableCell width="61px">
          <Controller
            name={`${prefix}.invoiceLines.${index}.quantity`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                debounce
                fullWidth
                variant="filled"
                InputProps={{
                  inputComponent: TextFieldNumberFormat,
                  inputProps: {
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    decimalScale: 0,
                  },
                }}
              />
            )}
          />
        </TableCell>
        <TableCell width="104px">
          <Controller
            name={`${prefix}.invoiceLines.${index}.linePrice.amount`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                debounce
                fullWidth
                variant="filled"
                InputProps={{
                  inputComponent: CurrencyTextFieldNumberFormat,
                }}
              />
            )}
          />
        </TableCell>
        <TableCell width="104px">
          <Controller
            name={`${prefix}.invoiceLines.${index}.vatCodeId`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                debounce
                fullWidth
                variant="filled"
                select
              >
                {vatCodes
                  .filter((vatCode) => !!vatCode.percentage)
                  .map((vatCode) => (
                    <MenuItem key={vatCode.vatCodeId} value={vatCode.vatCodeId}>
                      {`${vatCode.percentage?.percentage}%`}
                    </MenuItem>
                  ))}
              </ReactHookFormTextField>
            )}
          />
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip
                title={<FormattedMessage id="label.isPerEvent.explained" />}
                placement="top"
              >
                <IconButton
                  onClick={() =>
                    setValue(
                      `${prefix}.invoiceLines.${index}.isPerEvent`,
                      !isPerEvent
                    )
                  }
                >
                  <Icon
                    icon={isPerEvent ? faRepeat : faRepeat1}
                    fontSize={20}
                    sx={{
                      cursor: 'pointer',
                      color: isPerEvent ? colors.blue[500] : 'inherit',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton onClick={() => remove(index)}>
                <Icon icon={faTrashCan} />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell>
          <Controller
            name={`${prefix}.invoiceLines.${index}.turnoverGroupId`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                select
                label={<FormattedMessage id="label.turnoverGroup" />}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                className="Bas-Select-WithLabel"
              >
                <MenuItem value="">
                  <FormattedMessage id="label.none" />
                </MenuItem>
                {turnoverGroups.map((turnoverGroup) => (
                  <MenuItem
                    key={turnoverGroup.turnoverGroupId}
                    value={turnoverGroup.turnoverGroupId}
                  >
                    {turnoverGroup.name}
                  </MenuItem>
                ))}
              </ReactHookFormTextField>
            )}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default FixedBillingMomentLineForm;
