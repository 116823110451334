import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basGardenTools: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'garden-tools',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M219.127 217.64L63.56 62.072c-3.905-3.905-10.488-3.919-12.636 1.17-14.548 34.472 42.887 104.036 89.359 150.509l41.367 41.366a9.97 9.97 0 007.565 2.917 46.784 46.784 0 0112.234-21.302 46.792 46.792 0 0120.608-12.047 9.966 9.966 0 00-2.93-7.045zM445.952 62.072L290.385 217.64a9.97 9.97 0 00-2.929 7.045 46.795 46.795 0 0120.607 12.047 46.784 46.784 0 0112.234 21.302 9.969 9.969 0 007.565-2.917l41.367-41.366c46.473-46.473 103.908-116.037 89.36-150.51-2.149-5.088-8.732-5.074-12.637-1.169zM198.147 370.397c5.857-5.857 5.857-15.354 0-21.213-5.859-5.859-15.357-5.859-21.214 0l-88.39 88.39c-5.859 5.859-5.859 15.357 0 21.214 5.857 5.858 15.355 5.858 21.213 0l88.39-88.39zm115.219-21.213c-5.859 5.859-5.859 15.356 0 21.213l88.39 88.39c5.859 5.86 15.356 5.86 21.214 0 5.858-5.856 5.858-15.354 0-21.213l-88.39-88.39c-5.858-5.859-15.356-5.859-21.214 0zm-10.346-63.177c0 25.957-21.044 47-47.002 47-25.958 0-47.002-21.043-47.002-47 0-25.958 21.044-47.002 47.002-47.002 25.958 0 47.002 21.044 47.002 47.002zm-30.001 0c0 9.389-7.612 17-17.001 17-9.389 0-17.001-7.611-17.001-17s7.612-17 17-17c9.39 0 17.002 7.611 17.002 17z',
  ],
};

export default basGardenTools;
