import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basWorkmate: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'workmate',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M52 63c0-8.3 6.7-15 15-15H197c8.3 0 15 6.7 15 15s-6.7 15-15 15H67c-8.3 0-15-6.7-15-15zm249 0c0-8.3 6.7-15 15-15H446c8.3 0 15 6.7 15 15s-6.7 15-15 15H316c-8.3 0-15-6.7-15-15zM24 106c-8.3 0-15 6.7-15 15v61c0 8.3 6.7 15 15 15H89.5c.5 .5 1.1 1 1.8 1.4L229.7 293 15.3 439.5c-6.8 4.7-7.6 13.2-1.7 19s16.2 6.7 23.1 2l219-149.7 219 149.7c6.8 4.7 17.2 3.8 23.1-2s5.2-14.3-1.7-19L281.8 293l138.4-94.6c.6-.4 1.2-.9 1.8-1.4H488c8.3 0 15-6.7 15-15V121c0-8.3-6.7-15-15-15H24zm346.1 91L255.7 275.2 141.4 197H370.1zM39 167V136H473v31H39z',
  ],
};

export default basWorkmate;
