import { StorageEventType, StorageWasPickedUpEvent } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageWasPickedUpEvent(
  object: unknown
): object is StorageWasPickedUpEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageWasPickedUpEvent
  );
}
