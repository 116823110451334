import {
  isEvent,
  MaterialAvailableHoursOverview,
} from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MaterialAvailableHoursOverviewByPeriodRequestProps = {
  startDate: Date;
  endDate: Date;
};

type Response = AxiosResponse<Collection<MaterialAvailableHoursOverview>>;
type QueryKeyType = QueryKey & {
  [0]: 'material-available-hours-overview';
  [1]: 'list';
  [2]: string;
  [3]: string;
};

export const MaterialAvailableHoursOverviewByPeriodRequest = async ({
  startDate,
  endDate,
  ...params
}: MaterialAvailableHoursOverviewByPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/by-material/${formatDate(startDate)}/${formatDate(
      endDate
    )}`,
    {
      params: { ...params },
    }
  );

export const useMaterialAvailableHoursOverviewByPeriodRequest = (
  request: MaterialAvailableHoursOverviewByPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () =>
      MaterialAvailableHoursOverviewByPeriodRequest({ ...request }),
    queryKey: [
      'material-available-hours-overview',
      'list',
      formatDate(request.startDate),
      formatDate(request.endDate),
    ],
  });

export const MaterialAvailableHoursOverviewByPeriodRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEvent(data)) {
      queryClient.invalidateQueries({
        queryKey: ['material-available-hours-overview', 'list'],
      });
    }
  };
