import {
  isVatMapping,
  Mapping,
  VatMapping,
} from '@bas/integration-domain/models';
import {
  MappingTableBasOption,
  MappingTableExternalOption,
} from '@bas/value-objects';
import { Grid, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type MappingTableProps = {
  vatMapping?: boolean;
  mappings: (VatMapping | Mapping)[];
  basOptions: MappingTableBasOption[];
  externalOptions: MappingTableExternalOption[];
};

const MappingTable = ({
  mappings,
  vatMapping,
  basOptions,
  externalOptions,
  ...args
}: MappingTableProps): ReactElement => (
  <Grid container {...args} columnSpacing={3}>
    {mappings.length === 0 && (
      <Grid item xs={12}>
        <Typography>
          <FormattedMessage id="label.noMappingMade" />
        </Typography>
      </Grid>
    )}
    {mappings.map((value, index) => {
      const basOption = basOptions.find(({ basId }) => basId === value.basId);
      const externalOption = externalOptions.find(
        ({ externalId }) => externalId === value.externalId
      );
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item xs={12} container columnSpacing={3} key={index}>
          {vatMapping && (
            <Grid item xs={4}>
              {isVatMapping(value) && value.reversed ? (
                <FormattedMessage id="label.reversedVat" />
              ) : (
                <FormattedMessage id="label.vatNotReversed" />
              )}
            </Grid>
          )}
          <Grid item xs={vatMapping ? 4 : 6}>
            {basOption?.label}
          </Grid>
          <Grid item xs={vatMapping ? 4 : 6}>
            {externalOption ? externalOption.label : value.externalId}
          </Grid>
        </Grid>
      );
    })}
  </Grid>
);

const StyledMappingTable = styled(MappingTable)(
  () => `
  .Bas-MappingTableForm-Grow {
    flex: 1;
  }
`
);
export default StyledMappingTable;
