import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { isTaskSettings, TaskSettings } from '@bas/task-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TaskSettingsRequestProps = Pagination;

type Response = AxiosResponse<Collection<TaskSettings>>;
type QueryKeyType = QueryKey & {
  [0]: 'task-settings';
  [1]: 'list';
};

export const TaskSettingsRequest = async ({
  ...params
}: TaskSettingsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/task-settings`, { params });

export const useTaskSettingsRequest = (
  request: TaskSettingsRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => TaskSettingsRequest({ ...request }),
    queryKey: ['task-settings', 'list'],
  });

export const TaskSettingsByTaskSettingsIdsRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isTaskSettings(data)) {
      queryClient.invalidateQueries({ queryKey: ['task-settings', 'list'] });
    }
  };
