import { colors, fontSizesWeb } from '@bas/theme';
import {
  ButtonProps,
  PaginationItem as MuiPaginationItem,
  PaginationItemProps as MuiPaginationItemProps,
  styled,
} from '@mui/material';

export type PaginationItemProps = MuiPaginationItemProps & ButtonProps;

const PaginationItem = ({ type, ...args }: PaginationItemProps) => (
  <MuiPaginationItem type={type} {...args} />
);

PaginationItem.defaultProps = {
  disableRipple: true,
};

const StyledPaginationItem = styled(PaginationItem)(
  ({ theme }) => `
&.MuiButtonBase-root {
  font-size: ${fontSizesWeb.sm};
  font-weight: bold;
  line-height: 18px;
  color: ${colors.lila[700]};
  background: inherit;
  box-sizing: border-box;
  border-radius: 0;

  &:hover {
    color: ${colors.blue[500]};
  }
}
&.Mui-disabled {
  color: ${colors.lila[400]};
}

&.Mui-selected:hover {
  background: inherit;
}

&.Mui-selected {
  background: inherit;
  color: ${colors.blue[500]};
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 50%;
    border-bottom: 2px solid ${colors.blue[500]};
  }
}
`
);
export default StyledPaginationItem;
