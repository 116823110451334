import { isObjectResponse } from '@bas/value-objects';
import { StorageEvent } from '../../types';

export function isStorageEvent(object: unknown): object is StorageEvent {
  return (
    isObjectResponse(object) &&
    // eslint-disable-next-line dot-notation
    typeof object.storage === 'string' &&
    // eslint-disable-next-line dot-notation
    typeof object.eventId === 'string' &&
    // eslint-disable-next-line dot-notation
    typeof object.eventDate === 'string'
  );
}
