import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ConnectAuthToIntegrationMutationProps = {
  integrationId: Uuid;
  tenantId: Uuid;
  authentication: unknown;
};

export const ConnectAuthToIntegrationMutation = async ({
  integrationId,
  ...values
}: ConnectAuthToIntegrationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/integrations/${integrationId}/auth`, {
    integrationId,
    ...values,
  });

export const useConnectAuthToIntegrationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ConnectAuthToIntegrationMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ConnectAuthToIntegrationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ConnectAuthToIntegrationMutationProps
  >({
    mutationFn: ConnectAuthToIntegrationMutation,
    throwOnError: false,
    ...options,
  });
