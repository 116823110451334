import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  InventoryOutstandingStock,
  isInventoryEvent,
} from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type OutstandingStockByCustomerIdRequestProps = Pagination &
  Filterable & {
    customerId: Uuid;
  };

export const OutstandingStockByCustomerIdRequest = async ({
  customerId,
  perPage,
  page,
  ...params
}: OutstandingStockByCustomerIdRequestProps): Promise<
  AxiosResponse<Collection<InventoryOutstandingStock>>
> =>
  axios.get(
    `api/{tenantId}/inventory/item/outstanding-by-customer/${customerId}`,
    {
      params: { ...params, perPage: perPage || 100, page: page || 1 },
    }
  );

export const useOutstandingStockByCustomerIdRequest = (
  request: OutstandingStockByCustomerIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<InventoryOutstandingStock>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<InventoryOutstandingStock>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<InventoryOutstandingStock>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<InventoryOutstandingStock>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<InventoryOutstandingStock>>
  >({
    ...options,
    queryFn: async () => OutstandingStockByCustomerIdRequest(request),
    queryKey: ['moving-boxes', 'outstanding', request.customerId],
  });

export const OutstandingStockByCustomerIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isInventoryEvent(data) && data.customerId) {
      queryClient.invalidateQueries({
        queryKey: ['moving-boxes', 'outstanding', data.customerId],
      });
    }
  };
