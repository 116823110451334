import { isPackage, Package } from '@bas/shared/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PackagesRequestProps = Pagination;

type Response = AxiosResponse<Collection<Package>>;
type QueryKeyType = QueryKey & {
  [0]: 'packages';
  [1]: 'list';
};
export const PackagesRequest = async ({
  ...params
}: PackagesRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/packages', { params });

export const usePackagesRequest = (
  request: PackagesRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => PackagesRequest({ ...request }),
    queryKey: ['packages', 'list', ...Object.values(request)],
  });

export const PackagesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isPackage(data)) {
    queryClient.invalidateQueries({ queryKey: ['packages', 'list'] });
  }
};
