import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RetryBatchDataMutationProps = {
  integrationId: Uuid;
  batchId?: Uuid | null;
  datas: { dataId: Uuid }[];
};

export const RetryBatchDataMutation = async ({
  integrationId,
  batchId,
  ...values
}: RetryBatchDataMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/integrations/${integrationId}/batch-datas/retry`, {
    integrationId,
    batchId,
    ...values,
  });

export const useRetryBatchDataMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RetryBatchDataMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RetryBatchDataMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RetryBatchDataMutationProps
  >({
    mutationFn: RetryBatchDataMutation,
    throwOnError: false,
    ...options,
  });
