import { ReactHookFormTextField } from '@bas/ui/web/molecules';
import { Grid } from '@mui/material';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const ConnectVerhuisOffertesForm = (): ReactElement => (
  <Grid container rowSpacing={3} columnSpacing={2}>
    <Grid item xs={12}>
      <Controller
        name="sharedSecret"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.sharedSecret" />}
          />
        )}
      />
    </Grid>
  </Grid>
);

export default ConnectVerhuisOffertesForm;
