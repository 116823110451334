import { StorageEventType, StoragePositionedForPickupEvent } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStoragePositionedForPickupEvent(
  object: unknown
): object is StoragePositionedForPickupEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StoragePositionedForPickupEvent
  );
}
