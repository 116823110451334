import { PricingType, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface ScanMoversSettingsInputType {
  packageId: Uuid;
  identityId?: Uuid | null;
  pricingType: PricingType;
}

export const ScanMoversSettingsInputTypeDefaultValues =
  (): ScanMoversSettingsInputType => ({
    packageId: '',
    identityId: '',
    pricingType: PricingType.UNKNOWN,
  });

export const ScanMoversSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ScanMoversSettingsInputType> =>
    Yup.object({
      packageId: Yup.string().required().label('label.packageId'),
      identityId: Yup.string().nullable().label('label.identityId'),
      pricingType: Yup.mixed<PricingType>()
        .label('label.pricingType')
        .oneOf(Object.values(PricingType))
        .required(),
    });
