import { ReactElement, useCallback } from 'react';
import { Grid } from '@mui/material';
import { Integration } from '@bas/integration-domain/models';
import { YukiSettingsInputType } from '@bas/integration-domain/input-types';
import { useUpdateIntegrationSettingsMutation } from '@bas/integration-domain/mutations';
import {
  EditableYukiSettingsBlock,
  IntegrationActionButtons,
} from '@bas/integration-domain/web/organisms';
import { formatDate } from '@bas/shared/utils';

export type YukiSettingsTabProps = {
  integration: Integration;
};

const YukiSettingsTab = ({
  integration,
}: YukiSettingsTabProps): ReactElement => {
  const { mutateAsync: updateIntegrationSettingsMutation } =
    useUpdateIntegrationSettingsMutation();

  const handleUpdateSettings = useCallback(
    async ({ startDate, ...settings }: YukiSettingsInputType) => {
      if (!startDate) {
        return;
      }

      await updateIntegrationSettingsMutation({
        integrationId: integration.integrationId,
        provider: integration.provider,
        settings: {
          provider: integration.provider,
          startDate: formatDate(startDate),
          ...settings,
        },
      });
    },
    [
      integration.integrationId,
      integration.provider,
      updateIntegrationSettingsMutation,
    ]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <IntegrationActionButtons integrationId={integration.integrationId} />
      </Grid>
      <Grid item xs={12}>
        <EditableYukiSettingsBlock
          integration={integration}
          onUpdateSettings={handleUpdateSettings}
        />
      </Grid>
    </Grid>
  );
};

export default YukiSettingsTab;
