import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import { Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import {
  faBoxOpen,
  faForklift,
  faMoneyCheckEditAlt,
  faPersonCarry,
  faTools,
  faUserCrown,
} from '@fortawesome/pro-light-svg-icons';
import { Grid, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type EmployeeSkillsProps = {
  className?: string;
  employeeSkills: (OfficeSkill | MovingJobSkill)[];
};

const EmployeeSkills = ({
  className,
  employeeSkills,
}: EmployeeSkillsProps): ReactElement => (
  <Grid container spacing={2} className={className}>
    <Grid
      item
      className={clsx('Bas-EmployeeSkill-Skill', {
        'Bas-EmployeeSkills-ActiveSkill': employeeSkills.includes(
          MovingJobSkill.APPRAISER
        ),
      })}
    >
      <Tooltip title={<FormattedMessage id="employeeSkills.appraiser" />}>
        <span>
          <Icon icon={faMoneyCheckEditAlt} />
        </span>
      </Tooltip>
    </Grid>
    <Grid
      item
      className={clsx('Bas-EmployeeSkill-Skill', {
        'Bas-EmployeeSkills-ActiveSkill': employeeSkills.includes(
          MovingJobSkill.FOREMAN
        ),
      })}
    >
      <Tooltip title={<FormattedMessage id="employeeSkills.foreman" />}>
        <span>
          <Icon icon={faUserCrown} />
        </span>
      </Tooltip>
    </Grid>
    <Grid
      item
      className={clsx('Bas-EmployeeSkill-Skill', {
        'Bas-EmployeeSkills-ActiveSkill': employeeSkills.includes(
          MovingJobSkill.HANDYMAN
        ),
      })}
    >
      <Tooltip title={<FormattedMessage id="employeeSkills.handyman" />}>
        <span>
          <Icon icon={faTools} />
        </span>
      </Tooltip>
    </Grid>
    <Grid
      item
      className={clsx('Bas-EmployeeSkill-Skill', {
        'Bas-EmployeeSkills-ActiveSkill': employeeSkills.includes(
          MovingJobSkill.CO_DRIVER
        ),
      })}
    >
      <Tooltip title={<FormattedMessage id="employeeSkills.co-driver" />}>
        <span>
          <Icon icon={faPersonCarry} />
        </span>
      </Tooltip>
    </Grid>
    <Grid
      item
      className={clsx('Bas-EmployeeSkill-Skill', {
        'Bas-EmployeeSkills-ActiveSkill': employeeSkills.includes(
          MovingJobSkill.PACKER
        ),
      })}
    >
      <Tooltip title={<FormattedMessage id="employeeSkills.packer" />}>
        <span>
          <Icon icon={faBoxOpen} />
        </span>
      </Tooltip>
    </Grid>
    <Grid
      item
      className={clsx('Bas-EmployeeSkill-Skill', {
        'Bas-EmployeeSkills-ActiveSkill': employeeSkills.includes(
          MovingJobSkill.LIFT_TRUCK
        ),
      })}
    >
      <Tooltip title={<FormattedMessage id="employeeSkills.lift-truck" />}>
        <span>
          <Icon icon={faForklift} />
        </span>
      </Tooltip>
    </Grid>
  </Grid>
);

const StyledEmployeeSkills = styled(EmployeeSkills)(
  ({ theme }) => `
  font-size: 18px;
  .Bas-EmployeeSkill-Skill {
    color: ${theme.palette.error.main};
    &.Bas-EmployeeSkills-ActiveSkill {
      color: ${theme.palette.success.main};
    }
  }
`
);
export default StyledEmployeeSkills;
