import { isTurnoverGroup, TurnoverGroup } from '@bas/financial-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TurnoverGroupByTurnoverGroupIdRequestProps = {
  turnoverGroupId: Uuid;
};

export const TurnoverGroupByTurnoverGroupIdRequest = async ({
  turnoverGroupId,
  ...params
}: TurnoverGroupByTurnoverGroupIdRequestProps): Promise<
  AxiosResponse<TurnoverGroup>
> =>
  axios.get(`api/{tenantId}/turnover-groups/${turnoverGroupId}`, {
    params,
  });

export const useTurnoverGroupByTurnoverGroupIdRequestQuery = (
  request: TurnoverGroupByTurnoverGroupIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<TurnoverGroup>,
    AxiosError,
    AxiosResponse<TurnoverGroup>
  > = {}
): UseQueryResult<AxiosResponse<TurnoverGroup>, AxiosError> =>
  useQuery<
    AxiosResponse<TurnoverGroup>,
    AxiosError,
    AxiosResponse<TurnoverGroup>
  >({
    ...options,
    queryFn: async () => TurnoverGroupByTurnoverGroupIdRequest(request),
    queryKey: ['turnover-groups', 'detail', request.turnoverGroupId],
  });

export const TurnoverGroupByTurnoverGroupIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isTurnoverGroup(data)) {
      queryClient.invalidateQueries({
        queryKey: ['turnover-groups', 'detail', data.turnoverGroupId],
      });
    }
  };
