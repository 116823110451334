import { ConnectedWebsite } from '@bas/integration-domain/models';
import { PricingType } from '@bas/value-objects';
import * as Yup from 'yup';

export interface WebsiteSettingsInputType {
  websites: ConnectedWebsite[];
}

export const WebsiteSettingsInputTypeDefaultValues =
  (): WebsiteSettingsInputType => ({
    websites: [],
  });

export const WebsiteSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<WebsiteSettingsInputType> =>
    Yup.object({
      websites: Yup.array()
        .of(
          Yup.object({
            name: Yup.string().max(200).required().label('label.name'),
            websiteCode: Yup.string().required().label('label.websiteCode'),
            packageId: Yup.string().required().label('label.packageId'),
            identityId: Yup.string()
              .optional()
              .nullable()
              .label('label.packageId'),
            pricingType: Yup.mixed<PricingType>()
              .label('label.pricingType')
              .oneOf(Object.values(PricingType))
              .required(),
          })
        )
        .required()
        .label('label.websites'),
    });
