import { isNationalHoliday, NationalHoliday } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type NationalHolidaysRequestProps = Pagination & Filterable;

export const NationalHolidaysRequest = async ({
  ...params
}: NationalHolidaysRequestProps): Promise<
  AxiosResponse<Collection<NationalHoliday>>
> =>
  axios.get(`api/{tenantId}/hrm/national-holidays`, {
    params: { ...params },
  });

export const useNationalHolidaysRequest = (
  request: NationalHolidaysRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<NationalHoliday>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<NationalHoliday>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<NationalHoliday>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<NationalHoliday>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<NationalHoliday>>
  >({
    ...options,
    queryFn: async () => NationalHolidaysRequest(request),
    queryKey: ['national-holidays', 'list', request],
  });

export const NationalHolidaysRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isNationalHoliday(data)) {
    queryClient.invalidateQueries({ queryKey: ['national-holidays', 'list'] });
  }
};
