import { IconPrefix } from '@fortawesome/fontawesome-common-types';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IconName } from '@fortawesome/free-brands-svg-icons';

const basHeadQuestion: IconDefinition = {
  prefix: 'bas' as IconPrefix,
  iconName: 'head-question' as IconName,
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M509.197 275c-20.93-47.12-48.43-151.73-73.07-186.75A207.91 207.91 0 00266.087 0h-66.09C95.466 0 4.126 80.08.136 184.55a191.271 191.271 0 0063.86 150.26V504a7.998 7.998 0 008 8h16a7.998 7.998 0 008-8V320.55L85.357 311a159.667 159.667 0 01-53.25-125.23C35.337 101 110.657 32 199.997 32h66.07a176.082 176.082 0 01143.93 74.66c12.52 17.79 29.11 66.75 42.46 106.07 9.72 28.71 18.92 55.83 27.56 75.27h-64.02v96a32 32 0 01-32 32h-96v88a7.999 7.999 0 008 8h16a7.996 7.996 0 008-8v-56h64a64.001 64.001 0 0064-64v-64h32a32.004 32.004 0 0026.804-14.582A31.996 31.996 0 00509.197 275zm-307.16 7.294l-1.345-55.81h15.459c11.428 0 21.846-1.016 31.256-3.045 9.41-2.03 16.916-5.976 22.517-11.838 5.601-5.863 8.402-14.432 8.402-25.708 0-10.824-3.249-19.28-9.746-25.368-6.497-6.313-15.123-9.47-25.878-9.47-11.202 0-19.941 3.157-26.214 9.47-6.274 6.089-9.41 14.432-9.41 25.03h-40.666c-.224-13.53 2.689-25.593 8.738-36.192 6.05-10.598 14.788-18.941 26.214-25.03 11.651-6.089 25.542-9.133 41.673-9.133 15.012 0 28.232 2.819 39.659 8.456 11.65 5.638 20.724 13.756 27.221 24.354C316.64 158.383 320 171.123 320 186.232c0 15.56-3.473 28.412-10.418 38.56-6.722 10.148-16.02 17.701-27.895 22.663-11.65 4.961-25.206 7.554-40.666 7.78l-1.008 27.059h-37.977zm19.156 76.106c-7.843 0-14.34-2.48-19.493-7.442-4.93-4.96-7.393-10.936-7.393-17.926 0-7.217 2.464-13.305 7.393-18.266 5.153-4.961 11.65-7.442 19.493-7.442 7.841 0 14.227 2.48 19.156 7.442 4.93 4.961 7.394 11.049 7.394 18.266 0 6.99-2.464 12.966-7.394 17.926-4.929 4.961-11.315 7.442-19.156 7.442z',
  ],
};

export default basHeadQuestion;
