import { colors } from '@bas/theme';
import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type FormControlLabelProps = MuiFormControlLabelProps & {
  light?: boolean;
};

const FormControlLabel = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  light,
  ...props
}: FormControlLabelProps) => <MuiFormControlLabel {...props} />;

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, light }) => `
  & .MuiFormControlLabel-label {
    color: ${light ? theme.palette.common.white : colors.lila[800]};
    padding-left: 4px;
  }
`
);
export default StyledFormControlLabel;
