import { ReeleezeeDepartment } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequestProps =
  Pagination & {
    integrationId: Uuid;
    administrationId: string;
  };

type Response = AxiosResponse<Collection<ReeleezeeDepartment>>;

export const ReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequest =
  async ({
    integrationId,
    administrationId,
    ...params
  }: ReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequestProps): Promise<Response> =>
    axios.get(
      `api/{tenantId}/integrations/${integrationId}/reeleezee/${administrationId}/reeleezee-departments`,
      {
        params: { ...params },
        paramsSerializer(param) {
          return Qs.stringify(param, { arrayFormat: 'brackets' });
        },
      }
    );

export const useReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequest =
  (
    request: ReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequestProps,
    options: QueryOptionsWithKey<
      Response,
      AxiosError<ErrorResponse>,
      Response
    > = {}
  ): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
    useQuery<Response, AxiosError<ErrorResponse>, Response>({
      ...options,
      queryFn: async () =>
        ReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequest(request),
      queryKey: [
        'integrations',
        'reeleezee-departments',
        request.integrationId,
        request,
      ],
    });
