import {
  EstimationSettings,
  isMovingJobEstimationSettings,
  MovingJobEstimationSettings,
} from '@bas/project-domain/models';
import {
  CurrencyTextFieldNumberFormat,
  TextField,
  TextFieldNumberFormat,
} from '@bas/ui/web/atoms';
import { FeatureHelpTooltip, Switch } from '@bas/ui/web/molecules';
import { BuildingType, InternalServiceType } from '@bas/value-objects';
import { Grid, styled, Typography } from '@mui/material';
import hash from 'object-hash';
import { ReactElement, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type EditableQuotationToolFeatureBlockProps = {
  estimationSettings: EstimationSettings[];
  disabled?: boolean;
  onUpdateEstimationSettings: (
    values: Omit<
      EstimationSettings | MovingJobEstimationSettings,
      '@id' | '@type'
    >
  ) => Promise<void> | void;
};

const mapMovingJobEstimationSettings = (
  estimationSettings: MovingJobEstimationSettings | undefined
): Omit<MovingJobEstimationSettings, '@id' | '@type'> | undefined => {
  if (!estimationSettings) {
    return undefined;
  }

  return {
    estimationSettingsId: estimationSettings.estimationSettingsId,
    estimationSettingsType: estimationSettings.estimationSettingsType,
    employeeCubicMeterPerHour: estimationSettings.employeeCubicMeterPerHour,
    cubicMeterRate: estimationSettings.cubicMeterRate,
    travelHourlyRate: estimationSettings.travelHourlyRate,
    pricePerKm: estimationSettings.pricePerKm,
    pricePerBox: estimationSettings.pricePerBox,
    buildingTypeRates: estimationSettings.buildingTypeRates.map((rate) => ({
      buildingType: rate.buildingType,
      rate: rate.rate,
    })),
    serviceTimeConsumptions: estimationSettings.serviceTimeConsumptions.map(
      (serviceTime) => ({
        serviceType: serviceTime.serviceType,
        timeNeeded: serviceTime.timeNeeded,
      })
    ),
    includeMovingCompanyAddress: estimationSettings.includeMovingCompanyAddress,
  };
};

const EditableQuotationToolFeatureBlock = ({
  estimationSettings,
  disabled,
  onUpdateEstimationSettings,
  ...args
}: EditableQuotationToolFeatureBlockProps): ReactElement | null => {
  const movingJobEstimationSettings = useMemo(
    () => estimationSettings.find(isMovingJobEstimationSettings),
    [estimationSettings]
  );
  const [savedHash, setSavedHash] = useState<string>();
  const [settings, setEstimationSettings] = useState<
    MovingJobEstimationSettings | undefined
  >(movingJobEstimationSettings);

  useDebounce(
    () => {
      const mappedSettings = mapMovingJobEstimationSettings(settings);
      const mappedMovingJobEstimationSettings = mapMovingJobEstimationSettings(
        movingJobEstimationSettings
      );
      if (
        !!mappedSettings &&
        !isEqual(mappedMovingJobEstimationSettings, mappedSettings) &&
        savedHash !== hash(mappedSettings)
      ) {
        onUpdateEstimationSettings(mappedSettings);
        setSavedHash(hash(mappedSettings));
      }
    },
    300,
    [
      settings,
      onUpdateEstimationSettings,
      savedHash,
      movingJobEstimationSettings,
    ]
  );

  if (!movingJobEstimationSettings || !settings) {
    return null;
  }

  return (
    <Grid container rowSpacing={5} {...args}>
      <Grid container item xs={12} spacing="12px">
        <Grid item xs={12} pt={2}>
          <Typography variant="subtitle1">
            <FormattedMessage id="featureTypes.quotationTool" />
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item>
            <Switch
              label={
                <FormattedMessage id="features.quotationTool.includeMovingCompanyAddress" />
              }
              checked={settings.includeMovingCompanyAddress}
              disabled={disabled}
              onChange={(e, val) =>
                setEstimationSettings((currentValue) =>
                  currentValue
                    ? {
                        ...currentValue,
                        includeMovingCompanyAddress: val,
                      }
                    : undefined
                )
              }
            />
          </Grid>
          <Grid item>
            <FeatureHelpTooltip
              title={
                <FormattedMessage id="features.quotationTool.includeMovingCompanyAddress.help" />
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item>
            <TextField
              value={settings.employeeCubicMeterPerHour}
              disabled={disabled}
              sx={{ width: '65px' }}
              onChange={(e) =>
                setEstimationSettings((curr) =>
                  curr
                    ? {
                        ...curr,
                        employeeCubicMeterPerHour: parseFloat(e.target.value),
                      }
                    : undefined
                )
              }
              InputProps={{
                inputComponent: TextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 2,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="textPrimary">
              <FormattedMessage id="features.quotationTool.employeeCubicMeterPerHour" />
            </Typography>
          </Grid>
        </Grid>
        {settings.serviceTimeConsumptions.map(
          ({ serviceType, timeNeeded }, index) => {
            const isMinutes = [
              InternalServiceType.ASSEMBLE,
              InternalServiceType.DISASSEMBLE,
            ].includes(serviceType);
            return (
              <Grid
                item
                xs={12}
                container
                spacing={1}
                alignItems="center"
                key={serviceType}
              >
                <Grid item>
                  <TextField
                    value={isMinutes ? timeNeeded * 60 : timeNeeded}
                    disabled={disabled}
                    sx={{ width: '65px' }}
                    onChange={(e) =>
                      setEstimationSettings((curr) => {
                        if (!curr) {
                          return undefined;
                        }

                        const newTime = [...curr.serviceTimeConsumptions];
                        newTime[index] = {
                          serviceType,
                          timeNeeded: isMinutes
                            ? parseFloat(e.target.value) / 60
                            : parseFloat(e.target.value),
                        };

                        return {
                          ...curr,
                          serviceTimeConsumptions: newTime,
                        };
                      })
                    }
                    InputProps={{
                      inputComponent: TextFieldNumberFormat,
                      inputProps: {
                        pattern: '[0-9]*',
                        inputMode: 'numeric',
                        decimalScale: 2,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography color="textPrimary">
                    <FormattedMessage
                      id={`features.quotationTool.serviceTimeConsumptions.${serviceType}`}
                      values={{
                        timeNeeded: isMinutes ? timeNeeded * 60 : timeNeeded,
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        )}
      </Grid>
      <Grid container item xs={12} spacing="12px">
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="features.quotationTool.prices" />
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item>
            <TextField
              value={settings.cubicMeterRate.amount}
              disabled={disabled}
              sx={{ width: '65px' }}
              onChange={(e) =>
                setEstimationSettings((curr) =>
                  curr
                    ? {
                        ...curr,
                        cubicMeterRate: {
                          ...curr.cubicMeterRate,
                          amount: parseFloat(e.target.value) || 0,
                        },
                      }
                    : undefined
                )
              }
              InputProps={{
                inputComponent: CurrencyTextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 2,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="textPrimary">
              <FormattedMessage id="features.quotationTool.cubicMeterRate" />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item>
            <TextField
              value={settings.travelHourlyRate.amount}
              disabled={disabled}
              sx={{ width: '65px' }}
              onChange={(e) =>
                setEstimationSettings((curr) =>
                  curr
                    ? {
                        ...curr,
                        travelHourlyRate: {
                          ...curr.travelHourlyRate,
                          amount: parseFloat(e.target.value) || 0,
                        },
                      }
                    : undefined
                )
              }
              InputProps={{
                inputComponent: CurrencyTextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 2,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="textPrimary">
              <FormattedMessage id="features.quotationTool.travelHourlyRate" />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item>
            <TextField
              value={settings.pricePerKm.amount}
              disabled={disabled}
              sx={{ width: '65px' }}
              onChange={(e) =>
                setEstimationSettings((curr) =>
                  curr
                    ? {
                        ...curr,
                        pricePerKm: {
                          ...curr.pricePerKm,
                          amount: parseFloat(e.target.value) || 0,
                        },
                      }
                    : undefined
                )
              }
              InputProps={{
                inputComponent: CurrencyTextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 2,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Typography color="textPrimary">
              <FormattedMessage id="features.quotationTool.pricePerKm" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing="12px">
        <Grid item xs={12} pt={2}>
          <Typography variant="subtitle1">
            <FormattedMessage id="features.quotationTool.surcharges" />
          </Typography>
        </Grid>
        {Object.values(BuildingType)
          .filter((o) => !!o)
          .map((buildingType) => {
            const buildingRateIndex = settings.buildingTypeRates.findIndex(
              (rate) => rate.buildingType === buildingType
            );

            return (
              <Grid
                item
                xs={12}
                container
                spacing={1}
                alignItems="center"
                key={buildingType}
              >
                <Grid item>
                  <TextField
                    value={
                      buildingRateIndex >= 0
                        ? settings.buildingTypeRates[buildingRateIndex].rate
                            .amount
                        : 0
                    }
                    disabled={disabled}
                    sx={{ width: '65px' }}
                    onChange={(e) =>
                      setEstimationSettings((curr) => {
                        if (!curr) {
                          return undefined;
                        }

                        const newBuildingRates = [...curr.buildingTypeRates];
                        const newBuildingRateIndex = newBuildingRates.findIndex(
                          (rate) => rate.buildingType === buildingType
                        );

                        if (newBuildingRateIndex >= 0) {
                          newBuildingRates[newBuildingRateIndex] = {
                            buildingType,
                            rate: {
                              currency: 'EUR',
                              amount: parseFloat(e.target.value || '0'),
                            },
                          };
                        } else {
                          newBuildingRates.push({
                            buildingType,
                            rate: {
                              currency: 'EUR',
                              amount: parseFloat(e.target.value || '0'),
                            },
                          });
                        }

                        return {
                          ...curr,
                          buildingTypeRates: newBuildingRates,
                        };
                      })
                    }
                    InputProps={{
                      inputComponent: CurrencyTextFieldNumberFormat,
                      inputProps: {
                        pattern: '[0-9]*',
                        inputMode: 'numeric',
                        decimalScale: 2,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography color="textPrimary">
                    <FormattedMessage
                      id="features.quotationTool.buildingRate.surcharge"
                      values={{
                        buildingType: (
                          <FormattedMessage
                            id={`buildingTypes.${buildingType}`}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
const StyledEditableQuotationToolFeatureBlock = styled(
  EditableQuotationToolFeatureBlock
)(
  () => `
  .MuiFormControl-root {
    width: 65px;
  }
  .MuiInput-input {
    text-align: center;
  }
`
);
export default StyledEditableQuotationToolFeatureBlock;
