import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basFoldingBed: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'folding-bed',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M392.1 168.5c-4.4-6.7-11.9-11-20.5-11h-82c-13.5 0-24.5 11-24.5 24.5v13.5H246.9V182c0-13.5-11-24.5-24.5-24.5h-82c-8.6 0-16.1 4.4-20.5 11H83.5c-12.2 0-22 9.9-22 22v9.1H30c-13.5 0-24.5 11-24.5 24.5v60.4c0 13.5 11 24.5 24.5 24.5H42L23.1 334.6c-4.1 5.5-3 13.4 2.6 17.5s13.4 3 17.5-2.6l26.5-35.7c1.1-1.4 1.8-3 2.2-4.7H94.7c1.8 0 3.6-.2 5.3-.6H412c1.7 .4 3.5 .6 5.3 .6h26.6c.4 1.6 1.1 3.2 2.2 4.7l26.5 35.7c4.1 5.5 11.9 6.7 17.5 2.6s6.7-11.9 2.6-17.5l-18.9-25.5H482c13.5 0 24.5-11 24.5-24.5V224.2c0-13.5-11-24.5-24.5-24.5H450.4v-9.1c0-12.1-9.9-22-22-22H392.1zM119.2 254.8c4.3 7.2 12.1 12.1 21.1 12.1h82c13.5 0 24.5-11 24.5-24.5V220.5h18.3v21.9c0 13.5 11 24.5 24.5 24.5h82c9 0 16.9-4.9 21.1-12.1v28.7H119.2V254.8zm276.9-66.2h32.3c1.1 0 2 .9 2 2v9.1H417.3c-9 0-16.9 4.9-21.1 12.1V188.5zM115.8 211.8c-4.3-7.2-12.1-12.1-21.1-12.1H81.5v-9.1c0-1.1 .9-2 2-2h32.3v23.2zm255.3-29.3v59.4h-81V182.5h81zM140.8 241.9V182.5h81v59.4h-81zM30.5 224.7H94.2v59.4H30.5V224.7zm451 0v59.4H417.8V224.7h63.7z',
  ],
};

export default basFoldingBed;
