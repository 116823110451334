import { isObjectResponse } from '@bas/value-objects';
import { Material } from '../types';

export function isMaterial(object: unknown): object is Material {
  return (
    isObjectResponse(object) &&
    ['Material', 'Vehicle', 'MovingTruck', 'MovingLift'].includes(
      object['@type']
    ) &&
    Object.prototype.hasOwnProperty.call(object, 'materialId')
  );
}
