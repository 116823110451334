import {
  EmployeeDeclarationStatistics,
  isDeclaration,
} from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type DeclarationStatisticsByEmployeeIdRequestProps = {
  employeeId: Uuid;
  year: number;
};

type Response = AxiosResponse<EmployeeDeclarationStatistics>;

export const DeclarationStatisticsByEmployeeIdRequest = async ({
  employeeId,
  year,
  ...params
}: DeclarationStatisticsByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/${employeeId}/declaration-statistics/${year}`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useDeclarationStatisticsByEmployeeIdRequest = (
  { employeeId, ...request }: DeclarationStatisticsByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      DeclarationStatisticsByEmployeeIdRequest({ ...request, employeeId }),
    queryKey: ['declaration-statistics', 'list', employeeId, request],
  });

export const usePrefetchDeclarationStatisticsByEmployeeIdRequest = ({
  employeeId,
  ...request
}: DeclarationStatisticsByEmployeeIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'declaration-statistics',
        'list',
        employeeId,
        ...Object.values({ employeeId, ...request }),
      ],
      queryFn: async () =>
        DeclarationStatisticsByEmployeeIdRequest({ employeeId, ...request }),
    });
  }, [employeeId, queryClient, request]);
};

export const DeclarationStatisticsByEmployeeIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isDeclaration(data)) {
      queryClient.invalidateQueries({
        queryKey: ['declaration-statistics', 'list', data.employeeId],
      });
    }
  };
