import * as Yup from 'yup';

export interface MollieSettingsInputType {
  profileId: string;
  testMode: boolean;
}

export const MollieSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<MollieSettingsInputType> =>
    Yup.object({
      profileId: Yup.string().required().label('label.profileId'),
      testMode: Yup.boolean().required().label('label.testMode'),
    });
