import { usePackagesRequest } from '@bas/project-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import {
  IdentityIndicator,
  ReactHookFormButtonGroupField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { PricingType, ProjectType } from '@bas/value-objects';
import { Grid, MenuItem } from '@mui/material';
import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const VerhuisOffertesSettingsForm = (): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);

  const { data: identitiesData, isInitialLoading: isLoadingIdentities } =
    useIdentitiesRequest(
      {},
      {
        enabled: tenantState?.useIdentities,
      }
    );

  const useIdentities = useMemo(
    () => tenantState?.useIdentities,
    [tenantState]
  );
  const identityId = useWatch({ name: 'identityId' });

  const projectType = ProjectType.MOVING_JOB;
  const { setValue } = useFormContext();

  const pricingType = useWatch({
    name: `pricingType`,
  });
  const packageId = useWatch({
    name: `packageId`,
  });

  const { data: packagesData, isPending: isLoadingPackages } =
    usePackagesRequest({ perPage: 999 });

  const packages = useMemo(
    () =>
      (packagesData?.data['hydra:member'] || []).filter(
        (p) =>
          p.pricingType === pricingType &&
          p.projectType === projectType &&
          (!useIdentities || p.identityId === identityId)
      ),
    [identityId, packagesData?.data, pricingType, projectType, useIdentities]
  );

  useEffect(() => {
    if (
      packageId &&
      (packagesData?.data?.['hydra:member']?.length || 0) > 0 &&
      !packages.find((p) => p.packageId === packageId)
    ) {
      setValue(`packageId`, '');
    }
  }, [packages, packageId, setValue, packagesData?.data]);

  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12}>
        <Controller
          name="pricingType"
          render={(registered) => (
            <ReactHookFormButtonGroupField
              {...registered}
              label={<FormattedMessage id="label.pricingType" />}
              toggleButtonGroup={{
                size: 'small',
                exclusive: true,
              }}
              desktop
              options={Object.values(PricingType)
                .filter((type) => type !== '')
                .map((option) => ({
                  value: option,
                  label: (
                    <FormattedMessage
                      id={`pricingTypes.${option || 'unknown'}`}
                    />
                  ),
                }))}
            />
          )}
        />
      </Grid>
      {useIdentities && (
        <Grid item xs={12}>
          <Controller
            name="identityId"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                autoComplete="off"
                fullWidth
                label={<FormattedMessage id="label.identityId" />}
                loading={isLoadingIdentities}
                disabled={isLoadingIdentities}
                select
              >
                {(identitiesData?.data['hydra:member'] || []).map(
                  (identity) => (
                    <MenuItem
                      key={identity.identityId}
                      value={identity.identityId}
                    >
                      <IdentityIndicator
                        identityId={identity.identityId}
                        full
                      />
                    </MenuItem>
                  )
                )}
              </ReactHookFormTextField>
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="packageId"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.packageId" />}
              select
              disabled={
                isLoadingPackages ||
                !pricingType ||
                (useIdentities && !identityId)
              }
            >
              {packages.map((p) => (
                <MenuItem key={p.packageId} value={p.packageId}>
                  {p.packageName}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default VerhuisOffertesSettingsForm;
