import {
  ContentSection,
  ContentSectionCancelAction,
  ContentSectionSaveAction,
} from '@bas/ui/web/molecules';
import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScanMoversSettingsForm } from '../ScanMoversSettingsForm';

export type EditingScanMoversSettingsContentSectionProps = {
  onCancel: () => void;
};

const EditingScanMoversSettingsContentSection = ({
  onCancel,
}: EditingScanMoversSettingsContentSectionProps): ReactElement => (
  <ContentSection
    editing
    actions={[
      <ContentSectionCancelAction onClick={onCancel} />,
      <ContentSectionSaveAction />,
    ]}
  >
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} pt={4}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.settings" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ScanMoversSettingsForm />
      </Grid>
    </Grid>
  </ContentSection>
);

export default EditingScanMoversSettingsContentSection;
