import { colors, fontSizesWeb } from '@bas/theme';
import { Draggable } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { UseDraggableArguments } from '@dnd-kit/core';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { Box, Grid, styled, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { MaterialBox, MaterialBoxProps } from '../MaterialBox';

export type MaterialBoxCategoryProps = {
  label?: string | ReactNode;
  resources: (MaterialBoxProps & {
    draggableProps?: UseDraggableArguments;
  })[];
  appendToResources?: ReactNode[];
  className?: string;
  hideLabel?: boolean;
};

const MaterialBoxCategory = ({
  label,
  resources,
  hideLabel,
  className,
  appendToResources,
}: MaterialBoxCategoryProps): ReactElement => (
  <Box className={className}>
    {!hideLabel && (
      <Typography
        className="Bas-MaterialBox-Category-Label"
        variant="subtitle1"
      >
        {label}
      </Typography>
    )}
    <Grid container className="Bas-MaterialBox-Resources" spacing={1}>
      {resources.map(({ draggableProps, ...resource }, resourceIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item key={resourceIndex}>
          {draggableProps ? (
            <Draggable draggableProps={draggableProps}>
              {({ isDragging, setNodeRef, listeners, attributes }) => (
                <MaterialBox
                  isActive={isDragging}
                  {...listeners}
                  {...attributes}
                  ref={setNodeRef}
                  style={{ cursor: 'grab' }}
                  {...resource}
                />
              )}
            </Draggable>
          ) : (
            <MaterialBox {...resource} />
          )}
        </Grid>
      ))}
      {appendToResources}
      {[...resources, ...(appendToResources || [])].length === 0 && (
        <Grid item className="Bas-MaterialBox-Resource-Depleted-Container">
          <Icon icon={faBan} className="Bas-MaterialBox-Resource-Depleted" />
        </Grid>
      )}
    </Grid>
  </Box>
);

const StyledMaterialBoxCategory = styled(MaterialBoxCategory)(
  ({ theme }) => `
  .Bas-MaterialBox-Category-Label {
    font-size: ${fontSizesWeb.sm};
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: ${colors.lila[600]};
    text-align: center;
    width: 100%;
    margin-bottom: ${theme.spacing(1)};
  }

  .Bas-MaterialBox-Resources {
    padding: ${theme.spacing(0, 2)};
  }

  .Bas-MaterialBox-Resource-Depleted-Container {
    padding: ${theme.spacing(0, 1)};
    height: 52px;
  }

  .Bas-MaterialBox-Resource-Depleted {
    font-size: 44px;
    color: ${colors.orange[700]};
  }
  `
);
export default StyledMaterialBoxCategory;
