import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MarkBatchDataAsCompletedMutationProps = {
  integrationId: Uuid;
  batchId?: Uuid | null;
  datas: { dataId: Uuid; externalId?: string }[];
};

export const MarkBatchDataAsCompletedMutation = async ({
  integrationId,
  batchId,
  ...values
}: MarkBatchDataAsCompletedMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/integrations/${integrationId}/batch-datas/mark-as-completed`,
    {
      integrationId,
      batchId,
      ...values,
    }
  );

export const useMarkBatchDataAsCompletedMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkBatchDataAsCompletedMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  MarkBatchDataAsCompletedMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkBatchDataAsCompletedMutationProps
  >({
    mutationFn: MarkBatchDataAsCompletedMutation,
    throwOnError: false,
    ...options,
  });
