import {
  CostTypeRequestInvalidator,
  DocumentTemplateByTemplateIdRequestInvalidator,
  DocumentTemplatesRequestInvalidator,
  DownloadsByDownloadIdsRequestInvalidator,
  IdentitiesByIdentityIdsRequestInvalidator,
  InternalTenantByHostnameRequestInvalidator,
  NewsByNewsIdRequestInvalidator,
  NewsRequestInvalidator,
  PackageByPackageIdRequestInvalidator,
  PreviewDocumentTemplateByLanguageRequestInvalidator,
  publicFurnitureTypeRequestInvalidator,
  publicRoomTypeRequestInvalidator,
  PublicServiceTypeRequestInvalidator,
  PublishedNewsRequestInvalidator,
  ServiceTypeRequestInvalidator,
  TenantByHostnameRequestInvalidator,
  TenantByTenantIdRequestInvalidator,
  TenantInvoicesByTenantInvoiceIdsRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  CostTypeRequestInvalidator,
  DocumentTemplateByTemplateIdRequestInvalidator,
  DocumentTemplatesRequestInvalidator,
  DownloadsByDownloadIdsRequestInvalidator,
  IdentitiesByIdentityIdsRequestInvalidator,
  InternalTenantByHostnameRequestInvalidator,
  NewsByNewsIdRequestInvalidator,
  NewsRequestInvalidator,
  PackageByPackageIdRequestInvalidator,
  PreviewDocumentTemplateByLanguageRequestInvalidator,
  publicFurnitureTypeRequestInvalidator,
  publicRoomTypeRequestInvalidator,
  PublicServiceTypeRequestInvalidator,
  PublishedNewsRequestInvalidator,
  ServiceTypeRequestInvalidator,
  TenantByHostnameRequestInvalidator,
  TenantByTenantIdRequestInvalidator,
  TenantInvoicesByTenantInvoiceIdsRequestInvalidator,
];
