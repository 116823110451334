import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basSlide: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'slide',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M7.494 63.994c0-8.284 6.716-15 15-15h130.268l.263.009c26.268.925 59.41 9.648 86.152 34.348 27.105 25.034 45.824 64.808 45.824 124.147 0 11.42-.691 24.37-1.412 37.808l-.024.451c-.722 13.45-1.481 27.583-1.723 42.11-.49 29.485 1.186 59.52 9.165 86.434 7.897 26.639 21.825 49.79 45.793 66.499 24.13 16.823 59.992 28.205 113.705 28.205h36.5c8.286 0 15 6.715 15 15 0 8.284-6.714 15-15 15h-36.5c-57.79 0-100.24-12.245-130.863-33.594-30.784-21.463-48.045-51.033-57.398-82.583-9.271-31.275-10.907-64.898-10.399-95.46.252-15.086 1.036-29.689 1.751-43.01v-.002l.035-.658c.732-13.647 1.37-25.743 1.37-36.2 0-52.664-16.367-83.809-36.18-102.109-15.27-14.104-33.652-21.559-50.824-24.672v407.288c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15v-71.001H38.995v71.001c0 8.284-6.716 15-15 15-8.285 0-15.001-6.716-15.001-15V70.541a14.94 14.94 0 01-1.5-6.547zm31.5 15v56.002h99.003V78.995H38.995zm0 86.003V259h99.003v-94.002H38.995zm0 124.003v98.003h99.003V289H38.995z',
  ],
};

export default basSlide;
