import {
  isSnelStartSettings,
  SnelStartSettings,
} from '@bas/integration-domain/models';
import { useSnelStartMappingOptions } from '@bas/integration-domain/web/hooks';
import { Alert } from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountingIntegrationSettingsBlock } from '../AccountingIntegrationSettingsBlock';

export type SnelStartSettingsContentSectionProps = {
  integrationId: Uuid;
  providerSettings: SnelStartSettings;
  onEdit: () => void;
};

const SnelStartSettingsContentSection = ({
  integrationId,
  providerSettings,
  onEdit,
}: SnelStartSettingsContentSectionProps): ReactElement => {
  const {
    generalLedgersError,
    generalLedgerExternalOptions,
    costCentersError,
    costCenterExternalOptions,
    vatExternalOptions,
  } = useSnelStartMappingOptions(integrationId);

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        {(generalLedgersError || costCentersError) && (
          <Grid item xs={12} mt={2}>
            <Alert severity="error">
              <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          {isSnelStartSettings(providerSettings) && (
            <AccountingIntegrationSettingsBlock
              providerSettings={providerSettings}
              externalGeneralLedgers={generalLedgerExternalOptions}
              externalCostCenters={costCenterExternalOptions}
              externalVatCodes={vatExternalOptions}
            />
          )}
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default SnelStartSettingsContentSection;
