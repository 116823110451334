import {
  DeclarationCategory,
  isDeclarationCategory,
} from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DeclarationCategoriesRequestProps = Pagination & {
  withDisabled?: boolean;
};

export const DeclarationCategoriesRequest = async ({
  ...params
}: DeclarationCategoriesRequestProps): Promise<
  AxiosResponse<Collection<DeclarationCategory>>
> =>
  axios.get(`api/{tenantId}/hrm/declaration-categories`, {
    params: {
      ...params,
    },
  });

export const useDeclarationCategoriesRequest = (
  request: DeclarationCategoriesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<DeclarationCategory>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<DeclarationCategory>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<DeclarationCategory>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<DeclarationCategory>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<DeclarationCategory>>
  >({
    ...options,
    queryFn: async () => DeclarationCategoriesRequest(request),
    queryKey: ['declaration-categories', 'list', request],
  });

export const DeclarationCategoriesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isDeclarationCategory(data)) {
    queryClient.invalidateQueries({
      queryKey: ['declaration-categories', 'list'],
    });
  }
};
