import { Invoice, isInvoice } from '@bas/financial-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type InvoiceByInvoiceIdRequestQueryProps = {
  invoiceId: Uuid;
};

export const InvoiceByInvoiceIdRequestQuery = async ({
  invoiceId,
  ...params
}: InvoiceByInvoiceIdRequestQueryProps): Promise<AxiosResponse<Invoice>> =>
  axios.get(`api/{tenantId}/invoices/${invoiceId}`, {
    params,
  });

export const useInvoiceByInvoiceIdRequestQuery = (
  request: InvoiceByInvoiceIdRequestQueryProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Invoice>,
    AxiosError,
    AxiosResponse<Invoice>
  > = {}
): UseQueryResult<AxiosResponse<Invoice>, AxiosError> =>
  useQuery<AxiosResponse<Invoice>, AxiosError, AxiosResponse<Invoice>>({
    ...options,
    queryFn: async () => InvoiceByInvoiceIdRequestQuery(request),
    queryKey: ['invoices', 'detail', request.invoiceId],
  });

export const InvoiceByInvoiceIdRequestQueryInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isInvoice(data)) {
    queryClient.invalidateQueries({
      queryKey: ['invoices', 'detail', data.invoiceId],
    });
  }
};
