import { isEvent } from '@bas/planning-domain/models';
import {
  isListedProject,
  isProject,
  ListedProject,
} from '@bas/project-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ListedProjectsRequestProps = Pagination &
  Filterable & {
    customer?: Uuid;
  };

type Response = AxiosResponse<Collection<ListedProject>>;
type QueryKeyType = QueryKey & {
  [0]: 'projects';
  [1]: 'listed';
};

export const ListedProjectsRequest = async ({
  ...params
}: ListedProjectsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/listed-projects`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useListedProjectsRequest = (
  request: ListedProjectsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => ListedProjectsRequest({ ...request }),
    queryKey: ['projects', 'listed', JSON.stringify(request)],
    placeholderData: keepPreviousData,
  });

export const ListedProjectsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isProject(data) || isListedProject(data) || isEvent(data)) {
    queryClient.invalidateQueries({ queryKey: ['projects', 'listed'] });
  }
};
