export enum FeatureType {
  MOBILE_APP = 'mobile-app',
  CALENDAR_SYNC = 'calendar-sync',
  CUSTOMER_NOTIFICATIONS = 'customer-notifications',
  TIME_REGISTRATION = 'time-registration',
  INVOICING = 'invoicing',
  QUOTATION = 'quotation',
  TASKS = 'tasks',
  PROJECT = 'project',
  UI = 'ui-feature',
}
