import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basGardenTable: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'garden-table',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M40 110.003c8.054 0 14.626-6.348 14.985-14.313.114-.174.312-.445.641-.817 1.32-1.49 3.955-3.644 8.577-6.168 9.23-5.039 23.576-10.053 42.586-14.443 34.656-8.004 82.071-13.324 135.217-14.148v206.893H67c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15h128.003v4c0 11.047 8.955 20.002 20 20.002h27.002v142.003h-72.003c-11.045 0-20 8.955-20 20v10H364.01v-10c0-11.045-8.955-20-20.001-20h-72.002V321.009h27c11.047 0 20.002-8.955 20.002-20.002v-4H445.51c8.285 0 15-6.716 15-15 0-8.284-6.715-15-15-15H272.006V60.13c52.74.875 99.778 6.178 134.217 14.132 19.01 4.39 33.356 9.404 42.586 14.443 4.622 2.524 7.256 4.678 8.576 6.168.329.372.527.643.641.817.36 7.965 6.931 14.313 14.985 14.313 8.284 0 15-6.716 15-15 0-8.15-3.64-14.912-8.171-20.025-4.448-5.02-10.32-9.146-16.654-12.604-12.682-6.925-30.042-12.685-50.212-17.343C372.393 35.659 317.082 30 256.506 30c-60.575 0-115.888 5.658-156.468 15.03-20.17 4.658-37.53 10.418-50.212 17.343-6.334 3.458-12.206 7.584-16.654 12.604C28.64 80.09 25 86.854 25 95.002c0 8.285 6.716 15 15 15zm14.85-14.074s.004-.015.02-.044a.346.346 0 01-.02.044zm403.307-.009c.002.006.005.009.005.009l-.004-.012a.594.594 0 01-.015-.032l.014.035z',
  ],
};

export default basGardenTable;
