import { Identity } from '@bas/tenant-domain/models';
import { IdentityIndicator } from '@bas/ui/web/molecules';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';

export type IdentityDetailsPageTemplateProps = BoxProps & {
  identity: Identity;
  className?: string;
  onClose?: () => void;
};

const IdentityDetailsPageTemplate = ({
  identity,
  children,
  onClose,
  ...args
}: IdentityDetailsPageTemplateProps): ReactElement => (
  <Box {...args}>
    <Box className="Bas-IdentityDetailsPageTemplate-Header">
      <Typography color="textPrimary" variant="h3">
        <IdentityIndicator
          identityId={identity.identityId}
          size="medium"
          full
        />
      </Typography>
    </Box>
    <Box className="Bas-IdentityDetailsPageTemplate-Content">{children}</Box>
  </Box>
);

const StyledIdentityDetailsPageTemplate = styled(IdentityDetailsPageTemplate)(
  ({ theme }) => `
  position: relative;

  .Bas-IdentityDetailsPageTemplate-Close {
    position: absolute;
    font-size: 24px;
    padding: 0;
    z-index: 15;
    top: -${theme.spacing(3)};
    left: -${theme.spacing(3)};
    ${theme.breakpoints.down('lg')} {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
    @media (max-width: 1024px) {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
  }

  .Bas-IdentityDetailsPageTemplate-Header {
    padding-bottom: ${theme.spacing(2)};
  }
`
);
export default StyledIdentityDetailsPageTemplate;
