import { basAssemble, basDisassemble } from '@bas/svg-icons';
import { Icon, IconProps } from '@bas/ui/web/base';
import { InternalServiceType } from '@bas/value-objects';
import {
  faBoxFull,
  faBoxOpen,
  faForklift,
  faHouseChimneyCrack,
  faMemoCircleCheck,
  faPalletBoxes,
  faQuestion,
  faRecycle,
  faWarehouseAlt,
  faWineGlassCrack,
} from '@fortawesome/pro-light-svg-icons';
import { FunctionComponent } from 'react';

export type InternalServiceTypeIconProps = Omit<IconProps, 'icon'> & {
  serviceType: InternalServiceType;
};

const serviceIconMap = {
  [InternalServiceType.PROVIDE_BOXES]: faPalletBoxes,
  [InternalServiceType.PACKING]: faBoxFull,
  [InternalServiceType.UNPACK]: faBoxOpen,
  [InternalServiceType.ASSEMBLE]: basAssemble,
  [InternalServiceType.DISASSEMBLE]: basDisassemble,
  [InternalServiceType.MOVING_LIFT]: faForklift,
  [InternalServiceType.WASTE_DISPOSAL]: faRecycle,
  [InternalServiceType.PERMITS]: faMemoCircleCheck,
  [InternalServiceType.STORAGE]: faWarehouseAlt,
  [InternalServiceType.INSURANCE]: faHouseChimneyCrack,
  [InternalServiceType.CERTIFICATE]: faWineGlassCrack,
};

const InternalServiceTypeIcon: FunctionComponent<
  InternalServiceTypeIconProps
> = ({ serviceType, ...args }: InternalServiceTypeIconProps) => {
  let icon = faQuestion;
  if (serviceIconMap[serviceType]) {
    icon = serviceIconMap[serviceType];
  }

  return <Icon {...args} icon={icon} />;
};

InternalServiceTypeIcon.defaultProps = {};

export default InternalServiceTypeIcon;
