import {
  isKingFinanceSettings,
  KingFinanceSettings,
} from '@bas/integration-domain/models';
import { useKingFinanceMappingOptions } from '@bas/integration-domain/web/hooks';
import { Alert } from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountingIntegrationSettingsBlock } from '../AccountingIntegrationSettingsBlock';

export type KingFinanceSettingsContentSectionProps = {
  integrationId: Uuid;
  providerSettings: KingFinanceSettings;
  onEdit: () => void;
};

const KingFinanceSettingsContentSection = ({
  integrationId,
  providerSettings,
  onEdit,
}: KingFinanceSettingsContentSectionProps): ReactElement => {
  const {
    generalLedgersError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsExternalOptions,
    journalsError,
  } = useKingFinanceMappingOptions(integrationId);

  const salesJournal = useMemo(() => {
    if (!isKingFinanceSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.salesJournalCode) {
      return undefined;
    }

    return journalsExternalOptions.find(
      ({ externalId }) => externalId === providerSettings?.salesJournalCode
    );
  }, [providerSettings, journalsExternalOptions]);

  const contraGeneralLedger = useMemo(() => {
    if (!isKingFinanceSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.contraGeneralLedger) {
      return undefined;
    }

    return generalLedgerExternalOptions.find(
      ({ externalId }) => externalId === providerSettings?.contraGeneralLedger
    );
  }, [providerSettings, generalLedgerExternalOptions]);

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        {(journalsError ||
          generalLedgersError ||
          departmentsError ||
          vatCodesError) && (
          <Grid item xs={12} mt={2}>
            <Alert severity="error">
              <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
            </Alert>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.salesJournal" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isKingFinanceSettings(providerSettings) && salesJournal ? (
              salesJournal.label
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.contraGeneralLedger" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isKingFinanceSettings(providerSettings) && contraGeneralLedger ? (
              contraGeneralLedger.label
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isKingFinanceSettings(providerSettings) && (
            <AccountingIntegrationSettingsBlock
              providerSettings={providerSettings}
              externalGeneralLedgers={generalLedgerExternalOptions}
              externalCostCenters={costCenterExternalOptions}
              externalVatCodes={vatExternalOptions}
            />
          )}
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default KingFinanceSettingsContentSection;
