import { ErrorFallbackTemplate } from '@bas/ui/web/templates';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntercom } from 'react-use-intercom';

type AppErrorBoundaryProps = {
  error: Error;
  eventId: string | null;
  resetError(): void;
};

const AppErrorBoundary = ({
  error,
  eventId,
  resetError,
}: AppErrorBoundaryProps) => {
  const { showNewMessage, trackEvent } = useIntercom();
  const handleRequestHelp = useCallback(
    () => showNewMessage(),
    [showNewMessage]
  );

  useEffect(() => {
    trackEvent('User got an exception', {
      name: error.name,
      message: error.message,
      eventId,
    });
  }, [trackEvent, error, eventId]);

  return (
    <ErrorFallbackTemplate
      title={<FormattedMessage id="errorFallback.oopsSomethingWentWrong" />}
      description={<FormattedMessage id="errorFallback.errorDescription" />}
      resetError={resetError}
      requestHelp={handleRequestHelp}
    />
  );
};

export default AppErrorBoundary;
