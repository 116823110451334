import localForage from 'localforage';
import { PersistStorage, StorageValue } from 'zustand/middleware/persist';

export const secureZustandStorage = <S>(): PersistStorage<S> => ({
  getItem: async (key) => localForage.getItem<StorageValue<S>>(key),
  setItem: async (key, newValue) => {
    await localForage.setItem<StorageValue<S>>(
      key,
      JSON.parse(JSON.stringify(newValue))
    );
  },
  removeItem: async (key) => {
    await localForage.removeItem(key);
  },
});
