import { isTeam, Team } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TeamByTeamIdRequestProps = {
  teamId: Uuid;
};

export const TeamByTeamIdRequestQuery = async ({
  teamId,
  ...params
}: TeamByTeamIdRequestProps): Promise<AxiosResponse<Team>> =>
  axios.get(`api/{tenantId}/hrm/teams/${teamId}`, {
    params,
  });

export const useTeamByTeamIdRequestQuery = (
  request: TeamByTeamIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Team>,
    AxiosError,
    AxiosResponse<Team>
  > = {}
): UseQueryResult<AxiosResponse<Team>, AxiosError> =>
  useQuery<AxiosResponse<Team>, AxiosError, AxiosResponse<Team>>({
    ...options,
    queryFn: async () => TeamByTeamIdRequestQuery(request),
    queryKey: ['teams', 'detail', request.teamId],
  });

export const TeamByTeamIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTeam(data)) {
    queryClient.invalidateQueries({
      queryKey: ['teams', 'detail', data.teamId],
    });
  }
};
