import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basDressingTable: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'dressing-table',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M318.6 119.9c5.9 5.9 5.9 15.4 0 21.2l-72 72c-5.9 5.9-15.4 5.9-21.2 0s-5.9-15.4 0-21.2l72-72c5.9-5.9 15.4-5.9 21.2 0zm-57 3.7c5.9-5.9 5.9-15.4 0-21.2s-15.4-5.9-21.2 0l-36 36c-5.9 5.9-5.9 15.4 0 21.2s15.4 5.9 21.2 0l36-36zM398 91.5V249h90c8.3 0 15 6.7 15 15V435c0 8.3-6.7 15-15 15H407l-6 42H365l-6-42H153l-6 42H111l-6-42H24c-8.3 0-15-6.7-15-15V264c0-8.3 6.7-15 15-15h90V91.5c0-12.6 5.9-23.5 14.2-32c8.2-8.5 19.4-15.4 32-20.9C185.5 27.5 219.4 21 256 21s70.5 6.5 95.8 17.5c12.6 5.5 23.8 12.5 32 20.9c8.3 8.6 14.2 19.4 14.2 32zm-254 0V249H368V91.5c0-2.7-1.2-6.4-5.8-11.2c-4.6-4.8-12.1-9.8-22.4-14.3c-20.7-9-50.3-15-83.8-15s-63.1 6-83.8 15c-10.4 4.5-17.8 9.5-22.4 14.3c-4.6 4.7-5.8 8.5-5.8 11.2zM39 279v56H473V279H39zm0 86v55H473V365H39z',
  ],
};

export default basDressingTable;
