import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(isBetween);
dayjs.extend(localeData);

export const areDayjsRangesOverlapping = (
  a: {
    start: dayjs.Dayjs | Date;
    end: dayjs.Dayjs | Date;
  },
  b: {
    start: dayjs.Dayjs | Date;
    end: dayjs.Dayjs | Date;
  }
) =>
  dayjs(a.start).isBetween(dayjs(b.start), dayjs(b.end), 'minute', '()') ||
  dayjs(a.end).isBetween(dayjs(b.start), dayjs(b.end), 'minute', '()') ||
  dayjs(b.start).isBetween(dayjs(a.start), dayjs(a.end), 'minute', '()') ||
  dayjs(b.end).isBetween(dayjs(a.start), dayjs(a.end), 'minute', '()') ||
  (dayjs(a.start).isSame(dayjs(b.start), 'minute') &&
    dayjs(a.end).isSame(dayjs(b.end), 'minute'));
