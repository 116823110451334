import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basSideTable: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'side-table',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M300.9 29c2.1-6 7.8-10 14.1-10h63c6.3 0 12 4 14.1 10l32 90c1.6 4.6 .9 9.7-1.9 13.7s-7.4 6.4-12.3 6.4H362v69h26c5.5 0 10 4.5 10 10v17h37c8.3 0 15 6.7 15 15v74c0 8.3-6.7 15-15 15H370l-6 173H328l-6-173H189l-6 173H147l-6-173H88c-8.3 0-15-6.7-15-15V250c0-8.3 6.7-15 15-15H295V218c0-5.5 4.5-10 10-10h27V139H283c-4.9 0-9.4-2.4-12.3-6.4s-3.5-9.1-1.9-13.7l32-90zm24.7 20l-21.3 60h84.5L367.4 49H325.6zM103 309H420V265H103v44z',
  ],
};

export default basSideTable;
