import * as Yup from 'yup';
import {
  ConnectIntegrationInputType,
  ConnectIntegrationInputTypeValidationBuilder,
} from '../connectIntegrationInputType';

export interface ConnectReeleezeeIntegrationInputType
  extends ConnectIntegrationInputType {
  username: string;
  password: string;
}

export const ConnectReeleezeeIntegrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectReeleezeeIntegrationInputType> =>
    Yup.object({
      username: Yup.string().max(200).required().label('label.username'),
      password: Yup.string().required().label('label.password'),
    }).concat(ConnectIntegrationInputTypeValidationBuilder());
