import { ReactElement } from 'react';

export type RenderPersonNameProps = {
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
};

const RenderRenderPersonName = ({
  firstName,
  middleName,
  lastName,
}: RenderPersonNameProps): ReactElement => {
  if (!firstName && !middleName) {
    return <span>{lastName}</span>;
  }

  if (firstName && !middleName) {
    return <span>{`${firstName} ${lastName}`}</span>;
  }

  return <span>{`${firstName} ${middleName} ${lastName}`}</span>;
};

export default RenderRenderPersonName;
