import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import {
  DriversLicenceCharacteristics,
  ErrorResponse,
  Uuid,
} from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ImportExternalEmployeeMutationProps = {
  integrationId: Uuid;
  externalId?: Uuid | null;
  skills: (OfficeSkill | MovingJobSkill)[];
  driversLicense: DriversLicenceCharacteristics;
};

export const ImportExternalEmployeeMutation = async ({
  integrationId,
  externalId,
  ...values
}: ImportExternalEmployeeMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/integrations/${integrationId}/external-employees/${externalId}/import`,
    {
      integrationId,
      externalId,
      ...values,
    }
  );

export const useImportExternalEmployeeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ImportExternalEmployeeMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ImportExternalEmployeeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ImportExternalEmployeeMutationProps
  >({
    mutationFn: ImportExternalEmployeeMutation,
    throwOnError: false,
    ...options,
  });
