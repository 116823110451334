import * as Yup from 'yup';

export interface RejectExternalEmployeeInputType {
  externalId: string;
  reason?: string;
}

export const RejectExternalEmployeeInputTypeDefaultValues =
  (): RejectExternalEmployeeInputType => ({
    externalId: '',
    reason: '',
  });

export const RejectExternalEmployeeInputTypeValidationBuilder =
  (): Yup.ObjectSchema<RejectExternalEmployeeInputType> =>
    Yup.object({
      externalId: Yup.string().required().label('label.externalId'),
      reason: Yup.string().optional().label('label.reason'),
    });
