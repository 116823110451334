import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DisconnectIntegrationMutationProps = {
  integrationId: Uuid;
};

export const DisconnectIntegrationMutation = async ({
  integrationId,
}: DisconnectIntegrationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/integrations/${integrationId}/disconnect`, {});

export const useDisconnectIntegrationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisconnectIntegrationMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DisconnectIntegrationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisconnectIntegrationMutationProps
  >({
    mutationFn: DisconnectIntegrationMutation,
    throwOnError: false,
    ...options,
  });
