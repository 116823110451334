import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basPram: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'pram',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M117.5 52L107.3 62.4C117.5 52 117.5 52 117.5 52l.3 .3c.2 .2 .5 .5 .9 .9c.8 .7 1.9 1.9 3.3 3.3c2.9 2.9 7 7 12.2 12.2c10.3 10.4 24.5 24.9 40.4 41.5c29.8 31.2 65.8 70.3 92 103.3H402.2V174.6c0-18.9 15.3-34.1 34.1-34.1h37.1c8.1 0 14.6 6.6 14.6 14.6s-6.6 14.6-14.6 14.6H436.3c-2.7 0-4.9 2.2-4.9 4.9v49.3c0 1.8-.3 3.5-.9 5c0 .5-.1 .9-.2 1.4c-6.6 41.1-25.1 78.2-51.8 107.7c30.8 3.5 54.8 29.7 54.8 61.5c0 34.2-27.7 62-62 62s-62-27.7-62-62c0-4 .4-7.9 1.1-11.7c-26.6 12.2-56.2 19-87.5 19c-30.4 0-59.2-6.5-85.3-18.1c.6 3.5 .9 7.1 .9 10.7c0 34.2-27.7 62-62 62s-62-27.7-62-62c0-31.2 23-57 53-61.3c-26.9-29.5-45.4-66.7-52-107.9c-.1-.6-.2-1.2-.2-1.8C7.2 186.1 13.7 97.6 100.2 49.6c5.7-3.1 12.7-2.2 17.4 2.4zM262.7 242.6c-1.9 .4-3.9 .4-5.9 0H48.3c20.2 77.6 90.8 134.9 174.8 134.9s154.6-57.3 174.8-134.9H262.7zM153.4 130.3c-15.7-16.5-29.8-30.8-40-41.1c-3.2-3.2-6-6-8.3-8.4C48 117.9 39 177.9 42.9 213.4H228.8c-23.2-27.5-51.1-57.6-75.4-83zM76.8 432.2a32.7 32.7 0 1 0 0-65.4 32.7 32.7 0 1 0 0 65.4zm294.6 0a32.7 32.7 0 1 0 0-65.4 32.7 32.7 0 1 0 0 65.4z',
  ],
};

export default basPram;
