import { formatDate } from '@bas/shared/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import localForage from 'localforage';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  DefaultValues,
  FieldValues,
  Path,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import useDebounce from 'react-use/lib/useDebounce';
import { AnyObjectSchema } from 'yup';
import { usePersistForm } from './usePersistForm';

type FieldValuesWithGlobalFilter = FieldValues & {
  globalFilter?: string | undefined;
};

export type RequestFilters = {
  [key: string]: string | number | string[] | boolean;
};

type BasedUponFiltersType = {
  [key: string]:
    | string
    | number
    | Date
    | string[]
    | boolean
    | { [key: string]: string | ReactNode };
};

export type UseFilterFormResult<
  TFieldValues extends FieldValues = FieldValues
> = {
  form: UseFormReturn<TFieldValues>;
  searchGlobally: (value: string | undefined) => void;
  resetFilters: () => void;
  basedUponFilters: BasedUponFiltersType;
  requestFilters: RequestFilters;
  loadedData: boolean;
};

export type UseFilterFormProps<TFieldValues extends FieldValues = FieldValues> =
  {
    defaultValues?: TFieldValues;
    validationSchema?: AnyObjectSchema;
    onFilter?: () => void;
    dateFields?: string[];
    cacheKey?: string;
    onChange?: (values: TFieldValues) => void;
  };

const valueIsValidForBasedUponFilter = (value: unknown): boolean =>
  !!value &&
  ((Array.isArray(value) && value.length > 0) ||
    (typeof value === 'object' && !!value && Object.keys(value).length > 0) ||
    ['string', 'number', 'boolean'].includes(typeof value) ||
    value instanceof Date);

const convertToRequestFilterValue = (
  value:
    | string
    | number
    | Date
    | string[]
    | boolean
    | { [key: string]: string | ReactNode },
  isDateField: boolean
): string | number | string[] | boolean => {
  if (
    value instanceof Date ||
    dayjs.isDayjs(value) ||
    (isDateField && typeof value === 'string' && dayjs(value).isValid())
  ) {
    return formatDate(value);
  }

  if (typeof value === 'object') {
    return Object.keys(value);
  }

  return value;
};

export const useFilterForm = <
  TFieldValues extends FieldValuesWithGlobalFilter = FieldValuesWithGlobalFilter
>({
  defaultValues,
  validationSchema,
  onFilter,
  cacheKey,
  onChange,
  dateFields = [],
}: UseFilterFormProps<TFieldValues>): UseFilterFormResult<TFieldValues> => {
  const [loadedData, setLoadedData] = useState(false);
  let resolver;
  if (validationSchema) {
    resolver = yupResolver(validationSchema);
  }

  const storedValuesPromise = useMemo(async (): Promise<
    TFieldValues | undefined
  > => {
    if (cacheKey) {
      const localForageData = await localForage.getItem<TFieldValues>(cacheKey);
      if (localForageData && typeof localForageData === 'object') {
        return { ...defaultValues, ...localForageData };
      }
    }

    return defaultValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey]);

  const [basedUponFilters, setBasedUponFilters] =
    useState<BasedUponFiltersType>({});

  const [requestFilters, setRequestFilters] = useState<RequestFilters>({});

  const form = useForm<TFieldValues>({
    mode: 'all',
    defaultValues: defaultValues as DefaultValues<TFieldValues>,
    resolver,
    shouldFocusError: true,
  });

  const { watch } = form;

  usePersistForm<TFieldValues>({
    watch: form.watch,
    persist: cacheKey,
    disableLocalStorage: true,
    onChange,
  });

  useEffect(() => {
    if (!loadedData) {
      (async () => {
        const storedValues = await storedValuesPromise;
        form.reset(storedValues, {
          keepDefaultValues: false,
          keepValues: false,
          keepDirtyValues: false,
        });
        setLoadedData(true);
      })();
    }
  }, [loadedData, storedValuesPromise, defaultValues, form]);

  useEffect(() => {
    const subscription = watch((values) => {
      const newFilters: BasedUponFiltersType = {};
      Object.keys(values).forEach((key) => {
        const value = values[key];
        if (valueIsValidForBasedUponFilter(value)) {
          newFilters[key] = value;
        }
      });

      setBasedUponFilters(newFilters);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useDebounce(
    () => {
      const newFilters: RequestFilters = {};
      Object.keys(basedUponFilters).forEach((key) => {
        const value = basedUponFilters[key];
        if (value) {
          newFilters[key] = convertToRequestFilterValue(
            value,
            dateFields.includes(key)
          );
        }
      });

      setRequestFilters(newFilters);
      if (onFilter) {
        onFilter();
      }
    },
    150,
    [basedUponFilters, onFilter]
  );

  return {
    searchGlobally: (value) =>
      form.setValue(
        'globalFilter' as Path<TFieldValues>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value as any
      ),
    resetFilters: () => form.reset(defaultValues),
    basedUponFilters,
    requestFilters,
    form,
    loadedData,
  };
};
