import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basDrawerUnit: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'drawer-unit',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M374 9H121V9c-8.3 0-15 6.7-15 15V446c0 8.3 6.7 15 15 15h45.1l5.9 41h36l5.9-41h84.3l5.9 41h36l5.9-41H391c8.3 0 15-6.7 15-15V24c0-8.3-6.7-15-15-15H374V9zM136 180H376V291H136V180zm0 251V321H376V431H136zm0-281V39H376V150H136zm89-68.5c-8.3 0-15 6.7-15 15s6.7 15 15 15h62c8.3 0 15-6.7 15-15s-6.7-15-15-15H225zM225 221c-8.3 0-15 6.7-15 15s6.7 15 15 15h62c8.3 0 15-6.7 15-15s-6.7-15-15-15H225zM210 378c0 8.3 6.7 15 15 15h62c8.3 0 15-6.7 15-15s-6.7-15-15-15H225c-8.3 0-15 6.7-15 15z',
  ],
};

export default basDrawerUnit;
