import {
  BasedUponFilters,
  BasedUponFiltersType,
  OverlayFetchingIndicator,
} from '@bas/ui/web/atoms';
import { TableToolbar } from '@bas/ui/web/molecules';
import { FullPagination, FullPaginationProps } from '@bas/ui/web/organisms';
import { Box, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type DataTableTemplateProps = {
  className?: string;
  toolbarActions?: ReactNode | ReactNode[];
  rightSideActions?: ReactNode | ReactNode[];
  onSearch?: (newValue: string | undefined) => void;
  appliedFilters?: BasedUponFiltersType;
  filters?: ReactNode;
  children?: ReactNode;
  pagination: FullPaginationProps;
  isFetching?: boolean;
  allowOverflow?: boolean;
  allowScroll?: boolean;
  onResetFilters?: () => void;
};

const DataTableTemplate = ({
  className,
  toolbarActions,
  onSearch,
  filters,
  appliedFilters,
  children,
  pagination: { itemCount, ...pagination },
  onResetFilters,
  isFetching,
  allowOverflow,
  allowScroll,
  rightSideActions,
}: DataTableTemplateProps) => {
  const hasFiltersApplied =
    appliedFilters && Object.keys(appliedFilters).length > 0;

  return (
    <Box className={className}>
      <TableToolbar
        onSearch={onSearch}
        filters={filters}
        rightSideActions={rightSideActions}
      >
        {toolbarActions}
      </TableToolbar>
      <Box className="Bas-DataTableTemplate-ResultsText">
        <Typography variant="h6">
          {!hasFiltersApplied && (
            <FormattedMessage
              id="label.results"
              values={{ numberOfResults: itemCount }}
            />
          )}
          {appliedFilters && hasFiltersApplied && (
            <FormattedMessage
              id="label.resultsBasedUpon"
              values={{
                numberOfResults: itemCount,
                filters: (
                  <BasedUponFilters
                    onResetFilters={onResetFilters}
                    filters={appliedFilters}
                  />
                ),
              }}
            />
          )}
        </Typography>
      </Box>
      <Box
        className={clsx(
          'Bas-DataTableTemplate-Content',
          {
            'Bas-DataTableTemplate-Content-AllowOverflow': allowOverflow,
          },
          {
            'Bas-DataTableTemplate-Content-AllowScroll': allowScroll,
          }
        )}
      >
        {isFetching && <OverlayFetchingIndicator />}
        {children}
      </Box>
      <Box>
        <FullPagination {...pagination} itemCount={itemCount} />
      </Box>
    </Box>
  );
};

const StyledDataTableTemplate = styled(DataTableTemplate)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .Bas-DataTableTemplate-ResultsText {
    margin-top: ${theme.spacing(5)};
    margin-bottom: ${theme.spacing(3)};
  }


  .Bas-DataTableTemplate-Content {
    position: relative;
    flex: 1;
    display: flex;

    &:not(.Bas-DataTableTemplate-Content-AllowOverflow) {
      overflow: hidden;
    }

    &.Bas-DataTableTemplate-Content-AllowScroll {
      overflow: auto;
      margin-left: -${theme.spacing(3)};
      margin-right: -${theme.spacing(3)};
      padding-left: ${theme.spacing(3)};
      padding-right: ${theme.spacing(3)};
    }
  }
`
);
export default StyledDataTableTemplate;
