import { useEmployeesRequest } from '@bas/hrm-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import { colors } from '@bas/theme';
import {
  IdentityIndicator,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { EventType } from '@bas/value-objects';
import { Grid, MenuItem, Typography } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Chip, RenderPersonName, RectangularTile } from '@bas/ui/web/atoms';

const allEventTypes = Object.values(EventType).filter((type) => !!type);

const PlanningFilters = (): ReactElement => {
  const eventTypes = useWatch({ name: 'eventTypes' });

  const { setValue } = useFormContext();
  const { data: employeesData } = useEmployeesRequest({
    perPage: 9999,
    activeEmploymentOnly: true,
  });

  const handleToggleStatus = useCallback(
    (eventType: EventType) => {
      const newEventTypes = [...eventTypes];
      if (newEventTypes.includes(eventType)) {
        newEventTypes.splice(newEventTypes.indexOf(eventType), 1);
      } else {
        newEventTypes.push(eventType);
      }

      setValue('eventTypes', newEventTypes);
    },
    [eventTypes, setValue]
  );
  const handleToggleAll = useCallback(() => {
    if (eventTypes.length === 0) {
      setValue('eventTypes', allEventTypes);
    } else {
      setValue('eventTypes', []);
    }
  }, [eventTypes, setValue]);
  const tenantState = useTenantStore((state) => state.tenant);
  const useIdentities = tenantState?.useIdentities;
  const { data: identitiesData, isInitialLoading: isLoadingIdentities } =
    useIdentitiesRequest({}, { enabled: useIdentities || false });

  return (
    <Grid container rowSpacing={2} columnSpacing={3} alignContent="flex-start">
      <Grid
        item
        xs={12}
        container
        rowSpacing={2}
        columnSpacing={3}
        alignContent="flex-start"
      >
        <Grid item xs={6}>
          <Controller
            name="employee"
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.employee" />}
                select
              >
                <MenuItem value="">
                  <FormattedMessage id="label.none" />
                </MenuItem>
                {employeesData?.data?.['hydra:member'].map((employee) => (
                  <MenuItem
                    key={employee.employeeId}
                    value={employee.employeeId}
                  >
                    <RenderPersonName {...employee.personName} />
                  </MenuItem>
                ))}
              </ReactHookFormTextField>
            )}
          />
        </Grid>
        {useIdentities && (
          <Grid item xs={6}>
            <Controller
              name="identityId"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  autoComplete="off"
                  fullWidth
                  label={<FormattedMessage id="label.identity" />}
                  loading={isLoadingIdentities}
                  select
                >
                  <MenuItem value="">
                    <FormattedMessage id="label.none" />
                  </MenuItem>
                  {(identitiesData?.data['hydra:member'] || []).map(
                    (identity) => (
                      <MenuItem
                        key={identity.identityId}
                        value={identity.identityId}
                      >
                        <IdentityIndicator
                          identityId={identity.identityId}
                          full
                        />
                      </MenuItem>
                    )
                  )}
                </ReactHookFormTextField>
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            <FormattedMessage id="label.eventType" />
          </Typography>
        </Grid>
        <Grid item maxWidth="350px">
          <RectangularTile
            sx={{
              height: '48px',
              background: colors.blue[500],
              padding: (theme) => theme.spacing(1, 1),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: (theme) => theme.palette.common.white,
              cursor: 'pointer',
            }}
            onClick={handleToggleAll}
          >
            <Typography fontSize="18px" lineHeight="23px" fontWeight="bold">
              <FormattedMessage
                id={
                  eventTypes.length === allEventTypes.length
                    ? 'button.deselectAll'
                    : 'button.selectAll'
                }
              />
            </Typography>
          </RectangularTile>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {Object.values(EventType)
          .filter((type) => !!type)
          .map((eventType) => (
            <Grid item key={eventType} xs={3}>
              <Chip
                disableMinWidth
                color={eventTypes.includes(eventType) ? 'primary' : 'secondary'}
                sx={{ width: '100%' }}
                onClick={() => handleToggleStatus(eventType)}
                label={
                  <FormattedMessage
                    id={`planning.events.eventType.${eventType}`}
                  />
                }
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default PlanningFilters;
