import { Icon } from '@bas/ui/web/base';
import { faShieldExclamation } from '@fortawesome/pro-light-svg-icons';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

const NoPermissionsPage = ({ className, ...props }: BoxProps): ReactElement => (
  <Box className={className} {...props}>
    <Box className="Bas-NoPermissionsPage-Content">
      <Box className="Bas-NoPermissionsPage-Content-Icon">
        <Icon icon={faShieldExclamation} />
      </Box>
      <Box className="Bas-NoPermissionsPage-TitleHolder">
        <Typography variant="h3">
          <FormattedMessage id="noPermissionsPage.title" />
        </Typography>
      </Box>
      <Box className="Bas-NoPermissionsPage-DescriptionHolder">
        <Box className="Bas-NoPermissionsPage-Content-Text">
          <FormattedMessage id="noPermissionsPage.text" />
        </Box>
      </Box>
    </Box>
  </Box>
);

const StyledNoPermissionsPage = styled(NoPermissionsPage)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20%;

  .Bas-NoPermissionsPage-Content {
    max-width: 400px;
    text-align: center;

    .Bas-NoPermissionsPage-Content-Icon {
      font-size: 104px;
      text-align: center;
      color: ${({ theme }) => theme.palette.error.main};
    }

    .Bas-NoPermissionsPage-TitleHolder {
      text-align: center;
      margin-bottom: ${({ theme }) => theme.spacing(4)};
      white-space: nowrap;
    }

    .Bas-NoPermissionsPage-DescriptionHolder {
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    ${({ theme }) => theme.breakpoints.only('xs')} {
      padding: 0 20px;
    }
  }
`;

export default StyledNoPermissionsPage;
