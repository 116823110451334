import { colors, fontSizesWeb } from '@bas/theme';
import { Box, BoxProps, Skeleton, styled, Typography } from '@mui/material';

export type EventCounterProps = BoxProps & {
  loading?: boolean;
  value?: number;
};

const EventCounter = ({
  loading,
  value,
  className,
  ...args
}: EventCounterProps) =>
  loading ? (
    <Skeleton variant="rectangular" className={className} />
  ) : (
    <Box className={className} {...args}>
      <Typography className="Bas-EventCounter-Value">{value}</Typography>
    </Box>
  );

EventCounter.defaultProps = {
  loading: false,
  value: undefined,
};

const StyledEventCounter = styled(EventCounter)(
  ({ theme }) => `
    background: ${colors.blue[200]};
    border-radius: 5px;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${theme.spacing(1)};

    & > .MuiTypography-root.Bas-EventCounter-Value {
        color: ${colors.lila[100]};
        font-size: ${fontSizesWeb.sm};
        font-weight: bold;
        line-height: 18px;
    }
`
);
export default StyledEventCounter;
