import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basMannequin: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'mannequin',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M238.025 36.332a4.999 4.999 0 014.713-3.33h26.5a5.001 5.001 0 014.713 3.33l4.197 11.852a35.003 35.003 0 0024.07 22.158l44.264 11.669a5 5 0 013.297 6.858l-45.913 103.757a34.998 34.998 0 002.494 32.98l35.91 56.322a5 5 0 01.784 2.688v57.394a5 5 0 01-5 5H173.92a5 5 0 01-5-5v-57.394c0-.953.272-1.886.784-2.688l35.91-56.323a34.998 34.998 0 002.494-32.98L162.195 88.87a5 5 0 013.297-6.858l44.264-11.669a35 35 0 0024.07-22.158l4.199-11.852zm4.713-33.331a35.002 35.002 0 00-32.993 23.315l-4.197 11.85a5.001 5.001 0 01-3.44 3.166l-44.262 11.67c-20.828 5.49-31.802 28.31-23.085 48.008l45.914 103.755c.677 1.531.544 3.3-.356 4.712l-35.91 56.321a35.003 35.003 0 00-5.488 18.818v57.394c0 19.33 15.67 35 35 35h67.076v39.013c-14.118.201-32.14 3.373-48.682 12.406-20.914 11.419-38.942 32.023-43.204 65.417-1.05 8.217 4.762 15.73 12.98 16.78 8.217 1.049 15.73-4.764 16.779-12.981 2.937-23.007 14.576-35.653 27.822-42.885 11.394-6.221 24.31-8.535 34.305-8.734v50.487c0 8.286 6.716 15.002 15 15.002 8.286 0 15-6.716 15-15.002v-50.496c10.062.132 23.215 2.42 34.796 8.743 13.244 7.232 24.885 19.878 27.822 42.885 1.049 8.217 8.561 14.03 16.779 12.98 8.217-1.05 14.03-8.562 12.98-16.78-4.264-33.393-22.291-53.997-43.205-65.416-16.733-9.137-34.981-12.277-49.171-12.411V377.01h67.055c19.33 0 35.002-15.67 35.002-35v-57.394a35.004 35.004 0 00-5.489-18.818l-35.909-56.32a5.003 5.003 0 01-.357-4.713l45.915-103.755C385.93 81.313 374.957 58.492 354.13 53l-44.264-11.669a5 5 0 01-3.438-3.165l-4.198-11.851A35 35 0 00269.238 3h-26.5z',
  ],
};

export default basMannequin;
