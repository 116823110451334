import { colors } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import { StorageStatus } from '@bas/wms-domain/models';
import { faThumbsUp, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type StorageStatusWithIconProps = {
  storageStatus: StorageStatus;
};

const StorageStatusWithIcon = ({
  storageStatus,
}: StorageStatusWithIconProps): ReactElement => (
  <Box>
    <Box
      component="span"
      sx={{
        color: (theme) =>
          storageStatus === StorageStatus.FREE
            ? theme.palette.success.main
            : colors.red[400],
      }}
    >
      <Icon
        icon={storageStatus === StorageStatus.FREE ? faThumbsUp : faTimesCircle}
      />
    </Box>
    &nbsp;
    <FormattedMessage id={`label.${storageStatus}`} />
  </Box>
);

export default StorageStatusWithIcon;
