import { isTimeEntry, TimeEntry } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeEntryByTimeEntryIdRequestQueryProps = {
  timeEntryId: Uuid;
};

export const TimeEntryByTimeEntryIdRequestQuery = async ({
  timeEntryId,
  ...params
}: TimeEntryByTimeEntryIdRequestQueryProps): Promise<
  AxiosResponse<TimeEntry>
> =>
  axios.get(`api/{tenantId}/hrm/time-entries/${timeEntryId}`, {
    params,
  });

export const useTimeEntryByTimeEntryIdRequestQuery = (
  request: TimeEntryByTimeEntryIdRequestQueryProps,
  options: QueryOptionsWithKey<
    AxiosResponse<TimeEntry>,
    AxiosError,
    AxiosResponse<TimeEntry>
  > = {}
): UseQueryResult<AxiosResponse<TimeEntry>, AxiosError> =>
  useQuery<AxiosResponse<TimeEntry>, AxiosError, AxiosResponse<TimeEntry>>({
    ...options,
    queryFn: async () => TimeEntryByTimeEntryIdRequestQuery(request),
    queryKey: ['time-entries', 'detail', request.timeEntryId],
  });

export const TimeEntryByTimeEntryIdRequestQueryInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTimeEntry(data)) {
    queryClient.invalidateQueries({
      queryKey: ['time-entries', 'detail', data.timeEntryId],
    });
  }
};
