import { colors } from '@bas/theme';
import { Tooltip, TooltipProps } from '@bas/ui/web/atoms';
import { Box, Grid, styled, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { StackedLinearProgress } from '../StackedLinearProgress';

export type AvailabilityPerCategoryTooltipProps = Omit<
  TooltipProps,
  'title'
> & {
  categories: {
    label: ReactNode | string;
    totalLabel?: string;
    availableLabel?: string;
    availablePercentage: number;
    reservedPercentage: number;
    reservedLabel?: string;
    confirmedPercentage: number;
    confirmedLabel?: string;
  }[];
};

const AvailabilityPerCategoryTooltip = ({
  categories,
  ...args
}: AvailabilityPerCategoryTooltipProps): ReactElement => (
  <Tooltip
    arrow
    title={
      <Grid
        className="Bas-AvailabilityPerCategoryTooltip-Content"
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
        pt={4}
      >
        {categories.map(
          (
            {
              label,
              availablePercentage,
              reservedPercentage,
              confirmedPercentage,
            },
            index
          ) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={12} key={index}>
              <Typography variant="subtitle1">{label}</Typography>
              <Box>
                <StackedLinearProgress
                  values={[
                    {
                      value: reservedPercentage,
                      color: colors.yellow[700],
                    },
                    {
                      value: confirmedPercentage,
                      color: colors.blue[500],
                    },
                    {
                      value: 100 - availablePercentage,
                      color: colors.red[200],
                    },
                  ].filter(({ value }) => value > 0)}
                />
              </Box>
            </Grid>
          )
        )}
      </Grid>
    }
    {...args}
    light
  />
);

const StyledAvailabilityPerCategoryTooltip = styled(
  AvailabilityPerCategoryTooltip
)(
  ({ theme }) => `
  box-sizing: border-box;
  height: 200px;
  width: 228px;
  border-radius: 3px;
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  .MuiTooltip-arrow {
    margin-top: -14px !important;
    height: 14px;
    width: 29px;
  }
`
);
export default StyledAvailabilityPerCategoryTooltip;
