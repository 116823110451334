import { Box, BoxProps, styled } from '@mui/material';
import { ReactElement } from 'react';

export type TwoLinesBoxProps = BoxProps;

const TwoLinesBox = ({ ...args }: TwoLinesBoxProps): ReactElement => (
  <Box {...args} />
);

const StyledTwoLinesBox = styled(TwoLinesBox)(
  () => `
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`
);
export default StyledTwoLinesBox;
