import { colors, fontSizesWeb } from '@bas/theme';
import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type AccordionSummaryProps = MuiAccordionSummaryProps;

const AccordionSummary = ({
  className,
  children,
  ...props
}: AccordionSummaryProps) => (
  <MuiAccordionSummary className={className} {...props}>
    {children}
  </MuiAccordionSummary>
);

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => `
  padding: ${theme.spacing(0)};
  min-height: 64px;
  .MuiAccordionSummary-expandIconWrapper {
    margin-left: ${theme.spacing(2)};
    color: ${colors.lila[800]};
    font-size: ${fontSizesWeb.base};
  }
  `
);
export default StyledAccordionSummary;
