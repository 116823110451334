import { isQuoteEvent } from './QuoteEvent';
import { QuoteEventType, SendingQuoteFailedEvent } from '../types';

export function isSendingQuoteFailedEvent(
  object: unknown
): object is SendingQuoteFailedEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.SendingQuoteFailedEvent
  );
}
