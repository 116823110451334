import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basMirror: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'mirror',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M263.752 179.54c5.778 5.937 5.647 15.435-.29 21.211l-37.002 36.002c-5.936 5.776-15.434 5.647-21.21-.29-5.779-5.939-5.648-15.435.29-21.213l37-36c5.939-5.778 15.435-5.647 21.212.29zm69.755 25.17c5.913-5.8 6.005-15.298.206-21.213-5.801-5.915-15.299-6.007-21.214-.206L208.497 285.294c-5.915 5.801-6.007 15.297-.206 21.212 5.8 5.915 15.298 6.007 21.213.206L333.507 204.71zm45.425 256.798h45.073c8.284 0 15-6.716 15-15V195c0-99.395-77.569-181.004-174.503-181.004-96.936 0-174.505 81.609-174.505 181.004v251.508c0 8.284 6.716 15 15 15h45.073l5.929 41.5H192l5.928-41.5h133.147l5.929 41.5h36l5.928-41.5zM409.005 195v236.507H119.998V195c0-83.967 65.255-151.003 144.504-151.003 79.247 0 144.503 67.036 144.503 151.003z',
  ],
};

export default basMirror;
