import {
  isTimeOffRegistration,
  TimeOffRegistration,
} from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type TimeOffRegistrationsRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<TimeOffRegistration>>;

export const TimeOffRegistrationsRequest = async ({
  startBefore,
  startAfter,
  endBefore,
  endAfter,
  ...params
}: TimeOffRegistrationsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/time-off-registrations`, {
    params: {
      'start[before]': startBefore ? formatDate(startBefore) : undefined,
      'start[after]': startAfter ? formatDate(startAfter) : undefined,
      'end[before]': endBefore ? formatDate(endBefore) : undefined,
      'end[after]': endAfter ? formatDate(endAfter) : undefined,
      ...params,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useTimeOffRegistrationsRequest = (
  { ...request }: TimeOffRegistrationsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TimeOffRegistrationsRequest({ ...request }),
    queryKey: ['time-off-registrations', 'list', request],
  });

export const TimeOffRegistrationsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTimeOffRegistration(data)) {
    queryClient.invalidateQueries({
      queryKey: ['time-off-registrations', 'list'],
    });
  }
};
