import { isObjectResponse } from '@bas/value-objects';
import type { TimeType } from '../types';

export function isTimeType(object: unknown): object is TimeType {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'TimeType'
  );
}
