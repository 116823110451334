import { ReactElement, useState } from 'react';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Document, Page, pdfjs } from 'react-pdf';
import { Grid, Skeleton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export type DocumentTemplateTranslationPreviewContentSectionProps = {
  onEdit: () => void;
  pdfUrl?: string;
  isError?: boolean;
};

const DocumentTemplateTranslationPreviewContentSection = ({
  onEdit,
  pdfUrl,
  isError,
}: DocumentTemplateTranslationPreviewContentSectionProps): ReactElement => {
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.preview" />
          </Typography>
        </Grid>
        {!isError && pdfUrl && (
          <Grid item xs={12}>
            <AutoSizer disableHeight>
              {({ width }) => (
                <Document
                  file={pdfUrl}
                  loading={<Skeleton width="100%" height="993" />}
                  onLoadSuccess={(pdf) => setNumberOfPages(pdf.numPages)}
                >
                  <Page
                    pageNumber={1}
                    width={width}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                  {numberOfPages >= 2 && (
                    <Page
                      pageNumber={2}
                      width={width}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  )}
                  {numberOfPages > 3 && (
                    <Page
                      pageNumber={numberOfPages}
                      width={width}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  )}
                </Document>
              )}
            </AutoSizer>
          </Grid>
        )}
        <Grid item xs={12} hidden={!isError}>
          <Typography>
            <FormattedMessage id="label.couldNotLoadPreviewCheckTemplate" />
          </Typography>
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default DocumentTemplateTranslationPreviewContentSection;
