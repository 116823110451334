import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basLoungeTable: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'lounge-table',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M24 91c-8.3 0-15 6.7-15 15V207c0 8.3 6.7 15 15 15H71.1L77 406h36l3.1-97H395.9l3.1 97h36l5.9-184H488c8.3 0 15-6.7 15-15V106c0-8.3-6.7-15-15-15H24zM393.1 222l1.8 57H117.1l1.8-57H393.1zM39 192V121H473v71H39z',
  ],
};

export default basLoungeTable;
