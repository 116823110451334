import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { isReusableMaterial, ReusableMaterial } from '@bas/wms-domain/models';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type ReusableMaterialsRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<ReusableMaterial>>;

export const ReusableMaterialsRequest = async ({
  ...params
}: ReusableMaterialsRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/inventory/reusable-materials', {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useReusableMaterialsRequest = (
  request: ReusableMaterialsRequestProps = {
    page: 1,
    perPage: 999999,
  },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ReusableMaterialsRequest(request),
    queryKey: ['reusable-materials', 'list', ...Object.values(request)],
  });

export const usePrefetchReusableMaterialsRequest = (
  request: ReusableMaterialsRequestProps
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['reusable-materials', 'list', ...Object.values(request)],
      queryFn: async () => ReusableMaterialsRequest(request),
    });
  }, [queryClient, request]);
};

export const ReusableMaterialsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isReusableMaterial(data)) {
    queryClient.invalidateQueries({ queryKey: ['reusable-materials', 'list'] });
  }
};
