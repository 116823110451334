import { isTimeEntry, TimeEntry } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeEntriesRequestProps = Pagination & Filterable;

export const TimeEntriesRequest = async ({
  ...params
}: TimeEntriesRequestProps): Promise<AxiosResponse<Collection<TimeEntry>>> =>
  axios.get(`api/{tenantId}/hrm/time-entries`, {
    params: { ...params },
  });

export const useTimeEntriesRequest = (
  request: TimeEntriesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<TimeEntry>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeEntry>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<TimeEntry>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<TimeEntry>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeEntry>>
  >({
    ...options,
    queryFn: async () => TimeEntriesRequest(request),
    queryKey: ['time-entries', 'list', request],
  });

export const TimeEntriesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTimeEntry(data)) {
    queryClient.invalidateQueries({ queryKey: ['time-entries', 'list'] });
  }
};
