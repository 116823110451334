import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basGardenArmchair: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'garden-armchair',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M68.205 42.785c7.849-2.65 16.36 1.566 19.01 9.416l43.052 127.562a15.01 15.01 0 014.736-.763h232.005c8.284 0 15 6.716 15 15 0 8.285-6.716 15.001-15 15.001H140.134l15.188 45h229.687c24.138.002 42.005 12.77 53.298 28.215 11.042 15.102 16.704 33.828 16.704 49.314 0 1.416-.196 2.787-.564 4.086.043.457.064.92.064 1.389 0 8.284-6.716 15-15 15h-41.963l37.475 98.152c2.956 7.739-.923 16.41-8.663 19.364-7.739 2.955-16.41-.923-19.364-8.663l-41.562-108.853H179.579l-41.563 108.853c-2.954 7.74-11.625 11.618-19.364 8.663-7.74-2.955-11.618-11.625-8.663-19.364l42-110.003c.129-.334.267-.66.415-.98L58.79 61.793c-2.65-7.85 1.566-16.36 9.416-19.01zm110.068 279.22l-12.825-38.002h219.56c12.364 0 21.997 6.232 29.081 15.92 4.993 6.829 8.25 14.821 9.82 22.081H178.273z',
  ],
};

export default basGardenArmchair;
