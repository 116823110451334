import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basCrib: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'crib',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M24 36c8.3 0 15 6.7 15 15v53H473V51c0-8.3 6.7-15 15-15s15 6.7 15 15V470c0 8.3-6.7 15-15 15s-15-6.7-15-15V423L39 423v47c0 8.3-6.7 15-15 15s-15-6.7-15-15V51c0-8.3 6.7-15 15-15zm171 98H132V294h63V134zm30 0V294h63V134H225zM381 294V134H318V294h63zm30 0h62V134H411V294zM39 134V294h63V134H39zm0 259l434 0V324L39 324v69z',
  ],
};

export default basCrib;
