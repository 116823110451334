import {
  HoursEntry,
  isHoursEntry,
  isProjectHoursEntry,
} from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type HourEntriesByProjectIdRequestProps = Pagination & {
  projectId: Uuid;
};

type Response = AxiosResponse<Collection<HoursEntry>>;

export const HourEntriesByProjectIdRequest = async ({
  projectId,
  ...params
}: HourEntriesByProjectIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/projects/${projectId}/hours-entries`, {
    params: { ...params },
  });

export const useHourEntriesByProjectIdRequest = (
  { projectId, ...request }: HourEntriesByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      HourEntriesByProjectIdRequest({ ...request, projectId }),
    queryKey: ['hour-entries', 'list', projectId, ...Object.values(request)],
  });

export const usePrefetchHourEntriesByProjectIdRequest = ({
  projectId,
  ...request
}: HourEntriesByProjectIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'hour-entries',
        'list',
        projectId,
        ...Object.values({ projectId, ...request }),
      ],
      queryFn: async () =>
        HourEntriesByProjectIdRequest({ projectId, ...request }),
    });
  }, [projectId, queryClient, request]);
};

export const HourEntriesByProjectIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isHoursEntry(data) && isProjectHoursEntry(data)) {
    queryClient.invalidateQueries({
      queryKey: ['hour-entries', 'list', data.projectId],
    });
  }
};
