export const formatHours = (
  hours?: number | string,
  dontShowEmptyMinutes = false,
  hoursPad = 0
): string => {
  if (!hours || hours === 0) {
    if (dontShowEmptyMinutes) {
      return '0';
    }

    return '0:00';
  }

  let isNegative = false;
  let hoursToUse = Number(hours);
  if (hoursToUse < 0) {
    hoursToUse *= -1;
    isNegative = true;
  }

  const rhours = Math.floor(+hoursToUse);
  const minutes = +(+hoursToUse - +rhours) * 60;
  const rminutes = Math.round(minutes).toString();

  if (dontShowEmptyMinutes && Math.round(minutes) === 0) {
    return `${isNegative ? '-' : ''}${rhours.toString()}`;
  }

  return `${isNegative ? '-' : ''}${rhours
    .toString()
    .padStart(hoursPad, '0')}:${rminutes.padStart(2, '0')}`;
};

export const parseHoursToFloat = (time: string): number => {
  const hoursMinutes = time.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

  if (minutes.toString().length > 2) {
    minutes = parseInt(minutes.toString().slice(0, 2), 10);
  }

  if (minutes >= 60) {
    minutes = 59;
  }

  if (Number.isNaN(minutes)) {
    return hours;
  }

  return Math.round((hours + minutes / 60) * 100) / 100;
};
