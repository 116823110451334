import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basWheelbarrow: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'wheelbarrow',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M38 39c-8.3 0-15 6.7-15 15s6.7 15 15 15H60l24 90.6V324.5c0 .2 0 .3 0 .5c0 .2 0 .3 0 .5v127c0 6.1 3.7 11.5 9.3 13.9s12.1 1 16.3-3.3L233.2 339.5h48.5c25.7 0 50.1-3.8 72.2-11.6l24.8 36.4c-11 11.5-17.7 27.1-17.7 44.2c0 35.3 28.7 64 64 64s64-28.7 64-64s-28.7-64-64-64c-7.4 0-14.5 1.2-21.1 3.5l-22.3-32.9c10.3-6 20-13 28.7-21.3c33.1-31.2 51.7-77.5 51.7-137.4c0-8.3-6.7-15-15-15H110.2L86 50.2C84.3 43.6 78.3 39 71.5 39H38zm76 132.5H431.6c-2.8 45.6-18.5 78.5-41.9 100.6c-26 24.6-63.3 37.4-108 37.4H114v-138zm0 169h75.8L114 416.3V340.5zm345 68a34 34 0 1 1 -68 0 34 34 0 1 1 68 0z',
  ],
};

export default basWheelbarrow;
