import { ReactHookFormEmployeeSelect } from '@bas/hrm-domain/web/molecules';
import {
  ConnectExternalEmployeeInputType,
  ConnectExternalEmployeeInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { ExternalEmployee } from '@bas/integration-domain/models';
import { Button } from '@bas/ui/web/atoms';
import { DialogProps } from '@bas/ui/web/molecules';
import { FormDialog } from '@bas/ui/web/organisms';
import { Grid, styled } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export type ConnectExternalEmployeesToExistingEmployeesFormDialogProps = Omit<
  DialogProps,
  'onSubmit'
> & {
  open: boolean;
  externalEmployees: ExternalEmployee[];
  onSubmit: SubmitHandler<{
    externalEmployees: ConnectExternalEmployeeInputType[];
  }>;
};

const FormContent = ({
  externalEmployees,
}: {
  externalEmployees: ExternalEmployee[];
}): ReactElement => (
  <Grid container alignItems="stretch" spacing={4}>
    <Grid item xs={12}>
      <FormattedMessage id="label.connectExternalEmployeesToExistingEmployeesDescription" />
    </Grid>
    <Grid item xs={12} container>
      {externalEmployees.map(({ id, personName: { fullName } }, index) => (
        <Grid
          item
          xs={12}
          key={id}
          container
          spacing={2}
          alignItems="flex-end"
          pb={2}
        >
          <Grid item xs={6}>
            {fullName}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={`externalEmployees.${index}.employeeId`}
              render={(registered) => (
                <ReactHookFormEmployeeSelect {...registered} />
              )}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

const ConnectExternalEmployeesToExistingEmployeesFormDialog = ({
  onClose,
  externalEmployees,
  ...args
}: ConnectExternalEmployeesToExistingEmployeesFormDialogProps): ReactElement | null => {
  if (!externalEmployees.length) {
    return null;
  }

  return (
    <FormDialog<{
      externalEmployees: ConnectExternalEmployeeInputType[];
    }>
      name="connect-external-employees-to-existing-employees"
      onClose={onClose}
      title={
        <FormattedMessage id="label.connectExternalEmployeesToExistingEmployees" />
      }
      validationSchema={Yup.object({
        externalEmployees: Yup.array().of(
          ConnectExternalEmployeeInputTypeValidationBuilder()
        ),
      })}
      dialogActions={[
        <Button type="submit" key="save">
          <FormattedMessage id="button.connect" />
        </Button>,
      ]}
      defaultValues={{
        externalEmployees: externalEmployees.map((externalEmployee) => ({
          ...externalEmployee,
          employeeId: '',
        })),
      }}
      maxWidth="md"
      fullWidth
      useProvider
      {...args}
    >
      <FormContent externalEmployees={externalEmployees} />
    </FormDialog>
  );
};

const StyledConnectExternalEmployeesToExistingEmployeesFormDialog = styled(
  ConnectExternalEmployeesToExistingEmployeesFormDialog
)``;

export default StyledConnectExternalEmployeesToExistingEmployeesFormDialog;
