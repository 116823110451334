import {
  EstimationSettingsRequestInvalidator,
  DamageByDamageIdRequestInvalidator,
  DamagesRequestInvalidator,
  ListedProjectsRequestInvalidator,
  PackagesRequestInvalidator,
  ProjectByProjectIdRequestInvalidator,
  ProjectEstimatesRequestInvalidator,
  ProjectsByProjectIdsRequestInvalidator,
  ProjectStatisticsRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  EstimationSettingsRequestInvalidator,
  DamageByDamageIdRequestInvalidator,
  DamagesRequestInvalidator,
  ListedProjectsRequestInvalidator,
  PackagesRequestInvalidator,
  ProjectByProjectIdRequestInvalidator,
  ProjectEstimatesRequestInvalidator,
  ProjectsByProjectIdsRequestInvalidator,
  ProjectStatisticsRequestInvalidator,
];
