import {
  AutomatedEmailByAutomatedEmailIdRequestInvalidator,
  AutomatedEmailsRequestInvalidator,
  ConversationByConversationIdRequestInvalidator,
  ConversationsRequestInvalidator,
  EmailByEmailIdRequestInvalidator,
  ListedEmailsRequestInvalidator,
  MessagesByConversationIdRequestInvalidator,
  UnreadEmailsByFolderRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  AutomatedEmailByAutomatedEmailIdRequestInvalidator,
  AutomatedEmailsRequestInvalidator,
  ConversationByConversationIdRequestInvalidator,
  ConversationsRequestInvalidator,
  EmailByEmailIdRequestInvalidator,
  ListedEmailsRequestInvalidator,
  MessagesByConversationIdRequestInvalidator,
  UnreadEmailsByFolderRequestInvalidator,
];
