import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basTeaCupboard: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'tea-cupboard',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M159 32.9c0-2.2 1.8-3.9 4-3.9H301.1c2.2 0 4 1.7 4 3.9c0 45.8-24.9 84.1-58 93.1H487c8.3 0 15 6.7 15 15s-6.7 15-15 15H443V373.2c23.7 6.7 41 28.5 41 54.3c0 31.2-25.3 56.5-56.5 56.5s-56.5-25.3-56.5-56.5c0-26.2 17.8-48.2 42-54.6V350.5H123v22.7c23.7 6.7 41 28.5 41 54.3c0 31.2-25.3 56.5-56.5 56.5S51 458.7 51 427.5c0-26.2 17.8-48.2 42-54.6L93 156H26c-8.3 0-15-6.7-15-15s6.7-15 15-15H217c-14.7-4-27.8-13.8-37.8-27.4c-14.1 5.5-27.3 4.8-37.4-.9c-11.4-6.6-17.7-19.6-13.4-32.8c2.2-6.7 6-11.9 11-15.4c5-3.4 10.5-4.7 15.4-4.8c1.6 0 3.1 0 4.6 .2c-.4-3.9-.6-7.9-.6-11.9zm38.8 53.9c10 13 22.3 19.3 34.2 19.3s24.2-6.2 34.2-19.3c7.1-9.3 12.6-21.6 15.2-35.8H182.6c2.6 14.2 8.1 26.5 15.2 35.8zM163.7 66.4c-.5-.3-1.1-.5-1.8-.8c-2-.7-4.3-1.1-6.3-1.1c-2 .1-3.6 .6-4.7 1.3c-1 .7-2.3 2.1-3.3 5.1c-1 3.2 .1 6.9 4.3 9.3c3.3 1.9 9.2 3.2 17.2 .7c-2.1-4.6-3.9-9.5-5.3-14.6zM413 156H123v77H413V156zM123 263v57.5H413V263H123zM107.5 401a26.5 26.5 0 1 0 0 53 26.5 26.5 0 1 0 0-53zM401 427.5a26.5 26.5 0 1 0 53 0 26.5 26.5 0 1 0 -53 0z',
  ],
};

export default basTeaCupboard;
