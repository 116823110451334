import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basKitchenTrolley: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'kitchen-trolley',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M116.3 104.4c-8.1 0-14.6 6.6-14.6 14.6s6.6 14.6 14.6 14.6H384.2c8.1 0 14.6-6.6 14.6-14.6s-6.6-14.6-14.6-14.6H116.3zM9 170.7c0-8.1 6.6-14.6 14.6-14.6H476.4c8.1 0 14.6 6.6 14.6 14.6V242c0 8.1-6.6 14.6-14.6 14.6H421.7V379.7c23.1 6.6 40 27.8 40 53c0 30.4-24.7 55.1-55.1 55.1s-55.1-24.7-55.1-55.1c0-25.6 17.4-47 41-53.3V357.6H109.5v22.1c23.1 6.6 40 27.8 40 53c0 30.4-24.7 55.1-55.1 55.1s-55.1-24.7-55.1-55.1c0-25.6 17.4-47 41-53.3l0-122.8H23.7C15.6 256.6 9 250 9 242V170.7zm383.4 85.9H109.5l0 71.7H392.4V256.6zM38.3 185.4v42H461.7v-42H38.3zM94.4 406.8a25.9 25.9 0 1 0 0 51.7 25.9 25.9 0 1 0 0-51.7zm312.2 0a25.9 25.9 0 1 0 0 51.7 25.9 25.9 0 1 0 0-51.7z',
  ],
};

export default basKitchenTrolley;
