import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basTummyBox: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'tummybox',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M234 219.7c-5.5 0-10 4.5-10 10s4.5 10 10 10h45c5.5 0 10-4.5 10-10s-4.5-10-10-10H234zM224 332.9c0-5.5 4.5-10 10-10h45c5.5 0 10 4.5 10 10s-4.5 10-10 10H234c-5.5 0-10-4.5-10-10zm0-213.4c0-5.5 4.5-10 10-10h45c5.5 0 10 4.5 10 10s-4.5 10-10 10H234c-5.5 0-10-4.5-10-10zM7 69c0-8.3 6.7-15 15-15H490c8.3 0 15 6.7 15 15s-6.7 15-15 15H452.7c-5.1 17.7-7.9 37.9 6.9 54.5c15.5 17.5 19.4 36.8 14.4 55.3c-4.7 17.4-16.6 32.7-29.5 45c-8.9 8.5-9.8 16.9-7.6 25.8c2.5 10.2 9 20.3 15 29.5l0 .1c1 1.5 2 3 2.9 4.4c1.9 2.8 4.6 6.2 7.8 10.3l.8 1c7.9 10.1 19.7 25 24.1 40.2c2.5 8.4 3.2 18.4-1.1 28.2c-4.3 9.8-12.7 17.1-23.6 22.5c-3.5 1.7-7.6 2.4-10 2.7c-3 .4-6.2 .6-9.4 .7c-6.2 .2-13 .2-17.9 .1l-.1 0c-1.8 0-3.2 0-4.3 0H398.8l-4 53.6H371l-4-53.6H144.8l-4 53.6H117l-4-53.6H91.5c-1.1 0-2.6 0-4.4 0l-.1 0c-4.8 .1-11.6 .1-17.9-.1c-3.1-.1-6.4-.3-9.4-.7c-2.5-.3-6.5-1-10-2.7c-10.9-5.4-19.3-12.7-23.6-22.5c-4.3-9.8-3.6-19.8-1.1-28.2c4.5-15.2 16.2-30.1 24.1-40.2c.3-.4 .5-.7 .8-1c3.3-4.2 5.9-7.5 7.8-10.3c.9-1.4 1.9-2.9 2.9-4.4l0 0 0-.1c6.1-9.2 12.5-19.2 15-29.5c2.2-8.9 1.3-17.3-7.6-25.8c-12.9-12.3-24.9-27.6-29.5-45c-5-18.6-1.1-37.9 14.4-55.3c14.8-16.6 12-36.8 6.9-54.5H22C13.7 84 7 77.3 7 69zm98.2 201.4l302.2 0c-3.8-17.1-1.1-36.5 16.4-53.2c10.9-10.4 18.3-20.8 21.1-30.3H67.7c2.8 9.5 10.1 19.9 21.1 30.3c17.5 16.7 20.2 36.1 16.4 53.2zm-9.4 24c-3.4 6.2-6.9 11.6-9.6 15.6l-.4 .6c-1.1 1.6-2 3-2.8 4.3l-.1 .2-.1 .2c-2.6 3.8-5.8 7.9-8.9 11.9l-.2 .3c-9.1 11.5-17.1 22.1-19.8 31.2c-1.2 4-.8 6.2-.2 7.6c.6 1.4 2.4 4.2 8.7 7.3c.3 .1 .7 .1 1.4 .2c1.7 .2 4 .4 6.7 .5c5.4 .2 11.6 .1 16.5 .1l.1 0c1.7 0 3.3 0 4.6 0H421.1c1.3 0 2.9 0 4.6 0l.1 0c5 .1 11.1 .1 16.5-.1c2.7-.1 5-.3 6.7-.5c.7-.1 1.1-.2 1.4-.2c6.2-3.2 8-6 8.7-7.3c.6-1.4 1-3.6-.2-7.6c-2.7-9.1-10.7-19.6-19.8-31.2l-.2-.3c-3.1-3.9-6.4-8.1-8.9-11.9l-.1-.2-.1-.2c-.8-1.2-1.7-2.6-2.8-4.3l-.4-.6c-2.7-4.1-6.2-9.5-9.6-15.6l-321.1 0zm345-131.5c-1-1.4-2.2-2.9-3.5-4.4c-21.8-24.5-20.4-53-15.8-73.5H91.2c4.6 20.5 6 49-15.8 73.5c-1.3 1.5-2.5 3-3.5 4.4H440.7z',
  ],
};

export default basTummyBox;
