export enum ProjectEstimateItemType {
  CUBIC_METER = 'cubic-meter',
  SERVICE = 'service',
  DISTANCE = 'distance',
  TRAVEL_TIME = 'travel_time',
  HOURS = 'hours',
  STAFF = 'staff',
  BUILDING_TYPE = 'building-type',
  INVENTORY_ITEM = 'inventory-item',
  SURCHARGE = 'surcharge',
  BOXES = 'boxes',
}
