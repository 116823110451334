import { colors } from '@bas/theme';
import { EmployeeProjectRole } from '@bas/value-objects';
import { faTrashCan, faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { CSSProperties, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@bas/ui/web/base';
import { ContentSection } from '@bas/ui/web/molecules';

export type PlannableRoleItemProps = {
  projectRole: EmployeeProjectRole;
  className?: string;
  active?: boolean;
  onClick?: () => Promise<void> | void;
  onRemoveRole?: () => Promise<void> | void;
  style?: CSSProperties;
};

const PlannableRoleItem = ({
  projectRole,
  className,
  active,
  onClick,
  onRemoveRole,
  style,
}: PlannableRoleItemProps): ReactElement => (
  <ContentSection
    className={clsx(className, { 'Bas-PlannableRoleItem-Active': active })}
    actions={
      onRemoveRole
        ? [
            <IconButton
              className="Bas-PlannableRoleItem-Delete"
              onClick={onRemoveRole}
            >
              <Icon
                className="Bas-PlannableRoleItem-Delete"
                icon={faTrashCan}
              />
            </IconButton>,
          ]
        : []
    }
    style={style}
    onClick={
      onClick
        ? async (e) => {
            if (
              e.target instanceof HTMLElement &&
              !e.target.classList.contains('Bas-PlannableRoleItem-Delete')
            ) {
              return onClick?.();
            }

            return undefined;
          }
        : undefined
    }
  >
    <Box className="Bas-PlannableRoleItem-root">
      <Box className="Bas-PlannableRoleItem-Avatar">
        <Icon icon={faUserCircle} />
      </Box>
      <Grid container className="Bas-PlannableRoleItem-NameBlock">
        <Grid item xs={12} className="Bas-PlannableRoleItem-Role">
          <Typography>
            <FormattedMessage id={`projectRoles.${projectRole}`} />
          </Typography>
        </Grid>
        <Grid item xs={12} className="Bas-PlannableRoleItem-Name">
          <Typography>
            <FormattedMessage id="label.openProjectRole" />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </ContentSection>
);

const StyledPlannableRoleItem = styled(PlannableRoleItem)(
  ({ theme }) => `
  cursor: pointer;
  &:hover, &.Bas-PlannableRoleItem-Active {
    .Bas-PlannableRoleItem-root {
      .Bas-PlannableRoleItem-Role .MuiTypography-root, .Bas-PlannableRoleItem-Name .MuiTypography-root {
        color: ${colors.blue[500]};
      }
    }
  }

  .Bas-ContentSection-Actions {
    top: -${theme.spacing(0)};
    right: ${theme.spacing(0)};
  }

  &.Bas-ContentSection-HasActions {
    &:hover, &.Bas-ContentSection-Editing {
      &:before {
        left: -13px;
        right: -13px;
        top: -${theme.spacing(1)};
        bottom: -${theme.spacing(1)};
      }
    }
  }

  .Bas-PlannableRoleItem-root {
    display: flex;
    flex-direction: row;

    .Bas-PlannableRoleItem-Avatar {
      color: ${colors.lila[400]};
      padding-right: 12px;
      font-size: 44px;
    }

    .Bas-PlannableRoleItem-Role .MuiTypography-root {
      color: ${theme.palette.common.black};
    }

    .Bas-PlannableRoleItem-Name .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
    }
  }
`
);
export default StyledPlannableRoleItem;
