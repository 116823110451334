import { colors } from '@bas/theme';
import { Box, BoxProps, Skeleton, styled, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import primaryIconsBackground from './primaryIconsBackground.svg';
import secondaryIconsBackground from './secondaryIconsBackground.svg';

type StatsBlockProps = Omit<BoxProps, 'title'> & {
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: 'primary' | 'secondary';
  title: string | ReactNode;
  value?: string | ReactNode;
  valueSuffix?: string | ReactNode;
  children?: ReactElement;
  isLoading?: boolean;
};

const StatsBlock = ({
  title,
  value,
  valueSuffix,
  children,
  isLoading,
  ...props
}: StatsBlockProps): ReactElement => (
  <Box {...props}>
    <Box className="StatsBlock-Icons" />
    <Box className="StatsBlock-Content">
      <Typography
        variant="subtitle1"
        fontWeight={700}
        className="StatsBlock-Title"
      >
        {title}
      </Typography>
      <Box className="StatsBlock-Value">
        {isLoading ? (
          <Skeleton variant="text" width="100%" height={50} />
        ) : (
          <>
            {children}
            {!children && (
              <>
                <Typography variant="h2" fontWeight={700}>
                  {value}
                </Typography>
                {valueSuffix && (
                  <Typography variant="body2">{valueSuffix}</Typography>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  </Box>
);

const primaryStyling = `
background: ${colors.blue[700]};
.StatsBlock-Icons {
  background-image: url(${primaryIconsBackground});
  background-color: linear-gradient(0deg, rgba(18, 73, 183, 0.55) 0%, rgba(18, 73, 183, 0.00) 100%);
}
.StatsBlock-Title {
  color: ${colors.blue[200]};
}

.StatsBlock-Value {
  .MuiTypography-root {
    color: ${colors.white};
  }
}
`;

const secondaryStyling = `
background: ${colors.lila[100]};
.StatsBlock-Icons {
  background-image: url(${secondaryIconsBackground});
  background-color: linear-gradient(0deg, rgba(250, 250, 251, 0.46) 0%, rgba(250, 250, 251, 0.00) 100%);
}
.StatsBlock-Title {
  color: ${colors.lila[800]};
}

.StatsBlock-Value {
  .MuiTypography-root {
    color: ${colors.lila[800]};
  }
}
`;

const StyledStatsBlock = styled(StatsBlock)`
  position: relative;
  overflow: hidden;
  min-width: 145px;
  min-height: 145px;
  aspect-ratio: 1;
  border-radius: 5px;
  padding: 7px;
  box-sizing: border-box;

  ${({ variant }) => (variant === 'primary' || !variant) && primaryStyling}
  ${({ variant }) => variant === 'secondary' && secondaryStyling}
  .StatsBlock-Content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    text-align: center;
  }

  .StatsBlock-Value {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .StatsBlock-Icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

export default StyledStatsBlock;
