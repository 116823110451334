import {
  MollieSettingsInputType,
  MollieSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { Integration } from '@bas/integration-domain/models';
import { MollieSettingsContentSection } from '@bas/integration-domain/web/molecules';
import { ReactHookForm } from '@bas/ui/web/molecules';
import { ReactElement, useState } from 'react';
import { EditingMollieSettingsContentSection } from '../EditingMollieSettingsContentSection';

export type EditableMollieSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: MollieSettingsInputType) => Promise<void>;
};

const EditableMollieSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableMollieSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<MollieSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          profileId: '',
          testMode: false,
          ...integration.providerSettings,
        }}
        validationSchema={MollieSettingsInputTypeValidationBuilder()}
      >
        <EditingMollieSettingsContentSection
          integrationId={integration.integrationId}
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  return (
    <MollieSettingsContentSection
      onEdit={() => setIsEditing(true)}
      integration={integration}
    />
  );
};

export default EditableMollieSettingsBlock;
