import { Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type ContentSectionAddActionProps = {
  onClick: () => void | Promise<void>;
  label?: ReactNode;
  disabled?: boolean;
};

const ContentSectionAddAction = ({
  onClick,
  label,
  disabled,
}: ContentSectionAddActionProps): ReactElement => (
  <Tooltip title={label || <FormattedMessage id="label.contentSection.add" />}>
    <span>
      <IconButton
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={disabled}
        onClick={onClick}
      >
        <Icon icon={faPlusCircle} />
      </IconButton>
    </span>
  </Tooltip>
);

export default ContentSectionAddAction;
