import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faIntercom } from '@fortawesome/free-brands-svg-icons';
import { faShieldExclamation } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, styled, Typography } from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

export type NotFoundPageTemplateProps = {
  className?: string;
};

const NotFoundPageTemplate = ({
  className,
  ...props
}: NotFoundPageTemplateProps) => {
  const { showNewMessage } = useIntercom();
  const handleRequestHelp = useCallback(
    (content?: string) => showNewMessage(content),
    [showNewMessage]
  );

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const goHome = useCallback(() => {
    navigate('/', {
      replace: true,
    });
  }, [navigate]);

  return (
    <Box className={className} {...props}>
      <Box className="Bas-NotFoundPageTemplate-Content">
        <Box className="Bas-NotFoundPageTemplate-Content-Icon">
          <Icon icon={faShieldExclamation} />
        </Box>
        <Box className="Bas-NotFoundPageTemplate-TitleHolder">
          <Typography variant="h3">
            <FormattedMessage id="notFoundPage.title" />
          </Typography>
        </Box>
        <Box className="Bas-NotFoundPageTemplate-DescriptionHolder">
          <Box className="Bas-NotFoundPageTemplate-Content-Text">
            <FormattedMessage id="notFoundPage.text" />
          </Box>
        </Box>

        <Grid
          container
          spacing={2}
          className="Bas-NotFoundPageTemplate-Actions"
        >
          <Grid item>
            <Button
              onClick={() =>
                handleRequestHelp(
                  formatMessage({ id: 'notFoundPage.helpMessage' })
                )
              }
              className="Bas-NotFoundPageTemplate-Action"
            >
              <Icon icon={faIntercom} />
              &nbsp;
              <FormattedMessage id="notFoundPage.iNeedHelp" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={goHome}
              variant="outlined"
              className="Bas-NotFoundPageTemplate-Action"
            >
              <FormattedMessage id="notFoundPage.backToHome" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const StyledNotFoundPageTemplate = styled(NotFoundPageTemplate)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20%;

  .Bas-NotFoundPageTemplate-Content {
    max-width: 800px;
    text-align: center;

    .Bas-NotFoundPageTemplate-Content-Icon {
      font-size: 104px;
      text-align: center;
      color: ${({ theme }) => theme.palette.warning.main};
    }

    .Bas-NotFoundPageTemplate-TitleHolder {
      text-align: center;
      margin-bottom: ${({ theme }) => theme.spacing(4)};
      white-space: nowrap;
    }

    .Bas-NotFoundPageTemplate-DescriptionHolder {
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    ${({ theme }) => theme.breakpoints.only('xs')} {
      padding: 0 20px;
    }
  }
`;
export default StyledNotFoundPageTemplate;
