import { ReactElement } from 'react';
import { Box, Skeleton, styled } from '@mui/material';

export type LoadingCommunicationBlockProps = {
  className?: string;
};

const LoadingCommunicationBlock = ({
  className,
}: LoadingCommunicationBlockProps): ReactElement => (
  <Box className={className}>
    <Skeleton height={200} variant="rectangular" />
  </Box>
);

const StyledLoadingCommunicationBlock = styled(LoadingCommunicationBlock)(
  ({ theme }) => `
    margin-left: -${theme.spacing(3)};
    margin-right: -${theme.spacing(3)};
    .MuiSkeleton-root {
      border-radius: 5px;
    }
`
);
export default StyledLoadingCommunicationBlock;
