import {
  CertificateData,
  isSchoutenZekerheidCertificate,
  SyncStatus,
} from '@bas/integration-domain/models';
import { SyncStatusChip } from '@bas/integration-domain/web/molecules';
import { Button, TableCell, TableRow, Tooltip } from '@bas/ui/web/atoms';
import { Grid, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

export type CertificateTableRowProps = {
  data: CertificateData;
  onApprove: () => Promise<void> | void;
  onDisapprove: () => Promise<void> | void;
  onRetry: () => Promise<void> | void;
  onFinalized: () => Promise<void> | void;
};

const CertificateTableRow = ({
  data: {
    result,
    projectId,
    customerName,
    messages,
    syncStatus,
    approvedBy,
    approvedAt,
    processedAt,
    failedAt,
  },
  onApprove,
  onDisapprove,
  onRetry,
  onFinalized,
}: CertificateTableRowProps): ReactElement => (
  <TableRow>
    <TableCell paddingRight>
      <Link
        to={`/projects/projects/${projectId}`}
        component={RouterLink}
        color="textPrimary"
      >
        {customerName}
      </Link>
    </TableCell>
    <TableCell paddingRight>
      <SyncStatusChip syncStatus={syncStatus} messages={messages} />
    </TableCell>
    <TableCell paddingRight>
      {approvedAt && (
        <Tooltip
          title={
            <Typography>
              <FormattedMessage
                id="label.approvedByName"
                values={{ name: approvedBy?.personName?.fullName }}
              />
            </Typography>
          }
          disabled={!approvedBy}
        >
          <FormattedDate
            value={dayjs(approvedAt).toDate()}
            dateStyle="short"
            timeStyle="short"
          />
        </Tooltip>
      )}
    </TableCell>
    <TableCell paddingRight>
      {!failedAt && processedAt && (
        <FormattedDate
          value={dayjs(processedAt).toDate()}
          dateStyle="short"
          timeStyle="short"
        />
      )}
      {failedAt && (
        <FormattedDate
          value={dayjs(failedAt).toDate()}
          dateStyle="short"
          timeStyle="short"
        />
      )}
    </TableCell>
    <TableCell>
      {[SyncStatus.FAILED, SyncStatus.DISAPPROVED].includes(syncStatus) && (
        <Button onClick={onRetry} size="small" variant="outlined">
          <FormattedMessage id="button.retry" />
        </Button>
      )}
      {syncStatus === SyncStatus.SUCCESS &&
        isSchoutenZekerheidCertificate(result) &&
        !result.finalized && (
          <Grid container spacing="4px">
            <Grid item>
              <Button onClick={onFinalized} variant="outlined" size="small">
                <FormattedMessage id="button.finalized" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                target="_blank"
                href={
                  result.link.match(/https:\/[a-z]/)
                    ? result.link.replace('https:/', 'https://')
                    : result.link
                }
                size="small"
              >
                <FormattedMessage id="button.finalize" />
              </Button>
            </Grid>
          </Grid>
        )}
      {syncStatus === SyncStatus.AWAITING_APPROVAL && (
        <Grid container spacing="4px">
          <Grid item>
            <Button
              onClick={onDisapprove}
              variant="outlined"
              color="warning"
              size="small"
            >
              <FormattedMessage id="button.disapprove" />
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onApprove} size="small">
              <FormattedMessage id="button.approve" />
            </Button>
          </Grid>
        </Grid>
      )}
    </TableCell>
  </TableRow>
);

export default CertificateTableRow;
