import * as Yup from 'yup';
import {
  AccountingIntegrationSettingsInputType,
  AccountingIntegrationSettingsInputTypeDefaultValues,
  AccountingIntegrationSettingsInputTypeValidationBuilder,
} from '../accountingIntegrationSettingsInputType';

export interface ExactGlobeSettingsInputType
  extends AccountingIntegrationSettingsInputType {
  defaultDivisionCode: string;
  salesJournalCode: string;
  sendEntryNumbers: boolean;
}

export const ExactGlobeSettingsInputTypeDefaultValues =
  (): ExactGlobeSettingsInputType => ({
    ...AccountingIntegrationSettingsInputTypeDefaultValues(),
    defaultDivisionCode: '',
    salesJournalCode: '',
    sendEntryNumbers: false,
  });

export const ExactGlobeSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ExactGlobeSettingsInputType> =>
    Yup.object({
      defaultDivisionCode: Yup.string()
        .required()
        .label('label.administration'),
      salesJournalCode: Yup.string().required().label('label.salesJournal'),
      sendEntryNumbers: Yup.boolean()
        .required()
        .label('label.useInvoiceNumberAsTransactionNumber'),
    }).concat(AccountingIntegrationSettingsInputTypeValidationBuilder());
