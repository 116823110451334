import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basEndTable: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'end-table',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M256 78c-64.7 0-123.7 5.1-166.9 13.5c-21.5 4.2-39.8 9.3-53.1 15.4c-6.6 3-12.8 6.7-17.6 11.2S9 129.3 9 138c0 1.2 .1 2.4 .3 3.5H9V206c0 8.7 4.6 15.4 9.4 20s11 8.2 17.6 11.2c8.6 3.9 19.2 7.4 31.4 10.6L33.3 417c-1.6 8.1 3.6 16 11.7 17.7s16-3.6 17.7-11.7l34-169c12.7 2.3 26.6 4.2 41.5 5.9l-17.9 89c-1.6 8.1 3.6 16 11.7 17.7s16-3.6 17.7-11.7l18.5-92c27.2 2.1 56.8 3.3 87.8 3.3s60.6-1.2 87.8-3.3l18.5 92c1.6 8.1 9.5 13.4 17.7 11.7s13.4-9.5 11.7-17.7l-17.9-89c14.7-1.7 28.4-3.6 41-5.8l34 168.9c1.6 8.1 9.5 13.4 17.7 11.7s13.4-9.5 11.7-17.7l-34-169.2c12.4-3.1 23.2-6.7 31.9-10.7c6.6-3 12.8-6.7 17.6-11.2s9.4-11.2 9.4-20V141.5h-.3c.2-1.1 .3-2.3 .3-3.5c0-8.7-4.6-15.4-9.4-20s-11-8.2-17.6-11.2c-13.3-6.1-31.7-11.2-53.1-15.4C379.7 83.1 320.7 78 256 78zM38.6 140.6a.1 .1 0 1 1 -.2-.1 .1 .1 0 1 1 .2 .1zm9.9-6.5c10.2-4.7 25.8-9.2 46.3-13.2C135.6 113 192.6 108 256 108s120.4 5 161.2 12.9c20.5 4 36.2 8.6 46.3 13.2c3.2 1.5 5.5 2.8 7.1 3.9c-1.6 1.1-4 2.4-7.1 3.9c-10.2 4.7-25.8 9.2-46.3 13.2C376.4 163 319.4 168 256 168s-120.4-5-161.2-12.9c-20.5-4-36.2-8.6-46.3-13.2c-3.2-1.4-5.5-2.8-7.1-3.9c1.6-1.1 4-2.4 7.1-3.9zm425.1 6.4a0 0 0 1 0 0 0 0 0 0 1 0 0 0zm0-5a0 0 0 1 0 0-.1 0 0 0 1 0 0 .1zm-435.2 0a0 0 0 1 0 0-.1 0 0 0 1 0 0 .1zM473 170.5v33.7l0 0c-1.4 1.3-4.3 3.3-9.5 5.7c-10.2 4.7-25.8 9.2-46.3 13.2C376.4 231 319.4 236 256 236s-120.4-5-161.2-12.9c-20.5-4-36.2-8.6-46.3-13.2c-5.1-2.4-8-4.4-9.5-5.7l0 0V170.5c13 5.5 30.2 10.2 50.1 14.1C132.3 192.9 191.3 198 256 198s123.7-5.1 166.9-13.5c19.9-3.9 37.1-8.5 50.1-14.1zm.6 33a0 0 0 1 0 0 0 0 0 0 1 0 0 0zm-435.2 0a0 0 0 1 0 0-.1 0 0 0 1 0 0 .1z',
  ],
};

export default basEndTable;
