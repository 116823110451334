import { colors } from '@bas/theme';
import {
  RadioButtonCheckedRounded,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import {
  FormGroup,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement, useState } from 'react';
import { FormControlLabel } from '../FormControlLabel';

export type RadioProps = MuiRadioProps & {
  label: string | number | ReactElement;
  value?: string | number | boolean;
};

const CustomizedRadio = styled(MuiRadio)(
  ({ theme }) => `
  color: ${colors.lila[800]};
  &:hover {
    color: ${colors.lila[800]};
  }

  &.Mui-checked {
    color: #1F1F1F;
  }
`
);

const Radio = ({ label, disabled, value, ...props }: RadioProps) => {
  const [hover, setHover] = useState<boolean>(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const checkboxContent = (
    <CustomizedRadio
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
      icon={
        hover && !disabled ? (
          <RadioButtonCheckedRounded />
        ) : (
          <RadioButtonUnchecked />
        )
      }
      value={value}
      {...props}
    />
  );

  if (!label) {
    return checkboxContent;
  }

  return (
    <FormGroup>
      <FormControlLabel
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        label={label}
        disabled={disabled}
        control={checkboxContent}
        value={value}
      />
    </FormGroup>
  );
};

Radio.defaultProps = {};

export default Radio;
