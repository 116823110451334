import {
  useYukiAdministrationsByIntegrationIdRequest,
  useYukiDomainsByIntegrationIdRequest,
  useYukiGlAccountsByIntegrationIdRequest,
} from '@bas/integration-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';

export const useYukiMappingOptions = (integrationId: Uuid) => {
  const { data: glAccountsData, isError: generalLedgersError } =
    useYukiGlAccountsByIntegrationIdRequest({
      integrationId,
    });

  const generalLedgerExternalOptions = useMemo(
    () =>
      (glAccountsData?.data?.['hydra:member'] || [])
        .filter(({ YukiIsEnabled }) => !YukiIsEnabled)
        .map(({ YukiCode, YukiDescription }) => ({
          externalId: YukiCode,
          label: `${YukiDescription} (${YukiCode})`,
        })),
    [glAccountsData?.data]
  );

  const { data: administrationsData, isError: administrationsError } =
    useYukiAdministrationsByIntegrationIdRequest({
      integrationId,
    });

  const administrationsExternalOptions = useMemo(
    () =>
      (administrationsData?.data?.['hydra:member'] || []).map(
        ({ '@ID': id, Name }) => ({
          externalId: id,
          label: Name,
        })
      ),
    [administrationsData?.data]
  );

  const { data: domainsData, isError: domainsError } =
    useYukiDomainsByIntegrationIdRequest({
      integrationId,
    });

  const domainsExternalOptions = useMemo(
    () =>
      (domainsData?.data?.['hydra:member'] || []).map(
        ({ '@ID': id, Name }) => ({
          externalId: id,
          label: Name,
        })
      ),
    [domainsData?.data]
  );

  const vatExternalOptions = useMemo(
    () => [
      { externalId: '0', label: '0% Exempt from VAT' },
      { externalId: '1', label: 'Variable High VAT rate' },
      { externalId: '2', label: 'Variable Low VAT rate' },
      { externalId: '4', label: '0% Btw 0%' },
      { externalId: '6', label: '0% Export outside the EU' },
      { externalId: '7', label: '0% Export within the EU' },
      {
        externalId: '8',
        label: 'Country-specific Installation/distance sales within the EU',
      },
      { externalId: '17', label: '0% Reverse charged VAT' },
      { externalId: '19', label: '0% Export goods EU (leveringen 2010)' },
      { externalId: '20', label: ' Export services EU (diensten 2010)' },
      { externalId: '40', label: 'Variable	OSS' },
    ],
    []
  );

  return {
    generalLedgersError,
    generalLedgerExternalOptions,
    administrationsError,
    administrationsExternalOptions,
    domainsError,
    domainsExternalOptions,
    vatExternalOptions,
  };
};
