import {
  RejectExternalEmployeeInputType,
  RejectExternalEmployeeInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { ExternalEmployee } from '@bas/integration-domain/models';
import { Button } from '@bas/ui/web/atoms';
import { DialogProps, ReactHookFormTextField } from '@bas/ui/web/molecules';
import { FormDialog } from '@bas/ui/web/organisms';
import { Grid, styled } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export type RejectExternalEmployeesFormDialogProps = Omit<
  DialogProps,
  'onSubmit'
> & {
  open: boolean;
  externalEmployees: ExternalEmployee[];
  onSubmit: SubmitHandler<{
    externalEmployees: RejectExternalEmployeeInputType[];
  }>;
};

const FormContent = ({
  externalEmployees,
}: {
  externalEmployees: ExternalEmployee[];
}): ReactElement => (
  <Grid container alignItems="stretch" spacing={4}>
    <Grid item xs={12}>
      <FormattedMessage id="label.areYouSureYouWantToRejectTheFollowingEmployees" />
    </Grid>
    <Grid item xs={12} container>
      {externalEmployees.map(({ id, personName: { fullName } }, index) => (
        <Grid
          item
          xs={12}
          key={id}
          container
          spacing={2}
          alignItems="flex-end"
          pb={2}
        >
          <Grid item xs={6}>
            {fullName}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={`externalEmployees.${index}.reason`}
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  autoComplete="off"
                  fullWidth
                  label={<FormattedMessage id="label.reason" />}
                />
              )}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

const RejectExternalEmployeesFormDialog = ({
  onClose,
  externalEmployees,
  ...args
}: RejectExternalEmployeesFormDialogProps): ReactElement | null => {
  if (!externalEmployees.length) {
    return null;
  }

  return (
    <FormDialog<{
      externalEmployees: RejectExternalEmployeeInputType[];
    }>
      name="reject-external-employees-to-existing-employees"
      onClose={onClose}
      title={<FormattedMessage id="label.rejectEmployees" />}
      validationSchema={Yup.object({
        externalEmployees: Yup.array().of(
          RejectExternalEmployeeInputTypeValidationBuilder()
        ),
      })}
      dialogActions={[
        <Button type="submit" key="save" color="error">
          <FormattedMessage id="button.reject" />
        </Button>,
      ]}
      defaultValues={{
        externalEmployees: externalEmployees.map((externalEmployee) => ({
          ...externalEmployee,
          reason: '',
        })),
      }}
      maxWidth="md"
      dangerous
      fullWidth
      useProvider
      {...args}
    >
      <FormContent externalEmployees={externalEmployees} />
    </FormDialog>
  );
};

const StyledRejectExternalEmployeesFormDialog = styled(
  RejectExternalEmployeesFormDialog
)``;

export default StyledRejectExternalEmployeesFormDialog;
