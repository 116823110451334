import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ActiveIntake } from '@bas/project-domain/models';
import { Uuid } from '@bas/value-objects';
import isEqual from 'react-fast-compare';
import { zustandStorage } from './zustandStorage';

type TaxationStoreType = {
  activeTaxations: ActiveIntake[];
  removeAllTaxations: () => void;
  deleteTaxation: (projectId: Uuid) => void;
  updateOrCreateTaxation: (taxation: ActiveIntake) => void;
};

export const useTaxationToolStore = create<TaxationStoreType>()(
  persist(
    (set, get) => ({
      activeTaxations: [],
      removeAllTaxations: () => set({ activeTaxations: [] }),
      deleteTaxation: (projectId: Uuid) => {
        const { activeTaxations } = get();
        set({
          activeTaxations: activeTaxations.filter(
            (taxation) => taxation.projectId !== projectId
          ),
        });
      },
      updateOrCreateTaxation: (taxation: ActiveIntake) => {
        const { activeTaxations } = get();
        const newValue = [...activeTaxations];
        const currentIndex = activeTaxations.findIndex(
          (t) => t.projectId === taxation.projectId
        );

        if (currentIndex === -1) {
          newValue.push(taxation);
        } else {
          if (isEqual(newValue[currentIndex], taxation)) {
            return;
          }

          newValue[currentIndex] = taxation;
        }

        set({ activeTaxations: newValue });
      },
    }),
    {
      name: 'taxation-tool-store',
      storage: zustandStorage<TaxationStoreType>(),
    }
  )
);
