import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basBedSideTable: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'bed-side-table',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M12 77c0 8.3 6.7 15 15 15h75v11.8c0 21.3 4.3 38.8 13.3 52.8c9.1 14.1 22.2 23.1 37 28.8C178.2 195.3 212 196 246 196l0 226.5c-27 1.7-51.6 7.9-70.9 17.4c-21.8 10.9-41.1 28.6-41.1 52.8V512h30V492.7c0-6.6 5.8-16.6 24.5-25.9c17.8-8.8 43.4-14.7 72.5-14.7s54.7 5.9 72.5 14.7c18.7 9.3 24.5 19.4 24.5 25.9V512h30V492.7c0-24.2-19.3-42-41.1-52.8c-19.2-9.6-43.9-15.7-70.9-17.4L276 196c30.6-.2 60.4-1.7 83.7-10.6c14.8-5.7 27.9-14.7 37-28.8c9-14 13.3-31.5 13.3-52.8V92h75c8.3 0 15-6.7 15-15s-6.7-15-15-15H27c-8.3 0-15 6.7-15 15zM132 92H380v11.8c0 17.3-3.5 28.7-8.5 36.5c-4.9 7.6-12.2 13.1-22.6 17.1c-22 8.4-53.4 8.7-92.9 8.7s-70.9-.2-92.9-8.7c-10.3-4-17.6-9.5-22.6-17.1c-5-7.7-8.5-19.1-8.5-36.5V92z',
  ],
};

export default basBedSideTable;
