import {
  Integration,
  isWhatsAppSettings,
} from '@bas/integration-domain/models';
import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export type WhatsAppProfileTabProps = {
  integration: Integration;
};

const WhatsAppProfileTab = ({
  integration,
}: WhatsAppProfileTabProps): ReactElement => {
  const openManageProfile = useCallback((id: string) => {
    window.open(
      `https://business.facebook.com/wa/manage/phone-numbers/?waba_id=${id}&childRoute=biz-profile`
    );
  }, []);

  return (
    <Grid container spacing={4}>
      {isWhatsAppSettings(integration.providerSettings) && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.profile" />
          </Typography>
          <Grid container spacing={1} pt={1}>
            {integration.providerSettings.businesses.map((business) => (
              <Grid item xs={12} key={business.id}>
                <Button
                  onClick={() => openManageProfile(business.id)}
                  variant="outlined"
                  size="small"
                >
                  <Icon icon={faExternalLink} />
                  &nbsp;
                  <FormattedMessage
                    id="button.manageProfile"
                    values={{
                      name: business.name,
                    }}
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default WhatsAppProfileTab;
