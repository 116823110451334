import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Grid, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type AutomatedEmailTranslationPreviewContentSectionProps = {
  onEdit: () => void;
  content: string;
};

const AutomatedEmailTranslationPreviewContentSection = ({
  onEdit,
  content,
  ...args
}: AutomatedEmailTranslationPreviewContentSectionProps): ReactElement => (
  <ContentSection
    actions={[<ContentSectionEditAction onClick={onEdit} />]}
    {...args}
  >
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.preview" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <iframe
          className="Bas-AutomatedEmailTranslationPreviewContentSection-iFrame"
          title="email"
          srcDoc={content}
          sandbox=""
        />
      </Grid>
    </Grid>
  </ContentSection>
);

const StyledAutomatedEmailTranslationPreviewContentSection = styled(
  AutomatedEmailTranslationPreviewContentSection
)(
  () => `
  .Bas-AutomatedEmailTranslationPreviewContentSection-iFrame {
    width: 100%;
    height: 100%;
    min-height: 65vh;
    border: none;
  }
`
);
export default StyledAutomatedEmailTranslationPreviewContentSection;
