import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basWorkbench: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'workbench',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M322.5 30c-8.3 0-15 6.7-15 15s6.7 15 15 15l37.3 0-45 45H185c-8.3 0-15 6.7-15 15v17H24c-8.3 0-15 6.7-15 15v70c0 8.3 6.7 15 15 15H91.7L22.5 488c-1.2 4.5-.3 9.3 2.5 13.1s7.3 5.9 11.9 5.9H97.3c6.8 0 12.7-4.5 14.5-11l34.7-125.9L181.2 496c1.8 6.5 7.7 11 14.5 11H256c.2 0 .3 0 .5 0c.2 0 .3 0 .5 0h60.3c6.7 0 12.7-4.5 14.5-11l34.7-125.9L401.2 496c1.8 6.5 7.7 11 14.5 11H476c4.7 0 9.1-2.2 11.9-5.9s3.8-8.6 2.5-13.1L421.3 237h66.2c8.3 0 15-6.7 15-15V152c0-8.3-6.7-15-15-15L343 137V120c0-.3 0-.5 0-.8l56.1-56.1c.9-1 1.7-2 2.4-3.1l45 0c8.3 0 15-6.7 15-15s-6.7-15-15-15l-124 0zm67.7 207l66.1 240H427.1L381 309.6c-1.8-6.5-7.7-11-14.5-11s-12.7 4.5-14.5 11L305.9 477H276.7l60-217.8c3.8-2.7 6.3-7.2 6.3-12.2V237h47.2zM343 207V167l129.5 0v40l-70.9 0s0 0 0 0H343zm-38.2 55L256.5 437.4 208.2 262h96.6zM170 237v10c0 5 2.5 9.5 6.3 12.2l60 217.8H207.1L161 309.6c-1.8-6.5-7.7-11-14.5-11s-12.7 4.5-14.5 11L85.9 477H56.7l66.1-240 47.2 0zm0-30l-58.6 0s0 0 0 0H39V167H170v40zm30-72h84.8l-39.4 39.4c-5.9 5.9-5.9 15.4 0 21.2s15.4 5.9 21.2 0L313 149.2V232H200V135z',
  ],
};

export default basWorkbench;
