import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basIroningBoard: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'ironing-board',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M190.2 288.8a16.6 16.6 0 1 0 0-33.2 16.6 16.6 0 1 0 0 33.2zm32.2-16.6a16.6 16.6 0 1 1 33.2 0 16.6 16.6 0 1 1 -33.2 0zm65.4 16.6a16.6 16.6 0 1 0 0-33.2 16.6 16.6 0 1 0 0 33.2zM183.3 102.6c.8-.1 1.6-.2 2.4-.2H357.5c8.1 0 14.6 6.6 14.6 14.6s-6.6 14.6-14.6 14.6H187.1c-15.1 2.9-38.2 13.2-56.8 35.8c-5.6 6.8-10.9 14.9-15.5 24.3c11.9-5.9 25.9-8.4 41.7-8.4c108.7 0 165.5 6.1 198.2 17.2c16.9 5.8 28 13 36.5 21.8c5.8 6 10.5 13.1 14.4 19c1.2 1.9 2.4 3.7 3.5 5.2c.2 .3 .4 .5 .5 .8c21 34.7 19.5 68.7 18.7 86.3c0 .8-.1 1.6-.1 2.4c-.3 7.8-6.8 14-14.6 14h-281c-2.8 0-5.6-.8-7.9-2.3L78.8 318.3c-4.2-2.7-6.7-7.3-6.7-12.3c0-5.7 .1-11.3 .3-16.6c-.7-1.5-1.1-3.2-1.2-4.9C66.8 222 83.5 178.2 107.6 148.9c23.8-28.9 54.1-42.6 75.7-46.3zM136.9 321H399.3c.1-16.2-2-36.8-14.6-57.9c-1.9-2.8-3.5-5.2-5-7.4c-3.4-5.1-5.9-8.9-9.7-12.9c-4.8-4.9-11.6-9.9-24.8-14.4c-27.5-9.4-79.8-15.7-188.7-15.7c-23.2 0-34.9 6.3-42.1 17.8c-7.9 12.4-12.3 33.4-12.9 67.6L136.9 321zM86.4 377c-8.1 0-14.6 6.6-14.6 14.6s6.6 14.6 14.6 14.6H413.5c8.1 0 14.6-6.6 14.6-14.6s-6.6-14.6-14.6-14.6H86.4z',
  ],
};

export default basIroningBoard;
