import { CanPayAtCustomer } from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type CanPayAtCustomerByEventIdRequestProps = {
  eventId: string;
};

type Response = AxiosResponse<CanPayAtCustomer>;
type QueryKeyType = QueryKey & {
  [0]: 'events';
  [1]: 'can-pay-at-customer';
  [2]: Uuid;
};

export const CanPayAtCustomerByEventIdRequest = async ({
  eventId,
}: CanPayAtCustomerByEventIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events/${eventId}/can-pay-at-customer`);

export const useCanPayAtCustomerByEventIdRequest = (
  request: CanPayAtCustomerByEventIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    queryFn: async () => CanPayAtCustomerByEventIdRequest(request),
    queryKey: ['events', 'can-pay-at-customer', request.eventId],
    ...options,
  });

export const CanPayAtCustomerByEventIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  queryClient.invalidateQueries({
    queryKey: ['events', 'can-pay-at-customer'],
  });
};
