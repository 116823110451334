import { HoursEntry, isHoursEntry } from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import Qs from 'qs';
import { useEffect } from 'react';

export type HourEntriesByEmployeeIdRequestProps = Pagination & {
  employeeId: Uuid;
  startDate: Date | Dayjs;
  endDate: Date | Dayjs;
};

type Response = AxiosResponse<Collection<HoursEntry>>;

export const HourEntriesByEmployeeIdRequest = async ({
  employeeId,
  startDate,
  endDate,
  ...params
}: HourEntriesByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/employees/${employeeId}/hours-entries`, {
    params: {
      ...params,
      'startDate[before]': formatDate(startDate),
      'endDate[after]': formatDate(endDate),
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useHourEntriesByEmployeeIdRequest = (
  { employeeId, ...request }: HourEntriesByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      HourEntriesByEmployeeIdRequest({ ...request, employeeId }),
    queryKey: ['hour-entries', 'list', employeeId, request],
  });

export const usePrefetchHourEntriesByEmployeeIdRequest = ({
  employeeId,
  ...request
}: HourEntriesByEmployeeIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'hour-entries',
        'list',
        employeeId,
        ...Object.values({ employeeId, ...request }),
      ],
      queryFn: async () =>
        HourEntriesByEmployeeIdRequest({ employeeId, ...request }),
    });
  }, [employeeId, queryClient, request]);
};

export const HourEntriesByEmployeeIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isHoursEntry(data)) {
    queryClient.invalidateQueries({
      queryKey: ['hour-entries', 'list', data.employeeId],
    });
  }
};
