import { Button, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type ContentSectionSaveActionProps = {
  onClick?: () => void | Promise<void>;
  label?: ReactNode;
  asIcon?: boolean;
  disabled?: boolean;
};

const ContentSectionSaveAction = ({
  onClick,
  label,
  asIcon,
  disabled,
}: ContentSectionSaveActionProps): ReactElement => {
  const theme = useTheme();
  const forceIconButton = useMediaQuery(theme.breakpoints.down('xl'));

  if (forceIconButton || asIcon) {
    return (
      <Tooltip title={label || <FormattedMessage id="button.save" />}>
        <span>
          <IconButton
            type={!onClick ? 'submit' : 'button'}
            onClick={onClick}
            disabled={disabled}
          >
            <Icon icon={faSave} />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button
      type={!onClick ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {label || <FormattedMessage id="button.save" />}
    </Button>
  );
};

export default ContentSectionSaveAction;
