import { VatCode } from '@bas/financial-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type VatCodesRequestProps = Pagination;

export const VatCodesRequest = async ({
  ...params
}: VatCodesRequestProps): Promise<AxiosResponse<Collection<VatCode>>> =>
  axios.get('api/{tenantId}/financials/vat-codes', {
    params: { ...params },
  });

export const useVatCodesRequest = (
  request: VatCodesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<VatCode>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<VatCode>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<VatCode>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<VatCode>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<VatCode>>
  >({
    ...options,
    queryFn: async () => VatCodesRequest(request),
    queryKey: ['vat-codes', 'list'],
  });
