import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { isStorageLocation, StorageLocation } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StorageLocationByStorageLocationIdRequestProps = {
  storageLocationId: Uuid;
};

export const StorageLocationByStorageLocationIdRequest = async ({
  storageLocationId,
  ...params
}: StorageLocationByStorageLocationIdRequestProps): Promise<
  AxiosResponse<StorageLocation>
> =>
  axios.get(`api/{tenantId}/locations/${storageLocationId}`, {
    params,
  });

export const useStorageLocationByStorageLocationIdRequestQuery = (
  request: StorageLocationByStorageLocationIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<StorageLocation>,
    AxiosError,
    AxiosResponse<StorageLocation>
  > = {}
): UseQueryResult<AxiosResponse<StorageLocation>, AxiosError> =>
  useQuery<
    AxiosResponse<StorageLocation>,
    AxiosError,
    AxiosResponse<StorageLocation>
  >({
    ...options,
    queryFn: async () => StorageLocationByStorageLocationIdRequest(request),
    queryKey: ['storage-locations', 'detail', request.storageLocationId],
  });

export const StorageLocationByStorageLocationIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isStorageLocation(data)) {
      queryClient.invalidateQueries({
        queryKey: ['storage-locations', 'detail', data.storageLocationId],
      });
    }
  };
