import { Integration, isIntegration } from '@bas/integration-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type IntegrationsRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<Integration>>;

export const IntegrationsRequest = async ({
  ...params
}: IntegrationsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/integrations`, {
    params: { ...params },
  });

export const useIntegrationsRequest = (
  request: IntegrationsRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => IntegrationsRequest(request),
    queryKey: ['integrations', 'list', request],
  });

export const IntegrationsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isIntegration(data)) {
    queryClient.invalidateQueries({ queryKey: ['integrations', 'list'] });
  }
};
