import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';
import { ImagePickerAsset } from 'expo-image-picker';
import mime from 'mime';

export type UploadFileToCreateAMediaObjectMutationProps = {
  file: ImagePickerAsset | File;
  forceFormData?: boolean;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
};

export type UploadFileToCreateAMediaObjectMutationResponse = {
  mediaObjectId: Uuid;
  contentUrl: string;
};

export const UploadFileToCreateAMediaObjectMutation = async ({
  file,
  forceFormData,
  onUploadProgress,
}: UploadFileToCreateAMediaObjectMutationProps): Promise<
  AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>
> => {
  const formData = new FormData();
  if (file instanceof File || forceFormData) {
    formData.append('file', file as Blob);
  } else if (file.uri.includes('base64')) {
    const base64 = await fetch(file.uri);
    const blob = await base64.blob();
    formData.append('file', blob);
  } else {
    formData.append('file', {
      uri: file.uri,
      name: file.fileName || 'image.jpg',
      type: mime.getType(file.uri) || '',
    } as unknown as Blob);
  }

  return axios.post('api/{tenantId}/media-objects', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const useUploadFileToCreateAMediaObjectMutation = (
  options: UseMutationOptions<
    AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>,
    AxiosError<ErrorResponse>,
    UploadFileToCreateAMediaObjectMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>,
  AxiosError<ErrorResponse>,
  UploadFileToCreateAMediaObjectMutationProps
> =>
  useMutation<
    AxiosResponse<UploadFileToCreateAMediaObjectMutationResponse>,
    AxiosError<ErrorResponse>,
    UploadFileToCreateAMediaObjectMutationProps
  >({
    mutationFn: UploadFileToCreateAMediaObjectMutation,
    throwOnError: false,
    ...options,
  });
