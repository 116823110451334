import { isIcon } from '@bas/svg-icons';
import { colors } from '@bas/theme';
import { SquareTile, SquareTileProps } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid, styled, Typography } from '@mui/material';
import { isValidElement, ReactElement } from 'react';

export type SquareIconTileProps = Omit<SquareTileProps, 'children'> & {
  className?: string;
  icon: IconProp | ReactElement | { prefix: string; iconName: string };
  label: string | ReactElement;
  selected?: boolean;
};

const SquareIconTile = ({
  icon,
  selected,
  label,
  ...args
}: SquareIconTileProps) => (
  <SquareTile {...args}>
    {selected && <Icon icon={faCheck} className="Bas-SquareIconTile-Check" />}
    <Grid
      container
      alignItems="flex-end"
      justifyItems="center"
      className="Bas-SquareIconTile-Grid"
    >
      <Grid item xs={12} className="Bas-SquareIconTile-Icon">
        {isValidElement(icon) && icon}
        {isIcon(icon) && <Icon icon={icon} />}
      </Grid>
      <Grid
        item
        xs={12}
        className="Bas-SquareIconTile-LabelHolder"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className="Bas-SquareIconTile-Label">{label}</Typography>
      </Grid>
    </Grid>
  </SquareTile>
);

SquareIconTile.defaultProps = {
  selected: false,
};

const StyledSquareIconTile = styled(SquareIconTile)(
  ({ theme, selected }) => `
  background: ${selected ? colors.blue[500] : colors.lila[300]};
  color: ${selected ? colors.white : colors.lila[800]};
  cursor: pointer;

  .Bas-SquareIconTile-Grid {
    height: 100%;
    padding: 16px 0;
    max-height: 158px;
  }

  .Bas-SquareIconTile-Check {
    font-size: 22px;
    position: absolute;
    top: 13px;
    left: 11px;
  }

  .Bas-SquareIconTile-Icon {
    height: 61px;
  }

  .Bas-SquareIconTile-Label {
     white-space: pre;
     font-weight: bold;
     line-height: 18px;
   }

  .Bas-SquareIconTile-LabelHolder {
     margin-top: 14px;
     height: 37px;
     display: flex;
 }
   `
);
export default StyledSquareIconTile;
