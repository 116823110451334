import { QuoteMomentLineType } from '@bas/shared/models';
import { Money } from '@bas/value-objects';
import * as Yup from 'yup';
import {
  QuoteMomentLineInputType,
  QuoteMomentLineInputTypeDefaultValues,
  QuoteMomentLineInputTypeValidationBuilder,
} from './quoteMomentLineInputType';

export interface FixedAmountQuoteMomentLineInputType
  extends QuoteMomentLineInputType {
  linePrice: Money;
  quantity: number;
}

export const FixedAmountQuoteMomentLineInputTypeDefaultValues =
  (): FixedAmountQuoteMomentLineInputType => ({
    ...QuoteMomentLineInputTypeDefaultValues(),
    lineType: QuoteMomentLineType.FIXED_AMOUNT_QUOTE_MOMENT_LINE,
    linePrice: {
      amount: 0,
      currency: 'EUR',
    },
    quantity: 1,
  });

export const FixedAmountQuoteMomentLineInputTypeValidationBuilder =
  (): Yup.ObjectSchema<FixedAmountQuoteMomentLineInputType> =>
    QuoteMomentLineInputTypeValidationBuilder().concat(
      Yup.object({
        quantity: Yup.number()
          .transform((value, original) =>
            original === '' || original === null ? null : value
          )
          .nullable()
          .required()
          .label('label.quantity'),
        linePrice: Yup.object({
          amount: Yup.number()
            .transform((value, original) =>
              original === '' || original === null ? null : value
            )
            .nullable()
            .required()
            .label('label.linePrice'),
          currency: Yup.string().required(),
        })
          .required()
          .label('label.linePrice'),
      })
    );
