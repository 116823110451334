import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basLounger: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'lounger',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M36.682 125.375c8.079-1.836 16.116 3.224 17.952 11.302l32.347 142.328h386.037c8.284 0 15 6.717 15 15 0 8.285-6.716 15.001-15 15.001H433.61l33.77 56.284c4.263 7.104 1.959 16.319-5.145 20.58-7.104 4.262-16.318 1.96-20.58-5.144l-42-70.002a15.178 15.178 0 01-.889-1.718H144.26a14.96 14.96 0 01-.887 1.718l-42.001 70.002c-4.263 7.104-13.477 9.406-20.58 5.144-7.105-4.261-9.408-13.476-5.146-20.58l33.77-56.284H75.009A15 15 0 0160.38 297.33l-35-154.003c-1.837-8.078 3.224-16.116 11.302-17.952z',
  ],
};

export default basLounger;
