import * as Yup from 'yup';
import {
  ConnectIntegrationInputType,
  ConnectIntegrationInputTypeValidationBuilder,
} from '../connectIntegrationInputType';

export interface ConnectWhatsAppIntegrationInputType
  extends ConnectIntegrationInputType {
  clientId?: string | null;
  clientSecret?: string | null;
  token?: string | null;
}

export const ConnectWhatsAppIntegrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectWhatsAppIntegrationInputType> =>
    Yup.object({
      clientId: Yup.string().label('label.clientId'),
      clientSecret: Yup.string().label('label.clientSecret'),
      token: Yup.string().label('label.token'),
    }).concat(ConnectIntegrationInputTypeValidationBuilder());
