import { GridFilterModel } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';

type UseGridFiltersToApiFiltersProps = {
  filterModel: GridFilterModel;
};

export const useGridFiltersToApiFilters = ({
  filterModel,
}: UseGridFiltersToApiFiltersProps) =>
  useMemo(() => {
    const result: Record<string, string> = {};
    Object.values(filterModel.items).forEach((filter) => {
      if (filter.value) {
        result[filter.field] = filter.value;
      }
    });

    return result;
  }, [filterModel]);
