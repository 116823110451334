import CountryService from 'i18n-iso-countries';
import { Country } from '@bas/value-objects';

let locale = 'nl';
let defaultCountry: Country = 'NL';

export const setCountryServiceLocale = (newLocale: string): void => {
  locale = newLocale;
};

export const setDefaultCountry = (newDefaultCountry: Country): void => {
  defaultCountry = newDefaultCountry;
};

export const getDefaultCountry = (): Country =>
  defaultCountry.toUpperCase() as Country;

export type CountryOption = {
  code: string;
  label: string;
};

export const getCountryOptions = (): CountryOption[] =>
  Object.keys(CountryService.getAlpha2Codes()).map((code) => ({
    code,
    label: CountryService.getName(code, locale) || code,
  }));

export const getCountryName = (country: Country): string =>
  CountryService.getName(country, locale) || country;
