import {
  EmployeeRole,
  MovingJobSkill,
  OfficeSkill,
} from '@bas/hrm-domain/models';
import {
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { useCallback } from 'react';

export type temporaryPermissions =
  | 'projects'
  | 'customers'
  | 'planning'
  | 'financial'
  | 'wms'
  | 'hrm'
  | 'communication'
  | 'employees'
  | 'declarations'
  | 'time-off-requests'
  | 'conversations'
  | 'emails'
  | 'news'
  | 'quotes'
  | 'invoices'
  | 'storages'
  | 'teams'
  | 'vehicles'
  | 'warehouses'
  | 'storage-locations'
  | 'materials'
  | 'boxes'
  | 'settings'
  | 'damages'
  | 'data'
  | 'admin'
  | 'super-admin';

const temporaryPermissionToRoleMap: Record<
  temporaryPermissions,
  (OfficeSkill | MovingJobSkill)[]
> = {
  'super-admin': [],
  communication: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  financial: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  hrm: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  wms: [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
    OfficeSkill.WAREHOUSE,
  ],
  projects: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  damages: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  employees: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  declarations: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  teams: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  'time-off-requests': [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
  ],
  conversations: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  emails: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  news: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  customers: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  quotes: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  invoices: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  planning: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  storages: [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
    OfficeSkill.WAREHOUSE,
  ],
  vehicles: [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
    OfficeSkill.WAREHOUSE,
  ],
  warehouses: [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
    OfficeSkill.WAREHOUSE,
  ],
  'storage-locations': [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
    OfficeSkill.WAREHOUSE,
  ],
  materials: [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
    OfficeSkill.WAREHOUSE,
  ],
  boxes: [
    OfficeSkill.ADMINISTRATIVE_ASSISTANT,
    OfficeSkill.PLANNER,
    OfficeSkill.WAREHOUSE,
  ],
  settings: [OfficeSkill.ADMINISTRATIVE_ASSISTANT, OfficeSkill.PLANNER],
  data: [],
  admin: [],
};

const rolesToPermissionsMap: {
  [key: string]: string[];
} = {
  data: [EmployeeRole.ROLE_DATA, EmployeeRole.ROLE_ADMIN],
  admin: [EmployeeRole.ROLE_ADMIN],
};

export const useCheckPermissions = () => {
  const [getTenantRoles, roles] = useUserStore((state) => [
    state.getTenantRoles,
    state.user?.roles,
  ]);

  const tenantId = useTenantStore((state) => state.tenant?.tenantId);
  const employeeSkills = useEmployeeStore(
    (state) => state.employee?.employeeSkills
  );

  return useCallback(
    (permissionNeeded: temporaryPermissions) => {
      if (!tenantId) {
        return false;
      }

      const tenantRoles = getTenantRoles(tenantId);

      if ((roles || []).includes('ROLE_SUPER_ADMIN')) {
        return true;
      }

      if (rolesToPermissionsMap[permissionNeeded]) {
        return (tenantRoles || []).some((role) =>
          rolesToPermissionsMap[permissionNeeded].includes(role)
        );
      }

      const skillsNeeded = temporaryPermissionToRoleMap[permissionNeeded];

      if (
        employeeSkills &&
        !!employeeSkills.find((skill) => skillsNeeded.includes(skill))
      ) {
        return true;
      }

      return (
        (roles || []).includes('ROLE_SUPER_ADMIN') ||
        tenantRoles?.indexOf(EmployeeRole.ROLE_ADMIN) !== -1
      );
    },
    [getTenantRoles, employeeSkills, roles, tenantId]
  );
};
