import { KingFinanceSettingsInputType } from '@bas/integration-domain/input-types';
import {
  CostCenterMappingType,
  GeneralLedgerMappingType,
} from '@bas/integration-domain/models';
import { useKingFinanceMappingOptions } from '@bas/integration-domain/web/hooks';
import { useTenantStore } from '@bas/shared/state';
import { Alert, TextFieldNumberFormat } from '@bas/ui/web/atoms';
import {
  ReactHookFormAutocomplete,
  ReactHookFormDatePickerField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { MappingTableExternalOption, Uuid } from '@bas/value-objects';
import { Grid, MenuItem, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CostCenterMappingForm } from '../CostCenterMappingForm';
import { GeneralLedgerMappingForm } from '../GeneralLedgerMappingForm';
import { VatMappingForm } from '../VatMappingForm';

export type KingFinanceSettingsFormProps = {
  integrationId: Uuid;
};

const KingFinanceSettingsForm = ({
  integrationId,
}: KingFinanceSettingsFormProps): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);
  const [generalLedgerMappingType, costCenterMappingType] = useWatch<
    KingFinanceSettingsInputType,
    ['generalLedgerMappingType', 'costCenterMappingType']
  >({
    name: ['generalLedgerMappingType', 'costCenterMappingType'],
  });

  const {
    generalLedgersError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsExternalOptions,
    journalsError,
  } = useKingFinanceMappingOptions(integrationId);

  return (
    <Grid container columnSpacing={3} rowSpacing={3} pt={1}>
      {(generalLedgersError ||
        departmentsError ||
        journalsError ||
        vatCodesError) && (
        <Grid item xs={12} mt={2}>
          <Alert severity="error">
            <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
          </Alert>
        </Grid>
      )}
      <Grid item xs={6}>
        <Controller
          name="startDate"
          render={(registered) => (
            <ReactHookFormDatePickerField
              {...registered}
              textFieldProps={{
                fullWidth: true,
              }}
              label={<FormattedMessage id="label.startDate" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="leadingNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.leadingNumber" />}
              InputProps={{
                inputComponent: TextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 0,
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="generalLedgerMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.generalLedgerMappingType" />}
              select
            >
              {Object.values(GeneralLedgerMappingType)
                .filter(
                  (option) =>
                    tenantState?.useIdentities ||
                    option !== GeneralLedgerMappingType.PER_IDENTITY
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage
                      id={`generalLedgerMappingTypes.${option}`}
                    />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="costCenterMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.costCenterMappingType" />}
              select
            >
              {Object.values(CostCenterMappingType)
                .filter(
                  (option) =>
                    tenantState?.useIdentities ||
                    option !== CostCenterMappingType.PER_IDENTITY
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage id={`costCenterMappingTypes.${option}`} />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="defaultGeneralLedger"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.defaultGeneralLedger" />}
              select
            >
              <MenuItem value="">
                <FormattedMessage id="label.none" />
              </MenuItem>
              {generalLedgerExternalOptions.map(({ externalId, label }) => (
                <MenuItem key={externalId} value={externalId}>
                  {label}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="contraGeneralLedger"
          render={(registered) => (
            <ReactHookFormAutocomplete
              {...registered}
              fullWidth
              textField={{
                label: <FormattedMessage id="label.contraGeneralLedger" />,
              }}
              identifier="externalId"
              options={generalLedgerExternalOptions}
              getOptionLabel={(option: string | MappingTableExternalOption) => {
                if (typeof option === 'string') {
                  const found = generalLedgerExternalOptions.find(
                    ({ externalId }) => externalId === option
                  );

                  return found?.label || option;
                }

                return option.label;
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="salesJournalCode"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.salesJournal" />}
              select
            >
              {journalsExternalOptions.map((journal) => (
                <MenuItem key={journal.externalId} value={journal.externalId}>
                  {journal.label}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
      <Grid item xs={12} container spacing={4} mt={4}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="Bas-ContentSection-DontChangeColor"
            pb={2}
          >
            <FormattedMessage id="settings.integrationDetails.generalLedgerMapping" />
          </Typography>
          <GeneralLedgerMappingForm
            generalLedgerMappingType={generalLedgerMappingType}
            externalOptions={generalLedgerExternalOptions}
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={costCenterMappingType === CostCenterMappingType.NONE}
        >
          <Typography
            variant="subtitle1"
            className="Bas-ContentSection-DontChangeColor"
            pb={2}
          >
            <FormattedMessage id="settings.integrationDetails.costCenterMapping" />
          </Typography>
          <CostCenterMappingForm
            costCenterMappingType={costCenterMappingType}
            externalOptions={costCenterExternalOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="Bas-ContentSection-DontChangeColor"
            pb={2}
          >
            <FormattedMessage id="settings.integrationDetails.vatMapping" />
          </Typography>
          <VatMappingForm externalOptions={vatExternalOptions} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KingFinanceSettingsForm;
