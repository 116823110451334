export const colors = {
  white: '#fff',
  black: '#000',
  blue: {
    100: '#F0F5FF',
    200: '#ACC6F9',
    500: '#3A78F2',
    700: '#1249B7',
    800: '#0C40A6',
    900: '#021B4E',
  },
  lila: {
    100: '#FAFAFB',
    200: '#F2F3F8',
    300: '#E6E7EE',
    400: '#DADCE4',
    600: '#A2A1AE',
    700: '#7A7887',
    800: '#464457',
  },
  green: {
    300: '#8EDA96',
    500: '#00A74A',
    800: '#006B58',
  },
  gray: {
    200: '#C4C4C4',
    400: '#A3A1AE',
    600: '#464457',
  },
  red: {
    200: '#F7D8D8',
    300: '#F2BFBF',
    400: '#EB0000',
    500: '#e54e44',
    600: '#d52a1e',
    700: '#C24F4F',
  },
  yellow: {
    100: '#FFF7E5',
    700: '#FFBF47',
    800: '#FFA600',
  },
  orange: {
    300: '#FFEEDA',
    600: '#FFC785',
    700: '#FFAF66',
    750: '#FFA04D',
    800: '#AF5F00',
    900: '#804500',
  },
  pink: {
    200: '#FFE5E6',
  },
};
