import { HoursOverview } from '@bas/planning-domain/models';
import { calculatePercentagesFromHours, formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Tooltip } from '@bas/ui/web/atoms';
import { Box, BoxProps, Grid, styled } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, ReactElement, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StackedLinearProgress } from '../StackedLinearProgress';

export type MaterialBoxProps = BoxProps & {
  className?: string;
  name: ReactNode;
  licensePlate?: string;
  empty?: boolean;
  light?: boolean;
  isActive?: boolean;
  hours?: HoursOverview;
};

const MaterialBox = forwardRef<unknown, MaterialBoxProps>(
  (
    {
      name,
      licensePlate,
      empty,
      className,
      light,
      isActive,
      hours,
      ...props
    }: MaterialBoxProps,
    ref
  ): ReactElement => {
    let percentages:
      | {
          availablePercentage: number;
          reservedPercentage: number;
          confirmedPercentage: number;
        }
      | undefined;
    if (hours) {
      percentages = calculatePercentagesFromHours(
        hours.totalHoursOfDay,
        hours.totalConfirmedHours,
        hours.totalReservedHours
      );
    }

    const stackedLinearProgressValues = useMemo(() => {
      if (!hours || !percentages) {
        return undefined;
      }

      return [
        {
          value: percentages.reservedPercentage,
          color: colors.yellow[700],
        },
        {
          value:
            percentages.confirmedPercentage > 0
              ? percentages.confirmedPercentage + percentages.reservedPercentage
              : 0,
          color: colors.blue[500],
        },
        {
          value: 100 - percentages.availablePercentage,
          color: colors.red[200],
        },
      ];
    }, [percentages, hours]);

    const content = (
      <Box
        {...props}
        ref={ref}
        className={clsx(
          className,
          'Bas-MaterialBox',
          { 'Bas-MaterialBox--Light': light },
          { 'Bas-MaterialBox--Active': isActive }
        )}
      >
        {name}
        {stackedLinearProgressValues && (
          <Box className="Bas-MaterialBox-Progress">
            <StackedLinearProgress
              values={[
                ...stackedLinearProgressValues,
                {
                  value: 100,
                  color: '#bdbdbd',
                },
              ].filter(({ value }) => value > 0)}
            />
          </Box>
        )}
      </Box>
    );

    if (isActive) {
      return content;
    }

    return (
      <Tooltip
        disabled={empty}
        title={
          <Grid container>
            <Grid item xs={12} fontWeight="bold">
              {name}
            </Grid>
            {licensePlate && (
              <Grid item xs={12}>
                {licensePlate}
              </Grid>
            )}
            {hours && (
              <>
                <Grid item xs={8} pt={2}>
                  <FormattedMessage id="label.availableHours" />
                </Grid>
                <Grid item xs={4} pt={2}>
                  + {formatHours(hours.totalHoursOfDay)}
                </Grid>
                {hours.realReservedHours > 0 && (
                  <>
                    <Grid item xs={8}>
                      <FormattedMessage id="label.reservedHours" />
                    </Grid>
                    <Grid item xs={4}>
                      - {formatHours(hours.realReservedHours)}
                    </Grid>
                  </>
                )}
                {hours.realConfirmedHours > 0 && (
                  <>
                    <Grid item xs={8}>
                      <FormattedMessage id="label.confirmedHours" />
                    </Grid>
                    <Grid item xs={4}>
                      - {formatHours(hours.realConfirmedHours)}
                    </Grid>
                  </>
                )}
                {hours.totalShortageHours < 0 ? (
                  <>
                    <Grid item xs={8} fontWeight="bold">
                      <FormattedMessage id="label.shortageHours" />
                    </Grid>
                    <Grid item xs={4} fontWeight="bold">
                      = {formatHours(Math.abs(hours.totalShortageHours))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={8} fontWeight="bold">
                      <FormattedMessage id="label.plannableHours" />
                    </Grid>
                    <Grid item xs={4} fontWeight="bold">
                      = {formatHours(hours.totalAvailableHours)}
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        }
      >
        {content}
      </Tooltip>
    );
  }
);

const StyledMaterialBox = styled(MaterialBox)`
  height: 34px;
  width: 100px;
  max-width: 100px;
  background: #e6e7ee;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px;
  position: relative;

  &.Bas-MaterialBox--Light {
    background: #ffffff;
    color: ${colors.lila[800]};
  }

  &.Bas-MaterialBox--Active {
    border: 2px solid #3a78f2;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }

  .Bas-MaterialBox-Progress {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 4px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
export default StyledMaterialBox;
