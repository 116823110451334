import { AutocompleteValue } from '@mui/base';
import { ChipTypeMap } from '@mui/material/Chip';
import * as React from 'react';
import { FocusEventHandler, ReactElement, SyntheticEvent } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { Autocomplete, AutocompleteProps } from '../Autocomplete';

export interface ReactHookFormAutocompleteProps<
  T,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'name' | 'value' | 'error'
  > {
  identifier?: string;
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
  saveOnBlur?: boolean;
  overrideValue?:
    | AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
    | undefined;
}

type Option = {
  [identifier: string]: unknown;
};

function isOption(object: unknown, idField: string): object is Option {
  return Object.prototype.hasOwnProperty.call(object, idField);
}

const isOptionEqualToValue =
  <T = Option | unknown,>(identifier: string) =>
  (option: T, currentValue: T): boolean => {
    if (
      !!option &&
      typeof option === 'object' &&
      identifier in option &&
      currentValue &&
      isOption(currentValue, identifier)
    ) {
      return (
        option[identifier as keyof typeof option] ===
        currentValue[identifier as keyof typeof currentValue]
      );
    }

    if (
      typeof option[identifier as keyof typeof option] !== 'undefined' &&
      currentValue
    ) {
      return option[identifier as keyof typeof option] === currentValue;
    }

    return false;
  };

const fieldToAutocomplete = <
  T = Option,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({
  disabled,
  identifier,
  field: { onChange, onBlur, value },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  helperText,
  multiple,
  overrideValue,
  saveOnBlur,
  ...props
}: ReactHookFormAutocompleteProps<
  T,
  TFieldValues,
  TName,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>): AutocompleteProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
> => {
  const fieldError = error?.message;
  const showError = invalid;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let handleChange: any = (e: SyntheticEvent, newValue: T) => {
    onChange(newValue);
  };

  let handleBlur: FocusEventHandler<HTMLInputElement> | undefined = onBlur;
  if (identifier) {
    handleChange = (e: SyntheticEvent, newValue: T) => {
      if (multiple) {
        onChange(
          newValue && Array.isArray(newValue)
            ? newValue.map((option) => option[identifier])
            : []
        );
      } else {
        onChange(
          newValue &&
            !Array.isArray(newValue) &&
            typeof newValue === 'object' &&
            identifier in newValue
            ? newValue[identifier as keyof typeof newValue]
            : ''
        );
      }
    };
  } else if (multiple) {
    handleChange = (e: SyntheticEvent, newValue: T) => {
      onChange(newValue);
    };
    handleBlur = (e) => {
      onBlur?.();
      if (saveOnBlur && !!e.target.value) {
        onChange([...value, e.target.value]);
      }
    };
  }

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur: handleBlur,
    value: (overrideValue || value || null) as AutocompleteValue<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >,
    multiple,
    isOptionEqualToValue: isOptionEqualToValue<T>(identifier || ''),
    onChange: handleChange,
    clearOnBlur: saveOnBlur,
    ...props,
  };
};

const ReactHookFormAutocomplete = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T = Option | any,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({
  ...args
}: ReactHookFormAutocompleteProps<
  T,
  TFieldValues,
  TName,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>): ReactElement => <Autocomplete {...fieldToAutocomplete(args)} />;

export default ReactHookFormAutocomplete;
