import { QuoteMomentLineType } from '@bas/shared/models';
import { Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface QuoteMomentLineInputType {
  description: string;
  optional: boolean;
  hourlyLine: boolean;
  vatCodeId: Uuid;
  turnoverGroupId?: Uuid | null;
  lineType: QuoteMomentLineType;
}

export const QuoteMomentLineInputTypeDefaultValues =
  (): QuoteMomentLineInputType => ({
    description: '',
    optional: false,
    hourlyLine: false,
    vatCodeId: '',
    turnoverGroupId: '',
    lineType: QuoteMomentLineType.CALCULATED_QUOTE_MOMENT_LINE,
  });

export const QuoteMomentLineInputTypeValidationBuilder =
  (): Yup.ObjectSchema<QuoteMomentLineInputType> =>
    Yup.object({
      description: Yup.string().required().ensure().label('label.description'),
      optional: Yup.bool().required().label('label.optional'),
      hourlyLine: Yup.bool().required().label('label.hourlyLine'),
      vatCodeId: Yup.string().required().ensure().label('label.vatCodeId'),
      turnoverGroupId: Yup.string().nullable().label('label.turnoverGroupId'),
      lineType: Yup.mixed<QuoteMomentLineType>()
        .oneOf(Object.values(QuoteMomentLineType))
        .required()
        .label('label.lineType'),
    });
