import { faBox } from '@fortawesome/pro-light-svg-icons/faBox';
import { faCar } from '@fortawesome/pro-light-svg-icons/faCar';
import { faTruck } from '@fortawesome/pro-light-svg-icons/faTruck';
import { faForklift } from '@fortawesome/pro-light-svg-icons/faForklift';
import { MaterialType } from '../types';

export const materialTypeIconMap = {
  [MaterialType.MATERIAL]: faBox,
  [MaterialType.VEHICLE]: faCar,
  [MaterialType.MOVING_TRUCK]: faTruck,
  [MaterialType.MOVING_LIFT]: faForklift,
};
