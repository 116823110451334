import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basChestOfDrawers: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'chest-of-drawers',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M210 251c0-8.3 6.7-15 15-15h62c8.3 0 15 6.7 15 15s-6.7 15-15 15H225c-8.3 0-15-6.7-15-15zm15 113c-8.3 0-15 6.7-15 15s6.7 15 15 15h62c8.3 0 15-6.7 15-15s-6.7-15-15-15H225zm0-259.5c-8.3 0-15 6.7-15 15s6.7 15 15 15h62c8.3 0 15-6.7 15-15s-6.7-15-15-15H225zM24 41C15.7 41 9 47.7 9 56V446c0 8.3 6.7 15 15 15h81l6 42h36l6-42H359l6 42h36l6-42h81c8.3 0 15-6.7 15-15V56c0-8.3-6.7-15-15-15H24zM39 301V201H473V301H39zm0 30H473V431H39V331zM473 171H39V71H473V171z',
  ],
};

export default basChestOfDrawers;
