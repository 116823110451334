import {
  isOctopusSettings,
  OctopusSettings,
} from '@bas/integration-domain/models';
import { useOctopusMappingOptions } from '@bas/integration-domain/web/hooks';
import { Alert } from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountingIntegrationSettingsBlock } from '../AccountingIntegrationSettingsBlock';

export type OctopusSettingsContentSectionProps = {
  integrationId: Uuid;
  providerSettings: OctopusSettings;
  onEdit: () => void;
};

const OctopusSettingsContentSection = ({
  integrationId,
  providerSettings,
  onEdit,
}: OctopusSettingsContentSectionProps): ReactElement => {
  const {
    dossiersData,
    dossiersError,
    generalLedgersError,
    generalLedgerExternalOptions,
    costCentersError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsExternalOptions,
    journalsError,
  } = useOctopusMappingOptions(integrationId, providerSettings.dossierId);

  const dossier = useMemo(() => {
    if (!isOctopusSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.dossierId) {
      return undefined;
    }

    return (dossiersData?.data?.['hydra:member'] || []).find(
      ({ dossierKey }) => dossierKey === providerSettings?.dossierId
    );
  }, [providerSettings, dossiersData?.data]);

  const salesJournal = useMemo(() => {
    if (!isOctopusSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.salesJournalCode) {
      return undefined;
    }

    return journalsExternalOptions.find(
      ({ externalId }) => externalId === providerSettings?.salesJournalCode
    );
  }, [providerSettings, journalsExternalOptions]);

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        {(dossiersError ||
          generalLedgersError ||
          costCentersError ||
          vatCodesError ||
          journalsError) && (
          <Grid item xs={12} mt={2}>
            <Alert severity="error">
              <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
            </Alert>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.administration" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isOctopusSettings(providerSettings) && dossier ? (
              dossier.dossierDescription
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.salesJournal" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isOctopusSettings(providerSettings) && salesJournal ? (
              salesJournal.label
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isOctopusSettings(providerSettings) && (
            <AccountingIntegrationSettingsBlock
              providerSettings={providerSettings}
              externalGeneralLedgers={generalLedgerExternalOptions}
              externalCostCenters={costCenterExternalOptions}
              externalVatCodes={vatExternalOptions}
            />
          )}
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default OctopusSettingsContentSection;
