import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { isTenant, PublicTenant } from '@bas/tenant-domain/models';
import { ErrorResponse, isObjectResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TenantByHostnameRequestProps = {
  hostname: string;
};

type Response = AxiosResponse<PublicTenant>;

export const TenantByHostnameRequest = async ({
  hostname,
}: TenantByHostnameRequestProps): Promise<Response> =>
  axios.get(`/api/public/tenants/${hostname}`);

export const useTenantByHostnameRequest = (
  request: TenantByHostnameRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TenantByHostnameRequest({ ...request }),
    queryKey: ['public-tenant', request.hostname],
  });

export const TenantByHostnameRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isObjectResponse(data) && data['@type'] === 'Tenant') {
    queryClient.invalidateQueries({ queryKey: ['public-tenant'] });
  }
  if (isTenant(data)) {
    queryClient.invalidateQueries({ queryKey: ['public-tenant'] });
  }
};
