import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basDesk: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'desk',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M317 217c-8.3 0-15 6.7-15 15s6.7 15 15 15h89c8.3 0 15-6.7 15-15s-6.7-15-15-15H317zM24 54C15.7 54 9 60.7 9 69V488c0 8.3 6.7 15 15 15s15-6.7 15-15V169H219V313c0 8.3 6.7 15 15 15H473V488c0 8.3 6.7 15 15 15s15-6.7 15-15V69c0-8.3-6.7-15-15-15H24zM249 298V169H473V298H249zM473 139H39V84H473v55z',
  ],
};

export default basDesk;
