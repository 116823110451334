import {
  HoursInsight,
  isTimeEntry,
  isTimeOffRegistration,
} from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type HoursInsightByEmployeeIdRequestProps = Pagination &
  Filterable & {
    employeeId: Uuid;
    startDate: Date;
    endDate: Date;
  };

export const HoursInsightByEmployeeIdRequest = async ({
  employeeId,
  startDate,
  endDate,
  ...params
}: HoursInsightByEmployeeIdRequestProps): Promise<
  AxiosResponse<Collection<HoursInsight>>
> =>
  axios.get(
    `api/{tenantId}/hrm/employees/${employeeId}/hours-insight/${formatDate(
      startDate
    )}/${formatDate(endDate)}`,
    {
      params: {
        ...params,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      },
    }
  );

export const useHoursInsightByEmployeeIdRequest = (
  request: HoursInsightByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<HoursInsight>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<HoursInsight>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<HoursInsight>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<HoursInsight>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<HoursInsight>>
  >({
    ...options,
    queryFn: async () => HoursInsightByEmployeeIdRequest(request),
    queryKey: ['hours-insight', 'list', request.employeeId, request],
  });

export const HoursInsightByEmployeeIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTimeEntry(data) || isTimeOffRegistration(data)) {
    queryClient.invalidateQueries({
      queryKey: ['hours-insight', 'list', data.employeeId],
    });
  }
};
