import {
  CommunicationsRequestInvalidator,
  CustomersRequestInvalidator,
  DocumentsRequestInvalidator,
  RelationByRelationIdRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  CommunicationsRequestInvalidator,
  CustomersRequestInvalidator,
  DocumentsRequestInvalidator,
  RelationByRelationIdRequestInvalidator,
];
