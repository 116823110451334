export enum InvoiceEventType {
  SendingInvoiceFailedEvent = 'sending-invoice-failed-event',
  InvoiceExpiredEvent = 'invoice-expired-event',
  InvoiceFinalizedEvent = 'invoice-finalized-event',
  InvoicePaidEvent = 'invoice-paid-event',
  InvoicePaymentProcessedEvent = 'invoice-payment-processed-event',
  InvoiceReminderSentEvent = 'invoice-reminder-sent-event',
  InvoiceScheduledEvent = 'invoice-scheduled-event',
  InvoiceSentEvent = 'invoice-sent-event',
  InvoicePaymentRemovedEvent = 'invoice-payment-removed-event',
  InvoiceWasNotPaidEvent = 'invoice-was-not-paid-event',
}
