export enum InternalServiceType {
  PROVIDE_BOXES = 'provide_boxes',
  PACKING = 'packing',
  UNPACK = 'unpack',
  ASSEMBLE = 'assemble',
  DISASSEMBLE = 'disassemble',
  MOVING_LIFT = 'moving-lift',
  WASTE_DISPOSAL = 'waste-disposal',
  PERMITS = 'permits',
  STORAGE = 'storage',
  INSURANCE = 'insurance',
  CERTIFICATE = 'certificate',
}
