import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faIntercom } from '@fortawesome/free-brands-svg-icons';
import { faCarCrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { DarkCenteredTemplate } from '../DarkCenteredTemplate';

export type ErrorFallbackTemplateProps = {
  className?: string;
  title: string | ReactNode;
  description: string | ReactNode;
  resetError: () => void;
  requestHelp?: () => void;
};

const ErrorFallbackTemplate = ({
  className,
  title,
  description,
  resetError,
  requestHelp,
}: ErrorFallbackTemplateProps) => (
  <DarkCenteredTemplate className={className}>
    <Box className="Bas-ErrorFallbackTemplate-CrashIcon">
      <FontAwesomeIcon icon={faCarCrash} />
    </Box>
    <Box className="Bas-ErrorFallbackTemplate-TitleHolder">
      <Typography className="Bas-ErrorFallbackTemplate-Title" variant="h3">
        {title}
      </Typography>
    </Box>
    <Box className="Bas-ErrorFallbackTemplate-DescriptionHolder">
      <Typography className="Bas-ErrorFallbackTemplate-Description">
        {description}
      </Typography>
    </Box>
    <Grid container spacing={2} className="Bas-ErrorFallbackTemplate-Actions">
      <Grid item>
        <Button
          onClick={resetError}
          className="Bas-ErrorFallbackTemplate-Action"
        >
          <FormattedMessage id="errorFallback.tryAgain" />
        </Button>
      </Grid>
      {requestHelp && (
        <Grid item>
          <Button
            onClick={requestHelp}
            className="Bas-ErrorFallbackTemplate-Action"
          >
            <Icon icon={faIntercom} />
            &nbsp;
            <FormattedMessage id="errorFallback.iNeedHelp" />
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          onClick={resetError}
          variant="outlined"
          className="Bas-ErrorFallbackTemplate-Action"
        >
          <FormattedMessage id="errorFallback.backToHome" />
        </Button>
      </Grid>
    </Grid>
  </DarkCenteredTemplate>
);

const StyledErrorFallbackTemplate = styled(ErrorFallbackTemplate)(
  ({ theme }) => `

  .Bas-DarkCenteredTemplate-Content {
    max-width: 400px;
    ${theme.breakpoints.only('xs')} {
        padding: 0 20px;
    }
  }

  .Bas-ErrorFallbackTemplate-CrashIcon {
    font-size: 104px;
    text-align: center;
  }

  .Bas-ErrorFallbackTemplate-TitleHolder {
    text-align: center;
    margin-bottom: ${theme.spacing(4)};
    white-space: nowrap;
  }

  .Bas-ErrorFallbackTemplate-DescriptionHolder {
    margin-bottom: ${theme.spacing(2)};
  }
`
);
export default StyledErrorFallbackTemplate;
