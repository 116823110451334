import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basPaperclip: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'paperclip',
  icon: [
    272,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M168 200c0-13.254-10.745-24-24-24s-24 10.746-24 24h48zm-48 223.14c0 18.494-14.671 32.86-32 32.86v48c44.527 0 80-36.55 80-80.86h-48zM88 456c-17.329 0-32-14.366-32-32.86H8C8 467.45 43.473 504 88 504v-48zm-32-32.86V144H8v279.14h48zm112 0V200h-48v223.14h48zM56 144c0-50.307 37.461-88 80-88V8C63.664 8 8 70.595 8 144h48zm80-88c42.54 0 80 37.693 80 88h48C264 70.595 208.336 8 136 8v48z',
  ],
};

export default basPaperclip;
