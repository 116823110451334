import {
  CalculatedTotalsForAcceptanceQuote,
  QuoteLine,
} from '@bas/financial-domain/models';
import {
  AcceptingFinancialDocumentLineTableRow,
  FinancialDocumentTotalTableRow,
} from '@bas/financial-domain/web/molecules';
import { Table, TableBody, TableFooter } from '@bas/ui/web/atoms';
import { Uuid } from '@bas/value-objects';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type DesktopQuoteTableForAcceptanceProps = {
  setAcceptedLines: (newValue: Uuid[]) => void;
  reloadTotals: (newValue: Uuid[]) => Promise<void> | void;
  loadingTotals?: boolean;
  totals: CalculatedTotalsForAcceptanceQuote;
  hasOptionalLines: boolean;
  showTotals?: boolean;
  showQuantity?: boolean;
  showTotalPerLine?: boolean;
  acceptedLines: Uuid[];
  lines: QuoteLine[];
};

const DesktopQuoteTableForAcceptance = ({
  setAcceptedLines,
  reloadTotals,
  loadingTotals,
  totals,
  hasOptionalLines,
  acceptedLines,
  showTotals,
  showQuantity,
  showTotalPerLine,
  lines,
}: DesktopQuoteTableForAcceptanceProps): ReactElement => (
  <Table>
    <TableBody>
      {lines.map((line) => (
        <AcceptingFinancialDocumentLineTableRow
          key={line.lineId}
          showCheckbox={hasOptionalLines}
          line={line}
          acceptedLines={acceptedLines}
          showQuantity={!!showQuantity}
          showTotal={!!showTotalPerLine}
          onCheck={() => {
            const newValue = [...acceptedLines];
            const index = newValue.indexOf(line.lineId);

            if (index === -1) {
              newValue.push(line.lineId);
            } else {
              newValue.splice(index, 1);
            }

            reloadTotals(newValue);
            setAcceptedLines(newValue);
          }}
        />
      ))}
    </TableBody>
    {showTotals && (
      <TableFooter noBorder dense>
        <FinancialDocumentTotalTableRow
          isLoadingValue={loadingTotals}
          description={<FormattedMessage id="label.subTotal" />}
          value={totals.totalExcludingVat}
        />
        {totals.vatAmounts
          .sort(
            ({ vatPercentage: vatA }, { vatPercentage: vatB }) =>
              parseInt(vatA, 10) - parseInt(vatB, 10)
          )
          .map(({ vatPercentage, vatAmount }) => (
            <FinancialDocumentTotalTableRow
              key={vatPercentage}
              isLoadingValue={loadingTotals}
              description={
                <>
                  {`${vatPercentage}% `}
                  <FormattedMessage id="label.vat" />
                </>
              }
              value={vatAmount}
            />
          ))}
        <FinancialDocumentTotalTableRow
          bold
          isLoadingValue={loadingTotals}
          description={<FormattedMessage id="label.total" />}
          value={totals.totalIncludingVat}
        />
      </TableFooter>
    )}
  </Table>
);

DesktopQuoteTableForAcceptance.defaultProps = {
  showTotals: true,
  showQuantity: true,
  showTotalPerLine: true,
};

export default DesktopQuoteTableForAcceptance;
