import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basGardenCushions: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'garden-cushions',
  icon: [
    512,
    512,
    [],
    'e001',
    'M91.835 77.754c25.643-6.395 75.951-14.76 163.798-14.76 87.625 0 137.9 8.323 163.602 14.712.15-.135.302-.269.454-.402 5.745-4.983 12.842-7.947 19.936-8.63 6.99-.675 15.24.814 21.797 6.503 6.886 5.974 9.408 14.454 8.394 22.37-.95 7.423-4.865 14.052-10.327 18.982 7.283 23.963 15.776 67.274 15.776 139.014 0 70.645-8.235 113.722-15.442 137.9 5.288 4.898 9.061 11.406 9.993 18.677 1.014 7.916-1.508 16.396-8.394 22.37-6.556 5.69-14.807 7.178-21.797 6.503-6.757-.65-13.518-3.37-19.108-7.934-25.298 6.428-75.781 15.033-164.884 15.033-89.328 0-139.84-8.649-165.075-15.082-5.602 4.594-12.387 7.33-19.167 7.983-6.991.675-15.242-.814-21.799-6.502-6.885-5.975-9.407-14.455-8.393-22.37.942-7.362 4.799-13.941 10.189-18.858C44.194 369.05 36 326.013 36 255.543c0-71.565 8.451-114.84 15.723-138.838-5.565-4.948-9.562-11.646-10.524-19.158-1.014-7.916 1.508-16.397 8.393-22.37 6.557-5.69 14.808-7.178 21.799-6.504 7.093.684 14.19 3.648 19.935 8.631.171.149.34.299.509.45zm163.798 15.241c-99.013 0-147.34 10.863-165.107 16.209l-4.869 1.465-1.814 4.715C77.768 131.174 66 172.495 66 255.543c0 81.138 11.232 122.45 17.414 139.031l2.163 5.8 4.39 1.34c17.297 5.281 65.59 16.378 165.665 16.378 99.701 0 148.007-11.014 165.47-16.32l4.578-1.39 2.145-5.744c6.177-16.538 17.439-57.85 17.439-139.095 0-83.15-11.797-124.472-17.865-140.217l-1.799-4.667-5.062-1.515c-17.93-5.367-66.263-16.149-164.906-16.149z',
  ],
};

export default basGardenCushions;
