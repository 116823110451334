import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

type IntercomStoreType = {
  bootedIntercom: boolean;
  pushToken?: string;
  setPushToken: (pushToken: string) => void;
  setBootedIntercom: (bootedIntercom: boolean) => void;
  logout: () => void;
};

export const useIntercomStore = create<IntercomStoreType>()(
  persist(
    (set) => ({
      bootedIntercom: false,
      pushToken: undefined,
      setPushToken: (pushToken: string) => set({ pushToken }),
      setBootedIntercom: (bootedIntercom: boolean) => set({ bootedIntercom }),
      logout: () => set({ pushToken: undefined, bootedIntercom: false }),
    }),
    {
      name: 'intercom-store',
      storage: zustandStorage<IntercomStoreType>(),
    }
  )
);
