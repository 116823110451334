import { List, ListProps, styled } from '@mui/material';
import { ReactElement } from 'react';
import {
  MaterialListItem,
  MaterialListItemProps,
  MaterialListItemVariant,
} from '../MaterialListItem';

export type MaterialListProps = ListProps & {
  materialItems: MaterialListItemProps[];
  variant?: MaterialListItemVariant;
  onClickMaterial?: (index: number) => void;
};

const MaterialList = ({
  materialItems,
  children,
  variant,
  onClickMaterial,
  ...args
}: MaterialListProps): ReactElement => (
  <List {...args}>
    {materialItems.map(
      ({ variant: materialItemVariant, ...materialItem }, index) => (
        <MaterialListItem
          variant={variant || materialItemVariant}
          {...materialItem}
          onClick={
            materialItem.onClick ||
            (onClickMaterial ? () => onClickMaterial(index) : undefined)
          }
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        />
      )
    )}
    {children}
  </List>
);

MaterialList.defaultProps = {
  variant: undefined,
  onClickMaterial: undefined,
  materialItems: [],
};

const StyledMaterialList = styled(MaterialList)(
  () => `
  padding: 0;
`
);
export default StyledMaterialList;
