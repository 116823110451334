import { useUploadFile } from '@bas/media-domain/utils';
import { Button } from '@bas/ui/web/atoms';
import { ReactHookFormTextField } from '@bas/ui/web/molecules';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const TenantOrIdentityInformationForm = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement => {
  const { setValue } = useFormContext();
  const { handleUpload, uploadingFile } = useUploadFile('logoId', setValue);

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={6}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="upload-file">
          <input
            accept="image/*"
            id="upload-file"
            style={{ display: 'none' }}
            type="file"
            onChange={handleUpload}
            disabled={
              uploadingFile &&
              uploadingFile.progress !== 100 &&
              !uploadingFile.failed
            }
          />
          <Button
            component="span"
            fullWidth
            disabled={
              uploadingFile &&
              uploadingFile.progress !== 100 &&
              !uploadingFile.failed
            }
            loading={
              uploadingFile &&
              uploadingFile.progress !== 100 &&
              !uploadingFile.failed
            }
          >
            <FormattedMessage id="button.uploadLogo" />
          </Button>
        </label>
      </Grid>
      <Grid item xs={6} />
      {uploadingFile && (
        <Grid item xs={12}>
          <img
            src={URL.createObjectURL(uploadingFile.file)}
            alt={uploadingFile.file.name}
            style={{ maxWidth: '350px', maxHeight: '350px' }}
          />
          <Typography>{uploadingFile.file.name}</Typography>
          {!uploadingFile.success && uploadingFile.failed && (
            <FormHelperText error={uploadingFile.failed}>
              {uploadingFile.reason}
            </FormHelperText>
          )}
        </Grid>
      )}
      <Grid item xs={6}>
        <Controller
          name="companyName"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              autoComplete="off"
              fullWidth
              label={<FormattedMessage id="label.companyName" />}
            />
          )}
        />
      </Grid>
      {children}
      <Grid item xs={6}>
        <Controller
          name="cocNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              autoComplete="off"
              fullWidth
              label={<FormattedMessage id="label.cocNumber" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="vatNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              autoComplete="off"
              fullWidth
              label={<FormattedMessage id="label.vatNumber" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="rsinNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              autoComplete="off"
              fullWidth
              label={<FormattedMessage id="label.rsinNumber" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="ibanNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              autoComplete="off"
              fullWidth
              label={<FormattedMessage id="label.ibanNumber" />}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

TenantOrIdentityInformationForm.defaultProps = {
  children: undefined,
};

export default TenantOrIdentityInformationForm;
