import { colors } from '@bas/theme';
import { Box, BoxProps, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

export type SquareTileProps = BoxProps & {
  className?: string;
  children: ReactNode;
};

const SquareTile = ({ children, className, ...args }: SquareTileProps) => (
  <Box className={clsx(className, 'Bas-SquareTile-Box')} {...args}>
    {children}
  </Box>
);

const StyledSquareTile = styled(SquareTile)(
  ({ theme }) => `
  background: ${colors.lila[300]};
  color: ${colors.lila[800]};
  border-radius: 6px;
  padding: ${theme.spacing(0)};
  box-sizing: border-box;
  font-size: 61px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  max-height: 258px;
  max-width: 258px;
  aspect-ratio: 1 / 1;

  @supports not (aspect-ratio: 1 / 1) {
    & > * {
      position: absolute;
    }

    &::before {
      content: "";
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: 100%;
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
  `
);
export default StyledSquareTile;
