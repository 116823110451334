import { colors, fontSizesWeb } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export type DialogProps = MuiDialogProps;

const Dialog = ({ children, fullScreen, onClose, ...args }: DialogProps) => {
  const theme = useTheme();
  const forceFullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiDialog
      fullScreen={(forceFullScreen && !isMobile) || fullScreen}
      onClose={onClose}
      {...args}
    >
      {onClose && (
        <IconButton
          disableRipple
          className="Bas-Dialog-Close"
          onClick={() => !!onClose && onClose({}, 'escapeKeyDown')}
        >
          <Icon icon={faTimes} />
        </IconButton>
      )}
      {children}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  maxWidth: 'lg',
};

const StyledDialog = styled(Dialog)(
  ({ theme }) => `
 .MuiDialog-backdrop, .MuiDialog-container {
    background-color: rgba(0, 0, 0, 0.5);
 }

 .Bas-Dialog-Close {
    position: absolute;
    font-size: 24px;
    top: ${theme.spacing(5)};
    right: ${theme.spacing(5)};
    padding: 0;
    z-index: 9;
 }

 .MuiDialogTitle-root {
    padding: ${theme.spacing(5)};
    padding-bottom: ${theme.spacing(2)};
    color: ${colors.blue[500]};
    font-weight: bold;
    font-size: ${fontSizesWeb.xl};
    line-height: 31px;
 }

 .MuiDialogTitle-root+.MuiDialogContent-root {
   padding-top: 0;
 }

 .MuiDialogContent-root+.MuiDialogActions-root {
   padding-top: 0;
 }

 .MuiDialogActions-root {
   padding: ${theme.spacing(5)};
 }

 .MuiDialogContent-root {
   padding: ${theme.spacing(5, 5, 4, 5)};
 }

 .MuiDialog-paper {
    background: ${theme.palette.common.white};
    color: ${colors.lila[800]};
    border-radius: 8px;
    box-sizing: border-box;
    min-width: 580px;
    min-height: 400px;

    ${theme.breakpoints.down('sm')} {
      min-width: 0;
      margin: 0;
      width: 100%;
    }
 }


`
);

const FinalDialog = ({ ...props }: DialogProps) => <StyledDialog {...props} />;

export default FinalDialog;
