import { isEmployeeOccasionalAvailability } from '@bas/hrm-domain/models';
import {
  EmployeeAvailabilityByDate,
  isEvent,
} from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { EmployeeProjectRole, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeesAvailabilityByRoleAndDateRequestProps = {
  projectRole: EmployeeProjectRole;
  date: Date;
};

type Response = AxiosResponse<EmployeeAvailabilityByDate>;
type QueryKeyType = QueryKey & {
  [0]: 'employee-availability';
  [1]: 'list';
  [2]: string;
  [3]: string;
};

export const EmployeesAvailabilityByRoleAndDateRequest = async ({
  projectRole,
  date,
  ...params
}: EmployeesAvailabilityByRoleAndDateRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/availability/employee/${projectRole}/${formatDate(
      date
    )}`,
    {
      params: { ...params },
    }
  );

export const useEmployeesAvailabilityByRoleAndDateRequest = (
  request: EmployeesAvailabilityByRoleAndDateRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () =>
      EmployeesAvailabilityByRoleAndDateRequest({ ...request }),
    queryKey: [
      'employee-availability',
      'list',
      formatDate(request.date),
      request.projectRole,
    ],
  });

export const EmployeesAvailabilityByRoleAndDateRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEvent(data)) {
      queryClient.invalidateQueries({
        queryKey: ['employee-availability', 'list', formatDate(data.start)],
      });
    }
    if (isEmployeeOccasionalAvailability(data)) {
      queryClient.invalidateQueries({
        queryKey: ['employee-availability', 'list', formatDate(data.startTime)],
      });
    }
  };
