import { isObjectResponse } from '@bas/value-objects';
import type { TimeTypeCategory } from '../types';

export function isTimeTypeCategory(
  object: unknown
): object is TimeTypeCategory {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'TimeTypeCategory'
  );
}
