import {
  CostCenterMappingType,
  GeneralLedgerMappingType,
  Mapping,
  VatMapping,
} from '@bas/integration-domain/models';
import * as Yup from 'yup';

export interface AccountingIntegrationSettingsInputType {
  startDate?: Date | null;
  leadingNumber?: number | null;
  costCenterMappingType: CostCenterMappingType;
  generalLedgerMappingType: GeneralLedgerMappingType;
  generalLedgerMapping: Mapping[];
  costCenterMapping: Mapping[];
  administrationMapping: Mapping[];
  vatMapping: VatMapping[];
  defaultGeneralLedger?: string | null;
  discountGeneralLedger?: string | null;
  enablePurchaseOrdersPerProjectByCostCenter: boolean;
  purchaseCostMapping: Mapping[];
}

export const AccountingIntegrationSettingsInputTypeDefaultValues =
  (): AccountingIntegrationSettingsInputType => ({
    startDate: null,
    leadingNumber: null,
    generalLedgerMappingType: GeneralLedgerMappingType.PER_VAT_CODE,
    costCenterMappingType: CostCenterMappingType.NONE,
    generalLedgerMapping: [],
    costCenterMapping: [],
    vatMapping: [],
    administrationMapping: [],
    enablePurchaseOrdersPerProjectByCostCenter: false,
    purchaseCostMapping: [],
  });

export const MappingValidationBuilder = (): Yup.ObjectSchema<Mapping> =>
  Yup.object({
    basId: Yup.string().nullable().required().label('label.thisField'),
    externalId: Yup.string().nullable().required().label('label.thisField'),
  });

export const VatMappingValidationBuilder = (): Yup.ObjectSchema<VatMapping> =>
  Yup.object({
    reversed: Yup.boolean().nullable().label('label.reversed'),
  }).concat(MappingValidationBuilder());

export const AccountingIntegrationSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<AccountingIntegrationSettingsInputType> =>
    Yup.object({
      administrationMapping: Yup.array()
        .of(MappingValidationBuilder())
        .required()
        .label('label.administrationMapping'),
      startDate: Yup.date().nullable().required().label('label.startDate'),
      leadingNumber: Yup.number()
        .transform((value, original) =>
          original === '' || original === null ? null : value
        )
        .nullable()
        .label('label.leadingNumber'),
      costCenterMappingType: Yup.mixed<CostCenterMappingType>()
        .oneOf(Object.values(CostCenterMappingType))
        .required()
        .label('label.costCenterMappingType'),
      generalLedgerMappingType: Yup.mixed<GeneralLedgerMappingType>()
        .oneOf(Object.values(GeneralLedgerMappingType))
        .required()
        .label('label.generalLedgerMappingType'),
      generalLedgerMapping: Yup.array()
        .of(MappingValidationBuilder())
        .required()
        .min(1)
        .label('label.generalLedgerMapping'),
      costCenterMapping: Yup.array()
        .of(MappingValidationBuilder())
        .required()
        .label('label.costCenterMapping'),
      vatMapping: Yup.array()
        .of(VatMappingValidationBuilder())
        .required()
        .label('label.vatMapping'),
      defaultGeneralLedger: Yup.string()
        .nullable()
        .label('label.defaultGeneralLedger'),
      discountGeneralLedger: Yup.string()
        .nullable()
        .label('label.discountGeneralLedger'),
      purchaseCostMapping: Yup.array()
        .of(MappingValidationBuilder())
        .required()
        .label('label.purchaseCostMapping'),
      enablePurchaseOrdersPerProjectByCostCenter: Yup.boolean()
        .required()
        .label('label.enablePurchaseOrdersPerProjectByCostCenter'),
    });
