import { isEmail, ListedEmail } from '@bas/communication-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ListedEmailsRequestProps = Pagination &
  Filterable & {
    quoteId?: Uuid;
    invoiceId?: Uuid;
  };

type Response = AxiosResponse<Collection<ListedEmail>>;

export const ListedEmailsRequest = async ({
  ...params
}: ListedEmailsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/communication/emails`, {
    params: { ...params },
  });

export const useListedEmailsRequest = (
  request: ListedEmailsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ListedEmailsRequest(request),
    queryKey: ['emails', 'list', request],
  });

export const ListedEmailsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isEmail(data)) {
    queryClient.invalidateQueries({ queryKey: ['emails', 'list'] });
  }
};
