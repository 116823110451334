import {
  StorageEventType,
  StorageWasReturnedToWarehouseEvent,
} from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageWasReturnedToWarehouseEvent(
  object: unknown
): object is StorageWasReturnedToWarehouseEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageWasReturnedToWarehouseEvent
  );
}
