import { HoursEntry, OtherPlannedEmployee } from '@bas/hrm-domain/models';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { TableCell, TableRow, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { PersonName, Uuid } from '@bas/value-objects';
import {
  faPencil,
  faThumbsUp,
  faTrashCan,
  faXmarkToSlot,
} from '@fortawesome/pro-light-svg-icons';
import { Grid, IconButton, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { OtherPlannedEmployeesTooltipContent } from '../OtherPlannedEmployeesTooltipContent';

export type HourEntriesToApproveRow = {
  eventId?: Uuid;
  employeeId: Uuid;
  employeeName: PersonName;
  title: string | ReactNode;
  start: Date;
  end: Date;
  workStart?: Date | null;
  workEnd?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  plannedStart: Date;
  plannedEnd: Date;
  totalHours: number;
  planned: number;
  worked: number;
  workedAccordingToForeman: number;
  hoursAlwaysIncludingBreakTime: number;
  travelTime: number;
  breakTime: number;
  entryId?: Uuid;
  otherPlannedEmployees: OtherPlannedEmployee[];
  entry: HoursEntry;
};

export type HourEntriesToApproveTableRowProps = HourEntriesToApproveRow & {
  onStartEditingHours: (entry: HoursEntry) => void;
  onStartApprovingHours: (entry: HoursEntry) => void;
  onStartRemovingHours: (entryId: Uuid) => void;
  className?: string;
};

const HourEntriesToApproveTableRow = ({
  onStartEditingHours,
  onStartApprovingHours,
  onStartRemovingHours,
  className,
  entry,
  ...entryRow
}: HourEntriesToApproveTableRowProps): ReactElement => {
  const mismatchingWithColleagues =
    entryRow.otherPlannedEmployees.findIndex(
      ({ workedHours: otherWorkedHours, plannedHours }) => {
        let hours: number = plannedHours;
        if (otherWorkedHours !== null) {
          hours = otherWorkedHours;
        }

        return hours === entryRow.worked;
      }
    ) !== -1;

  const mismatchingWithColleaguesAccordingToForeman =
    entryRow.otherPlannedEmployees.findIndex(
      ({ workedHoursAccordingToForeman: otherWorkedHours, plannedHours }) =>
        (otherWorkedHours === null ? plannedHours : otherWorkedHours) !==
        entryRow.workedAccordingToForeman
    ) !== -1;

  return (
    <TableRow className={className}>
      <TableCell>{entryRow.title}</TableCell>
      <TableCell>{entryRow.employeeName.fullName}</TableCell>
      <TableCell>
        <FormattedDate value={entryRow.start} day="numeric" month="long" />
      </TableCell>
      <TableCell align="center">
        <Tooltip
          arrow
          disabled={!entryRow.travelStart && !entryRow.workStart}
          title={
            <Grid container>
              {entryRow.travelStart && (
                <Grid item xs={12}>
                  <FormattedMessage id="label.departureTime" />
                  &nbsp;
                  <FormattedTime
                    value={entryRow.travelStart}
                    timeStyle="short"
                  />
                </Grid>
              )}
              {entryRow.workStart && (
                <Grid item xs={12}>
                  <FormattedMessage id="label.workStartedAt" />
                  &nbsp;
                  <FormattedTime value={entryRow.workStart} timeStyle="short" />
                </Grid>
              )}
            </Grid>
          }
        >
          <span>
            <FormattedTime value={entryRow.start} timeStyle="short" />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip
          arrow
          disabled={!entryRow.travelEnd && !entryRow.workEnd}
          title={
            <Grid container>
              {entryRow.travelEnd && (
                <Grid item xs={12}>
                  <FormattedMessage id="label.arrivalTimeWarehouse" />
                  &nbsp;
                  <FormattedTime value={entryRow.travelEnd} timeStyle="short" />
                </Grid>
              )}
              {entryRow.workEnd && (
                <Grid item xs={12}>
                  <FormattedMessage id="label.workEndedAt" />
                  &nbsp;
                  <FormattedTime value={entryRow.workEnd} timeStyle="short" />
                </Grid>
              )}
            </Grid>
          }
        >
          <span>
            <FormattedTime value={entryRow.end} timeStyle="short" />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center" sx={{ fontWeight: 'bold' }}>
        <Tooltip
          arrow
          title={
            <Grid container>
              <Grid item xs={8}>
                <FormattedMessage id="label.workedHoursIncludingBreakTime" />
              </Grid>
              <Grid item xs={4}>
                + {formatHours(entryRow.hoursAlwaysIncludingBreakTime)}
              </Grid>
              {entryRow.hoursAlwaysIncludingBreakTime !==
                entryRow.totalHours - entryRow.travelTime && (
                <>
                  <Grid item xs={8}>
                    <FormattedMessage id="label.breakTime" />
                  </Grid>
                  <Grid item xs={4}>
                    - {formatHours(entryRow.breakTime)}
                  </Grid>
                </>
              )}
              {entryRow.travelTime > 0 && (
                <>
                  <Grid item xs={8}>
                    <FormattedMessage id="label.travelTime" />
                  </Grid>
                  <Grid item xs={4}>
                    + {formatHours(entryRow.travelTime)}
                  </Grid>
                </>
              )}
              <Grid item xs={8} fontWeight="bold">
                <FormattedMessage id="label.totalHours" />
              </Grid>
              <Grid item xs={4} fontWeight="bold">
                = {formatHours(entryRow.totalHours)}
              </Grid>
            </Grid>
          }
        >
          <span>{formatHours(entryRow.totalHours)}</span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">{formatHours(entryRow.planned)}</TableCell>
      <TableCell
        align="center"
        className={clsx(
          {
            'Bas-HourEntriesToApproveTableRow-Overtime':
              entryRow.workedAccordingToForeman > entryRow.planned,
          },
          {
            'Bas-HourEntriesToApproveTableRow-MismatchingWithColleagues':
              mismatchingWithColleaguesAccordingToForeman,
          }
        )}
      >
        <Tooltip
          arrow
          disabled={entryRow.otherPlannedEmployees.length === 0}
          title={
            <OtherPlannedEmployeesTooltipContent
              plannedStart={entryRow.plannedStart}
              plannedEnd={entryRow.plannedEnd}
              otherPlannedEmployees={entryRow.otherPlannedEmployees}
            />
          }
        >
          <span
            className={clsx({
              'Bas-HourEntriesToApproveTableRow-Underline':
                entryRow.otherPlannedEmployees.length > 0,
            })}
          >
            {formatHours(entryRow.workedAccordingToForeman)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell
        align="center"
        className={clsx(
          {
            'Bas-HourEntriesToApproveTableRow-Overtime':
              entryRow.worked > entryRow.planned,
          },
          {
            'Bas-HourEntriesToApproveTableRow-MismatchingWithColleagues':
              mismatchingWithColleagues,
          }
        )}
      >
        <Tooltip
          disabled={entryRow.otherPlannedEmployees.length === 0}
          title={
            <OtherPlannedEmployeesTooltipContent
              plannedStart={entryRow.plannedStart}
              plannedEnd={entryRow.plannedEnd}
              otherPlannedEmployees={entryRow.otherPlannedEmployees}
            />
          }
        >
          <span
            className={clsx({
              'Bas-HourEntriesToApproveTableRow-Underline':
                entryRow.otherPlannedEmployees.length > 0,
            })}
          >
            {formatHours(entryRow.worked)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">{formatHours(entryRow.travelTime)}</TableCell>
      <TableCell align="center">{formatHours(entryRow.breakTime)}</TableCell>
      <TableCell>
        <Tooltip
          title={
            <FormattedMessage
              id={entryRow.entryId ? 'button.edit' : 'button.enterHours'}
            />
          }
        >
          <IconButton onClick={() => onStartEditingHours(entry)} size="small">
            <Icon icon={entryRow.entryId ? faPencil : faXmarkToSlot} />
          </IconButton>
        </Tooltip>
        <Tooltip title={<FormattedMessage id="button.approveHours" />}>
          <IconButton onClick={() => onStartApprovingHours(entry)} size="small">
            <Icon icon={faThumbsUp} />
          </IconButton>
        </Tooltip>
        <Tooltip title={<FormattedMessage id="button.removeHours" />}>
          <IconButton
            onClick={() => onStartRemovingHours(entryRow.entryId || '')}
            size="small"
          >
            <Icon icon={faTrashCan} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const StyledHourEntriesToApproveTableRow = styled(HourEntriesToApproveTableRow)(
  ({ theme }) => `
  .Bas-HourEntriesToApproveTableRow-Overtime {
    color: ${colors.yellow[700]};
    font-weight: bold;
  }

  .Bas-HourEntriesToApproveTableRow-MismatchingWithColleagues {
    color: ${colors.red[500]};
    font-weight: bold;
  }

  .Bas-HourEntriesToApproveTableRow-Underline {
    text-decoration: underline;
  }

  .Bas-HourEntriesToApproveTableRow-OnHover * {
    opacity: 0;
  }

  &:hover {
    .Bas-HourEntriesToApproveTableRow-OnHover * {
      opacity: 1;
    }
  }
`
);

const RealHourEntriesToApproveTableRow = (
  props: HourEntriesToApproveTableRowProps
) => <StyledHourEntriesToApproveTableRow {...props} />;

export default RealHourEntriesToApproveTableRow;
