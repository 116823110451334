import { Button, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faCancel } from '@fortawesome/pro-light-svg-icons';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type ContentSectionCancelActionProps = {
  onClick: () => void | Promise<void>;
  label?: ReactNode;
  asIcon?: boolean;
};

const ContentSectionCancelAction = ({
  onClick,
  label,
  asIcon,
}: ContentSectionCancelActionProps): ReactElement => {
  const theme = useTheme();
  const forceIconButton = useMediaQuery(theme.breakpoints.down('xl'));

  if (forceIconButton || asIcon) {
    return (
      <Tooltip title={label || <FormattedMessage id="button.cancel" />}>
        <IconButton onClick={onClick}>
          <Icon icon={faCancel} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Button onClick={onClick} variant="outlined" color="secondary" size="small">
      {label || <FormattedMessage id="button.cancel" />}
    </Button>
  );
};

export default ContentSectionCancelAction;
