import type { BackendViolations } from '../types';

export function isBackendViolations(
  object: unknown
): object is BackendViolations {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'violations')
  );
}
