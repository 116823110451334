import { colors } from '@bas/theme';
import { styled } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

export type SecretValueProps = {
  className?: string;
  children?: ReactNode;
};

const SecretValue = ({
  className,
  children,
}: SecretValueProps): ReactElement => (
  <span className={className}>{children}</span>
);

const StyledSecretValue = styled(SecretValue)(
  ({ theme }) => `
  color: transparent;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
  &:hover {
    color: ${colors.lila['800']};
    text-shadow: none;
  }
`
);
export default StyledSecretValue;
