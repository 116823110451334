import { basChevronDown } from '@bas/svg-icons';
import { colors, fontSizesWeb } from '@bas/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LabelDisplayedRowsArgs,
  styled,
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
  Theme,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

export type TablePaginationProps = MuiTablePaginationProps & {
  hideNextButton?: boolean;
  hideLastButton?: boolean;
};

const TablePagination = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hideNextButton,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hideLastButton,
  ...args
}: TablePaginationProps) => <MuiTablePagination component="div" {...args} />;

const ChevronIcon = ({ ...args }) => (
  <span {...args}>
    <FontAwesomeIcon icon={basChevronDown} />
  </span>
);

TablePagination.defaultProps = {
  hideNextButton: true,
  hideLastButton: true,
  SelectProps: {
    IconComponent: ChevronIcon,
    MenuProps: {
      PaperProps: {
        sx: {
          background: (theme: Theme) => colors.lila[200],
          borderRadius: '2px',
          marginLeft: 1,
        },
      },
      elevation: 0,
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'center',
        horizontal: 'center',
      },
    },
  },
  labelRowsPerPage: <FormattedMessage id="label.resultsPerPage" />,
  labelDisplayedRows: ({ from, to, count }: LabelDisplayedRowsArgs) => {
    const countMessage = count !== -1 ? count : `meer dan ${to}`;

    return `${from} - ${to} van ${countMessage}`;
  },
};

const StyledTablePagination = styled(TablePagination)(
  ({ theme, hideNextButton, hideLastButton }) => `
  border-bottom: none;
  color: ${colors.lila[700]};

  .MuiTablePagination-displayedRows {
    order: 1;
  }

  .MuiTablePagination-spacer {
    order: 2;
    margin: ${theme.spacing(0, 2)};
    height: 18px;
    width: 0.5px;
    background: ${colors.lila[400]};
  }

  .MuiPaper-root {
    background: ${colors.lila[200]};
  }

  .MuiInputBase-root {
    margin: 0;
    margin-right: ${theme.spacing(1)};
    order: 3;

    .MuiTablePagination-select.MuiSelect-select.MuiInputBase-input {
      min-width: 0;
      padding: 0;
      padding-right: ${theme.spacing(2)};

      &:focus {
        background-color: inherit;
      }
    }

    .MuiSelect-icon {
      display: flex;
      font-size: ${fontSizesWeb.base};
      top: inherit;
    }
  }

  .MuiTablePagination-selectLabel {
    order: 4;
  }

  ${theme.breakpoints.down('lg')} {
    .MuiTablePagination-toolbar {
      padding-left: 0;
    }
    .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiTablePagination-spacer {
      display: none;
    }
  }

  .MuiTablePagination-actions .MuiButtonBase-root[tabindex="-1"] {
    ${hideLastButton && 'display: none;'}
  }

  .MuiTablePagination-actions .MuiButtonBase-root[tabindex="0"] {
    ${hideNextButton && 'display: none;'}
  }
`
);
export default StyledTablePagination;
