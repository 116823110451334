import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basStandingSpeaker: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'standing-speaker',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M187.002 133.503c0-32.31 26.191-58.502 58.5-58.502 32.31 0 58.502 26.192 58.502 58.502 0 32.308-26.192 58.5-58.501 58.5-32.31 0-58.501-26.192-58.501-58.5zM245.502 99c-19.053 0-34.5 15.447-34.5 34.502 0 19.054 15.447 34.5 34.5 34.5 19.055 0 34.502-15.446 34.502-34.5 0-19.055-15.447-34.502-34.501-34.502zm0 122.004c-32.309 0-58.5 26.191-58.5 58.5 0 32.31 26.191 58.502 58.5 58.502 32.31 0 58.502-26.191 58.502-58.501s-26.192-58.501-58.501-58.501zm-34.5 58.5c0-19.053 15.447-34.5 34.5-34.5 19.055 0 34.502 15.447 34.502 34.5 0 19.055-15.447 34.502-34.501 34.502s-34.501-15.447-34.501-34.501zM143 34c0-8.285 6.716-15 15-15h175.005c8.284 0 15 6.715 15 15v434.01c0 8.284-6.716 15-15 15h-13.042l-3.37 23.591h-21.934l-3.37-23.59h-92.33l-3.369 23.59h-21.935l-3.369-23.59h-12.285c-8.285 0-15-6.717-15-15V34zm30.001 15v404.01h145.003V49H173.001z',
  ],
};

export default basStandingSpeaker;
