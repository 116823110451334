import { NewLeadData, SyncStatus } from '@bas/integration-domain/models';
import { SyncStatusChip } from '@bas/integration-domain/web/molecules';
import { Button, TableCell, TableRow, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ConfirmDialog } from '@bas/ui/web/organisms';
import { faCheckToSlot, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { Grid, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

export type NewLeadTableRowProps = {
  data: NewLeadData;
  onMarkAsCompleted: () => Promise<void> | void;
};

const NewLeadTableRow = ({
  data: {
    relationId,
    relationName,
    projectId,
    mappedData,
    originalData,
    errors,
    syncStatus,
    processedAt,
    failedAt,
  },
  onMarkAsCompleted,
}: NewLeadTableRowProps): ReactElement => {
  const [showData, setShowData] = useState<boolean>(false);
  const { customer, preferredMovingDate } = mappedData;

  return (
    <TableRow>
      <TableCell paddingRight>
        {relationId ? (
          <Link
            to={`/customers/${relationId}`}
            component={RouterLink}
            color="textPrimary"
          >
            {relationName || (
              <span>
                {customer?.firstName ? `${customer?.firstName} ` : ''}
                {customer?.middleName ? `${customer?.middleName} ` : ''}
                {customer?.lastName}
                {customer?.companyName ? ` (${customer?.companyName})` : ''}
              </span>
            )}
          </Link>
        ) : (
          <span>
            {customer?.firstName ? `${customer?.firstName} ` : ''}
            {customer?.middleName ? `${customer?.middleName} ` : ''}
            {customer?.lastName}
            {customer?.companyName ? ` (${customer?.companyName})` : ''}
          </span>
        )}
      </TableCell>
      <TableCell paddingRight>
        {projectId ? (
          <Link
            to={`/projects/projects/${projectId}`}
            component={RouterLink}
            color="textPrimary"
          >
            {preferredMovingDate ? (
              <FormattedDate value={dayjs(preferredMovingDate).toDate()} />
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Link>
        ) : (
          <span>
            {preferredMovingDate ? (
              <FormattedDate value={dayjs(preferredMovingDate).toDate()} />
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </span>
        )}
      </TableCell>
      <TableCell paddingRight>
        <SyncStatusChip
          syncStatus={syncStatus}
          messages={(errors.violations || []).map(
            (violation) => violation.title
          )}
        />
      </TableCell>
      <TableCell paddingRight>
        {(!failedAt || syncStatus === SyncStatus.SUCCESS) && processedAt && (
          <FormattedDate
            value={dayjs(processedAt).toDate()}
            dateStyle="short"
            timeStyle="short"
          />
        )}
        {failedAt && syncStatus !== SyncStatus.SUCCESS && (
          <FormattedDate
            value={dayjs(failedAt).toDate()}
            dateStyle="short"
            timeStyle="short"
          />
        )}
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              onClick={() => setShowData(true)}
              size="small"
              variant="outlined"
            >
              <Icon icon={faCircleInfo} />
            </Button>
          </Grid>
          {[SyncStatus.FAILED, SyncStatus.DISAPPROVED].includes(syncStatus) && (
            <Grid item>
              <Tooltip title={<FormattedMessage id="button.markAsCompleted" />}>
                <Button
                  onClick={onMarkAsCompleted}
                  size="small"
                  variant="outlined"
                >
                  <Icon icon={faCheckToSlot} />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </TableCell>
      {showData && (
        <ConfirmDialog
          open={showData}
          onCancel={() => setShowData(false)}
          onClose={() => setShowData(false)}
          onConfirm={async () => {
            setShowData(false);
          }}
          maxWidth="xl"
          fullWidth
          title={<FormattedMessage id="label.newLeadInfo" />}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="label.errors" />
                </Typography>
                <Typography
                  whiteSpace="pre-wrap"
                  sx={{ overflowWrap: 'break-word' }}
                >
                  {JSON.stringify(errors, null, 4)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="label.original" />
                </Typography>
                <Typography
                  whiteSpace="pre-wrap"
                  sx={{ overflowWrap: 'break-word' }}
                >
                  {JSON.stringify(originalData, null, 4)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="label.mapped" />
                </Typography>
                <Typography
                  whiteSpace="pre-wrap"
                  sx={{ overflowWrap: 'break-word' }}
                >
                  {JSON.stringify(mappedData, null, 4)}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )}
    </TableRow>
  );
};

export default NewLeadTableRow;
