import {
  Active,
  DraggableAttributes,
  Over,
  useDraggable,
  UseDraggableArguments,
} from '@dnd-kit/core';
import { CSSProperties, MutableRefObject, ReactElement } from 'react';

export type DraggableProps = {
  children: (args: {
    style: CSSProperties | undefined;
    active: Active | null;
    attributes: DraggableAttributes;
    isDragging: boolean;
    node: MutableRefObject<HTMLElement | null>;
    over: Over | null;
    setNodeRef: (element: HTMLElement | null) => void;
    listeners: Record<string, unknown> | undefined;
    setActivatorNodeRef: (element: HTMLElement | null) => void;
  }) => ReactElement;
  draggableProps: UseDraggableArguments;
};
const Draggable = ({
  children,
  draggableProps,
}: DraggableProps): ReactElement => {
  const draggableResult = useDraggable(draggableProps);
  const style = draggableResult.transform
    ? {
        transform: `translate3d(${draggableResult.transform.x}px, ${draggableResult.transform.y}px, 0)`,
      }
    : undefined;

  return children({ ...draggableResult, style });
};

export default Draggable;
