import { isObjectResponse } from '@bas/value-objects';
import type { InventoryItem } from '../../types';

export function isInventoryItem(object: unknown): object is InventoryItem {
  return (
    !!object &&
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'inventoryType') &&
    typeof object.inventoryType === 'string' &&
    ['moving-boxes', 'reusable-material'].includes(object.inventoryType)
  );
}
