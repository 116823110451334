import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basCabinet: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'cabinet',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M24 117c-8.3 0-15 6.7-15 15V339c0 8.3 6.7 15 15 15h81l6 42h36l6-42H359l6 42h36l6-42h81c8.3 0 15-6.7 15-15V132c0-8.3-6.7-15-15-15H24zM39 324V147H241l0 177H39zM271 221V147H473v74H271zm0 30H473v73H271V251z',
  ],
};

export default basCabinet;
