import { isPackage, Package } from '@bas/shared/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PackageByPackageIdRequestProps = {
  packageId: Uuid;
};

export const PackageByPackageIdRequest = async ({
  packageId,
  ...params
}: PackageByPackageIdRequestProps): Promise<AxiosResponse<Package>> =>
  axios.get(`api/{tenantId}/packages/${packageId}`, {
    params: {
      ...params,
    },
  });

export const usePackageByPackageIdRequest = (
  request: PackageByPackageIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Package>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Package>
  > = {}
): UseQueryResult<AxiosResponse<Package>, AxiosError<ErrorResponse>> =>
  useQuery<
    AxiosResponse<Package>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Package>
  >({
    ...options,
    queryFn: async () => PackageByPackageIdRequest(request),
    queryKey: ['packages', 'details', request.packageId],
  });

export const PackageByPackageIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isPackage(data)) {
    queryClient.invalidateQueries({
      queryKey: ['packages', 'details', data.packageId],
    });
  }
};
