import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Uuid } from '@bas/value-objects';
import { zustandStorage } from './zustandStorage';

type RequestFilters = {
  [key: string]: string | number | string[] | boolean;
};

type BatchDataBulkStore = {
  checkedBasedUponFilter: boolean;
  checkedBatchDataIds: Uuid[];
  requestFilters?: RequestFilters;
  toggleBatchData: (dataId: Uuid) => void;
  checkBatchData: (dataId: Uuid) => void;
  uncheckBatchData: (dataId: Uuid) => void;
  setCheckedBasedUponFilter: (checked: boolean) => void;
  setCheckedBatchDataIds: (dataIds: Uuid[]) => void;
  setRequestFilters: (requestFilters: RequestFilters) => void;
};

export const useBatchDataBulkStore = create<BatchDataBulkStore>()(
  persist(
    (set) => ({
      checkedBasedUponFilter: false,
      checkedBatchDataIds: [],
      requestFilters: undefined,
      toggleBatchData: (dataId: Uuid) =>
        set((state) => {
          if (state.checkedBatchDataIds.includes(dataId)) {
            return {
              checkedBatchDataIds: state.checkedBatchDataIds.filter(
                (id) => id !== dataId
              ),
            };
          }

          return {
            checkedBatchDataIds: [...state.checkedBatchDataIds, dataId],
          };
        }),

      checkBatchData: (dataId: Uuid) =>
        set((state) => ({
          checkedBatchDataIds: [...state.checkedBatchDataIds, dataId],
        })),
      uncheckBatchData: (dataId: Uuid) =>
        set((state) => ({
          checkedBatchDataIds: state.checkedBatchDataIds.filter(
            (id) => id !== dataId
          ),
        })),
      setCheckedBasedUponFilter: (checked: boolean) =>
        set({ checkedBasedUponFilter: checked }),
      setCheckedBatchDataIds: (dataIds: Uuid[]) =>
        set({ checkedBatchDataIds: dataIds }),
      setRequestFilters: (requestFilters: RequestFilters) =>
        set({ requestFilters }),
    }),
    {
      name: 'invoice-bulk-store',
      storage: zustandStorage<BatchDataBulkStore>(),
    }
  )
);
