import { IntegrationDetailsPageTemplate } from '@bas/settings-domain/web/templates';
import { SidebarType, useBackofficeSidebarStore } from '@bas/shared/state';
import { ComponentProps, lazy, ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const StorageDetailsPageTemplate = lazy(() =>
  import('@bas/wms-domain/storage/web/templates').then((module) => ({
    default: module.StorageDetailsPageTemplate,
  }))
);

const StorageLocationDetailsPageTemplate = lazy(() =>
  import('@bas/wms-domain/storage/web/templates').then((module) => ({
    default: module.StorageLocationDetailsPageTemplate,
  }))
);

const DamageDetailsPageTemplate = lazy(() =>
  import('@bas/project-domain/web/templates').then((module) => ({
    default: module.DamageDetailsPageTemplate,
  }))
);

const QuoteDetailsPageTemplate = lazy(() =>
  import('@bas/financial-domain/quote/web/templates').then((module) => ({
    default: module.QuoteDetailsPageTemplate,
  }))
);

const InvoiceDetailsPageTemplate = lazy(() =>
  import('@bas/financial-domain/invoice/web/templates').then((module) => ({
    default: module.InvoiceDetailsPageTemplate,
  }))
);

const CustomerDetailsPageTemplate = lazy(() =>
  import('@bas/crm-domain/web/templates').then((module) => ({
    default: module.CustomerDetailsPageTemplate,
  }))
);

const TaskDetailsPageTemplate = lazy(() =>
  import('@bas/task-domain/web/templates').then((module) => ({
    default: module.TaskDetailsPageTemplate,
  }))
);

const DeclarationDetailsPageTemplate = lazy(() =>
  import('@bas/hrm-domain/web/templates').then((module) => ({
    default: module.DeclarationDetailsPageTemplate,
  }))
);

const TimeOffRegistrationDetailsPageTemplate = lazy(() =>
  import('@bas/hrm-domain/web/templates').then((module) => ({
    default: module.TimeOffRegistrationDetailsPageTemplate,
  }))
);

const EventDetailsPageTemplate = lazy(() =>
  import('@bas/planning-domain/web/templates').then((module) => ({
    default: module.EventDetailsPageTemplate,
  }))
);

const ProjectDetailsPageTemplate = lazy(() =>
  import('@bas/project-domain/web/templates').then((module) => ({
    default: module.ProjectDetailsPageTemplate,
  }))
);

const SidebarContent = (): ReactElement | null => {
  const { key } = useLocation();
  const { openSidebar, args, close, openKey, setOpenKey } =
    useBackofficeSidebarStore((state) => ({
      openSidebar: state.openSidebars[0]?.sidebarType,
      args: state.openSidebars[0]?.args,
      close: state.close,
      openKey: state.openKey,
      setOpenKey: state.setOpenKey,
    }));

  useEffect(() => {
    if (openKey !== key) {
      setOpenKey(key);
      if (openSidebar) {
        close();
      }
    }
  }, [close, key, openKey, openSidebar, setOpenKey]);

  switch (openSidebar) {
    case SidebarType.PROJECT_DETAILS:
      return (
        <ProjectDetailsPageTemplate
          {...(args as ComponentProps<typeof ProjectDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.STORAGE_DETAILS:
      return (
        <StorageDetailsPageTemplate
          {...(args as ComponentProps<typeof StorageDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.STORAGE_LOCATION_DETAILS:
      return (
        <StorageLocationDetailsPageTemplate
          {...(args as ComponentProps<
            typeof StorageLocationDetailsPageTemplate
          >)}
          onClose={close}
        />
      );
    case SidebarType.DAMAGE_DETAILS:
      return (
        <DamageDetailsPageTemplate
          {...(args as ComponentProps<typeof DamageDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.EVENT_DETAILS:
      return (
        <EventDetailsPageTemplate
          {...(args as ComponentProps<typeof EventDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.QUOTE_DETAILS:
      return (
        <QuoteDetailsPageTemplate
          {...(args as ComponentProps<typeof QuoteDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.INVOICE_DETAILS:
      return (
        <InvoiceDetailsPageTemplate
          {...(args as ComponentProps<typeof InvoiceDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.CUSTOMER_DETAILS:
      return (
        <CustomerDetailsPageTemplate
          {...(args as ComponentProps<typeof CustomerDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.INTEGRATION_DETAILS:
      return (
        <IntegrationDetailsPageTemplate
          {...(args as ComponentProps<typeof IntegrationDetailsPageTemplate>)}
          onClose={close}
        />
      );
    case SidebarType.TASK_DETAILS:
      return (
        <TaskDetailsPageTemplate
          {...(args as unknown as ComponentProps<
            typeof TaskDetailsPageTemplate
          >)}
          onClose={close}
        />
      );
    case SidebarType.DECLARATION_DETAILS:
      return (
        <DeclarationDetailsPageTemplate
          {...(args as unknown as ComponentProps<
            typeof DeclarationDetailsPageTemplate
          >)}
          onClose={close}
        />
      );
    case SidebarType.TIME_OFF_REGISTRATION_DETAILS:
      return (
        <TimeOffRegistrationDetailsPageTemplate
          {...(args as unknown as ComponentProps<
            typeof TimeOffRegistrationDetailsPageTemplate
          >)}
          onClose={close}
        />
      );
    default:
      return <div>@todo {openSidebar}</div>;
  }
};

export default SidebarContent;
