import * as Yup from 'yup';
import {
  AccountingIntegrationSettingsInputType,
  AccountingIntegrationSettingsInputTypeDefaultValues,
  AccountingIntegrationSettingsInputTypeValidationBuilder,
} from '../accountingIntegrationSettingsInputType';

export interface OctopusSettingsInputType
  extends AccountingIntegrationSettingsInputType {
  dossierId: string;
}

export const OctopusSettingsInputTypeDefaultValues =
  (): OctopusSettingsInputType => ({
    ...AccountingIntegrationSettingsInputTypeDefaultValues(),
    dossierId: '',
  });

export const OctopusSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<OctopusSettingsInputType> =>
    Yup.object({
      dossierId: Yup.string().required().label('label.administration'),
    }).concat(AccountingIntegrationSettingsInputTypeValidationBuilder());
