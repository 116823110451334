import {
  ExternalEmployee,
  isExternalEmployee,
} from '@bas/integration-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ExternalEmployeesByIntegrationIdRequestProps = Pagination &
  Filterable & {
    integrationId: Uuid;
  };

type Response = AxiosResponse<Collection<ExternalEmployee>>;

export const ExternalEmployeesByIntegrationIdRequest = async ({
  integrationId,
  ...params
}: ExternalEmployeesByIntegrationIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/integrations/${integrationId}/external-employees`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useExternalEmployeesByIntegrationIdRequest = (
  request: ExternalEmployeesByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ExternalEmployeesByIntegrationIdRequest(request),
    queryKey: [
      'integrations',
      'external-employees',
      request.integrationId,
      request,
    ],
  });

export const ExternalEmployeesByIntegrationIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isExternalEmployee(data)) {
      queryClient.invalidateQueries({
        queryKey: ['integrations', 'external-employees', data.integrationId],
      });
    }
  };
