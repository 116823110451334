import {
  ScanMoversSettingsInputType,
  ScanMoversSettingsInputTypeDefaultValues,
  ScanMoversSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { Integration } from '@bas/integration-domain/models';
import { ScanMoversSettingsContentSection } from '@bas/integration-domain/web/molecules';
import { ReactHookForm } from '@bas/ui/web/molecules';
import { ReactElement, useState } from 'react';
import { EditingScanMoversSettingsContentSection } from '../EditingScanMoversSettingsContentSection';

export type EditableScanMoversSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: ScanMoversSettingsInputType) => Promise<void>;
};

const EditableScanMoversSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableScanMoversSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<ScanMoversSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          ...ScanMoversSettingsInputTypeDefaultValues(),
          ...integration.providerSettings,
        }}
        validationSchema={ScanMoversSettingsInputTypeValidationBuilder()}
      >
        <EditingScanMoversSettingsContentSection
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  return (
    <ScanMoversSettingsContentSection
      onEdit={() => setIsEditing(true)}
      integration={integration}
    />
  );
};

export default EditableScanMoversSettingsBlock;
