import { formatHours } from '@bas/shared/utils';
import { Avatar, AvatarSizeType, Badge, Tooltip } from '@bas/ui/web/atoms';
import { PersonName } from '@bas/value-objects';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type OtherPlannedEmployeeAvatarProps = {
  personName: PersonName;
  profilePictureUrl?: string;
  plannedHours: number;
  workedHours: number | null;
  workedHoursAccordingToForeman: number | null;
  approvedWorkedHours?: number | null;
};

const OtherPlannedEmployeeAvatar = ({
  personName: { firstName, lastName, fullName },
  profilePictureUrl,
  plannedHours,
  workedHours,
  workedHoursAccordingToForeman,
  approvedWorkedHours,
}: OtherPlannedEmployeeAvatarProps): ReactElement => {
  let color:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning' = 'default';
  let label = 'label.plannedHours';

  if (approvedWorkedHours !== null) {
    color = 'success';
    label = 'label.approvedHours';
  } else if (workedHours !== null) {
    color = 'warning';
    label = 'label.workedHours';
  } else if (workedHoursAccordingToForeman !== null) {
    color = 'primary';
    label = 'label.workedHoursAccordingToForeman';
  }

  const hours = useMemo(() => {
    if (approvedWorkedHours !== null) {
      return approvedWorkedHours;
    }

    if (workedHours !== null) {
      return workedHours;
    }

    if (workedHoursAccordingToForeman !== null) {
      return workedHoursAccordingToForeman;
    }
    return plannedHours;
  }, [
    approvedWorkedHours,
    plannedHours,
    workedHours,
    workedHoursAccordingToForeman,
  ]);

  return (
    <Tooltip
      placement="bottom"
      arrow
      title={
        <Grid container alignItems="center" direction="column">
          <Grid item>
            <Typography fontWeight="bold">{fullName}</Typography>
          </Grid>
          <Grid item>
            <FormattedMessage id={label} />
          </Grid>
        </Grid>
      }
    >
      <div>
        <Badge
          badgeContent={formatHours(hours)}
          color={color}
          overlap="circular"
        >
          <Avatar
            size={AvatarSizeType.SMALL}
            src={profilePictureUrl}
            name={`${firstName} ${lastName}`}
            shortName={lastName}
          />
        </Badge>
      </div>
    </Tooltip>
  );
};

export default OtherPlannedEmployeeAvatar;
