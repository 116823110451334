import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDateTime } from '@bas/shared/utils';
import { isNews, News } from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PublishedNewsRequestProps = Pagination & {
  publishedBefore: Date;
};

export const PublishedNewsRequest = async ({
  publishedBefore,
  ...params
}: PublishedNewsRequestProps): Promise<AxiosResponse<Collection<News>>> =>
  axios.get('api/{tenantId}/news', {
    params: {
      ...params,
      'publishedAt[before]': formatDateTime(publishedBefore),
    },
  });

export const usePublishedNewsRequest = (
  request: PublishedNewsRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<News>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<News>>
  > = {}
): UseQueryResult<AxiosResponse<Collection<News>>, AxiosError<ErrorResponse>> =>
  useQuery<
    AxiosResponse<Collection<News>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<News>>
  >({
    ...options,
    queryFn: async () => PublishedNewsRequest(request),
    queryKey: ['news', 'list', formatDateTime(request.publishedBefore)],
  });

export const PublishedNewsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isNews(data)) {
    queryClient.invalidateQueries({ queryKey: ['news', 'list'] });
  }
};
