import { isObjectResponse } from '@bas/value-objects';
import type { Declaration } from '../types';

export function isDeclaration(object: unknown): object is Declaration {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'Declaration'
  );
}
