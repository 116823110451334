import { colors, fontSizesWeb, lineHeights } from '@bas/theme';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { TabsHeaderTemplate } from '@bas/ui/web/templates';
import {
  faArrowProgress,
  faBallotCheck,
  faBuilding,
  faClockTwo,
  faEnvelope,
  faFileInvoiceDollar,
  faIslandTropical,
  faListTree,
  faMoneyCheckDollarPen,
  faTableLayout,
  faTableList,
  faToggleOff,
  faTreeChristmas,
} from '@fortawesome/pro-light-svg-icons';
import { Box, ListItemAvatar, styled } from '@mui/material';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

export type SettingsTemplateProps = {
  children?: ReactNode;
  useIdentities: boolean;
};

const SettingsTemplate = ({
  children,
  useIdentities,
  ...props
}: SettingsTemplateProps): ReactElement => {
  const tabs = useMemo(() => {
    const result = [
      {
        label: <FormattedMessage id="label.account" />,
        to: '/settings/account',
        exact: false,
        subMenu: [
          {
            label: <FormattedMessage id="label.companyInformation" />,
            to: 'company-information',
            icon: faBuilding,
            exact: false,
          },
          {
            label: <FormattedMessage id="label.identities" />,
            icon: faListTree,
            to: 'identities',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.billing" />,
            icon: faFileInvoiceDollar,
            to: 'billing',
            exact: false,
          },
        ],
      },
      {
        label: <FormattedMessage id="label.workflows" />,
        to: '/settings/workflows',
        exact: false,
        subMenu: [
          {
            label: <FormattedMessage id="label.packages" />,
            icon: faArrowProgress,
            to: 'packages',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.documentTemplates" />,
            icon: faTableLayout,
            to: 'document-templates',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.customerNotifications" />,
            icon: faEnvelope,
            to: 'customer-notifications',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.turnoverGroups" />,
            icon: faTableList,
            to: 'turnover-groups',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.features" />,
            icon: faToggleOff,
            to: 'features',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.tasks" />,
            icon: faBallotCheck,
            to: 'tasks',
            exact: false,
          },
        ],
      },
      {
        label: <FormattedMessage id="label.hrm" />,
        to: '/settings/hrm',
        exact: false,
        subMenu: [
          {
            label: <FormattedMessage id="label.timeTypes" />,
            icon: faClockTwo,
            to: 'time-types',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.declarationCategories" />,
            icon: faMoneyCheckDollarPen,
            to: 'declaration-categories',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.timeOffTypes" />,
            icon: faIslandTropical,
            to: 'time-off-types',
            exact: false,
          },
          {
            label: <FormattedMessage id="label.national-holidays" />,
            icon: faTreeChristmas,
            to: 'national-holidays',
            exact: false,
          },
        ],
      },
      {
        label: <FormattedMessage id="label.integrations" />,
        to: 'integrations',
        exact: false,
        subMenu: [],
      },
      {
        label: <FormattedMessage id="label.downloads" />,
        to: 'downloads',
        exact: false,
        subMenu: [],
      },
      {
        label: <FormattedMessage id="label.roadmap" />,
        to: 'roadmap',
        exact: false,
        subMenu: [],
      },
      {
        label: <FormattedMessage id="label.data" />,
        to: 'data',
        exact: false,
        subMenu: [],
      },
    ];

    if (useIdentities) {
      return result;
    }

    return result.filter(({ to }) => to !== 'identities');
  }, [useIdentities]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentTab = useMemo(
    () => tabs.find(({ to }) => pathname.includes(to)),
    [pathname, tabs]
  );

  const currentSubMenu = useMemo(
    () => (currentTab?.subMenu || []).find(({ to }) => pathname.includes(to)),
    [pathname, currentTab]
  );

  const subMenus = useMemo(() => {
    const result = currentTab?.subMenu || [];

    if (!useIdentities) {
      return result.filter(({ to }) => to !== 'identities');
    }

    return result;
  }, [currentTab, useIdentities]);

  return (
    <TabsHeaderTemplate parentPath="/settings" tabs={tabs}>
      {currentTab && (currentTab.subMenu.length || 0) > 0 ? (
        <Box {...props}>
          <Box className="Bas-Settings-SubMenus">
            <List className="Bas-SubMenu-Root">
              {subMenus.map(({ label, icon, to, exact }, index) => (
                <ListItem key={to}>
                  <ListItemButton
                    onClick={() => navigate(`${currentTab.to}/${to}`)}
                    selected={currentSubMenu?.to === to}
                  >
                    <ListItemAvatar>
                      <Icon icon={icon} />
                    </ListItemAvatar>
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box className="Bas-Settings-Content">{children}</Box>
        </Box>
      ) : (
        children
      )}
    </TabsHeaderTemplate>
  );
};

const StyledTemplate = styled(SettingsTemplate)`
  height: 100%;
  display: flex;

  .Bas-DataTableTemplate-ResultsText {
    h6 {
      padding-left: ${({ theme }) => theme.spacing(2)};
    }
  }

  .Bas-Settings-SubMenus {
    width: 200px;
    min-width: 200px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
  }

  .Bas-Settings-Content {
    flex-grow: 1;
    position: relative;
    border-left: 1px solid ${colors.lila[400]};
    padding-left: ${({ theme }) => theme.spacing(6)};

    .Bas-Content-Overflow {
      height: 100%;
      margin-right: ${({ theme }) => theme.spacing(6)};
      overflow: auto;
    }
  }

  .Bas-TableToolbar-Actions {
    padding-right: ${({ theme }) => theme.spacing(2)};
  }

  .Bas-SubMenu-Root {
    overflow: hidden;

    .MuiListItem-root {
      border-bottom: 0.5px solid ${colors.lila[400]};
      padding: ${({ theme }) => theme.spacing(1.5)} 0;
      position: relative;
      padding-right: ${({ theme }) => theme.spacing(0.5)};

      .MuiListItemAvatar-root {
        min-width: 24px;
      }

      &:not(.Bas-FolderListItem-None):hover,
      &.Bas-FolderListItem-Select {
        .MuiListItemText-primary,
        .MuiListItemAvatar-root {
          color: ${colors.blue[500]};
          cursor: pointer;
        }
      }

      .MuiListItemText-primary {
        font-size: ${fontSizesWeb.base};
        line-height: ${lineHeights.base}px;
        text-transform: lowercase;
      }
    }
  }
`;

export default StyledTemplate;
