import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { isWarehouse, Warehouse } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type WarehouseByWarehouseIdRequestProps = {
  warehouseId: Uuid;
};

export const WarehouseByWarehouseIdRequest = async ({
  warehouseId,
  ...params
}: WarehouseByWarehouseIdRequestProps): Promise<AxiosResponse<Warehouse>> =>
  axios.get(`api/{tenantId}/warehouses/${warehouseId}`, {
    params,
  });

export const useWarehouseByWarehouseIdRequestQuery = (
  request: WarehouseByWarehouseIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Warehouse>,
    AxiosError,
    AxiosResponse<Warehouse>
  > = {}
): UseQueryResult<AxiosResponse<Warehouse>, AxiosError> =>
  useQuery<AxiosResponse<Warehouse>, AxiosError, AxiosResponse<Warehouse>>({
    ...options,
    queryFn: async () => WarehouseByWarehouseIdRequest(request),
    queryKey: ['warehouses', 'detail', request.warehouseId],
  });

export const WarehouseByWarehouseIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isWarehouse(data)) {
    queryClient.invalidateQueries({
      queryKey: ['warehouses', 'detail', data.warehouseId],
    });
  }
};
