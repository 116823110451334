import { colors } from '@bas/theme';
import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

export type BadgeProps = MuiBadgeProps;

const Badge = ({ ...props }: BadgeProps): ReactElement => (
  <MuiBadge {...props} />
);

const StyledBadge = styled(Badge)(
  ({ theme }) => `
  .MuiBadge-badge:not(.MuiBadge-colorPrimary):not(.MuiBadge-colorSecondary):not(.MuiBadge-colorError):not(.MuiBadge-colorInfo):not(.MuiBadge-colorSuccess):not(.MuiBadge-colorWarning) {
    background-color: ${colors.lila[600]};
  }
`
);
export default StyledBadge;
