import { WhatsAppSettings } from '@bas/integration-domain/models';
import { ContentSection } from '@bas/ui/web/molecules';
import { Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { WhatsAppAccountReviewStatusChip } from '../WhatsAppAccountReviewStatusChip';

export type ConnectedWhatsAppBusinessesBlockProps = {
  providerSettings: WhatsAppSettings;
};

const ConnectedWhatsAppBusinessesBlock = ({
  providerSettings,
}: ConnectedWhatsAppBusinessesBlockProps): ReactElement => (
  <ContentSection actions={[]}>
    <Grid container columnSpacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.connectedWhatsAppBusinesses" />
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        {' '}
        {providerSettings.businesses.map((business) => (
          <Grid item xs={12} container key={business.id}>
            <Grid item xs={6}>
              {business.name}
            </Grid>
            <Grid item xs={3}>
              <WhatsAppAccountReviewStatusChip
                businessStatus={business.account_review_status}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </ContentSection>
);

export default ConnectedWhatsAppBusinessesBlock;
