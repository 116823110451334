import {
  useSnelStartCostCentersByIntegrationIdRequest,
  useSnelStartGeneralLedgersByIntegrationIdRequest,
} from '@bas/integration-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';

export const useSnelStartMappingOptions = (integrationId: Uuid) => {
  const { data: generalLedgersData, isError: generalLedgersError } =
    useSnelStartGeneralLedgersByIntegrationIdRequest({
      integrationId,
    });

  const generalLedgerExternalOptions = useMemo(
    () =>
      (generalLedgersData?.data?.['hydra:member'] || [])
        .filter(({ nonactief }) => !nonactief)
        .map(({ id, omschrijving, nummer }) => ({
          externalId: id,
          label: `${omschrijving} (${nummer})`,
        })),
    [generalLedgersData?.data]
  );

  const { data: costCentersData, isError: costCentersError } =
    useSnelStartCostCentersByIntegrationIdRequest({
      integrationId,
    });

  const costCenterExternalOptions = useMemo(
    () =>
      (costCentersData?.data?.['hydra:member'] || [])
        .filter(({ nonactief }) => !nonactief)
        .map(({ id, omschrijving, nummer }) => ({
          externalId: id,
          label: `${omschrijving} (${nummer})`,
        })),
    [costCentersData?.data]
  );

  const vatExternalOptions = useMemo(
    () => [
      { externalId: 'geen', label: 'Geen' },
      { externalId: 'hoog', label: 'Hoog' },
      { externalId: 'laag', label: 'Laag' },
      { externalId: 'overig', label: 'Overig' },
    ],
    []
  );

  return {
    generalLedgersError,
    generalLedgerExternalOptions,
    costCentersError,
    costCenterExternalOptions,
    vatExternalOptions,
  };
};
