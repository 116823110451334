import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { DarkCenteredTemplate } from '@bas/ui/web/templates';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faIntercom } from '@fortawesome/free-brands-svg-icons';
import { faShieldExclamation } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type FullScreenPermissionErrorPageProps = {
  className?: string;
  title: string | ReactNode;
  description: string | ReactNode;
  requestHelp?: () => void;
  customActions?: ReactNode | ReactNode[];
  customIcon?: IconProp;
};

const FullScreenPermissionErrorPage = ({
  className,
  title,
  description,
  requestHelp,
  customActions,
  customIcon,
}: FullScreenPermissionErrorPageProps) => (
  <DarkCenteredTemplate className={className}>
    <Box className="Bas-FullScreenPermissionErrorPage-Icon">
      <Icon icon={customIcon || faShieldExclamation} />
    </Box>
    <Box className="Bas-FullScreenPermissionErrorPage-TitleHolder">
      <Typography
        className="Bas-FullScreenPermissionErrorPage-Title"
        variant="h3"
      >
        {title}
      </Typography>
    </Box>
    <Box className="Bas-FullScreenPermissionErrorPage-DescriptionHolder">
      <Typography className="Bas-FullScreenPermissionErrorPage-Description">
        {description}
      </Typography>
    </Box>
    <Grid
      container
      spacing={2}
      className="Bas-FullScreenPermissionErrorPage-Actions"
      justifyContent="center"
    >
      {customActions}
      {requestHelp && (
        <Grid item>
          <Button
            onClick={requestHelp}
            className="Bas-FullScreenPermissionErrorPage-Action"
          >
            <Icon icon={faIntercom} />
            &nbsp;
            <FormattedMessage id="errorFallback.iNeedHelp" />
          </Button>
        </Grid>
      )}
    </Grid>
  </DarkCenteredTemplate>
);

const StyledFullScreenPermissionErrorPage = styled(
  FullScreenPermissionErrorPage
)`
  .Bas-DarkCenteredTemplate-Content {
    max-width: 400px;

    ${({ theme }) => theme.breakpoints.only('xs')} {
      padding: 0 20px;
    }
  }

  .Bas-FullScreenPermissionErrorPage-Icon {
    font-size: 104px;
    text-align: center;
  }

  .Bas-FullScreenPermissionErrorPage-TitleHolder {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  .Bas-FullScreenPermissionErrorPage-DescriptionHolder {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    text-align: center;
  }
`;
export default StyledFullScreenPermissionErrorPage;
