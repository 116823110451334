import { Event } from '@bas/planning-domain/models';
import { convertRRuleToText } from '@bas/planning-domain/web/hooks';
import { colors, fontSizesWeb } from '@bas/theme';
import { Autocomplete, AutocompleteProps } from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { styled, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';

export type EventsTextFieldProps = Omit<
  AutocompleteProps<
    Event & {
      label: string;
      group: string;
    },
    false,
    true
  >,
  'value' | 'options'
> & {
  events: Event[];
  selectedEventId?: Uuid;
};

const EventsTextField = ({
  events,
  selectedEventId,
  ...args
}: EventsTextFieldProps): ReactElement => {
  const { formatTime, formatDate, formatMessage } = useIntl();
  const groupedEvents = useMemo(() => {
    const sortedEvents = events.sort((a, b) =>
      dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1
    );
    const nonRecurringEvents = sortedEvents.filter(
      ({ recurringInformation }) => !recurringInformation?.recurring
    );
    const recurringEvents = sortedEvents.filter(
      ({ recurringInformation }) => recurringInformation?.recurring
    );

    return [
      {
        title: formatMessage({ id: 'label.recurringEvents' }),
        events: recurringEvents,
      },
      {
        title: formatMessage({ id: 'label.thisWeek' }),
        events: nonRecurringEvents.filter(
          ({ start }) =>
            dayjs(start).isSameOrAfter(dayjs().startOf('week'), 'day') &&
            dayjs(start).isSameOrBefore(dayjs().endOf('week'), 'day')
        ),
      },
      {
        title: formatMessage({ id: 'label.futureEvents' }),
        events: nonRecurringEvents.filter(({ start }) =>
          dayjs(start).isAfter(dayjs().endOf('week'), 'day')
        ),
      },
      {
        title: formatMessage({ id: 'label.pastEvents' }),
        events: nonRecurringEvents.filter(({ start }) =>
          dayjs(start).isBefore(dayjs().startOf('week'), 'day')
        ),
      },
    ];
  }, [events, formatMessage]);

  const options = useMemo(
    () =>
      groupedEvents.reduce(
        (acc, group) => [
          ...acc,
          ...group.events.map((event) => ({
            event: event.eventId,
            group: group.title,
            label: event.recurringInformation?.recurring
              ? `${convertRRuleToText(
                  event.recurringInformation.rrule || ''
                )} ${formatMessage({ id: 'label.at' })} ${formatTime(
                  event.start,
                  {
                    timeStyle: 'short',
                  }
                )} - ${formatMessage({
                  id: `event.eventType.${event.eventType}`,
                })}`
              : `${formatDate(event.start, {
                  dateStyle: 'long',
                  timeStyle: 'short',
                })} - ${formatMessage({
                  id: `event.eventType.${event.eventType}`,
                })}`,
            ...event,
          })),
        ],
        [] as (Event & {
          label: string;
          group: string;
        })[]
      ),
    [formatDate, formatMessage, formatTime, groupedEvents]
  );

  const selectedEvent = useMemo(() => {
    if (selectedEventId) {
      return options.find(({ eventId }) => eventId === selectedEventId);
    }

    return null;
  }, [options, selectedEventId]);

  return (
    <Autocomplete<
      Event & {
        label: string;
        group: string;
      },
      false,
      true
    >
      {...args}
      disableClearable
      value={selectedEvent as Event & { label: string; group: string }}
      defaultValue={undefined}
      options={options}
      getOptionLabel={(option) => option.label || ''}
      getOptionKey={(option) => option.eventId}
      groupBy={(option) => option.group}
      isOptionEqualToValue={(option, value) =>
        option?.eventId === value?.eventId
      }
      renderInput={(params) => <TextField variant="filled" {...params} />}
    />
  );
};

const StyledEventsTextField = styled(EventsTextField)`
  .MuiInputBase-root {
    background: ${colors.lila[200]};
    border: none;
    border-radius: 3px;
    padding: 0 9px 0 11px;
    height: 40px;
    font-size: ${fontSizesWeb.sm};
    line-height: 18px;

    &.Mui-focused {
      background-color: ${colors.lila[200]};
    }

    .MuiInputBase-input.MuiFilledInput-input {
      &:focus {
        background: ${colors.lila[200]};
      }

      padding: 0;
      padding-right: 6px !important;
      min-height: inherit;
    }

    &:before,
    &:after {
      display: none;
    }

    &:hover {
      background: ${colors.lila[200]};
    }
  }
`;
export default StyledEventsTextField;
