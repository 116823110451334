import {
  EmailAddress,
  Gender,
  PhoneNumber,
  Salutation,
} from '@bas/value-objects';
import * as Yup from 'yup';

export type ContactPersonInputType = {
  firstName?: string | null;
  middleName?: string | null;
  lastName: string;
  emailAddress?: EmailAddress;
  phoneNumber?: PhoneNumber | null;
  mobileNumber?: PhoneNumber | null;
  gender?: Gender | null;
  salutation?: Salutation | null;
};

export const initialContactPersonInputType: ContactPersonInputType = {
  gender: Gender.UNKNOWN,
  salutation: Salutation.UNKNOWN,
  firstName: '',
  middleName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  mobileNumber: '',
};

export const ContactPersonInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<ContactPersonInputType> =>
    Yup.object({
      gender: Yup.mixed<Gender>()
        .oneOf(Object.values(Gender))
        .nullable()
        .label('label.gender'),
      salutation: Yup.mixed<Salutation>()
        .oneOf(Object.values(Salutation))
        .nullable()
        .label('label.salutation'),
      firstName: Yup.string().label('label.firstName').nullable(),
      middleName: Yup.string().label('label.middleName').nullable(),
      lastName: Yup.string().required().label('label.lastName'),
      emailAddress: Yup.string()
        .email()
        .required()
        .ensure()
        .label('label.emailAddress'),
      phoneNumber: Yup.string().phone().nullable().label('label.phoneNumber'),
      mobileNumber: Yup.string().phone().nullable().label('label.mobileNumber'),
    });
