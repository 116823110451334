import { useTimeTypesRequest } from '@bas/hrm-domain/requests';
import { formatDate } from '@bas/shared/utils';
import { MobileAppFeatureInputType } from '@bas/tenant-domain/input-types';
import { DefaultHourTypes, MobileAppFeature } from '@bas/tenant-domain/models';
import { TextField, TextFieldNumberFormat } from '@bas/ui/web/atoms';
import {
  Autocomplete,
  DatePickerField,
  FeatureHelpTooltip,
  Switch,
} from '@bas/ui/web/molecules';
import { EventType, Uuid } from '@bas/value-objects';
import { Grid, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type EditableMobileAppFeatureBlockProps = {
  feature: MobileAppFeature;
  disabled?: boolean;
  onUpdateMobileAppFeature: (
    values: MobileAppFeatureInputType
  ) => Promise<void>;
};

const mapDefaultHourTypes = (
  defaultHourTypes: DefaultHourTypes | undefined
): DefaultHourTypes | undefined => {
  if (!defaultHourTypes) {
    return undefined;
  }

  return {
    drivingHourTypeId: defaultHourTypes.drivingHourTypeId,
    whenAtTenantHourTypeId: defaultHourTypes.whenAtTenantHourTypeId,
    otherHourTypeId: defaultHourTypes.otherHourTypeId,
    breakTimeTypeId: defaultHourTypes.breakTimeTypeId,
    defaultHourTypesByEventTypes:
      defaultHourTypes.defaultHourTypesByEventTypes.map(
        ({ eventType, hourTypeId }) => ({
          eventType,
          hourTypeId,
        })
      ),
  };
};

const EditableMobileAppFeatureBlock = ({
  feature,
  disabled,
  onUpdateMobileAppFeature,
  ...args
}: EditableMobileAppFeatureBlockProps): ReactElement => {
  const [daysInput, setDaysInput] = useState<number>(
    feature.howManyDaysCanEmployeesLookAhead
  );
  const [minutesInput, setMinutesInput] = useState<number>(
    feature.timeMarginBeforeEventInMinutes
  );
  const [hoursAfterInput, setHoursAfterInput] = useState<
    undefined | Date | null
  >(feature.showHoursAfter);

  const [defaultHourTypes, setDefaultHourTypes] = useState<
    DefaultHourTypes | undefined
  >(feature.defaultHourTypes);

  const { data: timeTypesData, isLoading } = useTimeTypesRequest();

  const timeTypes = useMemo(
    () => timeTypesData?.data?.['hydra:member'] || [],
    [timeTypesData?.data]
  );

  const nonEventTypes = [
    'drivingHourTypeId',
    'whenAtTenantHourTypeId',
    'otherHourTypeId',
  ];

  useDebounce(
    () => {
      if (
        daysInput !== feature.howManyDaysCanEmployeesLookAhead ||
        minutesInput !== feature.timeMarginBeforeEventInMinutes ||
        (hoursAfterInput &&
          feature.showHoursAfter &&
          formatDate(hoursAfterInput) !== formatDate(feature.showHoursAfter)) ||
        (defaultHourTypes &&
          feature.defaultHourTypes &&
          !isEqual(
            mapDefaultHourTypes(defaultHourTypes),
            mapDefaultHourTypes(feature.defaultHourTypes)
          ))
      ) {
        onUpdateMobileAppFeature({
          ...feature,
          howManyDaysCanEmployeesLookAhead: daysInput,
          timeMarginBeforeEventInMinutes: minutesInput,
          showHoursAfter: hoursAfterInput ? formatDate(hoursAfterInput) : null,
          defaultHourTypes,
        });
      }
    },
    300,
    [
      defaultHourTypes,
      feature.defaultHourTypes,
      daysInput,
      feature.howManyDaysCanEmployeesLookAhead,
      minutesInput,
      feature.timeMarginBeforeEventInMinutes,
      hoursAfterInput,
      feature.showHoursAfter,
    ]
  );

  return (
    <Grid container spacing="12px" {...args}>
      <Grid item xs={12} pt={2}>
        <Typography variant="subtitle1">
          <FormattedMessage id="featureTypes.mobile-app" />
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Switch
            label={
              <FormattedMessage id="features.mobile-app.showEmployeeDetails" />
            }
            checked={feature.showEmployeeDetails}
            disabled={disabled}
            onChange={(e, val) =>
              onUpdateMobileAppFeature({
                ...feature,
                showEmployeeDetails: val,
                showHoursAfter: feature.showHoursAfter
                  ? formatDate(feature.showHoursAfter)
                  : null,
              })
            }
          />
        </Grid>
        <Grid item>
          <FeatureHelpTooltip
            title={
              <FormattedMessage id="features.mobile-app.showEmployeeDetails.help" />
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Switch
          label={
            <FormattedMessage id="features.mobile-app.allowEmployeesToSeeMondayOnFriday" />
          }
          checked={feature.allowEmployeesToSeeMondayOnFriday}
          disabled={disabled}
          onChange={(e, val) =>
            onUpdateMobileAppFeature({
              ...feature,
              allowEmployeesToSeeMondayOnFriday: val,
              showHoursAfter: feature.showHoursAfter
                ? formatDate(feature.showHoursAfter)
                : null,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Switch
          label={
            <FormattedMessage id="features.mobile-app.makeAcceptedQuoteVisibleForEmployee" />
          }
          checked={feature.makeAcceptedQuoteVisibleForEmployee}
          disabled={disabled}
          onChange={(e, val) =>
            onUpdateMobileAppFeature({
              ...feature,
              makeAcceptedQuoteVisibleForEmployee: val,
              showHoursAfter: feature.showHoursAfter
                ? formatDate(feature.showHoursAfter)
                : null,
            })
          }
        />
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={daysInput}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) => setDaysInput(parseFloat(e.target.value))}
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.mobile-app.howManyDaysCanEmployeesLookAhead"
              values={{
                numberOfDays: daysInput,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item>
          <TextField
            value={minutesInput}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) => setMinutesInput(parseFloat(e.target.value))}
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.mobile-app.timeMarginBeforeEventInMinutes"
              values={{
                minutes: minutesInput,
              }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <FeatureHelpTooltip
            title={
              <FormattedMessage id="features.mobile-app.timeMarginBeforeEventInMinutes.help" />
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item className="Bas-DateField">
          <DatePickerField
            value={dayjs(hoursAfterInput)}
            disabled={disabled}
            sx={{ width: '130px' }}
            onChange={(date) =>
              setHoursAfterInput(date as unknown as Date | null)
            }
          />
        </Grid>
        <Grid item>
          <Typography color="textPrimary">
            <FormattedMessage id="features.mobile-app.showHoursAfter" />
          </Typography>
        </Grid>
        <Grid item>
          <FeatureHelpTooltip
            title={
              <FormattedMessage id="features.mobile-app.showHoursAfter.help" />
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight={700}>
          <FormattedMessage id="label.defaultHourTypes" />
        </Typography>
      </Grid>
      {[
        ...nonEventTypes,
        ...(defaultHourTypes?.defaultHourTypesByEventTypes || []).map(
          ({ eventType }) => eventType
        ),
      ].map((type) => (
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item>
            <FormattedMessage
              id={
                nonEventTypes.includes(type)
                  ? `label.${type}`
                  : `planning.events.eventType.${type}`
              }
            />
          </Grid>
          <Grid item width={250}>
            <Autocomplete<{ value: Uuid; label: string } | string>
              value={
                nonEventTypes.includes(type)
                  ? (defaultHourTypes?.[
                      type as keyof typeof defaultHourTypes
                    ] as string | undefined)
                  : defaultHourTypes?.defaultHourTypesByEventTypes.find(
                      ({ eventType }) => eventType === type
                    )?.hourTypeId
              }
              disabled={isLoading || disabled}
              fullWidth
              textField={{
                fullWidth: true,
              }}
              options={timeTypes.map((timeType) => ({
                value: timeType.timeTypeId,
                label: timeType.name,
              }))}
              getOptionLabel={(option) => {
                if (!option) {
                  return '';
                }

                if (typeof option === 'string') {
                  const found = timeTypes.find(
                    (timeType) => timeType.timeTypeId === option
                  );

                  return found ? found.name : '';
                }

                return option.label;
              }}
              isOptionEqualToValue={(option, value) =>
                typeof option === 'string'
                  ? option === value
                  : option.value === value
              }
              onChange={(e, value) => {
                let newId: string | undefined;
                if (!value) {
                  return;
                }
                if (typeof value === 'string' && !!value) {
                  newId = value;
                } else if (typeof value === 'object' && !!value?.value) {
                  newId = value.value;
                }

                if (!newId) {
                  return;
                }
                if (nonEventTypes.includes(type)) {
                  setDefaultHourTypes((prev) =>
                    prev
                      ? {
                          ...prev,
                          [type]: newId as string,
                        }
                      : undefined
                  );
                } else {
                  setDefaultHourTypes((prev) =>
                    prev
                      ? {
                          ...prev,
                          defaultHourTypesByEventTypes: [
                            ...(
                              prev?.defaultHourTypesByEventTypes || []
                            ).filter(({ eventType }) => eventType !== type),
                            {
                              eventType: type as EventType,
                              hourTypeId: newId as string,
                            },
                          ],
                        }
                      : undefined
                  );
                }
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const StyledEditableMobileAppFeatureBlock = styled(
  EditableMobileAppFeatureBlock
)(
  () => `
  *:not(.MuiAutocomplete-root) > .MuiFormControl-root {
    width: 36px;
  }

  .Bas-DateField {
    .MuiFormControl-root {
      width: 130px;
    }
  }

  .MuiInput-input:not(.MuiAutocomplete-input) {
    text-align: center;
  }
`
);
export default StyledEditableMobileAppFeatureBlock;
