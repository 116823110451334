import {
  Integration,
  isWhatsAppSettings,
} from '@bas/integration-domain/models';
import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faBook, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { Grid, Link, Typography } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export type WhatsAppMessageTemplatesTabProps = {
  integration: Integration;
};

const WhatsAppMessageTemplatesTab = ({
  integration,
}: WhatsAppMessageTemplatesTabProps): ReactElement => {
  const openManageTemplates = useCallback((id: string) => {
    window.open(
      `https://business.facebook.com/wa/manage/message-templates/?waba_id=${id}`
    );
  }, []);

  return (
    <Grid container spacing={4}>
      {isWhatsAppSettings(integration.providerSettings) && (
        <>
          <Grid item xs={12}>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              <FormattedMessage id="label.whatsAppMessageTemplatesExplanation" />
            </Typography>
            <br />
            <Link
              href="https://en-gb.facebook.com/business/help/2055875911147364?id=2129163877102343"
              target="_blank"
              underline="none"
            >
              <Icon icon={faBook} />
              &nbsp;
              <FormattedMessage id="button.learnMore" />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.manageMessageTemplates" />
            </Typography>
            <Typography>
              <FormattedMessage id="label.manageMessageTemplatesExplain" />
            </Typography>
            <Grid container spacing={1} pt={1}>
              {integration.providerSettings.businesses.map((business) => (
                <Grid item xs={12} key={business.id}>
                  <Button
                    onClick={() => openManageTemplates(business.id)}
                    variant="outlined"
                    size="small"
                  >
                    <Icon icon={faExternalLink} />
                    &nbsp;
                    <FormattedMessage
                      id="button.manageTemplates"
                      values={{
                        name: business.name,
                      }}
                    />
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default WhatsAppMessageTemplatesTab;
