import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import {
  DocumentTemplate,
  isDocumentTemplate,
} from '@bas/tenant-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DocumentTemplateByTemplateIdRequestProps = {
  documentTemplateId: Uuid;
};

type Response = AxiosResponse<DocumentTemplate>;

export const DocumentTemplateByTemplateIdRequest = async ({
  documentTemplateId,
  ...params
}: DocumentTemplateByTemplateIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/document-templates/${documentTemplateId}`, {
    params: { ...params },
  });

export const useDocumentTemplateByTemplateIdRequest = (
  request: DocumentTemplateByTemplateIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => DocumentTemplateByTemplateIdRequest(request),
    queryKey: ['document-templates', 'detail', request.documentTemplateId],
  });

export const DocumentTemplateByTemplateIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isDocumentTemplate(data)) {
      queryClient.invalidateQueries({
        queryKey: ['document-templates', 'detail', data.documentTemplateId],
      });
    }
  };
