import { colors } from '@bas/theme';
import {
  styled,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
} from '@mui/material';

export type TabsProps = MuiTabsProps;

const Tabs = ({ children, ...args }: TabsProps) => (
  <MuiTabs {...args}>{children}</MuiTabs>
);

Tabs.defaultProps = {
  scrollButtons: 'auto',
  variant: 'scrollable',
};

const StyledTabs = styled(Tabs)(
  ({ theme }) => `
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 1;
    color: ${colors.lila[400]};
  }
`
);
export default StyledTabs;
