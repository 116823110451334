import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basSwingSet: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'swing-set',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M462 60c8.3 0 15-6.7 15-15s-6.7-15-15-15H67c-8.3 0-15 6.7-15 15s6.7 15 15 15h85.1L26.6 482.8c-2.4 7.9 2.2 16.3 10.1 18.6s16.3-2.2 18.6-10.1l126.5-426c.5-1.8 .7-3.5 .6-5.3H189V358H170c-8.3 0-15 6.7-15 15s6.7 15 15 15H359c8.3 0 15-6.7 15-15s-6.7-15-15-15H340V60h6c-.1 1.7 .1 3.5 .6 5.3l126.5 426c2.4 7.9 10.7 12.5 18.6 10.1s12.5-10.7 10.1-18.6L376.3 60H462zM310 60V358H219V60h91z',
  ],
};

export default basSwingSet;
