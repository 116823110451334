import { Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type ContentSectionEditActionProps = {
  onClick: () => void | Promise<void>;
  label?: ReactNode;
  disabled?: boolean;
};

const ContentSectionEditAction = ({
  onClick,
  label,
  disabled,
}: ContentSectionEditActionProps): ReactElement => (
  <Tooltip title={label || <FormattedMessage id="label.contentSection.edit" />}>
    <span>
      <IconButton
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={disabled}
        onClick={onClick}
      >
        <Icon icon={faPencil} />
      </IconButton>
    </span>
  </Tooltip>
);

export default ContentSectionEditAction;
