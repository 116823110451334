import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { InventoryOpenOrder, isInventoryEvent } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type OpenOrdersRequestProps = Pagination &
  Filterable & {
    inventoryItemId: Uuid;
  };

type Response = AxiosResponse<Collection<InventoryOpenOrder>>;

export const OpenOrdersRequest = async ({
  inventoryItemId,
  perPage,
  page,
  ...params
}: OpenOrdersRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/inventory/item/${inventoryItemId}/open-orders`, {
    params: { ...params, perPage: perPage || 100, page: page || 1 },
  });

export const useOpenOrdersRequest = (
  request: OpenOrdersRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => OpenOrdersRequest(request),
    queryKey: ['moving-boxes', 'open-orders', request.inventoryItemId, request],
  });

export const OpenOrdersRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isInventoryEvent(data) && data.inventoryItemId) {
    queryClient.invalidateQueries({
      queryKey: ['moving-boxes', 'open-orders', data.inventoryItemId],
    });
  }
};
