import { useHasBackendRight } from '@bas/shared/hooks';
import {
  useAuthStore,
  useEmployeeStore,
  useIntercomStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { formatDate } from '@bas/shared/utils';
import { InternalTenant } from '@bas/tenant-domain/models';
import { useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation } from 'react-router-dom';
import { IntercomProps, useIntercom } from 'react-use-intercom';

const mapTenantToCompany = (tenant: InternalTenant) => ({
  companyId: tenant.tenantId,
  name: tenant.name,
  createdAt: formatDate(tenant.createdAt),
  customAttributes: {
    'Tenant ID': tenant.tenantId,
  },
});

const BootIntercom = (): null => {
  const user = useUserStore((state) => state.user);
  const employeeState = useEmployeeStore((state) => state.employee);
  const { setBootedIntercom, bootedIntercom } = useIntercomStore();
  const tenant = useTenantStore((state) => state.internalTenant);
  const { ...authState } = useAuthStore();
  const { pathname } = useLocation();
  const { boot, shutdown, update } = useIntercom();
  const companies = useMemo(
    () => (tenant ? [mapTenantToCompany(tenant)] : []),
    // @TODO
    // const mappedTenants = (user?.accessToTenants || [])
    //   .filter(({ tenantId }) => tenantId !== tenant?.tenantId)
    //   .map(({ tenantId }) => mapTenantToCompany(t));
    //
    // return tenant
    //   ? [mapTenantToCompany(tenant), ...mappedTenants]
    //   : mappedTenants;
    [tenant]
  );

  const hasBackendRights = useHasBackendRight();
  const [bootProps, setBootProps] = useState<IntercomProps>();

  useEffect(() => {
    if (employeeState && tenant && user) {
      const newBootProps: IntercomProps = {
        horizontalPadding: 25,
        verticalPadding: 110,
        userId: user.userId,
        userHash: authState.intercomHash,
        phone: employeeState.contactInformation?.phoneNumber || undefined,
        name: employeeState.personName?.fullName,
        email: employeeState.contactInformation?.emailAddress,
        createdAt: employeeState.createdAt
          ? formatDate(employeeState.createdAt)
          : undefined,
        languageOverride: employeeState.language,
        company: mapTenantToCompany(tenant),
        companies,
        avatar: {
          type: 'avatar',
          imageUrl: employeeState.profilePictureUrl,
        },
        hideDefaultLauncher:
          pathname.includes('/app') || pathname.includes('/quotes/accept'),
        customAttributes: {
          backoffice: hasBackendRights,
        },
      };

      if (!isEqual(bootProps, newBootProps)) {
        setBootProps(newBootProps);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authState.intercomHash,
    hasBackendRights,
    boot,
    companies,
    employeeState,
    shutdown,
    tenant,
  ]);

  useEffect(() => {
    if (bootProps && !bootedIntercom) {
      boot(bootProps);
      setBootedIntercom(true);
    } else {
      update(bootProps);
    }
  }, [boot, bootProps, bootedIntercom, setBootedIntercom, update]);

  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    if (pathname.includes('/app') || pathname.includes('/quotes/accept')) {
      if (!isHidden) {
        update({ hideDefaultLauncher: true });
        setIsHidden(true);
      }
    } else if (isHidden) {
      update({ hideDefaultLauncher: false });
      setIsHidden(false);
    }
  }, [update, pathname, isHidden]);

  return null;
};

export default BootIntercom;
