import { colors, fontSizesWeb } from '@bas/theme';
import {
  styled,
  TableHead as MuiTableHead,
  TableHeadProps as MuiTableHeadProps,
} from '@mui/material';

export type TableHeadProps = MuiTableHeadProps;

const TableHead = ({ ...args }: TableHeadProps) => <MuiTableHead {...args} />;

TableHead.defaultProps = {};

const StyledTableHead = styled(TableHead)(
  ({ theme }) => `
.MuiTableCell-root {
  padding: ${theme.spacing(2, 0, 1, 0)};
  font-size: ${fontSizesWeb.sm};
  font-weight: bold;
  line-height: 18px;
  color: ${colors.lila[800]};
  border-bottom: 0.5px solid ${colors.lila[400]};
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
`
);
export default StyledTableHead;
