import {
  DayOfInterestsByEmployeeIdRequestInvalidator,
  DeclarationByDeclarationIdRequestQueryInvalidator,
  DeclarationCategoriesRequestInvalidator,
  DeclarationCategoryByCategoryIdInvalidator,
  DeclarationsByEmployeeIdRequestInvalidator,
  DeclarationsRequestInvalidator,
  DeclarationStatisticsByEmployeeIdRequestInvalidator,
  DeclarationStatisticsRequestInvalidator,
  EmployeeByEmployeeIdRequestInvalidator,
  EmployeeOccasionalAvailabilitiesByEmployeeIdRequestInvalidator,
  EmployeesRequestInvalidator,
  HourEntriesByEmployeeIdRequestInvalidator,
  HourEntriesByEntryIdsRequestInvalidator,
  HourEntriesByEventIdRequestInvalidator,
  HourEntriesByProjectIdRequestInvalidator,
  HoursInsightByEmployeeIdRequestInvalidator,
  HoursOverviewRequestInvalidator,
  NationalHolidayByNationalHolidayIdRequestQueryInvalidator,
  NationalHolidaysRequestInvalidator,
  TeamByTeamIdRequestInvalidator,
  TeamsRequestInvalidator,
  TimeEntriesByEmployeeIdRequestInvalidator,
  TimeEntriesRequestInvalidator,
  TimeEntryByTimeEntryIdRequestQueryInvalidator,
  TimeOffBalancesRequestInvalidator,
  TimeOffRegistrationByTimeOffIdRequestQueryInvalidator,
  TimeOffRegistrationsByEmployeeIdRequestInvalidator,
  TimeOffRegistrationsRequestInvalidator,
  TimeOffStatisticsByEmployeeIdRequestInvalidator,
  TimeOffTypeByTimeOffTypeIdInvalidator,
  TimeOffTypesRequestInvalidator,
  TimeTypeByTimeTypeIdInvalidator,
  TimeTypeCategoriesRequestInvalidator,
  TimeTypesRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  DayOfInterestsByEmployeeIdRequestInvalidator,
  DeclarationByDeclarationIdRequestQueryInvalidator,
  DeclarationCategoriesRequestInvalidator,
  DeclarationCategoryByCategoryIdInvalidator,
  DeclarationsByEmployeeIdRequestInvalidator,
  DeclarationsRequestInvalidator,
  DeclarationStatisticsByEmployeeIdRequestInvalidator,
  DeclarationStatisticsRequestInvalidator,
  EmployeeByEmployeeIdRequestInvalidator,
  EmployeeOccasionalAvailabilitiesByEmployeeIdRequestInvalidator,
  EmployeesRequestInvalidator,
  HourEntriesByEmployeeIdRequestInvalidator,
  HourEntriesByEntryIdsRequestInvalidator,
  HourEntriesByEventIdRequestInvalidator,
  HourEntriesByProjectIdRequestInvalidator,
  HoursInsightByEmployeeIdRequestInvalidator,
  HoursOverviewRequestInvalidator,
  NationalHolidayByNationalHolidayIdRequestQueryInvalidator,
  NationalHolidaysRequestInvalidator,
  TeamByTeamIdRequestInvalidator,
  TeamsRequestInvalidator,
  TimeEntriesByEmployeeIdRequestInvalidator,
  TimeEntriesRequestInvalidator,
  TimeEntryByTimeEntryIdRequestQueryInvalidator,
  TimeOffBalancesRequestInvalidator,
  TimeOffRegistrationByTimeOffIdRequestQueryInvalidator,
  TimeOffRegistrationsByEmployeeIdRequestInvalidator,
  TimeOffRegistrationsRequestInvalidator,
  TimeOffStatisticsByEmployeeIdRequestInvalidator,
  TimeOffTypeByTimeOffTypeIdInvalidator,
  TimeOffTypesRequestInvalidator,
  TimeTypeByTimeTypeIdInvalidator,
  TimeTypeCategoriesRequestInvalidator,
  TimeTypesRequestInvalidator,
];
