export enum InventoryEventType {
  INVENTORY_IN = 'inventory-in',
  INVENTORY_OUT = 'inventory-out',
  INVENTORY_OUT_STORAGE = 'inventory-out-storage',
  INVENTORY_SOLD = 'inventory-sold',
  WORN_OUT = 'worn-out',
  ORDERED_INVENTORY = 'ordered-inventory',
  RECEIVED_ORDER = 'received-order',
  STORAGE = 'storage',
}
