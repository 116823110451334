import { useValidateVatNumberMutation } from '@bas/shared/mutations';
import { registerCustomYupTypes } from '@bas/shared/utils';
import { yupNlLocale } from '@bas/translations/yupNlLocale';
import { yupTranslatePath } from '@bas/translations/yupTranslatePath';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { setLocale } from 'yup';

const validatedVatNumbers: { [key: string]: boolean } = {};
const isValidatingVatNumbers: { [key: string]: boolean } = {};

export const useYupTranslations = (): boolean => {
  const didSetYupLocale = useRef(false);
  const { formatMessage } = useIntl();
  const [finished, setFinished] = useState(false);

  const { mutateAsync: validateVatNumber } = useValidateVatNumberMutation();

  const handleValidateVatNumber = useCallback(
    async (value: string) => {
      if (isValidatingVatNumbers[value]) {
        await new Promise((r) => {
          setTimeout(r, 1000);
        });

        return validatedVatNumbers[value] || false;
      }

      if (typeof validatedVatNumbers[value] !== 'undefined') {
        return validatedVatNumbers[value];
      }

      isValidatingVatNumbers[value] = true;

      let isValid: boolean;
      try {
        const result = await validateVatNumber({
          vatNumber: value,
        });

        isValid = result?.status === 202;
      } catch (e) {
        isValid = false;
      }

      validatedVatNumbers[value] = isValid;
      isValidatingVatNumbers[value] = false;

      return isValid;
    },
    [validateVatNumber]
  );

  useEffect(() => {
    if (didSetYupLocale.current) {
      return;
    }

    const yupLocale = yupTranslatePath(yupNlLocale, formatMessage);

    setLocale(yupLocale);
    registerCustomYupTypes(
      yupLocale,
      handleValidateVatNumber as (value: string) => Promise<boolean>
    );
    didSetYupLocale.current = true;
    setFinished(true);
  }, [formatMessage, handleValidateVatNumber]);

  return finished;
};
