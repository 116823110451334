import { isObjectResponse } from '@bas/value-objects';
import { WebsiteSettings } from '../../types';

export function isWebsiteSettings(object: unknown): object is WebsiteSettings {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'WebsiteSettings'
  );
}
