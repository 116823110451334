import { oAuthProviders } from '@bas/integration-domain/models';
import {
  useDisconnectIntegrationMutation,
  useRemoveIntegrationMutation,
} from '@bas/integration-domain/mutations';
import { useIntegrationByIntegrationIdRequest } from '@bas/integration-domain/requests';
import { Button } from '@bas/ui/web/atoms';
import { WorkflowButtons, WorkflowItem } from '@bas/ui/web/molecules';
import { ConfirmDialog } from '@bas/ui/web/organisms';
import { Uuid } from '@bas/value-objects';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export type IntegrationActionButtonsProps = {
  integrationId: Uuid;
};

const IntegrationActionButtons = ({
  integrationId,
}: IntegrationActionButtonsProps): ReactElement | null => {
  const navigate = useNavigate();
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>();
  const { data: integrationData } = useIntegrationByIntegrationIdRequest({
    integrationId,
  });

  const queryClient = useQueryClient();

  const integration = useMemo(() => integrationData?.data, [integrationData]);
  const { mutateAsync: disconnectIntegration } =
    useDisconnectIntegrationMutation();
  const { mutateAsync: removeIntegration } = useRemoveIntegrationMutation();

  const handleReconnect = useCallback(() => {
    if (
      !integration?.provider ||
      integration?.authentication?.connected ||
      !integration?.integrationId
    ) {
      return;
    }

    if (oAuthProviders.includes(integration?.provider)) {
      window.open(
        `${import.meta.env.VITE_API_URL}connect/${integration?.provider}/${
          integration?.integrationId
        }`
      );
    }
  }, [
    integration?.authentication?.connected,
    integration?.integrationId,
    integration?.provider,
  ]);

  const handleDisconnectIntegration = useCallback(async () => {
    if (!integration || !integration.authentication?.connected) {
      return;
    }

    await disconnectIntegration({
      integrationId: integration.integrationId,
    });
  }, [disconnectIntegration, integration]);

  const handleRemoveIntegration = useCallback(async () => {
    if (!integration || integration.authentication?.connected) {
      return;
    }

    await removeIntegration({
      integrationId: integration.integrationId,
    });
    queryClient.invalidateQueries({ queryKey: ['integrations', 'list'] });
    navigate('..');
  }, [queryClient, integration, navigate, removeIntegration]);

  const disconnectAction: WorkflowItem = useMemo(
    () => ({
      label: <FormattedMessage id="button.disconnectIntegration" />,
      variant: 'outlined',
      color: 'error',
      dialog: (
        <ConfirmDialog
          open
          onCancel={() => setActiveButtonIndex(undefined)}
          onClose={() => setActiveButtonIndex(undefined)}
          onConfirm={async () => {
            await handleDisconnectIntegration();
            setActiveButtonIndex(undefined);
          }}
          dangerous
          title={
            <FormattedMessage
              id="integration.areYouSureYouWantToDisconnectIntegration.title"
              values={{ provider: integration?.provider }}
            />
          }
          content={
            <Typography
              whiteSpace="pre-wrap"
              sx={{ overflowWrap: 'break-word' }}
            >
              <FormattedMessage
                id="integration.areYouSureYouWantToDisconnectIntegration.content"
                values={{ provider: integration?.provider }}
              />
            </Typography>
          }
        />
      ),
    }),
    [handleDisconnectIntegration, integration?.provider]
  );

  const removeAction: WorkflowItem = useMemo(
    () => ({
      label: <FormattedMessage id="button.removeIntegration" />,
      variant: 'outlined',
      color: 'error',
      dialog: (
        <ConfirmDialog
          open
          onCancel={() => setActiveButtonIndex(undefined)}
          onClose={() => setActiveButtonIndex(undefined)}
          onConfirm={async () => {
            await handleRemoveIntegration();
            setActiveButtonIndex(undefined);
          }}
          dangerous
          title={
            <FormattedMessage
              id="integration.areYouSureYouWantToRemoveIntegration.title"
              values={{ provider: integration?.provider }}
            />
          }
          content={
            <Typography
              whiteSpace="pre-wrap"
              sx={{ overflowWrap: 'break-word' }}
            >
              <FormattedMessage
                id="integration.areYouSureYouWantToRemoveIntegration.content"
                values={{ provider: integration?.provider }}
              />
            </Typography>
          }
        />
      ),
    }),
    [handleRemoveIntegration, integration?.provider]
  );

  const connectAction: WorkflowItem = useMemo(
    () => ({
      label: <FormattedMessage id="button.connectIntegration" />,
      buttonComponent: (
        <Button onClick={handleReconnect}>
          <FormattedMessage id="button.connectIntegration" />
        </Button>
      ),
    }),
    [handleReconnect]
  );

  const workflow = useMemo(() => {
    if (!integration) {
      return [];
    }

    const result: {
      [key: string]: WorkflowItem[];
    } = {
      connected: [disconnectAction],
      disconnected: [connectAction, removeAction],
    };

    if (integration.authentication?.connected) {
      return result.connected;
    }

    return result.disconnected;
  }, [connectAction, disconnectAction, integration, removeAction]);

  if (!integration) {
    return null;
  }

  return (
    <WorkflowButtons
      workflow={workflow}
      activeButtonIndex={activeButtonIndex}
      setActiveButtonIndex={setActiveButtonIndex}
    />
  );
};

export default IntegrationActionButtons;
