import { isInvoiceEvent } from './InvoiceEvent';
import { InvoiceEventType, SendingInvoiceFailedEvent } from '../types';

export function isSendingInvoiceFailedEvent(
  object: unknown
): object is SendingInvoiceFailedEvent {
  return (
    isInvoiceEvent(object) &&
    object.eventType === InvoiceEventType.SendingInvoiceFailedEvent
  );
}
