import { PricingType, Uuid } from '@bas/value-objects';
import * as Yup from 'yup';

export interface VerhuisOffertesSettingsInputType {
  packageId: Uuid;
  identityId?: Uuid | null;
  pricingType: PricingType;
}

export const VerhuisOffertesSettingsInputTypeDefaultValues =
  (): VerhuisOffertesSettingsInputType => ({
    packageId: '',
    pricingType: PricingType.UNKNOWN,
    identityId: '',
  });

export const VerhuisOffertesSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<VerhuisOffertesSettingsInputType> =>
    Yup.object({
      packageId: Yup.string().required().label('label.packageId'),
      identityId: Yup.string().nullable().label('label.identityId'),
      pricingType: Yup.mixed<PricingType>()
        .label('label.pricingType')
        .oneOf(Object.values(PricingType))
        .required(),
    });
