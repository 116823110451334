import { isObjectResponse } from '@bas/value-objects';
import type { Task } from '../types';

export function isTask(object: unknown): object is Task {
  return (
    !!object &&
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'taskType')
  );
}
