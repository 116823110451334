import {
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps as MuiAccordionDetailsProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type AccordionDetailsProps = MuiAccordionDetailsProps;

const AccordionDetails = ({
  className,
  children,
  ...props
}: AccordionDetailsProps) => (
  <MuiAccordionDetails className={className} {...props}>
    {children}
  </MuiAccordionDetails>
);

const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => `
  padding: ${theme.spacing(0, 0, 3, 0)};
  `
);
export default StyledAccordionDetails;
