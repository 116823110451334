import {
  AutomatedEmail,
  AutomatedEmailType,
  isAutomatedEmail,
} from '@bas/communication-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AutomatedEmailsRequestProps = Pagination &
  Filterable & {
    tenantId: Uuid;
    enabled?: boolean;
    'automatedEmailType.value'?: AutomatedEmailType[] | AutomatedEmailType;
    identityId?: Uuid;
  };

type Response = AxiosResponse<Collection<AutomatedEmail>>;

export const AutomatedEmailsRequest = async ({
  ...params
}: AutomatedEmailsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/communication/automated-emails`, {
    params: { ...params },
  });

export const useAutomatedEmailsRequest = (
  request: AutomatedEmailsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => AutomatedEmailsRequest(request),
    queryKey: ['automated-emails', 'list', request.tenantId, request],
  });

export const AutomatedEmailsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isAutomatedEmail(data)) {
    queryClient.invalidateQueries({
      queryKey: ['automated-emails', 'list', data.tenantId],
    });
  }
};
