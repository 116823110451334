import { BillingMomentType, QuoteMoment } from '@bas/shared/models';
import { basTruckQuestion } from '@bas/svg-icons';
import { colors, fontSizesWeb } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import { ProjectType } from '@bas/value-objects';
import {
  faArrowRightToBracket,
  faBallotCheck,
  faCheck,
  faFileContract,
  faMoneyCheckDollar,
  faTruck,
} from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  Step,
  StepButton,
  Stepper,
  StepperProps,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type BillingMomentsStepperProps = StepperProps & {
  quoteMoment?: QuoteMoment;
  hideQuote?: boolean;
  billedBillingMomentTypes: BillingMomentType[];
  usedBillingMomentTypes: BillingMomentType[];
  activeBillingMomentType?: BillingMomentType | 'quote';
  onChangeActiveBillingMomentType?: (
    value: BillingMomentType | 'quote'
  ) => void;
  projectType?: ProjectType;
};

const steps = [
  {
    billingMomentType: BillingMomentType.BILLING_MOMENT_AFTER_ACCEPTED_QUOTE,
    label: (
      <FormattedMessage
        id={`billingMoments.${BillingMomentType.BILLING_MOMENT_AFTER_ACCEPTED_QUOTE}`}
      />
    ),
    icon: basTruckQuestion,
  },
  {
    billingMomentType: BillingMomentType.BILLING_MOMENT_BEFORE_PROJECT_STARTS,
    label: (
      <FormattedMessage
        id={`billingMoments.${BillingMomentType.BILLING_MOMENT_BEFORE_PROJECT_STARTS}`}
      />
    ),
    icon: faTruck,
  },
  {
    billingMomentType:
      BillingMomentType.BILLING_MOMENT_AFTER_STORAGE_WAS_LOADED,
    label: (
      <FormattedMessage
        id={`billingMoments.${BillingMomentType.BILLING_MOMENT_AFTER_STORAGE_WAS_LOADED}`}
      />
    ),
    icon: faArrowRightToBracket,
  },
  {
    billingMomentType:
      BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED,
    label: (
      <FormattedMessage
        id={`billingMoments.${BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED}`}
      />
    ),
    icon: faBallotCheck,
  },
  {
    billingMomentType: BillingMomentType.BILLING_MOMENT_INVOICE_WITHOUT_PROJECT,
    label: (
      <FormattedMessage
        id={`billingMoments.${BillingMomentType.BILLING_MOMENT_INVOICE_WITHOUT_PROJECT}`}
      />
    ),
    icon: faBallotCheck,
  },
];

const allowedBillingMomentTypes = {
  manual: [BillingMomentType.BILLING_MOMENT_INVOICE_WITHOUT_PROJECT],
  [ProjectType.MOVING_JOB]: [
    BillingMomentType.BILLING_MOMENT_AFTER_ACCEPTED_QUOTE,
    BillingMomentType.BILLING_MOMENT_BEFORE_PROJECT_STARTS,
    BillingMomentType.BILLING_MOMENT_AFTER_STORAGE_WAS_LOADED,
    BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED,
  ],
  [ProjectType.MOVING_LIFT_JOB]: [
    BillingMomentType.BILLING_MOMENT_AFTER_ACCEPTED_QUOTE,
    BillingMomentType.BILLING_MOMENT_BEFORE_PROJECT_STARTS,
    BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED,
  ],
  [ProjectType.TRANSPORT_JOB]: [
    BillingMomentType.BILLING_MOMENT_AFTER_ACCEPTED_QUOTE,
    BillingMomentType.BILLING_MOMENT_BEFORE_PROJECT_STARTS,
    BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED,
  ],
};

const BillingMomentsStepper = ({
  usedBillingMomentTypes,
  billedBillingMomentTypes,
  activeBillingMomentType,
  onChangeActiveBillingMomentType,
  quoteMoment,
  hideQuote,
  projectType,
  ...args
}: BillingMomentsStepperProps): ReactElement => (
  <Stepper
    orientation="horizontal"
    alternativeLabel
    activeStep={1}
    nonLinear
    {...args}
  >
    {!hideQuote && (
      <Step
        completed={quoteMoment?.enabled || !projectType}
        className={clsx({
          'Bas-BillingMomentsStepper-Active':
            activeBillingMomentType === 'quote',
        })}
      >
        <StepButton
          onClick={
            onChangeActiveBillingMomentType
              ? () => onChangeActiveBillingMomentType('quote')
              : undefined
          }
          disableRipple
          disableTouchRipple
          icon={
            <>
              <Icon icon={faFileContract} />
              <Box className="Bas-BillingMomentsStepper-CompletedIcon">
                <Icon icon={faMoneyCheckDollar} />
              </Box>
            </>
          }
        >
          <FormattedMessage id="label.quote" />
        </StepButton>
      </Step>
    )}
    {steps
      .filter(
        ({ billingMomentType }) =>
          !allowedBillingMomentTypes[projectType || 'manual'] ||
          allowedBillingMomentTypes[projectType || 'manual'].includes(
            billingMomentType
          )
      )
      .map(({ billingMomentType, label, icon }) => (
        <Step
          key={billingMomentType}
          completed={
            usedBillingMomentTypes.includes(billingMomentType) ||
            billedBillingMomentTypes.includes(billingMomentType) ||
            !projectType
          }
          className={clsx(
            {
              'Bas-BillingMomentsStepper-Active':
                activeBillingMomentType === billingMomentType,
            },
            {
              'Bas-BillingMomentsStepper-Invoiced':
                billedBillingMomentTypes.includes(billingMomentType),
            }
          )}
        >
          <StepButton
            onClick={
              onChangeActiveBillingMomentType
                ? () => onChangeActiveBillingMomentType(billingMomentType)
                : undefined
            }
            disableRipple
            disableTouchRipple
            icon={
              <>
                <Icon
                  icon={
                    billedBillingMomentTypes.includes(billingMomentType)
                      ? faCheck
                      : icon
                  }
                />
                <Box className="Bas-BillingMomentsStepper-CompletedIcon">
                  <Icon icon={faMoneyCheckDollar} />
                </Box>
              </>
            }
          >
            {label}
          </StepButton>
        </Step>
      ))}
  </Stepper>
);

const StyledBillingMomentsStepper = styled(BillingMomentsStepper)(
  ({ theme }) => `
  .Bas-BillingMomentsStepper-CompletedIcon, .Bas-BillingMomentsStepper-InvoicedIcon {
    display: flex;
    opacity: 0;
    position: absolute;
    top: -9px;
    right: -3px;
    height: 32px;
    width: 32px;
    background: ${colors.green[300]};
    color: ${theme.palette.common.white};
    font-size: 19px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
  }

  .MuiStepLabel-iconContainer {
    border: 3px solid ${colors.lila[800]};
    height: 83px;
    width: 83px;
    justify-content: center;
    align-items: center;
    border-radius: 83px;
    font-size: 40px;
    color: ${colors.lila[800]};
    margin: 0;
    position: relative;
    transition: background-color 100ms ease, color 100ms ease;
  }

  .MuiStepLabel-label {
    color: ${colors.lila[800]};
    font-size: ${fontSizesWeb.base};
    font-weight: bold;
    line-height: 19px;
  }

  .MuiStep-root {
    padding: 0 27px;
    &:first-of-type {
      margin-left: -27px;
    }

    &:last-of-type {
      margin-right: -27px;
    }

    .MuiStepConnector-root {
      top: 41.5px;
      left: calc(-50% + 41.5px);
      right: calc(50% + 41.5px);
      .MuiStepConnector-line {
        background: ${colors.lila[800]};
        border: none;
        height: 3px;
      }
    }
  }

  .Mui-completed {
    .MuiStepLabel-label {
      font-weight: bold;
    }

    .MuiStepLabel-iconContainer {
      color: ${colors.green[300]};
    }

    .Bas-BillingMomentsStepper-CompletedIcon {
      opacity: 1;
    }
  }

  .Bas-BillingMomentsStepper-Active {
    .MuiStepLabel-label {
      color: ${colors.blue[500]};
    }

    .MuiStepLabel-iconContainer {
      background: ${colors.blue[500]};
      color: ${theme.palette.common.white};
    }
  }
`
);
export default StyledBillingMomentsStepper;
