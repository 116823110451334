import * as Yup from 'yup';
import {
  AccountingIntegrationSettingsInputType,
  AccountingIntegrationSettingsInputTypeDefaultValues,
  AccountingIntegrationSettingsInputTypeValidationBuilder,
} from '../accountingIntegrationSettingsInputType';

export type SnelStartSettingsInputType = AccountingIntegrationSettingsInputType;

export const SnelStartSettingsInputTypeDefaultValues =
  (): SnelStartSettingsInputType => ({
    ...AccountingIntegrationSettingsInputTypeDefaultValues(),
  });

export const SnelStartSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<SnelStartSettingsInputType> =>
    AccountingIntegrationSettingsInputTypeValidationBuilder();
