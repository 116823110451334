import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { useEffect, useState } from 'react';

const { languages } = navigator;
const messages = {
  nl: 'We zijn Bas aan het laden... Even geduld!',
  en: 'We are loading Bas... Please wait!',
};

const language =
  languages.find((l) => Object.keys(messages).includes(l)) ?? 'en';

const message = messages[language as keyof typeof messages];

const BootPage = () => {
  const query = window.matchMedia('(prefers-color-scheme: dark)');
  const [isDark, setIsDark] = useState(query.matches);
  useEffect(() => {
    const listener = (e: MediaQueryListEvent) => {
      setIsDark(e.matches);
    };

    query.addEventListener('change', listener);

    return () => {
      query.removeEventListener('change', listener);
    };
  }, [query]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        background: isDark ? '#021B4E' : '#FAFAFB',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faSpinnerThird}
          size="4x"
          spin
          color={isDark ? '#F0F5FF' : '#021B4E'}
        />
        <p
          style={{
            color: isDark ? '#F0F5FF' : '#464457',
            textAlign: 'center',
          }}
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default BootPage;
