import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basSecretary: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'secretary',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M110 52c-8.3 0-15 6.7-15 15V228H36c-8.3 0-15 6.7-15 15V419c0 8.3 6.7 15 15 15h70l6 42h36l6-42H360l6 42h36l6-42h69c8.3 0 15-6.7 15-15V243c0-8.3-6.7-15-15-15H419V67c0-8.3-6.7-15-15-15H110zm279 69H125V82H389v39zm0 30v77H125V151H389zM51 258H241V404H51V258zM462 404H271V258H462V404z',
  ],
};

export default basSecretary;
