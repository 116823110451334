import { colors } from '@bas/theme';
import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  styled,
} from '@mui/material';

export type ListItemButtonProps = MuiListItemButtonProps;

const ListItemButton = ({ ...args }: ListItemButtonProps) => (
  <MuiListItemButton {...args} />
);

ListItemButton.defaultProps = {
  disableGutters: true,
  disableRipple: true,
};

const StyledListItemButton = styled(ListItemButton)(
  ({ theme }) => `
  padding: 0;
  justify-content: center;

  &:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible {
    background: inherit;
    color: ${colors.blue[500]};

    .MuiTypography-root, .MuiTypography-root span {
      color: ${colors.blue[500]};
    }
  }
`
);
export default StyledListItemButton;
