import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basDressboy: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'dressboy',
  icon: [
    325,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M162.5 36.1c-9.7 0-17.6 7.9-17.6 17.6c0 8.1-6.6 14.6-14.6 14.6s-14.6-6.6-14.6-14.6c0-25.9 21-46.8 46.8-46.8s46.8 21 46.8 46.8c0 20.8-13.5 38.4-32.2 44.5V119L305 187c5.9 3.2 9 9.9 7.3 16.5s-7.5 11.1-14.2 11.1h-121l0 202c26.3 1.6 50.4 7.7 69.1 17c21.3 10.6 40.1 27.9 40.1 51.5v18.8H257.1V485.1c0-6.4-5.6-16.2-23.9-25.3c-17.4-8.6-42.3-14.4-70.8-14.4s-53.4 5.8-70.8 14.4c-18.2 9.1-23.9 18.9-23.9 25.3v18.8H38.6V485.1c0-23.6 18.8-40.9 40.1-51.5c18.8-9.3 42.9-15.4 69.1-17l0-202H26.9c-6.7 0-12.6-4.6-14.2-11.1s1.4-13.3 7.3-16.5l127.8-68V85.9c0-8.1 6.6-14.6 14.6-14.6c9.7 0 17.6-7.9 17.6-17.6s-7.9-17.6-17.6-17.6zm0 108.3L85.6 185.3H239.4l-76.9-40.9z',
  ],
};

export default basDressboy;
