import { colors, fontSizesWeb } from '@bas/theme';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { forwardRef, ReactElement } from 'react';

export type ChipProps = MuiChipProps & {
  numerical?: boolean;
  disableMinWidth?: boolean;
};

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    // eslint-disable-next-line react/prop-types
    { numerical, disableMinWidth, className, ...props }: ChipProps,
    ref
  ): ReactElement => (
    <MuiChip
      {...props}
      ref={ref}
      className={clsx(
        className,
        { 'Bas-Chip-DisableMinWidth': disableMinWidth || numerical },
        { 'Bas-Chip-Numerical': numerical }
      )}
    />
  )
);

Chip.defaultProps = {
  numerical: false,
  disableMinWidth: false,
};

const StyledChip = styled(Chip)(
  ({ theme }) => `
  background: ${colors.lila[200]};
  color: ${colors.lila[700]};
  height: 28px;

  &.MuiChip-filledSecondary {
    background: ${theme.palette.common.white};
    color: ${colors.lila[700]};
  }

  &.MuiChip-filledPrimary {
    background: ${colors.blue[500]};
    color: ${theme.palette.common.white};
  }

  &.MuiChip-filledSuccess {
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
  }


  &.MuiChip-filledError {
    background: ${theme.palette.error.main};
    color: ${theme.palette.error.contrastText};
  }

  font-size: ${fontSizesWeb.sm};
  min-width: 127px;
  .MuiChip-label {
    font-weight: normal;
  }

  &.Bas-Chip-Numerical {
    color: ${colors.lila[800]};
      height: 22px;

    &.MuiChip-filledPrimary {
      color: ${theme.palette.common.white};
      border-radius: 100px;
      min-width: 25px;
      height: 22px;

      .MuiChip-label {
        min-width: 25px;
        padding: 2px 10px;
        box-sizing: border-box;
      }
    }

    .MuiChip-label {
      font-weight: bold;
    }
  }

  &.Bas-Chip-DisableMinWidth {
    min-width: inherit;
  }
`
);
export default StyledChip;
