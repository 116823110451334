import { useTenantStore } from '@bas/shared/state';
import { useTenantByTenantIdRequestQuery } from '@bas/tenant-domain/requests';
import { TextField, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { FeatureHelpTooltip } from '@bas/ui/web/molecules';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  Grid,
  GridProps,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

const IncomingEmailFeatureContentSection = ({
  ...args
}: GridProps): ReactElement => {
  const tenantState = useTenantStore((state) => state.internalTenant);
  const { data: tenantData } = useTenantByTenantIdRequestQuery(
    {
      tenantId: tenantState?.tenantId || '',
    },
    { enabled: !!tenantState }
  );

  return (
    <Grid container columnSpacing={1} {...args}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.incomingEmail" />
        </Typography>
      </Grid>
      <Grid item xs={12} spacing={1} container>
        {(tenantData?.data?.incomingEmailAddresses || []).map(
          (emailAddress) => (
            <Grid
              item
              xs={12}
              spacing={1}
              container
              alignItems="center"
              key={emailAddress}
            >
              <Grid item>
                <Typography>
                  {!emailAddress && <Skeleton variant="text" />}
                  {emailAddress && (
                    <TextField
                      id="emailAddress"
                      value={emailAddress}
                      variant="outlined"
                      sx={{ padding: 0 }}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={<FormattedMessage id="label.copy" />}>
                  <Box>
                    <Icon
                      sx={{ cursor: 'pointer' }}
                      icon={faCopy}
                      onClick={() => {
                        const copyText =
                          document.getElementById('emailAddress');
                        if (copyText instanceof HTMLInputElement) {
                          copyText.select();
                          copyText.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(copyText.value);
                        }
                      }}
                    />
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item>
                <FeatureHelpTooltip
                  onClick={() => {
                    window.open(
                      'https://help.bas.software/',
                      '_blank',
                      'noopener'
                    );
                  }}
                />
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};

const StyledIncomingEmailFeatureContentSection = styled(
  IncomingEmailFeatureContentSection
)(
  () => `
  .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root {
    width: 260px;
    padding: 0;
  }
`
);
export default StyledIncomingEmailFeatureContentSection;
