import { Icon } from '@bas/ui/web/base';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ModuleLoadingPage = () => (
  <Grid
    style={{ width: '100%', height: '100%' }}
    container
    alignContent="center"
    justifyContent="center"
    direction="row"
  >
    <Grid item>
      <Icon icon={faSpinnerThird} size="4x" spin color="#021B4E" />
    </Grid>
    <Grid item xs={12} pt={1}>
      <Typography align="center">
        <FormattedMessage id="label.loadingModule" />
      </Typography>
    </Grid>
  </Grid>
);

export default ModuleLoadingPage;
