import { StorageEventType, StorageReservedForCustomerEvent } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageReservedForCustomerEvent(
  object: unknown
): object is StorageReservedForCustomerEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageReservedForCustomerEvent
  );
}
