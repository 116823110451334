import { Event, isEvent } from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RecurringEventsByPeriodRequestProps = {
  fromDate: Date;
  untilDate: Date;
};

type Response = AxiosResponse<Collection<Event>>;
type QueryKeyType = QueryKey & {
  [0]: 'recurring-events';
  [1]: 'list';
  [2]: string;
  [3]: string;
};

export const RecurringEventsByPeriodRequest = async ({
  fromDate,
  untilDate,
  ...params
}: RecurringEventsByPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/events/recurring/${formatDate(fromDate)}/${formatDate(
      untilDate
    )}`,
    {
      params: {
        ...params,
        perPage: 5000,
      },
    }
  );

export const useRecurringEventsByPeriodRequest = (
  request: RecurringEventsByPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => RecurringEventsByPeriodRequest({ ...request }),
    queryKey: [
      'recurring-events',
      'list',
      formatDate(request.fromDate),
      formatDate(request.untilDate),
    ],
  });

export const RecurringEventsByPeriodRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isEvent(data)) {
    queryClient.invalidateQueries({ queryKey: ['recurring-events', 'list'] });
  }
};
