import { colors } from '@bas/theme';
import { Button } from '@bas/ui/web/atoms';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type RequestIntegrationBlockProps = BoxProps;

const RequestIntegrationBlock = ({
  ...args
}: RequestIntegrationBlockProps): ReactElement => (
  <Box {...args}>
    <Typography whiteSpace="pre-wrap" sx={{ overflowWrap: 'break-word' }}>
      <FormattedMessage id="label.isYourIntegrationNotAvailableYet" />
    </Typography>
    <Button href="https://roadmap.bas.software" target="_blank">
      <FormattedMessage id="button.passOnWish" />
    </Button>
  </Box>
);

const StyledRequestIntegrationBlock = styled(RequestIntegrationBlock)(
  ({ theme }) => `
  border: 2px solid ${colors.lila[300]};
  box-sizing: border-box;
  border-radius: 5px;
  box-sizing: border-box;
  width: 294px;
  height: 217px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .MuiTypography-root {
    margin-bottom: 14px;
    font-weight: bold;
    color: ${colors.lila[700]};
    text-align: center;
  }

  .MuiButton-root {
    text-transform: lowercase;
  }
`
);
export default StyledRequestIntegrationBlock;
