import { CostCenterMappingType } from '@bas/integration-domain/models';
import { useCostCenterBasOptions } from '@bas/integration-domain/web/hooks';
import { MappingTableExternalOption } from '@bas/value-objects';
import { Grid } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { MappingTableForm } from '../MappingTableForm';

export type CostCenterMappingFormProps = {
  costCenterMappingType: CostCenterMappingType;
  externalOptions: MappingTableExternalOption[];
  noExternalSelect?: boolean;
};

const CostCenterMappingForm = ({
  costCenterMappingType,
  externalOptions,
  noExternalSelect,
}: CostCenterMappingFormProps): ReactElement => {
  const basIdLabel = useMemo(() => {
    if (costCenterMappingType === CostCenterMappingType.PER_IDENTITY) {
      return <FormattedMessage id="label.identity" />;
    }

    if (costCenterMappingType === CostCenterMappingType.PER_TURNOVER_GROUP) {
      return <FormattedMessage id="label.turnoverGroup" />;
    }

    return <FormattedMessage id="label.unknown" />;
  }, [costCenterMappingType]);

  const basOptions = useCostCenterBasOptions(costCenterMappingType);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MappingTableForm
          basOptions={basOptions}
          externalOptions={externalOptions}
          prefix="costCenterMapping"
          basIdLabel={basIdLabel}
          externalIdLabel={<FormattedMessage id="label.costCenter" />}
          noExternalSelect={noExternalSelect}
        />
      </Grid>
    </Grid>
  );
};

export default CostCenterMappingForm;
