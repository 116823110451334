import { ReeleezeeTaxRate } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequestProps =
  Pagination & {
    integrationId: Uuid;
    administrationId: string;
  };

type Response = AxiosResponse<Collection<ReeleezeeTaxRate>>;

export const ReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequest =
  async ({
    integrationId,
    administrationId,
    ...params
  }: ReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequestProps): Promise<Response> =>
    axios.get(
      `api/{tenantId}/integrations/${integrationId}/reeleezee/${administrationId}/reeleezee-tax-rates`,
      {
        params: { ...params },
        paramsSerializer(param) {
          return Qs.stringify(param, { arrayFormat: 'brackets' });
        },
      }
    );

export const useReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequest = (
  request: ReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequest(request),
    queryKey: [
      'integrations',
      'reeleezee-tax-rates',
      request.integrationId,
      request,
    ],
  });
