import { temporaryPermissions, useCheckPermissions } from '@bas/shared/hooks';
import { ComponentType, ReactElement, useMemo } from 'react';
import { NoPermissionsPage as NoPermissionsPageRender } from '../NoPermissionsPage';

type PermissionNeededRouteProps = {
  NoPermissionsPage: ComponentType;
  children: ReactElement;
  permissionsNeeded: temporaryPermissions;
};

const PermissionNeededRoute = ({
  NoPermissionsPage,
  permissionsNeeded,
  children,
}: PermissionNeededRouteProps): ReactElement => {
  const checkPermissions = useCheckPermissions();

  const hasPermissions = useMemo(
    () => checkPermissions(permissionsNeeded),
    [checkPermissions, permissionsNeeded]
  );

  if (!hasPermissions) {
    return <NoPermissionsPage />;
  }

  return children;
};

PermissionNeededRoute.defaultProps = {
  NoPermissionsPage: NoPermissionsPageRender,
};

export default PermissionNeededRoute;
