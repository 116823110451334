import { NationalHoliday } from '@bas/hrm-domain/models';
import { NationalHolidayWorkflow } from '@bas/settings-domain/web/organisms';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { ReactElement } from 'react';

dayjs.extend(timezone);

export type NationalHolidayInformationTabProps = {
  nationalHoliday: NationalHoliday;
};

const NationalHolidayInformationTab = ({
  nationalHoliday,
}: NationalHolidayInformationTabProps): ReactElement => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <NationalHolidayWorkflow
        nationalHolidayId={nationalHoliday.nationalHolidayId}
      />
    </Grid>
    <Grid item xs={6}>
      {dayjs.tz.guess()}
    </Grid>
  </Grid>
);

export default NationalHolidayInformationTab;
