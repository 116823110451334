import {
  basAssemble,
  basBabyCarriage,
  basBarstool,
  basBathroomCabinet,
  basBbq,
  basBedSideTable,
  basCabinet,
  basCarpet,
  basChestOfDrawers,
  basChevronDown,
  basChildrenBicycle,
  basClothesRack,
  basCoatRack,
  basCradle,
  basCrib,
  basCrockeryCabinet,
  basDesk,
  basDisassemble,
  basDishwasher,
  basDisplayCabinet,
  basDrawerUnit,
  basDressboy,
  basDressingTable,
  basEndTable,
  basExerciseBike,
  basFoldingBed,
  basGardenArmchair,
  basGardenCushions,
  basGardenSeat,
  basGardenTable,
  basGardenTools,
  basGoKart,
  basHeadQuestion,
  basHorseTrolley,
  basIroningBoard,
  basKitchenTrolley,
  basLadder,
  basLaundryBasket,
  basLawnmower,
  basLounger,
  basLoungeTable,
  basMannequin,
  basMattress,
  basMirror,
  basPaddlingPool,
  basPainting,
  basPaperclip,
  basParaph,
  basPatioHeater,
  basPorchSwing,
  basPram,
  basRoofbox,
  basSandbar,
  basSecretary,
  basShoeCloset,
  basSideboard,
  basSideTable,
  basSinkCabinet,
  basSlide,
  basStandingSpeaker,
  basStatue,
  basSwingSet,
  basTeaCupboard,
  basTrampoline,
  basTruckQuestion,
  basTummybox,
  basTvCabinet,
  basWardrobe,
  basWaterbed,
  basWheelbarrow,
  basWineRack,
  basWorkbench,
  basWorkmate,
} from '@bas/svg-icons';
import { Icon, IconProps } from '@bas/ui/web/base';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { ReactElement } from 'react';

const bas = {
  assemble: basAssemble,
  babycarriage: basBabyCarriage,
  barstool: basBarstool,
  bathroomcabinet: basBathroomCabinet,
  bbq: basBbq,
  bedsidetable: basBedSideTable,
  cabinet: basCabinet,
  carpet: basCarpet,
  chestofdrawers: basChestOfDrawers,
  chevrondown: basChevronDown,
  childrenbicycle: basChildrenBicycle,
  clothesrack: basClothesRack,
  coatrack: basCoatRack,
  cradle: basCradle,
  crib: basCrib,
  crockerycabinet: basCrockeryCabinet,
  desk: basDesk,
  disassemble: basDisassemble,
  dishwasher: basDishwasher,
  displaycabinet: basDisplayCabinet,
  drawerunit: basDrawerUnit,
  dressboy: basDressboy,
  dressingtable: basDressingTable,
  endtable: basEndTable,
  exercisebike: basExerciseBike,
  foldingbed: basFoldingBed,
  gardenarmchair: basGardenArmchair,
  gardencushions: basGardenCushions,
  gardenseat: basGardenSeat,
  gardentable: basGardenTable,
  gardentools: basGardenTools,
  gokart: basGoKart,
  headquestion: basHeadQuestion,
  horsetrolley: basHorseTrolley,
  ironingboard: basIroningBoard,
  kitchentrolley: basKitchenTrolley,
  ladder: basLadder,
  laundrybasket: basLaundryBasket,
  lawnmower: basLawnmower,
  lounger: basLounger,
  loungetable: basLoungeTable,
  mannequin: basMannequin,
  mattress: basMattress,
  mirror: basMirror,
  paddlingpool: basPaddlingPool,
  painting: basPainting,
  paperclip: basPaperclip,
  paraph: basParaph,
  patioheater: basPatioHeater,
  porchswing: basPorchSwing,
  pram: basPram,
  roofbox: basRoofbox,
  sandbar: basSandbar,
  secretary: basSecretary,
  shoecloset: basShoeCloset,
  sideboard: basSideboard,
  sidetable: basSideTable,
  sinkcabinet: basSinkCabinet,
  slide: basSlide,
  standingspeaker: basStandingSpeaker,
  statue: basStatue,
  swingset: basSwingSet,
  teacupboard: basTeaCupboard,
  trampoline: basTrampoline,
  truckquestion: basTruckQuestion,
  tummybox: basTummybox,
  tvcabinet: basTvCabinet,
  wardrobe: basWardrobe,
  waterbed: basWaterbed,
  wheelbarrow: basWheelbarrow,
  winerack: basWineRack,
  workbench: basWorkbench,
  workmate: basWorkmate,
};

export type BackendIconProps = Omit<IconProps, 'icon'> & {
  icon: string;
};

library.add(fal);

library.add(bas);

const BackendIcon = ({ icon, ...args }: BackendIconProps): ReactElement => (
  <Icon
    {...args}
    icon={
      {
        prefix: icon.includes('.svg') ? 'bas' : 'fal',
        iconName: icon
          .replace('fa-', '')
          .replace('.svg', '')
          .replaceAll('_', '-')
          .replaceAll(' ', '-'),
      } as IconProp
    }
  />
);

BackendIcon.defaultProps = {};

export default BackendIcon;
