import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basBbq: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'bbq',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M419.8 99c4.3-7.6 1.7-17.2-5.8-21.6s-17.2-1.7-21.6 5.8C367.6 126.4 328.2 160 281 177.5c-8.2 3-12.4 12.1-9.3 20.3s12.1 12.4 20.3 9.3c54.2-20 99.4-58.6 127.8-108.1zM10.7 21.7c0-8.7 7.1-15.8 15.8-15.8h459c8.7 0 15.8 7.1 15.8 15.8c0 99.8-59.6 185.7-145.2 224l49.6 142.9c13.2-3.3 27.4-3.6 41.5 0c8.5 2.1 13.6 10.7 11.5 19.1s-10.7 13.6-19.1 11.5c-28.8-7.2-57.9 10.3-65.1 39.1c-2.1 8.5-10.7 13.6-19.1 11.5s-13.6-10.7-11.5-19.1c5.1-20.4 17.2-37.3 33.1-48.6l-9.4-27H145l-38.3 112c-2.8 8.3-11.8 12.6-20.1 9.8s-12.6-11.8-9.8-20.1l79.1-230.9C70.3 207.4 10.7 121.5 10.7 21.7zm315.8 235c-22.3 6.7-46 10.3-70.5 10.3s-48.2-3.6-70.5-10.3l-29.6 86.6H356.6l-30.1-86.6zM42.9 37.5C50.9 148.1 143.3 235.4 256 235.4s205-87.3 213.1-197.9H42.9zm350 433.7a35.8 35.8 0 1 1 71.6 0 35.8 35.8 0 1 1 -71.6 0z',
  ],
};

export default basBbq;
