import { Integration } from '@bas/integration-domain/models';
import { useIntegrationByIntegrationIdRequest } from '@bas/integration-domain/requests';
import {
  ExactGlobeSettingsTab,
  ExactOnlineSettingsTab,
  ExternalEmployeesTab,
  InvoiceDataTab,
  KingFinanceSettingsTab,
  MollieSettingsTab,
  NewLeadDataTab,
  NmbrsSettingsTab,
  OctopusSettingsTab,
  ReeleezeeSettingsTab,
  ScanMoversSettingsTab,
  SchoutenZekerheidDataTab,
  SchoutenZekerheidSettingsTab,
  SnelStartSettingsTab,
  VerhuisOffertesSettingsTab,
  VerifyExternalInvoiceDataTab,
  WebsiteSettingsTab,
  WhatsAppMessageTemplatesTab,
  WhatsAppProfileTab,
  WhatsAppSettingsTab,
  YukiSettingsTab,
} from '@bas/integration-domain/web/tab-pages';
import { getProviderName } from '@bas/settings-domain/web/hooks';
import { colors, fontSizesWeb } from '@bas/theme';
import {
  LoadingDetailsPageTemplate,
  RightSideWithTabsTemplate,
} from '@bas/ui/web/templates';
import { ProviderType, Uuid } from '@bas/value-objects';
import { Box, styled, Typography } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export type IntegrationDetailsPageTemplateProps = {
  integrationId: Uuid;
  className?: string;
  onClose?: () => void;
};

const getTabsForIntegration = (integration: Integration) => {
  const tabsPerIntegration = {
    [ProviderType.SCHOUTEN_ZEKERHEID]: [
      {
        tabKey: 'toBeProcessed',
        label: <FormattedMessage id="label.toBeProcessed" />,
        content: <SchoutenZekerheidDataTab pending integration={integration} />,
      },
      {
        tabKey: 'allCertificates',
        label: <FormattedMessage id="label.allCertificates" />,
        content: <SchoutenZekerheidDataTab integration={integration} />,
      },
      {
        tabKey: 'authentication',
        label: <FormattedMessage id="label.settings" />,
        content: <SchoutenZekerheidSettingsTab integration={integration} />,
      },
    ],
    [ProviderType.MOLLIE]: [
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <MollieSettingsTab integration={integration} />,
      },
    ],
    [ProviderType.EXACT_GLOBE]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <InvoiceDataTab failed integration={integration} allowBulk />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <ExactGlobeSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <InvoiceDataTab integration={integration} />,
      },
    ],
    [ProviderType.EXACT_ONLINE]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <InvoiceDataTab failed integration={integration} allowBulk />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <ExactOnlineSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <InvoiceDataTab integration={integration} />,
      },
      {
        tabKey: 'verifyData',
        label: <FormattedMessage id="label.verifyData" />,
        content: <VerifyExternalInvoiceDataTab integration={integration} />,
      },
    ],
    [ProviderType.OCTOPUS]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <InvoiceDataTab failed integration={integration} allowBulk />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <OctopusSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <InvoiceDataTab integration={integration} />,
      },
    ],
    [ProviderType.TWINFIELD]: [],
    [ProviderType.SNEL_START]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <InvoiceDataTab failed integration={integration} allowBulk />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <SnelStartSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <InvoiceDataTab integration={integration} />,
      },
      {
        tabKey: 'verifyData',
        label: <FormattedMessage id="label.verifyData" />,
        content: <VerifyExternalInvoiceDataTab integration={integration} />,
      },
    ],
    [ProviderType.REELEEZEE]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <InvoiceDataTab failed integration={integration} allowBulk />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <ReeleezeeSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <InvoiceDataTab integration={integration} />,
      },
    ],
    [ProviderType.IMUIS]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <InvoiceDataTab failed integration={integration} allowBulk />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <KingFinanceSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <InvoiceDataTab integration={integration} />,
      },
    ],
    [ProviderType.YUKI]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <InvoiceDataTab failed integration={integration} allowBulk />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <YukiSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <InvoiceDataTab integration={integration} />,
      },
    ],
    [ProviderType.KLANTENVERTELLEN]: [],
    [ProviderType.VERHUIS_OFFERTES]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <NewLeadDataTab failed integration={integration} />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <VerhuisOffertesSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <NewLeadDataTab integration={integration} />,
      },
    ],
    [ProviderType.SCAN_MOVERS]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <NewLeadDataTab failed integration={integration} />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <ScanMoversSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <NewLeadDataTab integration={integration} />,
      },
    ],
    [ProviderType.WEBSITE]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: <NewLeadDataTab failed integration={integration} />,
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <WebsiteSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <NewLeadDataTab integration={integration} />,
      },
    ],
    [ProviderType.WHATSAPP]: [
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <WhatsAppSettingsTab integration={integration} />,
      },
      {
        tabKey: 'profile',
        label: <FormattedMessage id="label.profile" />,
        content: <WhatsAppProfileTab integration={integration} />,
      },
      {
        tabKey: 'messageTemplates',
        label: <FormattedMessage id="label.messageTemplates" />,
        content: <WhatsAppMessageTemplatesTab integration={integration} />,
      },
    ],
    [ProviderType.GOOGLE_CALENDAR]: [
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <span />,
      },
    ],
    [ProviderType.NMBRS]: [
      {
        tabKey: 'attentionNeeded',
        label: <FormattedMessage id="label.attentionNeeded" />,
        content: (
          <ExternalEmployeesTab integration={integration} attentionNeeded />
        ),
      },
      {
        tabKey: 'settings',
        label: <FormattedMessage id="label.settings" />,
        content: <NmbrsSettingsTab integration={integration} />,
      },
      {
        tabKey: 'allData',
        label: <FormattedMessage id="label.allData" />,
        content: <ExternalEmployeesTab integration={integration} />,
      },
    ],
  };

  return tabsPerIntegration[integration.provider];
};

const IntegrationDetailsPageTemplate = ({
  integrationId,
  ...args
}: IntegrationDetailsPageTemplateProps): ReactElement => {
  const [activeTab, setActiveTab] = useState<string>();
  const { data: integrationData } = useIntegrationByIntegrationIdRequest({
    integrationId: integrationId || '',
  });

  const integration = useMemo(() => integrationData?.data, [integrationData]);
  const verifyInvoiceData = useFlag('verify-invoice-data');

  const tabs = useMemo(() => {
    if (!integration) {
      return [];
    }

    return getTabsForIntegration(integration).filter(
      ({ tabKey }) => tabKey !== 'verifyData' || verifyInvoiceData
    );
  }, [integration, verifyInvoiceData]);

  useEffect(() => {
    if (!activeTab && tabs?.length > 0) {
      setActiveTab(tabs[0].tabKey);
    }
  }, [activeTab, tabs]);

  if (!activeTab || !integration) {
    return <LoadingDetailsPageTemplate />;
  }

  return (
    <RightSideWithTabsTemplate
      header={[
        <Typography color="textPrimary" variant="h3">
          {getProviderName(integration.provider)}
        </Typography>,
        <Box
          className={clsx(
            'Bas-Status',
            { 'Bas-Active': integration.authentication?.connected },
            { 'Bas-Disconnected': !integration.authentication?.connected }
          )}
        >
          <FormattedMessage
            id={
              integration.authentication?.connected
                ? 'label.activeIntegration'
                : 'label.disconnected'
            }
          />
        </Box>,
      ]}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
      tabs={tabs}
      {...args}
    />
  );
};

const StyledIntegrationDetailsPageTemplate = styled(
  IntegrationDetailsPageTemplate
)`
  .Bas-Status {
    border-radius: 5px;
    padding: 6px 10px;
    font-size: ${fontSizesWeb.xs};
    line-height: 13px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 7px;

    &.Bas-Active {
      background: ${colors.green[300]};
      color: ${colors.green[800]};
    }

    &.Bas-Disconnected {
      background: ${colors.pink['200']};
      color: ${colors.red['500']};
    }
  }

  img.Bas-ProviderLogo {
    max-width: 150px;
    max-height: 50px;
  }
`;

const RenderedIntegrationDetailsPageTemplate = (
  props: IntegrationDetailsPageTemplateProps
) => <StyledIntegrationDetailsPageTemplate {...props} />;

export default RenderedIntegrationDetailsPageTemplate;
