import {
  InvoiceEvent,
  InvoiceEventType,
  isSendingInvoiceFailedEvent,
} from '@bas/financial-domain/models';
import { Icon } from '@bas/ui/web/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAlarmClock,
  faBallotCheck,
  faCircleQuestion,
  faClock,
  faExclamation,
  faHandWave,
  faInboxOut,
  faMoneyBill1Wave,
  faPartyHorn,
  faUndo,
} from '@fortawesome/pro-light-svg-icons';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type EventsTimelineInvoiceEventItemProps = {
  isLast: boolean;
  event: InvoiceEvent;
};

const eventTypeMapping: {
  [key: string]: {
    icon: IconProp;
    color:
      | 'inherit'
      | 'grey'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  };
} = {
  [InvoiceEventType.SendingInvoiceFailedEvent]: {
    icon: faExclamation,
    color: 'error',
  },
  [InvoiceEventType.InvoiceFinalizedEvent]: {
    icon: faBallotCheck,
    color: 'primary',
  },
  [InvoiceEventType.InvoicePaidEvent]: {
    icon: faPartyHorn,
    color: 'success',
  },
  [InvoiceEventType.InvoiceExpiredEvent]: {
    icon: faClock,
    color: 'warning',
  },
  [InvoiceEventType.InvoiceReminderSentEvent]: {
    icon: faAlarmClock,
    color: 'info',
  },
  [InvoiceEventType.InvoiceSentEvent]: {
    icon: faInboxOut,
    color: 'primary',
  },
  [InvoiceEventType.InvoicePaymentProcessedEvent]: {
    icon: faMoneyBill1Wave,
    color: 'success',
  },
  [InvoiceEventType.InvoicePaymentRemovedEvent]: {
    icon: faHandWave,
    color: 'warning',
  },
  [InvoiceEventType.InvoiceWasNotPaidEvent]: {
    icon: faUndo,
    color: 'warning',
  },
};

type InvoiceEventDetailsProps = {
  event: InvoiceEvent;
};

const InvoiceEventDetails = ({
  event,
}: InvoiceEventDetailsProps): ReactElement | null => {
  if (isSendingInvoiceFailedEvent(event)) {
    return <Typography>{event.reason}</Typography>;
  }

  return null;
};

const EventsTimelineInvoiceEventItem = ({
  event,
  isLast,
}: EventsTimelineInvoiceEventItemProps): ReactElement => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot color={eventTypeMapping[event.eventType]?.color}>
        <Icon
          icon={eventTypeMapping[event.eventType]?.icon || faCircleQuestion}
        />
      </TimelineDot>
      {isLast && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
      <Typography variant="subtitle1">
        <FormattedMessage id={`eventsTimeline.invoice.${event.eventType}`} />
      </Typography>
      <InvoiceEventDetails event={event} />
      <Typography variant="subtitle2">
        <FormattedDate
          value={event.eventDate}
          dateStyle="medium"
          timeStyle="short"
        />
      </Typography>
    </TimelineContent>
  </TimelineItem>
);

export default EventsTimelineInvoiceEventItem;
