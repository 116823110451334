import * as Yup from 'yup';

export interface NmbrsSettingsInputType {
  companyId: string;
  startDate?: Date | null;
}

export const NmbrsSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<NmbrsSettingsInputType> =>
    Yup.object({
      companyId: Yup.string().required().label('label.company'),
      startDate: Yup.date().nullable().required().label('label.startDate'),
    });
