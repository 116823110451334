import {
  KingFinanceSettingsInputType,
  KingFinanceSettingsInputTypeDefaultValues,
  KingFinanceSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import {
  Integration,
  isKingFinanceSettings,
} from '@bas/integration-domain/models';
import { KingFinanceSettingsContentSection } from '@bas/integration-domain/web/molecules';
import {
  ContentSection,
  ContentSectionEditAction,
  ReactHookForm,
} from '@bas/ui/web/molecules';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditingKingFinanceSettingsContentSection } from '../EditingKingFinanceSettingsContentSection';

export type EditableKingFinanceSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: KingFinanceSettingsInputType) => Promise<void>;
};

const EditableKingFinanceSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableKingFinanceSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<KingFinanceSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          ...KingFinanceSettingsInputTypeDefaultValues(),
          ...integration.providerSettings,
        }}
        validationSchema={KingFinanceSettingsInputTypeValidationBuilder()}
      >
        <EditingKingFinanceSettingsContentSection
          integrationId={integration.integrationId}
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  if (!isKingFinanceSettings(integration.providerSettings)) {
    return (
      <ContentSection
        actions={[
          <ContentSectionEditAction onClick={() => setIsEditing(true)} />,
        ]}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.settings" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="label.integrationIsNotSetUpYet" />
            </Typography>
          </Grid>
        </Grid>
      </ContentSection>
    );
  }

  return (
    <KingFinanceSettingsContentSection
      onEdit={() => setIsEditing(true)}
      integrationId={integration.integrationId}
      providerSettings={integration.providerSettings}
    />
  );
};

export default EditableKingFinanceSettingsBlock;
