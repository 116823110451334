import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basTrampoline: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'trampoline',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M87.167 136.628C131.068 121.87 190.758 113.001 256 113.001s124.932 8.868 168.833 23.627c21.874 7.355 40.656 16.445 54.275 27.27 13.464 10.703 23.899 24.825 23.899 42.105 0 17.28-10.435 31.402-23.9 42.105-7.56 6.01-16.712 11.485-27.102 16.426v137.474c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15V276.314c-16.397 5.336-34.901 9.867-55.002 13.443v58.25c0 8.284-6.716 15-15 15-8.285 0-15-6.716-15-15v-53.773c-25.397 3.1-52.677 4.771-81.003 4.771-27.955 0-54.89-1.628-80.003-4.65v53.651c0 8.285-6.714 15-15 15-8.284 0-15-6.715-15-15v-58.07c-20.488-3.605-39.333-8.198-56.001-13.622v125.694c0 8.284-6.716 15-15 15-8.285 0-15.001-6.716-15.001-15V264.534c-10.39-4.941-19.542-10.417-27.103-16.426-13.464-10.703-23.898-24.825-23.898-42.105 0-17.28 10.434-31.402 23.898-42.104 13.62-10.826 32.4-19.916 54.275-27.27zM51.56 187.384c-10.016 7.962-12.565 14.36-12.565 18.62 0 4.259 2.549 10.656 12.565 18.618 9.862 7.839 25.056 15.557 45.167 22.318 40.07 13.472 96.38 22.065 159.273 22.065s119.204-8.593 159.273-22.065c20.11-6.76 35.305-14.48 45.166-22.318 10.017-7.962 12.566-14.359 12.566-18.619s-2.549-10.657-12.566-18.619c-9.861-7.838-25.056-15.557-45.166-22.318-40.07-13.472-96.38-22.064-159.273-22.064s-119.204 8.592-159.273 22.064c-20.11 6.761-35.305 14.48-45.167 22.318z',
  ],
};

export default basTrampoline;
