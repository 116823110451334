import * as Yup from 'yup';
import {
  AccountingIntegrationSettingsInputType,
  AccountingIntegrationSettingsInputTypeDefaultValues,
  AccountingIntegrationSettingsInputTypeValidationBuilder,
} from '../accountingIntegrationSettingsInputType';

export interface ExactOnlineSettingsInputType
  extends AccountingIntegrationSettingsInputType {
  defaultDivisionCode: string;
  salesJournalCode: string;
  sendEntryNumbers: boolean;
  enableCreateCostCenterPerProjectInvoice: boolean;
}

export const ExactOnlineSettingsInputTypeDefaultValues =
  (): ExactOnlineSettingsInputType => ({
    ...AccountingIntegrationSettingsInputTypeDefaultValues(),
    defaultDivisionCode: '',
    salesJournalCode: '',
    sendEntryNumbers: false,
    enableCreateCostCenterPerProjectInvoice: false,
  });

export const ExactOnlineSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ExactOnlineSettingsInputType> =>
    Yup.object({
      defaultDivisionCode: Yup.string()
        .required()
        .label('label.defaultAdministration'),
      salesJournalCode: Yup.string().required().label('label.salesJournal'),
      sendEntryNumbers: Yup.boolean()
        .required()
        .label('label.useInvoiceNumberAsTransactionNumber'),
      enableCreateCostCenterPerProjectInvoice: Yup.boolean()
        .required()
        .label('label.enableCreateCostCenterPerProjectInvoice'),
    }).concat(AccountingIntegrationSettingsInputTypeValidationBuilder());
