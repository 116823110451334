import { EmailEvent } from '@bas/communication-domain/models';
import { Icon } from '@bas/ui/web/base';
import { Uuid } from '@bas/value-objects';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUpRightFromSquare,
  faClock,
  faEnvelopeDot,
  faEnvelopeOpen,
  faExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { faFolderXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type EventsTimelineEmailEventItemProps = {
  isLast: boolean;
  onShowEmail?: (emailId: Uuid) => void;
  event: EmailEvent & {
    emailId: Uuid;
    title: string;
  };
};

const eventTypeMapping: {
  [key: string]: {
    icon: IconProp;
    color:
      | 'inherit'
      | 'grey'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  };
} = {
  bounce: {
    icon: faExclamation,
    color: 'error',
  },
  click: {
    icon: faArrowUpRightFromSquare,
    color: 'success',
  },
  deferred: {
    icon: faClock,
    color: 'warning',
  },
  delivered: {
    icon: faEnvelopeDot,
    color: 'primary',
  },
  open: {
    icon: faEnvelopeOpen,
    color: 'success',
  },
  'spam-report': {
    icon: faFolderXmark,
    color: 'error',
  },
};

const EventsTimelineEmailEventItem = ({
  event,
  isLast,
  onShowEmail,
}: EventsTimelineEmailEventItemProps): ReactElement => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot color={eventTypeMapping[event.eventType]?.color}>
        <Icon icon={eventTypeMapping[event.eventType]?.icon} />
      </TimelineDot>
      {isLast && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
      <Typography variant="subtitle1">
        <FormattedMessage id={`eventsTimeline.email.${event.eventType}`} />
      </Typography>
      <Typography>{event.title}</Typography>
      <Typography>{event.recipient}</Typography>
      <Box
        sx={{ cursor: onShowEmail ? 'pointer' : 'normal' }}
        onClick={onShowEmail ? () => onShowEmail(event.emailId) : undefined}
      >
        <Typography>
          <FormattedMessage id="eventsTimeline.viewEmail" />
        </Typography>
      </Box>
      <Typography variant="subtitle2">
        <FormattedDate
          value={event.eventDate}
          dateStyle="medium"
          timeStyle="short"
        />
      </Typography>
    </TimelineContent>
  </TimelineItem>
);

export default EventsTimelineEmailEventItem;
