import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basBarstool: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'barstool',
  icon: [
    500,
    512,
    [],
    'e001',
    'M87.79 59.519c0-24.248 19.657-43.904 43.904-43.904h235.128c24.247 0 43.903 19.656 43.903 43.904v82.928c0 24.248-19.656 43.904-43.903 43.904h-20.988l76.314 289.032c2.064 7.814-2.48 16.224-10.145 18.784-7.667 2.56-15.554-1.7-17.617-9.515l-30.463-115.369h-99.542v110.734c0 8.083-6.553 14.635-14.635 14.635s-14.634-6.552-14.634-14.635V369.283H135.06l-30.462 115.369c-2.064 7.815-9.951 12.074-17.618 9.515-7.666-2.56-12.208-10.97-10.145-18.784l76.316-289.032h-21.458c-24.247 0-43.903-19.656-43.903-43.904V59.52zm54.998 280.494h92.324V186.351h-52.22a15.083 15.083 0 01-.496 3.66l-39.608 150.002zm121.593 0h91.815L316.59 190.011a15.024 15.024 0 01-.497-3.66H264.38v153.662zM131.694 44.884c-8.082 0-14.634 6.552-14.634 14.635v82.928c0 8.083 6.552 14.635 14.634 14.635h235.128c8.082 0 14.634-6.552 14.634-14.635V59.52c0-8.083-6.552-14.635-14.634-14.635H131.694z',
  ],
};

export default basBarstool;
