import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { formatHours } from '@bas/shared/utils';
import { WorkedHoursSpecification } from '@bas/value-objects';

export type WorkedHoursSpecificationSummaryProps = {
  workedHoursSpecification: WorkedHoursSpecification;
};

const WorkedHoursSpecificationSummary = ({
  workedHoursSpecification,
}: WorkedHoursSpecificationSummaryProps): ReactElement => (
  <Grid container>
    <Grid item xs={8}>
      <FormattedMessage id="label.workedHoursIncludingBreakTime" />
    </Grid>
    <Grid item xs={4}>
      + {formatHours(workedHoursSpecification.workedHoursIncludingBreakTime)}
    </Grid>
    {workedHoursSpecification.workedHoursIncludingBreakTime !==
      workedHoursSpecification.workedHours && (
      <>
        <Grid item xs={8}>
          <FormattedMessage id="label.breakTime" />
        </Grid>
        <Grid item xs={4}>
          - {formatHours(workedHoursSpecification.breakTime)}
        </Grid>
      </>
    )}
    {workedHoursSpecification.travelTime > 0 && (
      <>
        <Grid item xs={8}>
          <FormattedMessage id="label.travelTime" />
        </Grid>
        <Grid item xs={4}>
          + {formatHours(workedHoursSpecification.travelTime)}
        </Grid>
      </>
    )}
    <Grid item xs={8} fontWeight="bold">
      <FormattedMessage id="label.totalHours" />
    </Grid>
    <Grid item xs={4} fontWeight="bold">
      ={' '}
      {formatHours(
        workedHoursSpecification.workedHours +
          workedHoursSpecification.travelTime
      )}
    </Grid>
  </Grid>
);

export default WorkedHoursSpecificationSummary;
