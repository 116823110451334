import * as Yup from 'yup';
import {
  AccountingIntegrationSettingsInputType,
  AccountingIntegrationSettingsInputTypeDefaultValues,
  AccountingIntegrationSettingsInputTypeValidationBuilder,
} from '../accountingIntegrationSettingsInputType';

export interface KingFinanceSettingsInputType
  extends AccountingIntegrationSettingsInputType {
  salesJournalCode: string;
  contraGeneralLedger: string;
}

export const KingFinanceSettingsInputTypeDefaultValues =
  (): KingFinanceSettingsInputType => ({
    ...AccountingIntegrationSettingsInputTypeDefaultValues(),
    salesJournalCode: '',
    contraGeneralLedger: '',
  });

export const KingFinanceSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<KingFinanceSettingsInputType> =>
    Yup.object({
      salesJournalCode: Yup.string().required().label('label.salesJournalCode'),
      contraGeneralLedger: Yup.string()
        .required()
        .label('label.contraGeneralLedger'),
    }).concat(AccountingIntegrationSettingsInputTypeValidationBuilder());
