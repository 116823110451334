import {
  EstimationItemToUseForQuote,
  QuoteMomentLineType,
} from '@bas/shared/models';
import {
  InternalServiceType,
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
} from '@bas/value-objects';
import * as Yup from 'yup';
import {
  QuoteMomentLineInputType,
  QuoteMomentLineInputTypeDefaultValues,
  QuoteMomentLineInputTypeValidationBuilder,
} from './quoteMomentLineInputType';

export interface CalculatedQuoteMomentLineInputType
  extends QuoteMomentLineInputType {
  itemsToUseForPrice: Omit<
    EstimationItemToUseForQuote,
    'allowedProjectTypes'
  >[];
  itemsToUseForQuantity: Omit<
    EstimationItemToUseForQuote,
    'allowedProjectTypes'
  >[];
  itemsToUseForDescription: Omit<
    EstimationItemToUseForQuote,
    'allowedProjectTypes'
  >[];
  ignoreQuantity: boolean;
}

export const CalculatedQuoteMomentLineInputTypeDefaultValues =
  (): CalculatedQuoteMomentLineInputType => ({
    ...QuoteMomentLineInputTypeDefaultValues(),
    lineType: QuoteMomentLineType.CALCULATED_QUOTE_MOMENT_LINE,
    itemsToUseForPrice: [],
    itemsToUseForQuantity: [],
    itemsToUseForDescription: [],
    ignoreQuantity: false,
  });

export const EstimationItemToUseForQuoteValidationBuilder =
  (): Yup.ObjectSchema<
    Omit<EstimationItemToUseForQuote, 'allowedProjectTypes'>
  > =>
    Yup.object({
      groupType: Yup.mixed<ProjectEstimateItemGroupType>()
        .oneOf(Object.values(ProjectEstimateItemGroupType))
        .required()
        .label('label.groupType'),
      itemType: Yup.mixed<ProjectEstimateItemType>()
        .oneOf(Object.values(ProjectEstimateItemType))
        .required()
        .label('label.itemType'),
      serviceTypes: Yup.array()
        .of(
          Yup.mixed<InternalServiceType>()
            .oneOf(Object.values(InternalServiceType))
            .required()
            .label('label.serviceTypes')
        )
        .required(),
    });

export const CalculatedQuoteMomentLineInputTypeValidationBuilder =
  (): Yup.ObjectSchema<CalculatedQuoteMomentLineInputType> =>
    QuoteMomentLineInputTypeValidationBuilder().concat(
      Yup.object({
        ignoreQuantity: Yup.bool().required().label('label.optional'),
        itemsToUseForPrice: Yup.array()
          .of(EstimationItemToUseForQuoteValidationBuilder())
          .required(),
        itemsToUseForQuantity: Yup.array()
          .of(EstimationItemToUseForQuoteValidationBuilder())
          .required(),
        itemsToUseForDescription: Yup.array()
          .of(EstimationItemToUseForQuoteValidationBuilder())
          .required(),
      })
    );
