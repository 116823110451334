import { colors } from '@bas/theme';
import { Tooltip } from '@bas/ui/web/atoms';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  styled,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';

export type StackedLinearProgressProps = LinearProgressProps & {
  values: {
    value: number;
    label?: string;
    color: string;
    showValue?: boolean;
  }[];
  showTotalLabel?: boolean;
  totalLabel?: string;
};

const StackedLinearProgress = ({
  values,
  showTotalLabel,
  totalLabel,
  ...props
}: StackedLinearProgressProps) => {
  let totalValue = 0;
  let currentTransform = 0;
  return (
    <Box sx={{ position: 'relative' }}>
      {showTotalLabel && !!totalLabel && (
        <Typography
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            textAlign: 'center',
          }}
        >
          {totalLabel}
        </Typography>
      )}
      <LinearProgress
        variant="determinate"
        sx={{
          color: colors.lila[300],
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
        }}
        {...props}
        value={100}
      />
      {values
        // .sort(({ value: aValue }, { value: bValue }) =>
        //   aValue > bValue ? -1 : 1
        // )
        .map(({ value, color, showValue, label }) => {
          totalValue += value;

          let transformPercentage = (100 - totalValue) / 2;
          if (value !== totalValue) {
            transformPercentage = currentTransform - (100 - totalValue);
          }

          currentTransform += transformPercentage;
          if (value === totalValue) {
            transformPercentage *= -1;
          }

          const content = (
            <LinearProgress
              variant="determinate"
              sx={{
                color,
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
              }}
              {...props}
              value={totalValue > 100 ? 100 : totalValue}
            />
          );

          return (
            <Fragment key={color}>
              {!showValue && !!label ? (
                <Tooltip title={label}>{content}</Tooltip>
              ) : (
                content
              )}

              {showValue && !!label && (
                <Typography
                  sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    transform: `translateX(${transformPercentage}%)`,
                    top: 0,
                    textAlign: 'center',
                  }}
                >
                  {label || totalValue}
                </Typography>
              )}
            </Fragment>
          );
        })
        .reverse()}
    </Box>
  );
};

const StyledStackedLinearProgress = styled(StackedLinearProgress)(
  () => `
  height: 3px;
  background-color: transparent;
  .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate {
    background-color: currentColor !important;
  }
`
);
export default StyledStackedLinearProgress;
