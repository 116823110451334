import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { isStorageLocation, StorageLocation } from '@bas/wms-domain/models';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type StorageLocationsRequestProps = Pagination &
  Filterable & {
    warehouseId?: Uuid | null;
    withDisabled?: boolean;
  };
type Response = AxiosResponse<Collection<StorageLocation>>;

export const StorageLocationsRequest = async ({
  warehouseId,
  warehouse,
  ...params
}: StorageLocationsRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/${
      warehouseId ? `warehouses/${warehouseId}/` : ''
    }locations`,
    {
      params: { ...params, warehouse: warehouseId || warehouse },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useStorageLocationsRequest = (
  request: StorageLocationsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => StorageLocationsRequest(request),
    queryKey: ['storage-locations', 'list', request],
  });

export const usePrefetchStorageLocationsRequest = (
  request: StorageLocationsRequestProps
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['storage-locations', 'list', request],
      queryFn: async () => StorageLocationsRequest(request),
    });
  }, [queryClient, request]);
};

export const StorageLocationsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isStorageLocation(data)) {
    queryClient.invalidateQueries({ queryKey: ['storage-locations', 'list'] });
  }
};
