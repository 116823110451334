import {
  ExternalContract,
  isExternalContract,
} from '@bas/integration-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ExternalContractByEmployeeIdRequestProps = Pagination &
  Filterable & {
    integrationId: Uuid;
    externalEmployeeId: string;
  };

type Response = AxiosResponse<Collection<ExternalContract>>;

export const ExternalContractByEmployeeIdRequest = async ({
  integrationId,
  externalEmployeeId,
  ...params
}: ExternalContractByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/integrations/${integrationId}/external-employees/${externalEmployeeId}/external-contracts`,
    {
      params: { ...params },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useExternalContractByEmployeeIdRequest = (
  request: ExternalContractByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ExternalContractByEmployeeIdRequest(request),
    queryKey: [
      'integrations',
      'external-contracts',
      request.integrationId,
      request.externalEmployeeId,
      request,
    ],
  });

export const ExternalContractByEmployeeIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isExternalContract(data)) {
      queryClient.invalidateQueries({
        queryKey: [
          'integrations',
          'external-contracts',
          data.integrationId,
          data.externalId,
        ],
      });
    }
  };
