import { isObjectResponse } from '@bas/value-objects';
import { BatchData } from '../types';

export function isBatchData(object: unknown): object is BatchData {
  return (
    !!object &&
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'dataType') &&
    typeof object.dataType === 'string' &&
    ['lead', 'invoice', 'certificate'].includes(object.dataType)
  );
}
