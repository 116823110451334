import { ProviderType } from '@bas/value-objects';
import * as Yup from 'yup';

export interface ConnectIntegrationInputType {
  provider: ProviderType;
}

export const ConnectIntegrationInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ConnectIntegrationInputType> =>
    Yup.object({
      provider: Yup.mixed<ProviderType>()
        .oneOf(Object.values(ProviderType))
        .required()
        .label('label.provider'),
    });
