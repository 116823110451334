import { colors } from '@bas/theme';
import { Box, Grid, Skeleton, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { ContentSection } from '../ContentSection';

export type LoadingPlanningItemProps = {
  className?: string;
  large?: boolean;
};

const LoadingPlanningItem = ({
  className,
  large,
}: LoadingPlanningItemProps): ReactElement => (
  <ContentSection
    className={clsx(className, { 'Bas-LoadingPlanningItem-Large': large })}
    actions={[]}
  >
    <Box className="Bas-LoadingPlanningItem-root">
      <Box className="Bas-LoadingPlanningItem-Avatar">
        <Skeleton variant="circular" height={44} width={44} />
      </Box>
      <Grid container className="Bas-LoadingPlanningItem-NameBlock">
        <Grid item xs={12} className="Bas-LoadingPlanningItem-Role">
          <Typography>
            <Skeleton variant="text" width={130} />
          </Typography>
        </Grid>
        <Grid item xs={12} className="Bas-LoadingPlanningItem-Name">
          <Typography>
            <Skeleton variant="text" width={80} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </ContentSection>
);

const StyledLoadingPlanningItem = styled(LoadingPlanningItem)(
  ({ theme }) => `
  &.Bas-LoadingPlanningItem-Large {
    padding: ${theme.spacing(2, 3)};
    border-bottom: 1px solid ${colors.lila[300]};
    .Bas-LoadingPlanningItem-Avatar {
      padding-right: ${theme.spacing(2)};
    }

    &:first-of-type {
      border-top: 1px solid ${colors.lila[300]};
    }
  }

  .Bas-LoadingPlanningItem-root {
    display: flex;
    flex-direction: row;

    .Bas-LoadingPlanningItem-Avatar {
      color: ${colors.lila[400]};
      padding-right: 12px;
      font-size: 44px;
    }

    .Bas-LoadingPlanningItem-Role .MuiTypography-root {
      color: ${theme.palette.common.black};
    }

    .Bas-LoadingPlanningItem-Name .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
    }
  }
`
);
export default StyledLoadingPlanningItem;
