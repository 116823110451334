import { isTimeEntry, TimeEntry } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeEntriesByEmployeeIdRequestProps = Pagination &
  Filterable & {
    employeeId: Uuid;
  };

export const TimeEntriesByEmployeeIdRequest = async ({
  employeeId,
  ...params
}: TimeEntriesByEmployeeIdRequestProps): Promise<
  AxiosResponse<Collection<TimeEntry>>
> =>
  axios.get(`api/{tenantId}/hrm/employees/${employeeId}/time-entries`, {
    params: { ...params },
  });

export const useTimeEntriesByEmployeeIdRequest = (
  request: TimeEntriesByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<TimeEntry>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeEntry>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<TimeEntry>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<TimeEntry>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeEntry>>
  >({
    ...options,
    queryFn: async () => TimeEntriesByEmployeeIdRequest(request),
    queryKey: ['time-entries', 'list', request.employeeId, request],
  });

export const TimeEntriesByEmployeeIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTimeEntry(data)) {
    queryClient.invalidateQueries({
      queryKey: ['time-entries', 'list', data.employeeId],
    });
  }
};
