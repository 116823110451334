import { isObjectResponse } from '@bas/value-objects';
import type { EmailEvent } from '../../types';

export function isEmailEvent(object: unknown): object is EmailEvent {
  return (
    isObjectResponse(object) &&
    [
      'BounceEvent',
      'ClickEvent',
      'DeferredEvent',
      'DeliveredEvent',
      'OpenEvent',
      'SpamReportEvent',
    ].includes(object['@type']) &&
    Object.prototype.hasOwnProperty.call(object, 'eventType')
  );
}
