import { isRelation, Relation } from '@bas/crm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RelationByRelationIdRequestProps = {
  relationId: Uuid;
};

export const RelationByRelationIdRequest = async ({
  relationId,
  ...params
}: RelationByRelationIdRequestProps): Promise<AxiosResponse<Relation>> =>
  axios.get(`api/{tenantId}/relations/${relationId}`, {
    params,
  });

export const useRelationByRelationIdRequestQuery = (
  request: RelationByRelationIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Relation>,
    AxiosError,
    AxiosResponse<Relation>
  > = {}
): UseQueryResult<AxiosResponse<Relation>, AxiosError> =>
  useQuery<AxiosResponse<Relation>, AxiosError, AxiosResponse<Relation>>({
    ...options,
    queryFn: async () => RelationByRelationIdRequest(request),
    queryKey: ['relations', 'detail', request.relationId],
  });

export const RelationByRelationIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isRelation(data)) {
    queryClient.invalidateQueries({
      queryKey: ['relations', 'detail', data.relationId],
    });
  }
};
