import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basPainting: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'painting',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M100.095 135.578c-19.33 0-35 15.67-35 35v171.849c0 19.33 15.67 35 35 35h310.908c19.33 0 35.001-15.67 35.001-35v-171.85c0-19.33-15.67-35-35.001-35H100.095zm-5 35a5 5 0 015-5h310.908a5 5 0 015 5v171.849a5 5 0 01-5 5H100.095a5 5 0 01-5-5v-171.85zm-59.097-92.58c-19.33 0-35.001 15.67-35.001 35v287.008c0 19.33 15.67 35 35 35h439.104c19.331 0 35.002-15.67 35.002-35V113c0-19.33-15.671-35.001-35.002-35.001H35.998zm-5 35a5 5 0 015-5H475.1a5 5 0 015.001 5v287.008a5 5 0 01-5 5H35.997a5 5 0 01-5-5V113z',
  ],
};

export default basPainting;
