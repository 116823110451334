import * as Yup from 'yup';

export interface DriversLicenseInputType {
  driversLicense: {
    bLicense: boolean;
    beLicense: boolean;
    bPlusLicense: boolean;
    cLicense: boolean;
    c1License: boolean;
    c1eLicense: boolean;
    ceLicense: boolean;
    code95License: boolean;
  };
}

export const DriversLicenseInputTypeDefaultValues =
  (): DriversLicenseInputType => ({
    driversLicense: {
      bLicense: false,
      beLicense: false,
      bPlusLicense: false,
      cLicense: false,
      c1License: false,
      c1eLicense: false,
      ceLicense: false,
      code95License: false,
    },
  });

export const DriversLicenseInputTypeValidatorBuilder =
  (): Yup.ObjectSchema<DriversLicenseInputType> =>
    Yup.object({
      driversLicense: Yup.object({
        bLicense: Yup.boolean().required().label('label.bLicense'),
        beLicense: Yup.boolean().required().label('label.beLicense'),
        bPlusLicense: Yup.boolean().required().label('label.bPlusLicense'),
        cLicense: Yup.boolean().required().label('label.cLicense'),
        c1License: Yup.boolean().required().label('label.c1License'),
        c1eLicense: Yup.boolean().required().label('label.c1eLicense'),
        ceLicense: Yup.boolean().required().label('label.ceLicense'),
        code95License: Yup.boolean().required().label('label.code95License'),
      }),
    });
