import * as Yup from 'yup';
import {
  AccountingIntegrationSettingsInputType,
  AccountingIntegrationSettingsInputTypeDefaultValues,
  AccountingIntegrationSettingsInputTypeValidationBuilder,
} from '../accountingIntegrationSettingsInputType';

export interface ReeleezeeSettingsInputType
  extends AccountingIntegrationSettingsInputType {
  administrationId: string;
}

export const ReeleezeeSettingsInputTypeDefaultValues =
  (): ReeleezeeSettingsInputType => ({
    ...AccountingIntegrationSettingsInputTypeDefaultValues(),
    administrationId: '',
  });

export const ReeleezeeSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ReeleezeeSettingsInputType> =>
    Yup.object({
      administrationId: Yup.string().required().label('label.administration'),
    }).concat(AccountingIntegrationSettingsInputTypeValidationBuilder());
