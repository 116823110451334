import { isNationalHoliday, NationalHoliday } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type NationalHolidayByNationalHolidayIdRequestQueryProps = {
  nationalHolidayId: Uuid;
};

export const NationalHolidayByNationalHolidayIdRequestQuery = async ({
  nationalHolidayId,
  ...params
}: NationalHolidayByNationalHolidayIdRequestQueryProps): Promise<
  AxiosResponse<NationalHoliday>
> =>
  axios.get(`api/{tenantId}/hrm/national-holidays/${nationalHolidayId}`, {
    params,
  });

export const useNationalHolidayByNationalHolidayIdRequestQuery = (
  request: NationalHolidayByNationalHolidayIdRequestQueryProps,
  options: QueryOptionsWithKey<
    AxiosResponse<NationalHoliday>,
    AxiosError,
    AxiosResponse<NationalHoliday>
  > = {}
): UseQueryResult<AxiosResponse<NationalHoliday>, AxiosError> =>
  useQuery<
    AxiosResponse<NationalHoliday>,
    AxiosError,
    AxiosResponse<NationalHoliday>
  >({
    ...options,
    queryFn: async () =>
      NationalHolidayByNationalHolidayIdRequestQuery(request),
    queryKey: ['national-holidays', 'detail', request.nationalHolidayId],
  });

export const NationalHolidayByNationalHolidayIdRequestQueryInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isNationalHoliday(data)) {
      queryClient.invalidateQueries({
        queryKey: ['national-holidays', 'detail', data.nationalHolidayId],
      });
    }
  };
