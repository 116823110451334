import { Icon } from '@bas/ui/web/base';
import { EventType } from '@bas/value-objects';
import {
  faArrowRightToBracket,
  faBoxFull,
  faBoxOpen,
  faCalendar,
  faCarWrench,
  faClipboardListCheck,
  faDesktopAlt,
  faDolly,
  faFaceThermometer,
  faForklift,
  faHandsHelping,
  faInventory,
  faIslandTropical,
  faLoveseat,
  faMoneyCheckEditAlt,
  faQuestion,
  faRecycle,
  faSign,
  faSteeringWheel,
  faTruck,
  faTruckRamp,
  faTruckRampBox,
  faUserDoctor,
  faWarehouseAlt,
} from '@fortawesome/pro-light-svg-icons';
import { basAssemble, basDisassemble } from '@bas/svg-icons';

export type EventIconProps = {
  eventType: EventType;
};

const eventIconMap = {
  [EventType.UNKNOWN]: faQuestion,
  [EventType.DAY_OFF_EVENT]: faLoveseat,
  [EventType.HRM_OTHER_ABSENCE_EVENT]: faUserDoctor,
  [EventType.SICK_EVENT]: faFaceThermometer,
  [EventType.VACATION_EVENT]: faIslandTropical,
  [EventType.INSPECTION_EVENT]: faClipboardListCheck,
  [EventType.MAINTENANCE_EVENT]: faCarWrench,
  [EventType.MATERIAL_OTHER_ABSENCE_EVENT]: faCarWrench,
  [EventType.ASSEMBLE_EVENT]: basAssemble,
  [EventType.DELIVER_BOXES_EVENT]: faBoxOpen,
  [EventType.DISASSEMBLE_EVENT]: basDisassemble,
  [EventType.DISPOSING_OF_WASTE_EVENT]: faRecycle,
  [EventType.LOAD_STORAGE_EVENT]: faArrowRightToBracket,
  [EventType.MOVING_EVENT]: faTruck,
  [EventType.MOVING_LIFT_EVENT]: faForklift,
  [EventType.PACKING_EVENT]: faBoxFull,
  [EventType.PICKUP_BOXES_EVENT]: faBoxOpen,
  [EventType.POST_ROAD_SIGNS_EVENT]: faSign,
  [EventType.PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT]: faClipboardListCheck,
  [EventType.REMOVE_ROAD_SIGNS_EVENT]: faSign,
  [EventType.UNLOAD_STORAGE_EVENT]: faWarehouseAlt,
  [EventType.UNPACKING_EVENT]: faBoxOpen,
  [EventType.INTAKE_EVENT]: faMoneyCheckEditAlt,
  [EventType.CUSTOM_EVENT]: faCalendar,
  [EventType.LENDING_STAFF_EVENT]: faHandsHelping,
  [EventType.LIFT_WORK_EVENT]: faForklift,
  [EventType.TRANSPORT_JOB_PICKUP]: faTruckRampBox,
  [EventType.TRANSPORT_JOB_DELIVER]: faTruckRamp,
  [EventType.DRIVING_DAY]: faSteeringWheel,
  [EventType.OFFICE_WORK_EVENT]: faDesktopAlt,
  [EventType.PROJECT_EVENT]: faCalendar,
  [EventType.TRANSPORT_EVENT]: faDolly,
  [EventType.WAREHOUSE_WORK_EVENT]: faInventory,
};

const EventIcon = ({ eventType, ...args }: EventIconProps) => {
  let icon = faQuestion;
  if (eventIconMap[eventType]) {
    icon = eventIconMap[eventType];
  }

  return <Icon {...args} icon={icon} />;
};

EventIcon.defaultProps = {};

export default EventIcon;
