import { isVehicle, Material } from '@bas/planning-domain/models';
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, AvatarSizeType } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ContentSection } from '@bas/ui/web/molecules';

export type PlannedMaterialItemProps = {
  material: Material;
  className?: string;
  onChange: () => void | Promise<void>;
  onRemove: () => void | Promise<void>;
};

const PlannedMaterialItem = ({
  material,
  className,
  onChange,
  onRemove,
}: PlannedMaterialItemProps): ReactElement => (
  <ContentSection
    className={className}
    actions={[
      <IconButton onClick={onChange}>
        <Icon icon={faPencil} />
      </IconButton>,
      <IconButton onClick={onRemove}>
        <Icon icon={faTrashCan} />
      </IconButton>,
    ]}
  >
    <Box className="Bas-PlannedMaterialItem-root">
      <Box className="Bas-PlannedMaterialItem-Avatar">
        <Avatar
          size={AvatarSizeType.SMALL}
          src={isVehicle(material) ? material.pictureUrl : undefined}
          name={material.name}
          shortName={material.name}
        />
      </Box>
      <Grid container className="Bas-PlannedMaterialItem-NameBlock">
        <Grid item xs={12} className="Bas-PlannedMaterialItem-Name">
          <Typography>{material.name}</Typography>
        </Grid>
        <Grid item xs={12} className="Bas-PlannedMaterialItem-Extra">
          <Typography>
            {isVehicle(material) && material.licensePlate ? (
              material.licensePlate
            ) : (
              <FormattedMessage id={`materialTypes.${material.materialType}`} />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </ContentSection>
);

const StyledPlannedMaterialItem = styled(PlannedMaterialItem)(
  ({ theme }) => `
  .Bas-ContentSection-Actions {
    top: -${theme.spacing(0)};
    right: ${theme.spacing(0)};
  }

  &.Bas-ContentSection-HasActions {
    &:hover, &.Bas-ContentSection-Editing {
      &:before {
        left: -13px;
        right: -13px;
        top: -${theme.spacing(1)};
        bottom: -${theme.spacing(1)};
      }
    }
  }
  .Bas-PlannedMaterialItem-root {
    display: flex;
    flex-direction: row;
    .Bas-PlannedMaterialItem-Avatar {
      padding-right: 12px;
    }

    .Bas-PlannedMaterialItem-Extra .MuiTypography-root {
      color: ${theme.palette.common.black};
    }

    .Bas-PlannedMaterialItem-Name .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
    }
  }
`
);
export default StyledPlannedMaterialItem;
