import { colors } from '@bas/theme';
import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type AccordionProps = MuiAccordionProps;

const Accordion = ({ className, children, ...props }: AccordionProps) => (
  <MuiAccordion className={className} {...props}>
    {children}
  </MuiAccordion>
);

Accordion.defaultProps = {
  elevation: 0,
};

const StyledAccordion = styled(Accordion)(
  ({ theme }) => `
  background: transparent;
  border-bottom: 1px solid ${colors.lila[400]};
  &.Mui-expanded {
    margin: 0;
  }

  &:last-of-type {
    border-radius: 0;
  }

  &:before {
    display: none;
  }
  `
);
export default StyledAccordion;
