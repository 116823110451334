import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveIntegrationMutationProps = {
  integrationId: Uuid;
};

export const RemoveIntegrationMutation = async ({
  integrationId,
}: RemoveIntegrationMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/integrations/${integrationId}/remove`, {});

export const useRemoveIntegrationMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveIntegrationMutationProps
  > = {}
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveIntegrationMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveIntegrationMutationProps
  >({
    mutationFn: RemoveIntegrationMutation,
    throwOnError: false,
    ...options,
  });
