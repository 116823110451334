import {
  AutomatedEmail,
  isAutomatedEmail,
} from '@bas/communication-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AutomatedEmailByAutomatedEmailIdRequestProps = {
  automatedEmailId: Uuid;
};

type Response = AxiosResponse<AutomatedEmail>;

export const AutomatedEmailByAutomatedEmailIdRequest = async ({
  automatedEmailId,
  ...params
}: AutomatedEmailByAutomatedEmailIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/communication/automated-emails/${automatedEmailId}`,
    {
      params: { ...params },
    }
  );

export const useAutomatedEmailByAutomatedEmailIdRequest = (
  request: AutomatedEmailByAutomatedEmailIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => AutomatedEmailByAutomatedEmailIdRequest(request),
    queryKey: ['automated-emails', 'detail', request.automatedEmailId],
  });

export const AutomatedEmailByAutomatedEmailIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isAutomatedEmail(data)) {
      queryClient.invalidateQueries({
        queryKey: ['automated-emails', 'detail', data.automatedEmailId],
      });
    }
  };
