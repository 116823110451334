import { isTimeType, TimeType } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeTypeByTimeTypeIdProps = {
  timeTypeId: Uuid;
};

export const TimeTypeByTimeTypeId = async ({
  timeTypeId,
  ...params
}: TimeTypeByTimeTypeIdProps): Promise<AxiosResponse<TimeType>> =>
  axios.get(`api/{tenantId}/hrm/time-types/${timeTypeId}`, {
    params,
  });

export const useTimeTypeByTimeTypeId = (
  request: TimeTypeByTimeTypeIdProps,
  options: QueryOptionsWithKey<
    AxiosResponse<TimeType>,
    AxiosError,
    AxiosResponse<TimeType>
  > = {}
): UseQueryResult<AxiosResponse<TimeType>, AxiosError> =>
  useQuery<AxiosResponse<TimeType>, AxiosError, AxiosResponse<TimeType>>({
    ...options,
    queryFn: async () => TimeTypeByTimeTypeId(request),
    queryKey: ['time-types', 'detail', request.timeTypeId],
  });

export const TimeTypeByTimeTypeIdInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTimeType(data)) {
    queryClient.invalidateQueries({
      queryKey: ['time-types', 'detail', data.timeTypeId],
    });
  }
};
