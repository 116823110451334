import { colors } from '@bas/theme';
import { Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { Box, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';

export type FeatureHelpTooltipProps = {
  title?: ReactElement | string;
  onClick?: () => void;
};

const FeatureHelpTooltip = ({
  title,
  onClick,
  ...args
}: FeatureHelpTooltipProps): ReactElement => (
  <Tooltip
    helpTooltip
    placement="right"
    onClick={onClick}
    disabled={!!onClick}
    color={colors.lila[300]}
    title={
      <Typography
        whiteSpace="pre-wrap"
        sx={{
          overflowWrap: 'break-word',
          color: `${colors.lila[800]} !important`,
        }}
      >
        {title}
      </Typography>
    }
  >
    <Box {...args} sx={{ cursor: onClick ? 'pointer' : undefined }}>
      <Icon icon={faQuestion} />
    </Box>
  </Tooltip>
);

const StyledFeatureHelpTooltip = styled(FeatureHelpTooltip)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  font-size: 16px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: background-color 100ms ease, color 100ms ease, border 100ms ease;
  background: ${colors.lila[300]};
  color: ${colors.lila[700]};

  &:hover {
    background: ${colors.blue[200]};
    color: ${colors.blue[500]};
    border: 1px solid ${colors.blue[500]};
  }
`
);
export default StyledFeatureHelpTooltip;
