import { colors } from '@bas/theme';
import {
  AvailabilityCategory,
  AvailabilityCategoryProps,
} from '@bas/ui/web/molecules';
import { Box, BoxProps, debounce, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

export type AvailabilityProps = BoxProps & {
  categories: AvailabilityCategoryProps[];
  disableLastOfRowCheck?: boolean;
  scrollInsteadOfWrap?: boolean;
};

const lastOfRowName = 'Bas-Availability-Category-Last-Of-Row';

const Availability = ({
  categories,
  disableLastOfRowCheck,
  children,
  scrollInsteadOfWrap,
  className,
  ...props
}: AvailabilityProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disableLastOfRowCheck) {
      return () => {
        // do nothing
      };
    }

    const checkRows = debounce(() => {
      let lastElement: HTMLDivElement | null = null;

      if (containerRef.current !== null) {
        Array.from(
          containerRef.current.getElementsByClassName(
            'Bas-Availability-Category'
          )
        ).forEach((element: Element) => {
          if (!(element instanceof HTMLDivElement)) {
            return;
          }
          element.classList.remove(lastOfRowName);

          if (lastElement && lastElement.offsetTop !== element.offsetTop) {
            lastElement.classList.add(lastOfRowName);
          }

          lastElement = element;
        });
      }
    }, 25);

    checkRows();
    window.addEventListener('resize', checkRows);
    return () => {
      window.removeEventListener('resize', checkRows);
    };
  });

  return (
    <Box
      className={clsx(className, {
        'Bas-Availability-Scroll': scrollInsteadOfWrap,
      })}
      {...props}
    >
      <Grid
        container
        ref={containerRef}
        spacing={2}
        className="Bas-Availability-Categories-Holder"
        justifyContent={scrollInsteadOfWrap ? 'flex-start' : 'center'}
        flexWrap={scrollInsteadOfWrap ? 'nowrap' : undefined}
      >
        {categories.map((category, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={index} className="Bas-Availability-Category">
            <AvailabilityCategory {...category} />
          </Grid>
        ))}
        {children && (
          <Grid item className="Bas-Availability-Category">
            {children}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Availability.defaultProps = {};

const StyledAvailability = styled(Availability)(
  ({ theme }) => `
  &.Bas-Availability-Scroll {
    white-space: nowrap;
    & .MuiGrid-container {
      flex-wrap: nowrap;
    }

    .Bas-Availability-Category:first-of-type {
      margin-left: auto;
    }

    .Bas-Availability-Category:last-of-type {
      margin-right: auto;
    }
  }

  .Bas-Availability-Category:not(:last-of-type):not(.${lastOfRowName}) > .MuiBox-root {
    border-right: 1px solid ${colors.lila[300]};
    padding-right: ${theme.spacing(2)};
    padding-left: ${theme.spacing(2)};
  }
`
);
export default StyledAvailability;
