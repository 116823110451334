import { ReeleezeeLedgerAccount } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequestProps =
  Pagination & {
    integrationId: Uuid;
    administrationId: string;
  };

type Response = AxiosResponse<Collection<ReeleezeeLedgerAccount>>;

export const ReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequest =
  async ({
    integrationId,
    administrationId,
    ...params
  }: ReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequestProps): Promise<Response> =>
    axios.get(
      `api/{tenantId}/integrations/${integrationId}/reeleezee/${administrationId}/reeleezee-ledger-accounts`,
      {
        params: { ...params },
        paramsSerializer(param) {
          return Qs.stringify(param, { arrayFormat: 'brackets' });
        },
      }
    );

export const useReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequest =
  (
    request: ReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequestProps,
    options: QueryOptionsWithKey<
      Response,
      AxiosError<ErrorResponse>,
      Response
    > = {}
  ): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
    useQuery<Response, AxiosError<ErrorResponse>, Response>({
      ...options,
      queryFn: async () =>
        ReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequest(
          request
        ),
      queryKey: [
        'integrations',
        'reeleezee-ledger-accounts',
        request.integrationId,
        request,
      ],
    });
