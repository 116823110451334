import { isBackendViolations } from '@bas/value-objects';
import axios from 'axios';
import { BaseSyntheticEvent, useCallback } from 'react';
import {
  FieldValues,
  Path,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

export const useFormBackendHandler = <
  TFieldValues extends FieldValues = FieldValues
>(
  formHandler: SubmitHandler<TFieldValues>,
  form: UseFormReturn<TFieldValues>
): SubmitHandler<TFieldValues> => {
  const { formatMessage } = useIntl();

  return useCallback(
    async (
      data: TFieldValues,
      event?: BaseSyntheticEvent
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> => {
      try {
        return await formHandler(data, event);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const response = error.response?.data;
          if (isBackendViolations(response)) {
            response.violations.forEach((violation) => {
              form.setError(violation.propertyPath as Path<TFieldValues>, {
                type: 'manual',
                message: violation.message,
              });
            });

            form.setError('backendErrors' as Path<TFieldValues>, {
              type: 'manual',
              message: response.violations
                .map((violation) => {
                  const name = formatMessage({
                    id: `label.${violation.propertyPath}`,
                  });

                  return `${name}: ${violation.message}`;
                })
                .join(', \n'),
            });

            return false;
          }

          if (response && typeof response.message === 'string') {
            form.setError('backendErrors' as Path<TFieldValues>, {
              type: 'manual',
              message: response.message,
            });

            return false;
          }

          if (
            error.response?.status === 404 &&
            error.response?.request.responseURL.includes('/api/public/tenants/')
          ) {
            form.setError('appUrl' as Path<TFieldValues>, {
              type: 'manual',
              message: formatMessage({ id: 'label.appUrlIsIncorrect' }),
            });

            return false;
          }

          if (error.response?.status.toString().substring(0, 1) === '5') {
            form.setError('backendErrors' as Path<TFieldValues>, {
              type: 'manual',
              message: formatMessage(
                {
                  id: 'label.serverError',
                },
                {
                  code: error.response?.status,
                }
              ),
            });

            return false;
          }
        }

        form.setError('backendErrors' as Path<TFieldValues>, {
          type: 'manual',
          message: error?.toString(),
        });

        return false;
      }
    },
    [form, formHandler, formatMessage]
  );
};
