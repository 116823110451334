import {
  styled,
  TableSortLabel as MuiTableSortLabel,
  TableSortLabelProps as MuiTableSortLabelProps,
} from '@mui/material';
import clsx from 'clsx';

export type TableSortLabelProps = MuiTableSortLabelProps;

const TableSortLabel = ({ className, ...args }: TableSortLabelProps) => (
  <MuiTableSortLabel className={clsx(className)} {...args} />
);

TableSortLabel.defaultProps = {};

const StyledTableSortLabel = styled(TableSortLabel)(
  () => `
`
);
export default StyledTableSortLabel;
