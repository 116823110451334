import { EmployeeProjectRole } from '@bas/value-objects';
import {
  faBoxOpen,
  faClipboardList,
  faForklift,
  faPersonCarryBox,
  faUser,
  faUserCrown,
} from '@fortawesome/pro-light-svg-icons';
import { basAssemble } from '@bas/svg-icons';

export const projectRoleIconMap = {
  [EmployeeProjectRole.PROJECT_LEADER]: faUserCrown,
  [EmployeeProjectRole.FOREMAN]: faUserCrown,
  [EmployeeProjectRole.APPRAISER]: faClipboardList,
  [EmployeeProjectRole.HANDYMAN]: basAssemble,
  [EmployeeProjectRole.CO_DRIVER]: faPersonCarryBox,
  [EmployeeProjectRole.PACKER]: faBoxOpen,
  [EmployeeProjectRole.LIFT_TRUCK]: faForklift,
  [EmployeeProjectRole.ATTENDEE]: faUser,
  [EmployeeProjectRole.INAPPLICABLE]: faUser,
};
