import {
  isQuoteAcceptedEvent,
  isQuoteDeclinedEvent,
  isQuoteFinalizedEvent,
  isQuoteSentEvent,
  isSendingQuoteFailedEvent,
  QuoteEvent,
  QuoteEventType,
} from '@bas/financial-domain/models';
import { Icon } from '@bas/ui/web/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAlarmClock,
  faBallotCheck,
  faBan,
  faCircleQuestion,
  faClock,
  faEnvelopeOpen,
  faExclamation,
  faInboxOut,
  faPartyHorn,
} from '@fortawesome/pro-light-svg-icons';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type EventsTimelineQuoteEventItemProps = {
  isLast: boolean;
  event: QuoteEvent;
};

const eventTypeMapping: {
  [key: string]: {
    icon: IconProp;
    color:
      | 'inherit'
      | 'grey'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  };
} = {
  [QuoteEventType.QuoteDeclinedEvent]: {
    icon: faExclamation,
    color: 'error',
  },
  [QuoteEventType.SendingQuoteFailedEvent]: {
    icon: faExclamation,
    color: 'error',
  },
  [QuoteEventType.QuoteFinalizedEvent]: {
    icon: faBallotCheck,
    color: 'primary',
  },
  [QuoteEventType.QuoteAcceptedEvent]: {
    icon: faPartyHorn,
    color: 'success',
  },
  [QuoteEventType.QuoteExpiredEvent]: {
    icon: faClock,
    color: 'warning',
  },
  [QuoteEventType.QuoteReminderSentEvent]: {
    icon: faAlarmClock,
    color: 'info',
  },
  [QuoteEventType.QuoteSentEvent]: {
    icon: faInboxOut,
    color: 'primary',
  },
  [QuoteEventType.QuoteCanceledEvent]: {
    icon: faBan,
    color: 'grey',
  },
  [QuoteEventType.QuoteOpenedEvent]: {
    icon: faEnvelopeOpen,
    color: 'success',
  },
  [QuoteEventType.ManuallyAcceptedQuoteEvent]: {
    icon: faPartyHorn,
    color: 'success',
  },
};

type QuoteEventDetailsProps = {
  event: QuoteEvent;
};

const QuoteEventDetails = ({
  event,
}: QuoteEventDetailsProps): ReactElement | null => {
  if (isQuoteAcceptedEvent(event) || isQuoteFinalizedEvent(event)) {
    return (
      <>
        <Typography>{event.name}</Typography>
        <Typography>{event.emailAddress}</Typography>
      </>
    );
  }

  if (isQuoteSentEvent(event)) {
    return <Typography>{event.emailAddress}</Typography>;
  }

  if (isQuoteDeclinedEvent(event)) {
    return (
      <>
        <Typography>{event.name}</Typography>
        <Typography>
          <FormattedMessage
            id={`declineReasons.${event.reason || 'unknown'}`}
          />
        </Typography>
      </>
    );
  }

  if (isSendingQuoteFailedEvent(event)) {
    return <Typography>{event.reason}</Typography>;
  }

  return null;
};

const EventsTimelineQuoteEventItem = ({
  event,
  isLast,
}: EventsTimelineQuoteEventItemProps): ReactElement => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot color={eventTypeMapping[event.eventType]?.color}>
        <Icon
          icon={eventTypeMapping[event.eventType]?.icon || faCircleQuestion}
        />
      </TimelineDot>
      {isLast && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
      <Typography variant="subtitle1">
        <FormattedMessage id={`eventsTimeline.quote.${event.eventType}`} />
      </Typography>
      <QuoteEventDetails event={event} />
      <Typography variant="subtitle2">
        <FormattedDate
          value={event.eventDate}
          dateStyle="medium"
          timeStyle="short"
        />
      </Typography>
    </TimelineContent>
  </TimelineItem>
);

export default EventsTimelineQuoteEventItem;
