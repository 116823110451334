import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basLadder: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'ladder',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M162.2 46.6c1.3-7.7 8.2-13.1 15.8-12.5c.3 0 .7 0 1 0H331c.7 0 1.3 0 2 .1c.7-.1 1.3-.1 2-.1c1.7 0 3.4 .3 5 .9c2.2 .8 4.2 2.1 5.8 3.7c1.4 1.4 2.4 3.1 3.2 5c.4 1.1 .7 2.2 .9 3.3l67.9 412.6c1.3 8.2-4.2 15.9-12.4 17.2s-15.9-4.2-17.2-12.4L335 141.3 281.8 464.5c-1.3 8.2-9.1 13.7-17.2 12.4s-13.7-9.1-12.4-17.2l6.5-39.6H131.1l-7.3 44.4c-1.3 8.2-9.1 13.7-17.2 12.4s-13.7-9.1-12.4-17.2l68-413zM136.1 390H263.7l9.7-59H145.8l-9.7 59zM189.8 64L180 123H307.6l9.7-59H189.8zm-14.7 89l-9.7 59H293l9.7-59H175.1zM278.3 301l9.7-59H160.4l-9.7 59H278.3z',
  ],
};

export default basLadder;
