import { isObjectResponse } from '@bas/value-objects';
import type { NationalHoliday } from '../types';

export function isNationalHoliday(object: unknown): object is NationalHoliday {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'NationalHoliday'
  );
}
