import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basDisplayCabinet: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'display-cabinet',
  icon: [
    512,
    512,
    [],
    'e001',
    'M320.718 132.5c5.801 5.914 5.709 15.412-.206 21.211L216.509 255.715c-5.913 5.8-15.41 5.708-21.212-.207-5.8-5.914-5.709-15.41.206-21.212l104.003-102.003c5.915-5.8 15.411-5.708 21.212.206zm-70.25 17.254c5.938-5.777 6.068-15.275.29-21.213-5.776-5.937-15.274-6.067-21.212-.29l-37 36.001c-5.937 5.778-6.068 15.274-.291 21.212 5.778 5.938 15.274 6.068 21.212.29l37-36zM73.003 24c0-8.285 6.716-15 15-15h337.01c8.284 0 15 6.715 15 15v422.01c0 8.285-6.716 15.001-15 15.001h-18.001l-6 42H365.01l-6-42H153.006l-6 42h-36.001l-6-42h-17c-8.285 0-15-6.716-15-15.001V24zm30 327.008v80.001h307.01v-80.001h-307.01zm0-30h307.01V39h-307.01v282.007z',
  ],
};

export default basDisplayCabinet;
