import { IconPrefix } from '@fortawesome/fontawesome-common-types';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IconName } from '@fortawesome/free-brands-svg-icons';

const basStatue: IconDefinition = {
  prefix: 'bas' as IconPrefix,
  iconName: 'statue' as IconName,
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M238.3 21.7c-33.5 0-58.5 29.2-58.5 62.4c0 18.4 7.7 35.6 20.2 47.2c-.4 .9-1 1.9-1.9 3c-2.6 3.4-7.6 7.5-17 8.5c-35.8 3.8-53.1 35-53.1 60.9V240c0 8.1 6.6 14.6 14.6 14.6h32.5c-.3 7.7-.3 16 .1 24.6h-.4c0 4 .4 7.8 1.1 11.5c.4 5.5 1.1 11.1 1.9 16.8c3.3 22.2 1.7 41.1-1.6 55.2c-2.2 9.4-4.9 15.9-7 19.9H59.8c-8.1 0-14.6 6.6-14.6 14.6v85.9c0 8.1 6.6 14.6 14.6 14.6H441.2c8.1 0 14.6-6.6 14.6-14.6V397.3c0-8.1-6.6-14.6-14.6-14.6h-133c-2.1-3.9-4.8-10.5-7-19.9c-3.3-14.1-4.9-33.1-1.6-55.2c.8-5.6 1.4-11.2 1.9-16.8c.7-3.7 1.1-7.6 1.1-11.5h-.4c.4-8.6 .3-16.9 .1-24.6h32.5c8.1 0 14.6-6.6 14.6-14.6V203.7c0-25.9-17.3-57.1-53.1-60.9c-9.3-1-14.3-5.1-17-8.5c-1.1-1.4-1.8-2.7-2.2-3.6c12.1-11.6 19.5-28.5 19.5-46.6c0-33.3-25.1-62.4-58.5-62.4zM209 84.1c0-19.5 14.3-33.2 29.3-33.2s29.3 13.7 29.3 33.2s-14.3 33.2-29.3 33.2s-29.3-13.7-29.3-33.2zm91.7 327.8H426.6v56.6H74.4V411.9H176.8c0 0 0 0 .1 0H300.7c0 0 0 0 .1 0zM201 382.6c1.4-4.2 2.7-8.6 3.8-13.3c2.4-10.3 4-22.4 4.1-35.8c9 4.6 19.2 7.1 29.8 7.1s20.9-2.6 29.8-7.1c.1 13.4 1.7 25.5 4.1 35.8c1.1 4.7 2.4 9.1 3.8 13.3H201zm37.8-71.2c-17 0-30.4-11.1-33.8-25.1c-1.2-16.8-.7-32.9 .3-46c.7-8.7 1.6-15.9 2.3-21c.4-2.5 .7-4.5 .9-5.8c.1-.7 .2-1.1 .2-1.5c0-.2 0-.3 .1-.3l0-.1 0 0 0 0 0 0c1.5-7.9-3.7-15.6-11.7-17.1s-15.6 3.7-17.1 11.7l14.4 2.7c-14.4-2.7-14.4-2.7-14.4-2.7l0 .1 0 .1c0 .1-.1 .3-.1 .5c-.1 .4-.2 1-.3 1.8c-.3 1.5-.6 3.7-1 6.5c-.4 2.8-.8 6.2-1.3 10.1h-20V203.7c0-15.1 9.8-30 26.9-31.8c17.8-1.9 29.8-10.4 37-19.7c1.8-2.3 3.3-4.7 4.5-7.1c4 .9 8.2 1.4 12.5 1.4c4.6 0 9.1-.6 13.4-1.6c1.2 2.4 2.8 4.9 4.6 7.3c7.2 9.3 19.2 17.8 37 19.7c17.1 1.8 26.9 16.7 26.9 31.8v21.6h-20c-.4-3.9-.9-7.3-1.3-10.1c-.4-2.8-.7-5-1-6.5c-.1-.8-.2-1.4-.3-1.8c0-.2-.1-.4-.1-.5l0-.1 0-.1s0 0-14.4 2.7l14.4-2.7c-1.5-7.9-9.1-13.2-17.1-11.7s-13.2 9.1-11.7 17.1l0 0 0 0 0 0 0 .1 0 .1c0 .1 0 .1 0 .2c.1 .3 .1 .8 .2 1.5c.2 1.3 .5 3.3 .9 5.8c.7 5 1.6 12.3 2.3 21c1 13.1 1.5 29.3 .3 46c-3.4 14-16.7 25.1-33.8 25.1z',
  ],
};

export default basStatue;
