import {
  isEmail,
  UnreadEmailsByFolder,
} from '@bas/communication-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type UnreadEmailsByFolderRequestProps = Pagination &
  Filterable & {
    quoteId?: Uuid;
    invoiceId?: Uuid;
  };

type Response = AxiosResponse<Collection<UnreadEmailsByFolder>>;

export const UnreadEmailsByFolderRequest = async ({
  ...params
}: UnreadEmailsByFolderRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/communication/emails/unread/count-by-folder`, {
    params: { ...params },
  });

export const useUnreadEmailsByFolderRequest = (
  request: UnreadEmailsByFolderRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => UnreadEmailsByFolderRequest(request),
    queryKey: ['unread-emails-by-folder', 'list', request],
  });

export const UnreadEmailsByFolderRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isEmail(data)) {
    queryClient.invalidateQueries({
      queryKey: ['unread-emails-by-folder', 'list'],
    });
  }
};
