import { isObjectResponse } from '@bas/value-objects';
import type { EstimationSettings } from '../types';

export function isEstimationSettings(
  object: unknown
): object is EstimationSettings {
  return (
    !!object &&
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'estimationSettingsType') &&
    typeof object.estimationSettingsType === 'string' &&
    [
      'moving-job-estimation-settings',
      'moving-lift-job-estimation-settings',
      'transport-job-estimation-settings',
    ].includes(object.estimationSettingsType)
  );
}
