import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basLawnmower: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'lawnmower',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M19 30c0-8.3 6.7-15 15-15H86c6.8 0 12.8 4.6 14.5 11.2L155 231.7c.2 .6 .3 1.2 .4 1.8l26.1 5.8 62.8-62.8H355c53.4 0 87.6 23.6 103.4 54.6c12.8 25.2 13.2 55.1 1.5 77.1c.4 .2 .7 .4 1.1 .7c9.3 5.8 18.9 13.5 25.1 23.3c6.6 10.5 9.1 23.6 3.1 37.4c-4.1 9.5-11.9 18.1-22.7 26.1c7.6 10.5 12 23.4 12 37.4c0 35.3-28.7 64-64 64c-29.1 0-53.7-19.4-61.4-46H164.9c-7.8 26.6-32.3 46-61.4 46c-35.3 0-64-28.7-64-64c0-18.6 7.9-35.3 20.5-46.9L37 324.3c-2.3-6.1-.4-12.9 4.7-17l84.5-67.4c-.1-.2-.1-.3-.1-.5L74.5 45H34c-8.3 0-15-6.7-15-15zM432.6 295.6c7.1-12.2 8.6-32.2-.9-50.8c-10-19.6-33-38.2-76.7-38.2H256.7l-40.6 40.6 214.1 47.8c.5 .1 1.1 .3 1.6 .4c.2 .1 .5 .2 .7 .2zm10.2 80c12.3-7.8 17.2-14 18.9-18c1.4-3.2 1.2-5.9-1.1-9.5c-2.7-4.3-7.9-9.1-15.4-13.7c-7-4.3-15-7.9-22.3-10.5L146.3 262.2 68.9 324l17.7 47.3c5.4-1.5 11.1-2.3 17-2.3c31.2 0 57.3 22.4 62.9 52H351.6c5.6-29.6 31.6-52 62.9-52c10.1 0 19.7 2.4 28.2 6.5zM69.5 433a34 34 0 1 0 68 0 34 34 0 1 0 -68 0zm311 0a34 34 0 1 0 68 0 34 34 0 1 0 -68 0z',
  ],
};

export default basLawnmower;
