import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basAssemble: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'assemble',
  icon: [
    500,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M368.976 258.536L490.34 380c12.196 12.194 12.196 31.903.098 44.196l-66.243 66.243c-6.05 6.146-14.049 9.17-22.05 9.17-8 0-16-3.121-22.048-9.17L258.634 368.976c-26.732-26.733-30.048-67.903-9.95-98.244l-83.415-83.415h-71.61L0 62.44 62.439 0l124.878 93.659v71.61l83.415 83.414c12.976-8.682 28-12.976 43.024-12.976 20 0 40 7.61 55.22 22.83zM156.098 156.098v-46.83l-90.732-68-24.098 24.098 68 90.732h46.83zm124.585 190.73L402.05 468.294l66.244-66.244-121.464-121.366c-8.78-8.878-20.585-13.756-33.073-13.756s-24.195 4.878-33.073 13.756c-8.878 8.78-13.756 20.585-13.756 33.073s4.878 24.195 13.756 33.073zM410.02 66.79c6.096-6.096 6.096-15.98 0-22.075-6.096-6.096-15.98-6.096-22.075 0l-78.872 78.87V74.744c0-8.621-6.988-15.61-15.61-15.61-8.62 0-15.61 6.989-15.61 15.61v74.927c0 15.517 12.58 28.098 28.098 28.098h74.927c8.622 0 15.61-6.99 15.61-15.61 0-8.622-6.988-15.61-15.61-15.61h-50.617L410.02 66.79zM62.256 265.366c-8.62 0-15.61 6.988-15.61 15.61 0 8.62 6.99 15.61 15.61 15.61h50.617l-79.758 79.758c-6.096 6.096-6.096 15.98 0 22.076 6.096 6.095 15.98 6.095 22.075 0l78.872-78.872v48.842c0 8.62 6.988 15.61 15.61 15.61 8.62 0 15.61-6.99 15.61-15.61v-74.927c0-15.518-12.581-28.097-28.098-28.097H62.256z',
  ],
};

export default basAssemble;
