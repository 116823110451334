import { Avatar, AvatarSizeType, Button } from '@bas/ui/web/atoms';
import { Dialog } from '@bas/ui/web/molecules';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type BasUpdateMessageProps = {
  waitingWorker: ServiceWorker | null;
};

const BasUpdateMessage = ({ waitingWorker }: BasUpdateMessageProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [forceClose, setForceClose] = useState(false);

  const onUpdateApp = () => {
    // eslint-disable-next-line no-console
    console.log('Updating service worker');
    if (waitingWorker) {
      waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  const { formatMessage } = useIntl();
  const onPostponeUpdate = () => {
    // eslint-disable-next-line no-console
    console.log('Postponing service worker update');
    enqueueSnackbar(formatMessage({ id: 'label.newUpdatePostponed' }), {
      autoHideDuration: undefined,
      persist: true,
      variant: 'warning',
      action: (
        <Button
          onClick={onUpdateApp}
          color="inherit"
          variant="outlined"
          size="small"
        >
          <Avatar
            src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExaXdqY2E5N2w1eWdvMmRyZ2FmeWZoM3g2Z2k0dXR2bHBwdWlkazFrdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/JFawGLFMCJNDi/giphy.gif"
            alt="Update"
            size={AvatarSizeType.XS}
          />
          &nbsp;
          {formatMessage({ id: 'button.updateApp' })}
        </Button>
      ),
    });
    setForceClose(true);
  };

  return (
    <Dialog
      open={!!waitingWorker && !forceClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="label.newUpdate.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box
            textAlign="center"
            width="100%"
            flex={1}
            justifyContent="center"
            display="flex"
          >
            <Avatar
              sx={{
                height: 200,
                width: 200,
                marginTop: 2,
                marginBottom: 2,
              }}
              src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExaXdqY2E5N2w1eWdvMmRyZ2FmeWZoM3g2Z2k0dXR2bHBwdWlkazFrdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/JFawGLFMCJNDi/giphy.gif"
              alt="Update"
            />
          </Box>
          <br />
          <FormattedMessage id="label.newUpdateDescription" />
          <br />
          <strong>
            <FormattedMessage id="label.newUpdateDescriptionWarning" />
          </strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPostponeUpdate} variant="outlined">
          <FormattedMessage id="button.postponeUpdate" />
        </Button>
        <Button onClick={onUpdateApp} autoFocus>
          <FormattedMessage id="button.updateApp" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BasUpdateMessage;
