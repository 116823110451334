import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { ReactElement } from 'react';
import {
  RightSideWithTabsTemplate,
  RightSideWithTabsTemplateProps,
} from '../RightSideWithTabsTemplate';

const LoadingDetailsPageTemplate = ({
  ...args
}: Partial<RightSideWithTabsTemplateProps>): ReactElement => (
  <RightSideWithTabsTemplate
    {...args}
    header={[
      <Skeleton width={120} sx={{ borderRadius: '16px' }} height={28} />,
      <Typography color="textPrimary" variant="h3">
        <Skeleton variant="text" width={225} />
      </Typography>,
    ]}
    activeTab="1"
    tabs={[1, 2, 3].map((value) => ({
      label: <Skeleton variant="text" width={100} />,
      value: value.toString(),
      tabKey: value.toString(),
      content: (
        <Grid container spacing={4}>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={150} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={150} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant="subtitle2">
                  <Skeleton variant="text" width={150} />
                </Typography>
              </Box>
              <Typography>
                <Skeleton variant="text" width={50} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={75} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant="subtitle2">
                  <Skeleton variant="text" width={75} />
                </Typography>
              </Box>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={100} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ),
    }))}
  />
);

export default LoadingDetailsPageTemplate;
