import { isObjectResponse } from '@bas/value-objects';
import { ExternalContract } from '../types';

export function isExternalContract(
  object: unknown
): object is ExternalContract {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'ExternalContract'
  );
}
