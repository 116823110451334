import { isMileageReport, MileageReport } from '@bas/planning-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type MileageReportsByProjectIdRequestProps = Pagination &
  Filterable & {
    projectId: Uuid;
  };

type Response = AxiosResponse<Collection<MileageReport>>;

export const MileageReportsByProjectIdRequest = async ({
  projectId,
  ...params
}: MileageReportsByProjectIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/projects/${projectId}/mileage-reports`, {
    params,
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useMileageReportsByProjectIdRequest = (
  { projectId, ...request }: MileageReportsByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      MileageReportsByProjectIdRequest({ ...request, projectId }),
    queryKey: ['mileage-reports', 'list', projectId, ...Object.values(request)],
  });

export const usePrefetchMileageReportsByProjectIdRequest = ({
  projectId,
  ...request
}: MileageReportsByProjectIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'mileage-reports',
        'list',
        projectId,
        ...Object.values({ projectId, ...request }),
      ],
      queryFn: async () =>
        MileageReportsByProjectIdRequest({ projectId, ...request }),
    });
  }, [projectId, queryClient, request]);
};

export const MileageReportsByProjectIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isMileageReport(data)) {
    queryClient.invalidateQueries({
      queryKey: ['mileage-reports', 'list', data.projectId],
    });
  }
};
