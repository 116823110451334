import * as Yup from 'yup';
import {
  AccountingIntegrationSettingsInputType,
  AccountingIntegrationSettingsInputTypeDefaultValues,
  AccountingIntegrationSettingsInputTypeValidationBuilder,
} from '../accountingIntegrationSettingsInputType';

export type YukiSettingsInputType = AccountingIntegrationSettingsInputType & {
  administrationId: string;
};

export const YukiSettingsInputTypeDefaultValues =
  (): YukiSettingsInputType => ({
    ...AccountingIntegrationSettingsInputTypeDefaultValues(),
    administrationId: '',
  });

export const YukiSettingsInputTypeValidationBuilder =
  (): Yup.ObjectSchema<YukiSettingsInputType> =>
    Yup.object({
      administrationId: Yup.string().required().label('label.administration'),
    }).concat(AccountingIntegrationSettingsInputTypeValidationBuilder());
