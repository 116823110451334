import {
  ConnectExternalEmployeeInputType,
  ImportExternalEmployeeInputType,
  RejectExternalEmployeeInputType,
} from '@bas/integration-domain/input-types';
import {
  ExternalEmployee,
  Integration,
  SyncStatus,
} from '@bas/integration-domain/models';
import {
  useConnectExternalEmployeeMutation,
  useImportExternalEmployeeMutation,
  useRejectExternalEmployeeMutation,
} from '@bas/integration-domain/mutations';
import { useExternalEmployeesByIntegrationIdRequest } from '@bas/integration-domain/requests';
import {
  ConnectExternalEmployeesToExistingEmployeesFormDialog,
  ExtraExternalEmployeeInformation,
  ImportExternalEmployeesFormDialog,
  RejectExternalEmployeesFormDialog,
} from '@bas/integration-domain/web/organisms';
import { useExternalEmployeesTable } from '@bas/integration-domain/web/tables';
import { Button, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { DataGrid } from '@bas/ui/web/organisms';
import {
  faChain,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/pro-light-svg-icons';
import { Box, Grid } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export type ExternalEmployeesTabProps = {
  integration: Integration;
  attentionNeeded?: boolean;
};

const ExternalEmployeesTab = ({
  integration,
  attentionNeeded,
}: ExternalEmployeesTabProps): ReactElement => {
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [importingEmployeeIds, setImportingEmployeeIds] = useState<string[]>(
    []
  );
  const [connectingEmployeeIds, setConnectingEmployeeIds] = useState<string[]>(
    []
  );
  const [rejectingEmployeeIds, setRejectingEmployeeIds] = useState<string[]>(
    []
  );

  const {
    data: employeesData,
    isLoading: isLoadingEmployees,
    isFetching: isFetchingEmployees,
    refetch: refetchEmployees,
  } = useExternalEmployeesByIntegrationIdRequest({
    integrationId: integration.integrationId,
    syncStatus: attentionNeeded
      ? [
          SyncStatus.IN_PROGRESS,
          SyncStatus.PENDING,
          SyncStatus.FAILED,
          SyncStatus.AWAITING_APPROVAL,
        ]
      : undefined,
  });

  const employees = useMemo(
    () => employeesData?.data?.['hydra:member'] || [],
    [employeesData?.data]
  );

  const { mutateAsync: importExternalEmployee } =
    useImportExternalEmployeeMutation();

  const { mutateAsync: connectExternalEmployee } =
    useConnectExternalEmployeeMutation();

  const { mutateAsync: rejectExternalEmployee } =
    useRejectExternalEmployeeMutation();

  const handleImportEmployee = useCallback((employeeId: string) => {
    setImportingEmployeeIds([employeeId]);
  }, []);

  const handleConnectEmployee = useCallback((employeeId: string) => {
    setConnectingEmployeeIds([employeeId]);
  }, []);

  const handleRejectEmployee = useCallback((employeeId: string) => {
    setRejectingEmployeeIds([employeeId]);
  }, []);

  const dataGridProps = useExternalEmployeesTable({
    isLoading: isLoadingEmployees,
    isFetching: isFetchingEmployees,
    data: employees,
    selectedEmployeeIds,
    onImportEmployee: handleImportEmployee,
    onConnectEmployee: handleConnectEmployee,
    onRejectEmployee: handleRejectEmployee,
  });

  const handleSelectRows = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      const newValue: string[] = [];
      rowSelectionModel.forEach((employeeId) => {
        newValue.push(employeeId as string);
      });

      setSelectedEmployeeIds(newValue);
    },
    []
  );

  const handleRenderDetailPanelContent = useCallback(
    ({ row }: { row: ExternalEmployee }) => (
      <Box padding={3}>
        <ExtraExternalEmployeeInformation externalEmployee={row} />
      </Box>
    ),
    []
  );

  const handleBulkImport = useCallback(() => {
    setImportingEmployeeIds(selectedEmployeeIds);
  }, [selectedEmployeeIds]);

  const handleBulkConnect = useCallback(() => {
    setConnectingEmployeeIds(selectedEmployeeIds);
  }, [selectedEmployeeIds]);

  const handleBulkReject = useCallback(() => {
    setRejectingEmployeeIds(selectedEmployeeIds);
  }, [selectedEmployeeIds]);

  const handleImportingEmployees = useCallback(
    async ({
      externalEmployees,
    }: {
      externalEmployees: ImportExternalEmployeeInputType[];
    }) => {
      await Promise.all(
        externalEmployees.map(({ employeeSkills, ...values }) =>
          importExternalEmployee({
            integrationId: integration.integrationId,
            skills: employeeSkills,
            ...values,
          })
        )
      );

      setImportingEmployeeIds([]);
      refetchEmployees();
    },
    [refetchEmployees, importExternalEmployee, integration.integrationId]
  );

  const handleConnectingEmployees = useCallback(
    async ({
      externalEmployees,
    }: {
      externalEmployees: ConnectExternalEmployeeInputType[];
    }) => {
      await Promise.all(
        externalEmployees.map((externalEmployee) =>
          connectExternalEmployee({
            integrationId: integration.integrationId,
            externalId: externalEmployee.externalId,
            employeeId: externalEmployee.employeeId,
          })
        )
      );
      setConnectingEmployeeIds([]);
      refetchEmployees();
    },
    [refetchEmployees, connectExternalEmployee, integration.integrationId]
  );

  const handleRejectingEmployees = useCallback(
    async ({
      externalEmployees,
    }: {
      externalEmployees: RejectExternalEmployeeInputType[];
    }) => {
      await Promise.all(
        externalEmployees.map(({ externalId, reason }) =>
          rejectExternalEmployee({
            integrationId: integration.integrationId,
            externalId,
            reason,
          })
        )
      );

      setRejectingEmployeeIds([]);
      refetchEmployees();
    },
    [integration.integrationId, refetchEmployees, rejectExternalEmployee]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Tooltip title={<FormattedMessage id="button.importEmployees" />}>
            <Button
              onClick={handleBulkImport}
              size="small"
              variant="outlined"
              disabled={selectedEmployeeIds.length === 0}
            >
              <Icon icon={faThumbsUp} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              <FormattedMessage id="button.connectEmployeesToExistingEmployees" />
            }
          >
            <Button
              onClick={handleBulkConnect}
              size="small"
              variant="outlined"
              disabled={selectedEmployeeIds.length === 0}
            >
              <Icon icon={faChain} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={<FormattedMessage id="button.rejectEmployees" />}>
            <Button
              onClick={handleBulkReject}
              size="small"
              variant="outlined"
              disabled={selectedEmployeeIds.length === 0}
            >
              <Icon icon={faThumbsDown} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          {...dataGridProps}
          gridName="external-employees"
          onRowSelectionModelChange={handleSelectRows}
          getDetailPanelContent={handleRenderDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
        />
      </Grid>
      {importingEmployeeIds.length > 0 && (
        <ImportExternalEmployeesFormDialog
          open={importingEmployeeIds.length > 0}
          externalEmployees={employees.filter((employee) =>
            importingEmployeeIds.includes(employee.externalId)
          )}
          onSubmit={handleImportingEmployees}
          onClose={() => setImportingEmployeeIds([])}
        />
      )}
      {connectingEmployeeIds.length > 0 && (
        <ConnectExternalEmployeesToExistingEmployeesFormDialog
          open={connectingEmployeeIds.length > 0}
          externalEmployees={employees.filter((employee) =>
            connectingEmployeeIds.includes(employee.externalId)
          )}
          onSubmit={handleConnectingEmployees}
          onClose={() => setConnectingEmployeeIds([])}
        />
      )}
      {rejectingEmployeeIds.length > 0 && (
        <RejectExternalEmployeesFormDialog
          open={rejectingEmployeeIds.length > 0}
          externalEmployees={employees.filter((employee) =>
            rejectingEmployeeIds.includes(employee.externalId)
          )}
          onSubmit={handleRejectingEmployees}
          onClose={() => setRejectingEmployeeIds([])}
        />
      )}
    </Grid>
  );
};

export default ExternalEmployeesTab;
