import {
  InvoiceLine,
  isQuoteLine,
  QuoteLine,
} from '@bas/financial-domain/models';
import { colors } from '@bas/theme';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormattedCurrency,
  OptionalLabel,
} from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Discount, DocumentType, Money, VatAmount } from '@bas/value-objects';
import { Box, Grid, styled, Typography } from '@mui/material';
import { Fragment, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type MobileFinancialDocumentLinesContentSectionProps = {
  className?: string;
  document: {
    lines: (QuoteLine | InvoiceLine)[];
    totalIncludingVat: Money;
    totalExcludingVat: Money;
    vatAmounts: VatAmount[];
    discount?: Discount;
    discountAmount: Money;
  };
  documentType: DocumentType;
  onEdit: () => void;
  disableEditing?: boolean;
};

const MobileFinancialDocumentLinesContentSection = ({
  className,
  document: {
    lines,
    totalExcludingVat,
    totalIncludingVat,
    vatAmounts,
    discount,
    discountAmount,
  },
  documentType,
  onEdit,
  disableEditing,
}: MobileFinancialDocumentLinesContentSectionProps): ReactElement => (
  <ContentSection
    actions={
      disableEditing ? [] : [<ContentSectionEditAction onClick={onEdit} />]
    }
  >
    <Grid container rowSpacing={2} className={className}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <FormattedMessage
            id={`financial.${documentType}Details.${documentType}Lines`}
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={3}>
          <Grid
            item
            xs={12}
            className="Bas-MobileFinancialDocumentLinesContentSection-Lines"
          >
            <Box className="Bas-MobileFinancialDocumentLinesContentSection-LinesHolder">
              <Box sx={{ position: 'relative' }}>
                {lines.map((line) => (
                  <Accordion disableGutters key={line.lineId}>
                    <AccordionSummary>
                      <Grid container alignItems="center">
                        <Grid item xs={9}>
                          {line.description}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography textAlign="right">
                            <FormattedCurrency {...line.totalPrice} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container rowSpacing={2} alignItems="center">
                        {isQuoteLine(line) && (
                          <Grid item xs={12}>
                            {line.optional && <OptionalLabel />}
                          </Grid>
                        )}
                        <Grid item xs={4}>
                          <Typography variant="subtitle1">
                            <FormattedMessage id="label.quantity" />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'right' }}>
                          {`${line.quantity}x`}
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1">
                            <FormattedMessage id="label.pricePerUnit" />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'right' }}>
                          <FormattedCurrency {...line.pricePerUnit} />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1">
                            <FormattedMessage id="label.total" />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'right' }}>
                          <FormattedCurrency {...line.totalPrice} />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle1">
                            <FormattedMessage id="label.vat" />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'right' }}>
                          {`${line.vatPercentage.percentage}%`}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            {discount && discountAmount.amount !== totalExcludingVat.amount && (
              <>
                <Grid item xs={6}>
                  <Typography>
                    {`${discount.description} ${discount.percentage}%`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography textAlign="right">
                    <FormattedCurrency {...discountAmount} />
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography>
                <FormattedMessage id="label.subTotal" />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                <FormattedCurrency {...totalExcludingVat} />
              </Typography>
            </Grid>
            {vatAmounts
              .sort(
                ({ vatPercentage: vatA }, { vatPercentage: vatB }) =>
                  parseInt(vatA, 10) - parseInt(vatB, 10)
              )
              .map(({ vatPercentage, vatAmount }) => (
                <Fragment key={vatPercentage}>
                  <Grid item xs={6}>
                    <Typography>
                      {`${vatPercentage}% `}
                      <FormattedMessage id="label.vat" />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography textAlign="right">
                      <FormattedCurrency {...vatAmount} />
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            <Grid item xs={6}>
              <Typography fontWeight="bold">
                <FormattedMessage id="label.total" />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right" fontWeight="bold">
                <FormattedCurrency {...totalIncludingVat} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </ContentSection>
);

const StyledMobileFinancialDocumentLinesContentSection = styled(
  MobileFinancialDocumentLinesContentSection
)(
  ({ theme }) => `

.MuiAccordion-root {
  background: ${theme.palette.common.white};
  border: 1px solid ${colors.lila[300]};
  padding: ${theme.spacing(2)} 11px;
  box-sizing: border-box;

  margin-top: 0;
  margin-bottom: 0;

  &:not(:last-of-type) {
    margin-bottom: 6px !important;
  }

  &, &:first-of-type, &:last-of-type {
    border-radius: 5px;
  }

  .MuiAccordionSummary-root {
    min-height: unset;
    justify-content: flex-start;
  }

  .MuiTypography-subtitle1 {
    color: ${colors.lila[700]};
  }

  &:not(.Mui-expanded) {
    .MuiAccordionSummary-content {
      .MuiGrid-item {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;
  }

  .MuiAccordionDetails-root {
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(1)};
  }
}
`
);
export default StyledMobileFinancialDocumentLinesContentSection;
