import { colors, fontSizesWeb, lineHeights } from '@bas/theme';
import {
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type AutocompletePopperProps = MuiPopperProps;

const AutocompletePopper = ({ ...props }: AutocompletePopperProps) => (
  <MuiPopper {...props} />
);

AutocompletePopper.defaultProps = {};

const StyledAutocompletePopper = styled(AutocompletePopper)(
  ({ theme }) => `
  .MuiAutocomplete-paper {
    background: ${colors.lila[200]};
    padding: ${theme.spacing(2, 3)};
  }

  .MuiAutocomplete-listbox {
    padding: 0;
  }

  .MuiAutocomplete-option {
    padding: ${theme.spacing(1, 0)};
  }

  .MuiAutocomplete-option.Mui-focused, .MuiAutocomplete-option[aria-selected="true"], .MuiAutocomplete-option[aria-selected="true"].Mui-focused {
    background: inherit;
    color: ${colors.blue[500]};
  }

  .MuiAutocomplete-groupLabel {
    background: ${colors.lila[200]};
    color: ${colors.lila[800]};
    font-family: 'Work Sans', sans-serif;
    font-size: ${fontSizesWeb.sm};
    line-height: ${lineHeights.sm}px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding: ${theme.spacing(1, 0, 1, 0)};
  }

  .MuiAutocomplete-groupUl {
    padding-bottom: ${theme.spacing(2)};
  }

  .MuiAutocomplete-option[aria-selected="true"], .MuiAutocomplete-option[aria-selected="true"].Mui-focused {
    background: inherit;
    font-weight: bold;
  }
`
);
export default StyledAutocompletePopper;
