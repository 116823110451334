import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basSandbar: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'sandbar',
  icon: [
    500,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M291.3 162.82L364.8 35.51c4.144-7.174 13.317-9.632 20.492-5.49 7.174 4.142 9.632 13.316 5.49 20.49l-73.502 127.31 22.153 12.789c12.307 7.106 16.224 23.03 8.617 35.034l-22.8 35.98a19.998 19.998 0 0112.774 8.741l56.454 87.417 40.983-35.866c10.993-9.62 28.255-4.594 32.365 9.424l30.355 103.531c4.07 10.522 7.048 27.5-2.844 42.569-10.359 15.779-30.914 23.583-60.79 23.583H67.525c-.271 0-.542-.007-.813-.022-11.513-.625-28.816-4.746-41.325-16.874-6.528-6.329-11.598-14.733-13.546-25.316-1.92-10.441-.626-22.012 3.865-34.6 15.05-42.182 51.91-156.271 69.091-210.018 1.544-4.831 4.796-8.708 8.933-11.13l66.424-38.888c8.334-4.879 18.963-3.106 25.263 4.212L210.05 197l25.726-39.065c7.28-11.055 21.916-14.519 33.38-7.901L291.3 162.82zm-60.897 57.821l34.928 40.575h24.66l29.93-47.23-61.684-35.613-27.834 42.268zm-62.284-26.364l-55.925 32.741c-17.59 54.974-53.316 165.471-68.23 207.274-3.171 8.886-3.365 15.02-2.616 19.09.723 3.927 2.48 6.838 4.922 9.205 5.34 5.176 14.33 7.96 21.698 8.435h366.58c26.088 0 33.645-6.902 35.711-10.047 2.297-3.5 2.39-9.213-.194-15.627a15.031 15.031 0 01-.482-1.386L443.442 354.8 405.53 387.98c-9.182 8.034-23.355 6.048-29.974-4.2l-59.779-92.563h-55.032a20 20 0 01-15.158-6.952l-77.468-89.988z',
  ],
};

export default basSandbar;
