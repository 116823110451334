import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import { RRule } from 'rrule';
import { Event } from '../types';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export const isAllDayEvent = (event: Event, dayToCheck?: Date): boolean => {
  if (event.fullDay) {
    return event.fullDay;
  }

  if (
    dayjs(event.start).isSame(dayjs(event.start).startOf('day'), 'minute') &&
    dayjs(event.end).isSame(dayjs(event.end).endOf('day'), 'minute')
  ) {
    return true;
  }

  return !!(
    dayToCheck &&
    dayjs(event.start).isSameOrBefore(
      dayjs(dayToCheck).startOf('day'),
      'minute'
    ) &&
    dayjs(event.end).isSameOrAfter(dayjs(dayToCheck).endOf('day'), 'minute')
  );
};

export const createEventObjectForRecurringDate = (
  event: Event,
  date: Date
): Event => {
  const timesObject = {
    travelStart: event.travelStart,
    travelEnd: event.travelEnd,
    start: event.start,
    end: event.end,
  };

  Object.keys(timesObject).forEach((key) => {
    if (timesObject[key as keyof typeof timesObject]) {
      const dayjsDate = dayjs(date);
      timesObject[key as keyof typeof timesObject] = dayjs(
        timesObject[key as keyof typeof timesObject]
      )
        .set('date', dayjsDate.date())
        .set('month', dayjsDate.month())
        .set('year', dayjsDate.year())
        .toDate();
    }
  });

  return {
    ...event,
    ...timesObject,
  };
};

export const calculateRecurringEventsWithingPeriod = (
  event: Event,
  fromDate: Date,
  untilDate: Date
): Event[] => {
  if (
    !event.recurringInformation?.recurring ||
    !event.recurringInformation?.rrule
  ) {
    return [event];
  }

  const rrule = RRule.fromString(event.recurringInformation.rrule);
  let firstFromDate = fromDate;
  if (dayjs(fromDate).isBefore(dayjs(event.recurringInformation.repeatFrom))) {
    firstFromDate = dayjs(event.recurringInformation.repeatFrom).toDate();
  }

  let lastUntilDate = untilDate;
  if (dayjs(untilDate).isAfter(dayjs(event.recurringInformation.repeatUntil))) {
    lastUntilDate = dayjs(event.recurringInformation.repeatUntil).toDate();
  }

  const dates = rrule.between(
    dayjs(firstFromDate).startOf('day').utc(true).toDate(),
    dayjs(lastUntilDate).endOf('day').utc(true).toDate(),
    true
  );

  return dates.map((date) => createEventObjectForRecurringDate(event, date));
};
