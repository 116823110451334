import { WhatsAppPhoneNumberNameStatus } from '@bas/integration-domain/models';
import { Chip } from '@bas/ui/web/atoms';
import { Badge } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type WhatsAppPhoneNumberNameStatusChipProps = {
  phoneNumberStatus: WhatsAppPhoneNumberNameStatus;
};

const WhatsAppPhoneNumberNameStatusChip = ({
  phoneNumberStatus,
}: WhatsAppPhoneNumberNameStatusChipProps): ReactElement => {
  const categories: {
    [key: string]: {
      color:
        | 'primary'
        | 'secondary'
        | 'default'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    };
  } = useMemo(
    () => ({
      [WhatsAppPhoneNumberNameStatus.APPROVED]: {
        color: 'success',
      },
      [WhatsAppPhoneNumberNameStatus.EXPIRED]: {
        color: 'error',
      },
      [WhatsAppPhoneNumberNameStatus.PENDING_REVIEW]: {
        color: 'warning',
      },
      [WhatsAppPhoneNumberNameStatus.NONE]: {
        color: 'error',
      },
    }),
    []
  );

  const category = categories[phoneNumberStatus];
  if (typeof category !== 'undefined') {
    return (
      <Chip
        sx={{ width: '100%' }}
        label={
          <>
            <Badge color={category.color} badgeContent=" " variant="dot" />
            <span style={{ paddingLeft: '8px' }}>
              <FormattedMessage
                id={`whatsAppPhoneNumberNameStatus.${phoneNumberStatus}`}
              />
            </span>
          </>
        }
      />
    );
  }

  return (
    <Chip
      sx={{ width: '100%' }}
      label={
        <FormattedMessage
          id={`whatsAppPhoneNumberNameStatus.${phoneNumberStatus}`}
        />
      }
    />
  );
};

export default WhatsAppPhoneNumberNameStatusChip;
