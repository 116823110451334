import { create, useStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { createContext, useContext } from 'react';
import { StoreApi } from 'zustand/esm/vanilla';
import { zustandStorage } from './zustandStorage';

export type FillingInRoomWizardStoreType = {
  wizardKey: string;
  hasFurniture: boolean;
  hasFurnitureWithServicesPossible: boolean;
  setHasFurniture: (hasFurniture: boolean) => void;
  setHasFurnitureWithServicesPossible: (
    hasFurnitureWithServicesPossible: boolean
  ) => void;
};

export const createFillingInRoomWizardStore = (
  wizardKey: string,
  persistStore = false
) =>
  create<FillingInRoomWizardStoreType>()(
    persist(
      (set, get) => ({
        wizardKey,
        hasFurniture: false,
        hasFurnitureWithServicesPossible: false,
        setHasFurniture: (hasFurniture: boolean) => {
          if (get().hasFurniture !== hasFurniture) {
            set({ hasFurniture });
          }
        },
        setHasFurnitureWithServicesPossible: (
          hasFurnitureWithServicesPossible: boolean
        ) => {
          if (
            get().hasFurnitureWithServicesPossible !==
            hasFurnitureWithServicesPossible
          ) {
            set({ hasFurnitureWithServicesPossible });
          }
        },
      }),
      {
        version: 1,
        merge: (persistedState, initialState) => {
          if (!persistedState) {
            return { ...initialState, loadedData: true };
          }

          return { ...initialState, ...persistedState, loadedData: true };
        },
        name: `filling-in-room-wizard-store-${wizardKey}`,
        storage: persistStore
          ? zustandStorage<FillingInRoomWizardStoreType>()
          : {
              getItem: () => Promise.resolve(null),
              setItem: () => Promise.resolve(),
              removeItem: () => Promise.resolve(),
            },
      }
    )
  );

export const FillingInRoomWizardContext = createContext<
  StoreApi<FillingInRoomWizardStoreType>
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  null as any
);

export const useFillingInRoomWizardStore = () => {
  const context = useContext(FillingInRoomWizardContext);

  return useStore(context);
};
