import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basPatioHeater: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'patio-heater',
  icon: [
    512,
    512,

    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M145 138.5c-4.1 7.2-1.6 16.4 5.6 20.5s16.4 1.6 20.5-5.6c13-22.6 33.6-40.3 58.2-49.4c7.8-2.9 11.7-11.5 8.9-19.3s-11.5-11.7-19.3-8.9c-31.3 11.6-57.4 34-73.8 62.6zm286 82c8.3 0 15-6.7 15-15C446 100.3 360.7 15 255.5 15S65 100.3 65 205.5c0 8.3 6.7 15 15 15l97.6 0 14.5 121.3c.9 7.5 7.3 13.2 14.9 13.2h34V474H211.3c-2 0-3.8 1.2-4.6 3.1l-11.8 28c-1.4 3.3 1 6.9 4.6 6.9H313.5c3.6 0 6-3.6 4.6-6.9l-11.8-28c-.8-1.9-2.6-3.1-4.6-3.1H271V355h33c7.6 0 14-5.7 14.9-13.2l14.5-121.3 97.6 0zM255.5 45c83.6 0 152.2 63.9 159.8 145.5l-319.6 0C103.3 108.9 171.9 45 255.5 45zm35.2 280H220.3L207.9 221h95.2L290.7 325z',
  ],
};

export default basPatioHeater;
