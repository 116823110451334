import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const basRoofbox: IconDefinition = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefix: 'bas',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconName: 'roofbox',
  icon: [
    512,
    512,
    ['fillRule:evenodd', 'clipRule:evenodd'],
    'e001',
    'M124.92 159.005c-37.274 0-66.227 4.99-85.74 20.145-20.647 16.037-27.186 40.416-27.185 69.397a15 15 0 0010.452 14.293l64.308 20.462c1.47.469 3.005.707 4.548.707h394.62c7.801 0 14.301-5.98 14.949-13.755 1.533-18.406-9.664-51.187-52.996-63.837-41.381-12.955-163.066-47.412-322.957-47.412zm-31.288 95.003L42.4 237.706c1.456-18.139 6.795-28.348 15.183-34.863 11.066-8.595 31.075-13.837 67.336-13.837 155.928.001 274.502 33.67 314.135 46.085l.12.037.18.054c15.444 4.484 23.522 12.013 27.637 18.826H93.632zm-62.089 49.708c-7.894-2.513-16.33 1.85-18.842 9.746-2.512 7.894 1.851 16.33 9.746 18.841l64.308 20.462c1.47.469 3.005.707 4.548.707h394.62c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15H93.633l-62.09-19.756z',
  ],
};

export default basRoofbox;
