import {
  ConnectIntegrationInputType,
  ConnectSchoutenZekerheidIntegrationInputType,
  ConnectSchoutenZekerheidIntegrationInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { Integration } from '@bas/integration-domain/models';
import { useConnectAuthToIntegrationMutation } from '@bas/integration-domain/mutations';
import { ConnectSchoutenZekerheidForm } from '@bas/integration-domain/web/molecules';
import {
  ContentSection,
  ContentSectionCancelAction,
  ContentSectionEditAction,
  ContentSectionSaveAction,
  ReactHookForm,
} from '@bas/ui/web/molecules';
import { ProviderType } from '@bas/value-objects';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export type SchoutenZekerheidSettingsTabProps = {
  integration: Integration;
};

const SchoutenZekerheidSettingsTab = ({
  integration,
}: SchoutenZekerheidSettingsTabProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutateAsync: connectAuthToIntegration } =
    useConnectAuthToIntegrationMutation();

  const handleUpdateAuthentication = useCallback(
    async (values: ConnectSchoutenZekerheidIntegrationInputType) => {
      await connectAuthToIntegration({
        integrationId: integration.integrationId,
        tenantId: integration.tenantId,
        authentication: {
          ...values,
          provider: values.apiKeyPerIdentity
            ? 'schouten-zekerheid-per-identity'
            : 'schouten-zekerheid',
        },
      });
    },
    [integration.tenantId, integration.integrationId, connectAuthToIntegration]
  );

  if (isEditing) {
    return (
      <ReactHookForm<
        | ConnectSchoutenZekerheidIntegrationInputType
        | (ConnectIntegrationInputType & {
            apiKey: string;
            apiKeyPerIdentity: boolean;
            apiKeys: string[];
            provider: ProviderType;
          })
      >
        name="edit-settings"
        onSubmit={async (values) => {
          await handleUpdateAuthentication(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          apiKey: '',
          apiKeyPerIdentity: false,
          apiKeys: [],
          provider: ProviderType.SCHOUTEN_ZEKERHEID,
          ...integration.authentication,
        }}
        validationSchema={ConnectSchoutenZekerheidIntegrationInputTypeValidationBuilder()}
      >
        <ContentSection
          editing
          actions={[
            <ContentSectionCancelAction onClick={() => setIsEditing(false)} />,
            <ContentSectionSaveAction />,
          ]}
        >
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} pt={4}>
              <Typography variant="subtitle1">
                <FormattedMessage id="label.settings" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ConnectSchoutenZekerheidForm />
            </Grid>
          </Grid>
        </ContentSection>
      </ReactHookForm>
    );
  }

  return (
    <ContentSection
      actions={[
        <ContentSectionEditAction onClick={() => setIsEditing(true)} />,
      ]}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {integration.authentication?.connected ? (
              <FormattedMessage id="label.connected" />
            ) : (
              <FormattedMessage id="label.notConnected" />
            )}
          </Typography>
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default SchoutenZekerheidSettingsTab;
