import { useAppStore, useAuthStore } from '@bas/shared/state';
import { ConfirmDialog } from '@bas/ui/web/organisms';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const timeout = 2_600_000;
const promptBeforeIdle = 1_800_000;

const IdleTimer = ({ children }: { children: ReactNode }): ReactElement => {
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const appState = useAppStore();
  const { setInitialPath, ...authState } = useAuthStore();

  const onIdle = () => {
    if (authState.userId) {
      setOpen(false);
      setInitialPath(window.location.pathname);
      navigate('/logout');
    }
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    if (authState.userId) {
      setOpen(true);
    }
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    syncTimers: 200,
    crossTab: true,
    leaderElection: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
    setOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        open={open && !appState.sessionBlocked}
        onCancel={() => navigate('logout')}
        onConfirm={handleStillHere}
        title={<FormattedMessage id="label.areYouStillHere" />}
        content={
          <FormattedMessage
            id="label.areYouStillHereTheSessionIsAboutToExpire"
            values={{ remaining: Math.ceil(remaining / 60) }}
          />
        }
      />

      {children}
    </>
  );
};

export default IdleTimer;
