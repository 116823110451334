import { isSession, Session } from '@bas/authentication-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SessionBySessionIdRequestProps = {
  sessionId: Uuid;
  userId: Uuid;
};

export const SessionBySessionIdRequest = async ({
  userId,
  sessionId,
  ...params
}: SessionBySessionIdRequestProps): Promise<AxiosResponse<Session>> =>
  axios.get(`api/users/${userId}/sessions/${sessionId}`, {
    params,
  });

export const useSessionBySessionIdRequestQuery = (
  request: SessionBySessionIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Session>,
    AxiosError,
    AxiosResponse<Session>
  > = {}
): UseQueryResult<AxiosResponse<Session>, AxiosError> =>
  useQuery<AxiosResponse<Session>, AxiosError, AxiosResponse<Session>>({
    ...options,
    queryFn: async () => SessionBySessionIdRequest(request),
    queryKey: ['sessions', 'detail', request.sessionId],
  });

export const SessionBySessionIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isSession(data)) {
    queryClient.setQueryData<AxiosResponse<Session>>(
      ['sessions', 'detail', data.sessionId],
      (old) => {
        if (old) {
          return {
            ...old,
            data: {
              ...old.data,
              ...data,
            },
          };
        }

        return undefined;
      }
    );
  }
};
