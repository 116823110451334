import { EventType } from '../types';

export const movingEventTypes = [
  EventType.MOVING_EVENT,
  EventType.LOAD_STORAGE_EVENT,
  EventType.UNLOAD_STORAGE_EVENT,
];

export const transportEventTypes = [
  EventType.TRANSPORT_JOB_PICKUP,
  EventType.TRANSPORT_JOB_DELIVER,
];

export const eventsWithoutLocation = [
  EventType.MOVING_EVENT,
  EventType.DAY_OFF_EVENT,
  EventType.HRM_OTHER_ABSENCE_EVENT,
  EventType.SICK_EVENT,
  EventType.VACATION_EVENT,
  EventType.DRIVING_DAY,
];

export const eventsWithoutDepartureLocation = [
  EventType.DAY_OFF_EVENT,
  EventType.HRM_OTHER_ABSENCE_EVENT,
  EventType.SICK_EVENT,
  EventType.VACATION_EVENT,
  EventType.INSPECTION_EVENT,
  EventType.MAINTENANCE_EVENT,
  EventType.MATERIAL_OTHER_ABSENCE_EVENT,
  EventType.DRIVING_DAY,
];

export const eventsWithOptionalLocation = [
  EventType.OFFICE_WORK_EVENT,
  EventType.WAREHOUSE_WORK_EVENT,
];

export const eventsWithoutShowingAddresses = [
  EventType.DELIVER_BOXES_EVENT,
  EventType.PICKUP_BOXES_EVENT,
  EventType.DISASSEMBLE_EVENT,
  EventType.ASSEMBLE_EVENT,
];

export const eventsWithoutPage = [
  EventType.DAY_OFF_EVENT,
  EventType.HRM_OTHER_ABSENCE_EVENT,
  EventType.SICK_EVENT,
  EventType.VACATION_EVENT,
  EventType.INSPECTION_EVENT,
  EventType.MAINTENANCE_EVENT,
  EventType.MATERIAL_OTHER_ABSENCE_EVENT,
];

export const eventTypesWithoutName = [
  EventType.DAY_OFF_EVENT,
  EventType.HRM_OTHER_ABSENCE_EVENT,
  EventType.SICK_EVENT,
  EventType.VACATION_EVENT,
  EventType.INSPECTION_EVENT,
  EventType.MAINTENANCE_EVENT,
  EventType.MATERIAL_OTHER_ABSENCE_EVENT,
  EventType.OFFICE_WORK_EVENT,
  EventType.WAREHOUSE_WORK_EVENT,
];

export const eventTypesThatAreLessImportant = [
  EventType.DAY_OFF_EVENT,
  EventType.HRM_OTHER_ABSENCE_EVENT,
  EventType.SICK_EVENT,
  EventType.VACATION_EVENT,
  EventType.INSPECTION_EVENT,
  EventType.MAINTENANCE_EVENT,
  EventType.MATERIAL_OTHER_ABSENCE_EVENT,
  EventType.OFFICE_WORK_EVENT,
  EventType.WAREHOUSE_WORK_EVENT,
];

export const eventTypesThatUnavailable = [
  EventType.DAY_OFF_EVENT,
  EventType.HRM_OTHER_ABSENCE_EVENT,
  EventType.SICK_EVENT,
  EventType.VACATION_EVENT,
  EventType.INSPECTION_EVENT,
  EventType.MAINTENANCE_EVENT,
  EventType.MATERIAL_OTHER_ABSENCE_EVENT,
];
