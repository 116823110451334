import { Employee } from '@bas/hrm-domain/models';
import { Icon } from '@bas/ui/web/base';
import { EmployeeProjectRole } from '@bas/value-objects';
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, AvatarSizeType } from '@bas/ui/web/atoms';
import { ContentSection } from '@bas/ui/web/molecules';

export type PlannedEmployeeItemProps = {
  employee: Employee;
  projectRole: EmployeeProjectRole;
  className?: string;
  onChange?: () => void | Promise<void>;
  onRemove?: () => void | Promise<void>;
};

const PlannedEmployeeItem = ({
  employee: {
    profilePictureUrl,
    personName: { firstName, lastName, fullName },
  },
  projectRole,
  className,
  onChange,
  onRemove,
}: PlannedEmployeeItemProps): ReactElement => {
  const actions = [];
  if (onChange) {
    actions.push(
      <IconButton onClick={onChange}>
        <Icon icon={faPencil} />
      </IconButton>
    );
  }
  if (onRemove) {
    actions.push(
      <IconButton onClick={onRemove}>
        <Icon icon={faTrashCan} />
      </IconButton>
    );
  }

  return (
    <ContentSection className={className} actions={actions}>
      <Box className="Bas-PlannedEmployeeItem-root">
        <Box className="Bas-PlannedEmployeeItem-Avatar">
          <Avatar
            size={AvatarSizeType.SMALL}
            src={profilePictureUrl}
            name={`${firstName} ${lastName}`}
            shortName={lastName}
          />
        </Box>
        <Grid container className="Bas-PlannedEmployeeItem-NameBlock">
          <Grid item xs={12} className="Bas-PlannedEmployeeItem-Role">
            <Typography>
              <FormattedMessage id={`projectRoles.${projectRole}`} />
            </Typography>
          </Grid>
          <Grid item xs={12} className="Bas-PlannedEmployeeItem-Name">
            <Typography>{firstName || fullName}</Typography>
          </Grid>
        </Grid>
      </Box>
    </ContentSection>
  );
};

const StyledPlannedEmployeeItem = styled(PlannedEmployeeItem)(
  ({ theme }) => `
  .Bas-ContentSection-Actions {
    top: -${theme.spacing(0)};
    right: ${theme.spacing(0)};
  }

  &.Bas-ContentSection-HasActions {
    &:hover, &.Bas-ContentSection-Editing {
      &:before {
        left: -13px;
        right: -13px;
        top: -${theme.spacing(1)};
        bottom: -${theme.spacing(1)};
      }
    }
  }
  .Bas-PlannedEmployeeItem-root {
    display: flex;
    flex-direction: row;
    .Bas-PlannedEmployeeItem-Avatar {
      padding-right: 12px;
    }

    .Bas-PlannedEmployeeItem-Role .MuiTypography-root {
      color: ${theme.palette.common.black};
    }

    .Bas-PlannedEmployeeItem-Name .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
    }
  }
`
);
export default StyledPlannedEmployeeItem;
